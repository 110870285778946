import React from "react";
import { Rings } from "react-loader-spinner";
import styled from "styled-components";
import i18n from "../i18nextConf";

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(152, 169, 188, 0.3);
  backdrop-filter: blur(46.2108px);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 370px;
  background-color: #ffffff;
  border: 1px solid white;
  border-radius: 20px;
`;
const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #089bab;
  margin: 0;
  text-align: center;
`;
const WaitIndicator = ({ message }) => {
  return (
    <Overlay>
      <Background>
        <Rings color="#089BAB" height={150} width={150} />
        <Text>{message ? message : i18n.t("defaultWarning", {ns: "waitIndicator"})}</Text>
      </Background>
    </Overlay>
  );
};

export default WaitIndicator;
