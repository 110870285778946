import React from "react";
// import { Browser } from "@syncfusion/ej2-base";
import { ReactComponent as Square } from "../../../../assets/square.svg";
import "./CanisterTrayRatioGraph.css";

import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationDataLabel,
  AccumulationTooltip,
  AccumulationSelection,
  Inject,
  Selection,
  AccumulationAnnotationsDirective,
  AccumulationAnnotationDirective,
  ChartAnnotation,
  AccumulationAnnotation,
  DataLabel,
} from "@syncfusion/ej2-react-charts";

import styles from "./PouchesPerHourGraph.module.css";
import { useTranslation } from "react-i18next";

function CanisterTrayRatioGraph({
  data,
  fetchingData,
  palette,
  timeRange,
  onTimeRange,
  onShowMedicationsDispensed,
  onShowCanisterMedicationsDispensed,
  onShowTrayMedicationsDispensed,
  onShowDropdowns,
}) {
  const { t } = useTranslation("trupakDashboard");

  const onChartMouseClick = (args) => {
    if (
      args.target === "pie-chart-legend_chart_legend_shape_0" ||
      args.target === "pie-chart-legend_chart_legend_text_0" ||
      args.target === "pie-chart-legend_datalabel_Series_0_text_0" ||
      args.target === "pie-chart-legend_Series_0_Point_0" ||
      args.target === "pie-chart-legend_chart_legend_shape_1" ||
      args.target === "pie-chart-legend_chart_legend_text_1" ||
      args.target === "pie-chart-legend_datalabel_Series_0_text_1" ||
      args.target === "pie-chart-legend_Series_0_Point_1"
    ) {
      onShowMedicationsDispensed(true);
      onShowDropdowns(false);
    } else if ((args.name = "chartMouseClick")) {
      onShowDropdowns(true);
    }
  };

  // let legendContent = Browser.isDevice
  //   ? " "
  //   : "<div style='font-Weight:600;font-size:14px'>Browser<br>Market<br>Share</div>";

  const SAMPLE_CSS_PIE_CHART_LEGEND = `
    .control-fluid {
        padding: 0px !important;
    }
    .pie-chart-legend {
        align :center
    }`;

  const Doughnut = () => {
    const load = (args) => {
      let selectedTheme = window.location.hash.split("/")[1];
      selectedTheme = selectedTheme ? selectedTheme : "Material";
      args.accumulation.theme = (
        selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
      )
        .replace(/-dark/i, "Dark")
        .replace(/light/i, "Light")
        .replace(/contrast/i, "Contrast");
    };

    const handleStopPropagation = (e) => {
      e.stopPropagation();
    };

    return (
      <div className="control-pane">
        <style>{SAMPLE_CSS_PIE_CHART_LEGEND}</style>
        <div className="control-section">
          <AccumulationChartComponent
            id="pie-chart-legend"
            chartMouseClick={onChartMouseClick.bind(this)}
            title={t("canisterTrayTitle")}
            style={{ width: "450px", height: "288px" }}
            load={load.bind(this)}
            legendSettings={{
              visible: true,
              toggleVisibility: false,
              position: "Right",
              height: "30%",
              width: "30%",
              // textWrap: "Wrap",
              maximumLabelWidth: 86,
            }}
            enableSmartLabels={true}
            enableAnimation={false}
            selectionMode={"Point"}
            center={{ x: "50%", y: "50%" }}
            enableBorderOnMouseMove={false}
            tooltip={{
              enable: false,
              // format: "<b>${point.x}</b><br>Browser Share: <b>${point.y}%</b>",
              header: "",
            }}
          >
            <Inject
              services={[
                AccumulationLegend,
                PieSeries,
                AccumulationDataLabel,
                AccumulationTooltip,
                AccumulationSelection,
                Selection,
                ChartAnnotation,
                AccumulationAnnotation,
                DataLabel,
              ]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={data}
                palettes={palette}
                xName="x"
                yName="y"
                explode={false}
                explodeOffset="10%"
                explodeIndex={0}
                startAngle={90}
                innerRadius="0%"
                dataLabel={{
                  visible: true,
                  position: "Inside",
                  name: "text",
                  font: { fontWeight: "600", color: "#ffffff" },
                  connectorStyle: { length: "20px", type: "Curve" },
                }}
                radius="80%"
              />
            </AccumulationSeriesCollectionDirective>
            <AccumulationAnnotationsDirective>
              <AccumulationAnnotationDirective
                // content={legendContent}
                region="Series"
                x="52%"
                y="50%"
              />
            </AccumulationAnnotationsDirective>
          </AccumulationChartComponent>
        </div>

        <div
          className={styles.timeRangeContainer}
          onClick={handleStopPropagation}
        >
          <div
            className={styles.timeRangeOption}
            onClick={fetchingData ? () => {} : () => onTimeRange("year")}
          >
            <div
              className={
                timeRange === "year"
                  ? `${styles.squareContainer} ${styles["squareContainer--active"]}`
                  : styles.squareContainer
              }
            >
              <Square />
            </div>
            <p>{t("lastYearLabel")}</p>
          </div>
          <div
            className={styles.timeRangeOption}
            onClick={fetchingData ? () => {} : () => onTimeRange("month")}
          >
            <div
              className={
                timeRange === "month"
                  ? `${styles.squareContainer} ${styles["squareContainer--active"]}`
                  : styles.squareContainer
              }
            >
              <Square />
            </div>
            <p>{t("lastMonthLabel")}</p>
          </div>
          <div
            className={styles.timeRangeOption}
            onClick={fetchingData ? () => {} : () => onTimeRange("week")}
          >
            <div
              className={
                timeRange === "week"
                  ? `${styles.squareContainer} ${styles["squareContainer--active"]}`
                  : styles.squareContainer
              }
            >
              <Square />
            </div>
            <p>{t("lastWeekLabel")}</p>
          </div>
          <div
            className={styles.timeRangeOption}
            onClick={fetchingData ? () => {} : () => onTimeRange("day")}
          >
            <div
              className={
                timeRange === "day"
                  ? `${styles.squareContainer} ${styles["squareContainer--active"]}`
                  : styles.squareContainer
              }
            >
              <Square />
            </div>
            <p>{t("lastDayLabel")}</p>
          </div>
          <div
            className={styles.timeRangeOption}
            onClick={fetchingData ? () => {} : () => onTimeRange("12 hours")}
          >
            <div
              className={
                timeRange === "12 hours"
                  ? `${styles.squareContainer} ${styles["squareContainer--active"]}`
                  : styles.squareContainer
              }
            >
              <Square />
            </div>
            <p>{t("last12HoursLabel")}</p>
          </div>
        </div>
      </div>
    );
  };

  return Doughnut();
}

export default CanisterTrayRatioGraph;
