import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import React, { useState, useRef } from "react";
import "../SFDropDownList/SFDropDownList.css";
import "./MultiSelectCheckbox.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as FilterIcon } from "../../assets/funnel.svg";

// EXAMPLE OF USE

// checkboxId = "checkbox1"

// dataSource = [{ id: "device_id1", device: "device1" }, { id: "device_id2", device: "device2" }]

// fields = { value: "id", text: "device" }

// placeHolder="Select device(s)"

// onSelections = (arr) => setSelectedDevices(arr);

/****************************
<MultiSelectCheckbox
  checkboxId="checkbox1"
  dataSource={devices}
  fields={{ text: "device", value: "id" }}
  onSelections={handleSelectedDevices}
/>;
****************************/

const MultiSelectCheckbox = ({
  checkboxId,
  dataSource,
  fields,
  placeHolder,
  value,
  onChanged,
  onSelected,
  onRemoved,
  asFunnelIcon,
  centerPopup,
  selectAll,
  unselectAll,
  noRecordsTemplate,
}) => {
  const { t, i18n } = useTranslation("multiSelectCheckbox");
  const multiSelectRef = useRef(null);

  const handleChange = (args) => {
    onChanged(args.value);
  };

  const handleSelect = (args) => {
    onSelected(args.itemData[fields.value]);
  };

  const handleRemoved = (args) => {
    onRemoved(args.itemData[fields.value]);
  };

  const toggleDropdown = () => {
    if (multiSelectRef.current) {
      multiSelectRef.current.showPopup();
    }
  };

  return (
    <div
      className={`multiselect-container ${
        asFunnelIcon ? "as-funnel-icon" : ""
      } ${centerPopup ? "center-popup" : ""}`}
    >
      {asFunnelIcon && (
        <div className="funnel-icon-wrapper" onClick={toggleDropdown}>
          <FilterIcon
            width="16px"
            height="16px"
            color={
              value?.length === dataSource?.length ? "black" : "var(--green)"
            }
          />
        </div>
      )}
      <MultiSelectComponent
        ref={multiSelectRef}
        id={checkboxId}
        dataSource={dataSource}
        fields={fields}
        placeholder={placeHolder}
        value={value || []}
        mode="CheckBox"
        selectAllText={selectAll || t("selectAll")}
        unSelectAllText={unselectAll || t("unselectAll")}
        noRecordsTemplate={noRecordsTemplate || t("noRecordsTemplate")}
        showSelectAll={true}
        {...(onSelected && onRemoved
          ? { select: handleSelect, removed: handleRemoved }
          : {})}
        {...(onChanged ? { change: handleChange } : {})}
        locale={i18n.language}
        allowFiltering={!asFunnelIcon}
        cssClass={asFunnelIcon ? "as-funnel-icon" : ""}
        width={asFunnelIcon ? "300px" : "100%"}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

export default MultiSelectCheckbox;
