import React from "react";
import Spacer from "./Spacer";
import checkmarkImage from "../assets/checkmark.svg";
import styles from "./Checkbox.module.css";

const Checkbox = ({ labelName, labelColor = "", onCheck, isOn, disabled }) => {
  return (
    <div className={"row"}>
      <button
        className={`${styles.Checkbox__button} ${isOn ? styles.checked : ""} ${disabled ? styles.disabled : ""}`}
        onClick={(e) => {
          if (disabled) return;
          let val = !isOn;
          onCheck(val, e);
        }}
      >
        {isOn && <img src={checkmarkImage} alt={"checkbox is checked"} />}
      </button>
      <Spacer space={10} unit={"px"} />
      <label
        className={`${styles.Checkbox__label}  ${disabled ? styles.disabled : ""}`}
        style={{ color: !disabled && labelColor ? labelColor : undefined }}
      >
        {labelName}
      </label>
    </div>
  );
};

export default Checkbox;
