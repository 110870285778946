import { Button } from "antd";
import "./AntButton.css";

/* 
  Example of use
    <AntButton
      onClick={handleProcessOrder}
      text="Process Order"
      buttonstyle="ant-button-001"
      disabled={!dataValidated}
    />
*/

const AntButton = ({ text, buttonstyle, onClick, disabled, size }) => {
  let className;
  if (disabled) {
    className = `${buttonstyle} ${buttonstyle}--disabled`;
  } else {
    className = buttonstyle;
  }
  return (
    <div className={className || null}>
      <Button
        onClick={onClick}
        disabled={disabled || false}
        buttonstyle="ant-button-001"
        size={size || "small"}
      >
        {text}
      </Button>
    </div>
  );
};

export default AntButton;
