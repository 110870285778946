import axios from "axios";
import ApiService from "./ApiService";

class AccountService extends ApiService {
  static create = async (user, account) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const url = `${ApiService.BASE_URL}/accounts`;
      let response = await axios.post(url, account, rc);
      return response.data; // account document
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static exists = async (user, companyName) => {
    try {
      const rc = {
        headers: { "x-access-token": user?.token },
      };
      const url = `${
        ApiService.BASE_URL
      }/accounts/exists?companyName=${encodeURIComponent(companyName)}`;
      let response = await axios.get(url, rc);
      return response.data.exists;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static accountList = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const url = `${ApiService.BASE_URL}/accounts`;
      const response = await axios.get(url, rc);
      return response.data.accounts;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static put = async (user, account) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      const url = `${ApiService.BASE_URL}/accounts/${user.account._id}`;
      const data = {
        account: {
          productApprovalRequired: account.productApprovalRequired,
          fdbSettings: account.fdbSettings,
        },
      };

      await axios.put(url, data, rc);
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default AccountService;
