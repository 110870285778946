import ApiService from "./ApiService";
import axios from "axios";

class DashboardService extends ApiService {
  //   static list = async (user) => {
  //     try {
  //       const rc = {
  //         headers: { "x-access-token": user.token },
  //       };
  //       let response = await axios.get(
  //         `${ApiService.BASE_URL}/accounts/${user.account._id}/sites`,
  //         rc
  //       );
  //       return response.data.sites;
  //     } catch (error) {
  //       ApiService.handleResponseError(error.response);
  //     }
  //   };
  //   static getAlertsData = () => {
  //     try {
  //       return invAlertsData;
  //     } catch (error) {
  //       ApiService.handleResponseError(error.response);
  //     }
  //   };
  //   static getSelectPharmacyOptions = () => {
  //     const pharmacies = ["Pharmacy A", "Pharmacy B", "Pharmacy C", "Pharmacy D"];
  //     try {
  //       return pharmacies;
  //     } catch (error) {
  //       ApiService.handleResponseError(error.response);
  //     }
  //   };

  static getPillSortProductCountWithNoPrice = async (user, site, details) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/pill_sort/product_count_no_price`;
      if (site?._id) {
        url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dashboard/pill_sort/product_count_no_price`;
      }

      if (details) {
        url += "?details=true";
      }

      let response = await axios.get(url, rc);

      if (!details) {
        return response.data.count;
      } else {
        return response.data.products;
      }
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getPillSortProcessHistory = async (
    user,
    site,
    startDate,
    stopDate,
    details
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/pill_sort/process_history`;
      if (site?._id) {
        url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dashboard/pill_sort/process_history`;
      }
      url += `?startDateMs=${startDate.getTime()}&stopDateMs=${stopDate.getTime()}`;

      if (details) {
        url += "&details=true";
      }

      let response = await axios.get(url, rc);
      return response.data.results;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getPillSortAlarmHistory = async (
    user,
    site,
    startDate,
    stopDate,
    details
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/pill_sort/alarm_history`;
      if (site?._id) {
        url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dashboard/pill_sort/alarm_history`;
      }
      url += `?startDateMs=${startDate.getTime()}&stopDateMs=${stopDate.getTime()}`;
      if (details) {
        url += "&details=true";
      }

      let response = await axios.get(url, rc);
      return response.data.results;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getPillSortAlarmResolution = async (user, errorCode) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/pill_sort/alarm_resolution?errorCode=${errorCode}`;
      let response = await axios.get(url, rc);
      return response.data.alarmResolution;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getSuctionCupLife = async (user, site) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/pill_sort/suction_cup_life`;
      if (site?._id) {
        url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dashboard/pill_sort/suction_cup_life`;
      }

      let response = await axios.get(url, rc);
      return response.data.results;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getPillSortData = async (user, startDate, stopDate) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/pill-sort-data`;
      url += `?startDateMs=${startDate}&stopDateMs=${stopDate}`;

      let response = await axios.get(url, rc);
      return response.data.pillSortData;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static resetSuctionCupLife = async (user, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/site/${
        stockLocation.site._id || stockLocation.site
      }/${stockLocation._id}/dashboard/pill_sort/reset_suction_cup_life`;
      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getEV54ScriptsPerHourData = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/ev54/scriptsPerHour`;
      if (site) {
        url += `?site=${site._id}&utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }
      if (stockLocation) {
        url += `&stockLocation=${stockLocation._id}`;
      }

      if (!site && utcStartDate && utcStopDate) {
        url += `?utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }

      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getEV54ScriptTypes = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/ev54/scriptTypes`;
      if (site) {
        url += `?site=${site._id}&utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }
      if (stockLocation) {
        url += `&stockLocation=${stockLocation._id}`;
      }

      if (!site && utcStartDate && utcStopDate) {
        url += `?utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }

      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getEV54CanisterUtilization = async (user, site, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/ev54/canisterUtilization`;
      if (site) {
        url += `?site=${site._id}`;
      }
      if (stockLocation) {
        url += `&stockLocation=${stockLocation._id}`;
      }

      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getEV54AlarmHistory = async (
    user,
    site,
    startDate,
    stopDate,
    details
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/ev54/alarm_history`;
      if (site?._id) {
        url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dashboard/ev54/alarm_history`;
      }
      url += `?startDateMs=${startDate.getTime()}&stopDateMs=${stopDate.getTime()}`;
      if (details) {
        url += "&details=true";
      }

      let response = await axios.get(url, rc);
      return response.data.results;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getEV54AlarmResolution = async (user, errorCode) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/ev54/alarm_resolution?errorCode=${errorCode}`;
      let response = await axios.get(url, rc);
      return response.data.alarmResolution;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getEV54Inventory = async (user, site, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/ev54/inventory`;
      if (site) {
        url += `?site=${site._id}`;
      }
      if (stockLocation) {
        url += `&stockLocation=${stockLocation._id}`;
      }

      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getTruPakPouchesPerHourData = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/truPak/pouchesPerHour`;
      if (site) {
        url += `?site=${site._id}&utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }

      if (!site && utcStartDate && utcStopDate) {
        url += `?utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }

      if (stockLocation) {
        url += `&stockLocation=${stockLocation._id}`;
      }

      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getTruPakBatchesVsPackagedOnData = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/truPak/batchesPerDay`;
      if (site) {
        url += `?site=${site._id}&utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }

      if (!site && utcStartDate && utcStopDate) {
        url += `?utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }

      if (stockLocation) {
        url += `&stockLocation=${stockLocation._id}`;
      }

      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getTruPakInventory = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/truPak/inventory`;
      if (site) {
        url += `?site=${site._id}&utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }

      if (!site && utcStartDate && utcStopDate) {
        url += `?utcStartDate=${utcStartDate}&utcStopDate=${utcStopDate}`;
      }

      if (stockLocation) {
        url += `&stockLocation=${stockLocation._id}`;
      }

      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getTruPakAlarmHistory = async (
    user,
    site,
    startDate,
    stopDate,
    details,
    deviceId
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/truPak/alarm_history`;
      if (site?._id) {
        url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dashboard/truPak/alarm_history`;
      }
      url += `?startDateMs=${startDate.getTime()}&stopDateMs=${stopDate.getTime()}`;
      if (details) {
        url += "&details=true";
      }
      if (deviceId) {
        url += `&deviceId=${deviceId}`;
      }

      let response = await axios.get(url, rc);
      return response.data.results;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getTruPakCanisterUtilization = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/canister-utilization?startDate=${utcStartDate}&stopDate=${utcStopDate}`;
      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  // package Ids should be a comma-delimited list like 123,456,789
  static getTruPakTrayProductCounts = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate,
    packageIds,
    dispensingOrderIds
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/product-tray-count?startDate=${utcStartDate}&stopDate=${utcStopDate}&packageIds=${packageIds}&dispensingOrderIds=${dispensingOrderIds}`;
      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default DashboardService;
