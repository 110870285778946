import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import { pxToMm } from "../functions/converters.js";
import { placeholderImage } from "../svgs/placeholderImage";
import Input from "./Input.js";

const ImageProperties = ({ unit, selectedObject, updateObject }) => {
  const { t } = useTranslation("labelEditor");

  const handleInputChange = (value, name) => {
    updateObject({
      ...selectedObject,
      [name]: value,
    });
  };

  const onDrop = async (files) => {
    let file = files[0];
    const reader = new FileReader();
    reader.onloadend = (e) => {
      let stringArray = reader.result.split("base64,");

      const img = new Image();
      img.onload = () => {
        updateObject({
          ...selectedObject,
          base64EncodedString: stringArray[1],
          mimeType: `${file.type}`,
          width: pxToMm(img.width),
          height: pxToMm(img.height),
          name: file.name,
        });
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const handleImageCheckbox = () => {
    const revertPlaceholder = !selectedObject.getImagesFromDatabase ? {      
      mimeType: "image/png",
      base64EncodedString: placeholderImage,
      width: 7.9,
      height: 7.9,
    } : {}

    updateObject({
      ...selectedObject,
      getImagesFromDatabase: !selectedObject.getImagesFromDatabase,
      name: !selectedObject.getImagesFromDatabase ? t("productImageLabel") : t("customImageLabel"),
      ...revertPlaceholder,
    });
  };

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("widthLabel")}
          </label>
          <Input
            value={selectedObject.width}
            name="width"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            step={0.1}
            min={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("heightLabel")}
          </label>
          <Input
            value={selectedObject.height}
            name="height"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            step={0.1}
            min={0.1}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X</label>
          <Input
            value={selectedObject.x}
            name="x"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked || selectedObject?.parent?.type === "gridLayout"}
            step={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y</label>
          <Input
            value={selectedObject.y}
            name="y"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked || selectedObject?.parent?.type === "gridLayout"}
            step={0.1}
          />
        </div>
      </div>

      <div>
        <div className={styles.Properties__toggle}>
          <Checkbox 
            labelName={t("fromDatabaseLabel")}
            labelColor={"var(--black)"}
            isOn={selectedObject.getImagesFromDatabase}
            onCheck={handleImageCheckbox}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      {!selectedObject.getImagesFromDatabase && (
        <div className={styles.editProfile__profilePicButton}>
          <Dropzone
            accept={"image/jpeg, image/png, image/svg+xml"}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div style={{ marginTop: "20px" }} {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  labelName={t("changeImageLabel")}
                  isPrimary={true}
                  isInverse={true}
                  isDisabled={selectedObject.locked}
                  minWidth={null}
                  width="100%"
                />
              </div>
            )}
          </Dropzone>
        </div>
      )}
    </div>
  );
};

export default ImageProperties;
