import UserService from "./UserService";

class RoleCheckService {

    static canManageMedicationSettings = (user) => {
        return RoleCheckService._exists( ['SUPER ADMINISTRATOR', 'ADMINISTRATOR', 'PHARMACIST', 'INVENTORY MANAGER'], user.roles)
    }

    static canResetAndDeleteOrders = (user) => {
        return RoleCheckService._exists( ['SUPER ADMINISTRATOR', 'ADMINISTRATOR', 'SENIOR TECH', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST'], user.roles)
    }

    static canCreateEV54TestOrders = (user) => {
        return RoleCheckService._exists( ['SUPER ADMINISTRATOR'], user.roles)
    }
    static canCreateSites = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR'], user.roles)
    }

    static canInviteUsers = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER'], user.roles)
    }

    static viewSiteAccessAndRoles = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR'], user.roles)
    }

    static modifySiteAccessAndRoles = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR'], user.roles)
    }

    static canCreateFacilities = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER'], user.roles)
    }

    static canCreateScripts = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'RETAIL PHARMACIST', 'PHARMACIST'], user.roles)
    }

    static canCreateProduct = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'INVENTORY MANAGER', 'PURCHASING'], user.roles)
    }

    static canCreateReports = (user) => {
        return true; // everybody can
    }
    static canRemoveReports = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR'], user.roles)
    }

    static canReplenish = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PROCESSING TECH'], user.roles)
    }

    static canStock = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PROCESSING TECH'], user.roles)
    }
    static canReturnToStock = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER'], user.roles)
    }
    static canHandleControlledSubstances = (user) => {
        return RoleCheckService._exists( ['CONTROLLED SUBSTANCES'], user.roles)
    }

    static canReleaseFromEV54Manually = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'SENIOR TECH', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST'], user.roles);
    }

    static canAudit = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'RETAIL PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER'], user.roles);
    }

    static canTestDispense = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'RETAIL PHARMACIST', 'SENIOR TECH'], user.roles);
    }

    static canAlterProductWorkflow = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'PHARMACIST'], user.roles);
    }

    static canModifyPackageId = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'PHARMACIST', 'INVENTORY MANAGER'], user.roles);
    }

    static userCanSplitOrder = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER'], user.roles);
    }

    static viewSettings = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PROCESSING TECH', 'PURCHASING', 'BILLING'], user.roles);
    }

    static viewPharmacySettings = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PROCESSING TECH', 'PURCHASING', 'BILLING'], user.roles);
    }
    static viewFacilitySettings = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH', 'PURCHASING', 'BILLING'], user.roles);
    }
    static viewUserSettings = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH',], user.roles);
    }
    static viewProductSettings = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PROCESSING TECH', 'PURCHASING', 'BILLING'], user.roles);
    }
    static deletePharmacies = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR'], user.roles);
    }
    static deleteFacilities = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR','REGIONAL MANAGER'], user.roles);
    }
    static deleteUsers = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER','SITE MANAGER'], user.roles);
    }
    static deleteProducts = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'INVENTORY MANAGER'], user.roles);
    }
    static viewStockLocations = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PURCHASING'], user.roles);
    }
    static createStockLocations = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'INVENTORY MANAGER'], user.roles);
    }
    static deleteStockLocations = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER'], user.roles);
    }
    static viewDeliverySchedule = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH'], user.roles);
    }
    static createDeliverySchedule = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST'], user.roles);
    }
    static deleteDeliverySchedule = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER'], user.roles);
    }
    static addEquivalencies = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'PHARMACIST'], user.roles);
    }
    static createRegions = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR'], user.roles);
    }
    static removeCustomReports = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR'], user.roles);
    }
    static shareCustomReports = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR'], user.roles);
    }
    static editCustomReports = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR'], user.roles);
    }
    static viewOrders = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH', 'INVENTORY MANAGER', 'PURCHASING', 'BILLING'], user.roles);
    }
    static viewDevices = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH', 'INVENTORY MANAGER', 'PURCHASING', 'BILLING'], user.roles);
    }
    static viewInventory = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH', 'INVENTORY MANAGER', 'PURCHASING', 'BILLING'], user.roles);
    }
    static routeOrders = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PROCESSING TECH'], user.roles);
    }
    static deleteOrders = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST'], user.roles);
    }
    static reRouteOrders = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH'], user.roles);
    }
    static viewOrderDetails = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PROCESSING TECH'], user.roles);
    }
    static addProductFromOrderDetails = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH', 'INVENTORY MANAGER'], user.roles);
    }
    static substituteProductFromOrderDetails = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH'], user.roles);
    }
    static modifyAdminDateTimeFromOrderDetails = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH'], user.roles);
    }
    static adjustQtyFromOrderDetails = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH'], user.roles);
    }
    static changePatientLocationFromOrderDetails = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH'], user.roles);
    }
    static changePatientLanguageFromOrderDetails = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH'], user.roles);
    }
    static viewOrdersWithErrors = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH'], user.roles);
    }
    static viewOrderPickList = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PROCESSING TECH', 'PURCHASING'], user.roles);
    }
    static initializeDevice = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH'], user.roles);
    }
    static cleanDevice = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH'], user.roles);
    }
    static canisterManagementAddDeleteProduct = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'INVENTORY MANAGER', 'PURCHASING'], user.roles);
    }
    static canisterManagementModifyInfo = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PURCHASING'], user.roles);
    }
    static viewDeviceOrders = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH'], user.roles);
    }
    static viewDeviceAlarms = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'PROCESSING TECH'], user.roles);
    }

    static viewDeviceReplenishment = (user) => {
        return RoleCheckService._exists( ['ADMINISTRATOR', 'REGIONAL MANAGER', 'SITE MANAGER', 'PHARMACIST', 'SENIOR TECH', 'INVENTORY MANAGER', 'PROCESSING TECH'], user.roles);
    }

    static isSuperAdmin = (user) => {
        return RoleCheckService._exists( ['SUPER ADMINISTRATOR'], user.roles);
    }

    static isCustomerService = (user) => {
        return RoleCheckService._exists( ['SUPER ADMINISTRATOR', 'CUSTOMER SERVICE'], user.roles);
    }

    static _exists = (roleNameList, userRoles) => {
        let exists = false;
        roleNameList.forEach(roleName=>{
            if(userRoles.some(o=>o.type.replace(/\s+/g,'').toUpperCase() === roleName.replace(/\s+/g,'').toUpperCase())){
                exists = true;
            }
        })
        return exists;
    }

    static getAccessibleSites = (user) => {
        return new Promise((resolve, reject)=>{
            UserService.getAccessibleSites(user)
                .then(sites=>{
                    resolve(sites);
                })
                .catch(error=>{
                    reject(error.message);
                })
        })
    }

}

export default RoleCheckService;
