import React, { useEffect, useState, useContext, Fragment } from "react";
import { Rings } from "react-loader-spinner";
import PillsortAlarmsCardList from "./MobileViews/PillsortAlarms/PillsortAlarmsCardList";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table/Table";
import Popup from "../../../../components/PopupMenu/Popup";
import backIcon from "../../../../assets/back_icon.svg";
import { ReactComponent as MoreActionsIcon } from "../../../../assets/more-actions.svg";
import { ReactComponent as MoreActionsMobileIcon } from "../../../../assets/more-actions-mobile.svg";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./AlarmsTable.module.css";
import { useTranslation } from "react-i18next";

const AlarmsTable = ({
  goBackHandler,
  _controllerRef,
  user,
  selectedSite,
  startDate,
  stopDate,
  windowHeight,
}) => {
  const { t } = useTranslation("trusortAlarmsTable");

  const [items, setItems] = useState([]);
  const [showPopup, setShowPopup] = useState(null);
  const [popupIndex, setPopupIndex] = useState(-1);
  const [alarmsData, setAlarmsData] = useState([]);
  const [fetchingAlarmsData, setFetchingAlarmsData] = useState(false);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const loader = (
    <div className={styles.AlarmsTable__loader}>
      <Rings color="#089BAB" height={110} width={110} />
      <p>{t("fetching")}</p>
    </div>
  );

  const actionMenu = [
    {
      type: "linkToWiki",
      description: t("linkToWikiAction"),
    },
    {
      type: "logTicket",
      description: t("logTicketAction"),
    },
    {
      type: "chat",
      description: t("chatAction"),
    },
  ];

  const handleAction = (action) => {
    switch (action.type) {
      case "linkToWiki":
        alert("Link to Wiki");
        break;
      case "logTicket":
        alert("Order");
        break;
      case "chat":
        alert("Chat");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let site;

    if (typeof selectedSite === "object") {
      site = selectedSite;
    } else {
      site = "";
    }
    setFetchingAlarmsData(true);

    /* Get intitial table data here */
    _controllerRef.current
      .getAlarmHistory(user, site, startDate, stopDate, true)
      .then((results1) => {
        async function fetchResolutionData(i) {
          await _controllerRef.current
            .getPillSortAlarmResolution(user, "E" + results1[i]?.errorCode)
            .then((results2) => {
              // console.log("results2: ", results2);
              results1[i].resolution = results2?.resolution
                ? results2.resolution
                : "";
              if (i === results1.length - 1) {
                setAlarmsData(results1);
              }
            })
            .catch((error) => {
              setFetchingAlarmsData(false);
              console.log(error);
            });
          return null;
        }

        async function alarmResolutions() {
          for (let i = 0; i < results1.length; i++) {
            // console.log("i: ", i);
            await fetchResolutionData(i);
          }
          setFetchingAlarmsData(false);
        }

        if (results1.length) {
          alarmResolutions();
        } else {
          setAlarmsData([]);
          setFetchingAlarmsData(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user, selectedSite]);

  useEffect(() => {
    (async () => {
      const tempItems = [];

      try {
        alarmsData.forEach((tableRow, index) => {
          const dateString = tableRow.occurredAt;
          const month = dateString.substring(5, 7);
          const day = dateString.substring(8, 10);
          const year = dateString.substring(0, 4);
          const time = dateString.substring(11, 19);
          const actions = (
            <div
              className={styles.AlarmsTable__actionsContainer}
              onClick={(e) => {
                if (!showPopup) {
                  setShowPopup(true);
                  setPopupIndex(index);
                }
              }}
            >
              {smScreen ? <MoreActionsMobileIcon /> : <MoreActionsIcon />}
              {showPopup && popupIndex === index && (
                <Popup
                  menuItems={actionMenu}
                  onAction={(action) => {
                    setShowPopup(null);
                    setPopupIndex(-1);
                    handleAction(action);
                  }}
                  onClose={() => setShowPopup(null)}
                  rightJustify={true}
                />
              )}
            </div>
          );
          tempItems.push([
            tableRow.errorCode,
            tableRow.description,
            `${month}/${day}/${year}, ${time}`,
            tableRow.resolution,
            actions,
          ]);
        });
        setItems(tempItems);
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, [showPopup, popupIndex, alarmsData]);

  const tableHeight = (+windowHeight - 200).toString() + "px";

  const headers = [
    t("col0"),
    t("col1"),
    t("col2"),
    t("col3"),
    <div className={styles.AlarmsTable__actionsHeadingContainer}>{t("col4")}</div>,
  ];

  const DesktopView = (
    <Fragment>
      {fetchingAlarmsData && loader}
      <div className={styles.AlarmsTable__header}>
        <h1>{t("title")}</h1>
        <p>{t("contact")}: +1 262-729-9202</p>
      </div>
      <div
        style={{ height: tableHeight }}
        className={styles.AlarmsTable__mainContainer}
      >
        <div className={styles.AlarmsTable__tableContainer}>
          <Table
            padScrollBar={true}
            headers={headers}
            records={items}
            showPopup={showPopup}
            popupIndex={popupIndex}
            tableRowHeight={37}
            scrollAdjustConstant={160}
          />
        </div>
        <div className={styles.AlarmsTable__buttonGroup}>
          <Button
            onClick={goBackHandler}
            labelName={t("backButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            minWidth={null}
          />
        </div>
      </div>
    </Fragment>
  );

  const MobileView = (
    <Fragment>
      {fetchingAlarmsData && loader}
      <div className={styles.AlarmsTable__mainContainer}>
        <div className={styles.AlarmsTable__header}>
          <div className={styles.AlarmsTable__backButton}>
            <img src={backIcon} onClick={goBackHandler} />
          </div>
          <p className={styles.AlarmsTable__reportTitle}>{t("title")}</p>
        </div>
        {
          <div className={styles.AlarmsTable__cardListContainer}>
            <PillsortAlarmsCardList
              headings={headers}
              data={items}
              fetchingAlarmsData={fetchingAlarmsData}
            />
          </div>
        }
      </div>
    </Fragment>
  );

  return smScreen ? MobileView : DesktopView;
};

export default AlarmsTable;
