import React, { useState, useRef, useEffect } from "react";
import { Button, ConfigProvider, Divider, Select } from "antd";
import { ReactComponent as FilterIcon } from "../../assets/funnel.svg";
import "./MultiSelectDropDown.css";

const MultiSelectDropDown = ({
  value,
  options,
  fieldNames,
  onChanged,
  asFunnelIcon,
  placeholderLabel,
  selectAllLabel,
  unselectAllLabel,
  noContentLabel,
}) => {
  /*
    value:        array of selected options values
    options:      array of objects
    filedNames:   { value: <key>, label: <key> }
    onChanged:    value array setter
    asFunnelIcon: replace input with funnel icon    
  */
  
  const containerRef = useRef(null);
  const [all, setAll] = useState(true);
  const [open, setOpen] = useState(false);

  const handleChange = (args) => {
    onChanged(args);
  };

  const handleSelectAllClick = () => {
    if (all) {
      onChanged([])
    } else {
      onChanged(options.map((option) => option[fieldNames.value]))
    }
  }

  useEffect(() => {
    setAll(value?.length === options?.length)
  }, [value, options])

  return (
    <div ref={containerRef}>
      {asFunnelIcon && (
        <FilterIcon
          onClick={() => setOpen((prev) => !prev)}
          width="16px"
          height="16px"
          color={all ? "black" : "var(--green)"}
          style={{cursor: "pointer"}}
        />
      )}

      <ConfigProvider
        theme={{
          components: {
            Select: {
              optionSelectedBg: "var(--green)", // Selected background
              optionSelectedColor: "white",     // Selected text
              colorPrimary: "white",            // Selected checkmark
              optionActiveBg: "white",          // Last clicked background
              colorTextDisabled: "black",       // noContentLabel text
            },
          },
        }}
      >
        <Select
          mode="multiple"
          value={value}
          options={options}
          fieldNames={fieldNames}
          allowClear={true}
          open={open}
          onDropdownVisibleChange={(visible) => setOpen(visible)}
          getPopupContainer={() => containerRef.current}
          style={asFunnelIcon ? { width: 0, height: 0, opacity: 0 } : undefined}
          popupMatchSelectWidth={false}
          placeholder={placeholderLabel}
          notFoundContent={noContentLabel}     
          onChange={handleChange}
          dropdownStyle={{ minWidth: 150 }}      
          dropdownRender={
            options.length > 0 && selectAllLabel && unselectAllLabel
              ? (menu) => (
                  <>
                    <div>
                      <Button type="text" onClick={handleSelectAllClick}>
                        {all ? unselectAllLabel : selectAllLabel}
                      </Button>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                    {menu}
                  </>
                )
              : undefined
          }
        />
      </ConfigProvider>
    </div>
  );
};

export default MultiSelectDropDown;
