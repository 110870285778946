import React, { Suspense, useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// Global imports
import "./App.css";
//import Reports from "./partials/Reports/Reports";
import { InventoryProvider } from "./contexts/InventoryContext";
import { NavbarProvider } from "./contexts/NavbarContext";
import { ScreenProvider } from "./contexts/ScreenContext";
import { ScrollProvider } from "./contexts/ScrollContext";
import AuthController from "./controllers/AuthController";
import AccountSettings from "./partials/AccountSettings/AccountSettings";
import Dashboard from "./partials/Dashboard2/Dashboard";
import Devices from "./partials/Devices/Devices";
import AddRetailScript from "./partials/Dispensing/AddRetailScript/AddRetailScript";
import DispensingOrders from "./partials/Dispensing/DispensingOrders2/DispensingOrders2";
import PendingDispensingOrder from "./partials/Dispensing/Pending/PendingDispensingOrder";
import SuperAdminOrderGeneration from "./partials/Dispensing/SuperAdminUtilities/SuperAdminOrderGeneration";
import CreateFacility from "./partials/Facilities/CreateFacility";
import DeliverySchedule from "./partials/Facilities/DeliverySchedule";
import Facilities from "./partials/Facilities/Facilities";
import Help from "./partials/Help/Help";
import Inventory from "./partials/Inventory/Inventory";
import InventoryEquiv from "./partials/Inventory/InventoryEquiv";
import InventoryItems from "./partials/Inventory/InventoryItems";
import CreatePharmacySite from "./partials/PharmacySites/CreatePharmacySite";
import PharmacySites from "./partials/PharmacySites/PharmacySites";
import CreateProduct from "./partials/Product/CreateProduct2";
import Products from "./partials/Product/Products";
import PDFReportViewer from "./partials/Reports/PDFReportViewer";
import Reports from "./partials/Reports2/Reports2";
import Settings from "./partials/Settings";
import StockLocations from "./partials/StockLocations/StockLocations";
import SuperAdmin from "./partials/SuperAdmin/SuperAdmin";
import Support from "./partials/Support/Support";
import CreateTherapeuticEquivalency from "./partials/TherapeuticEquivalencies/CreateTherapeuticEquivalency";
import TherapeuticEquivalencies from "./partials/TherapeuticEquivalencies/TherapeuticEquivalencies";
import TrayCheckView from "./partials/TrayChecks/TrayCheckView";
import ChangePassword from "./partials/Users/ChangePassword";
import CreateUser from "./partials/Users/CreateUser";
import EditProfile from "./partials/Users/EditProfile";
import Users from "./partials/Users/Users";
import AccountSetupConfirmationView from "./views/Account/AccountSetupConfirmationView";
import AccountSetupView from "./views/Account/AccountSetupView";
import CreateUserErrorView from "./views/Account/CreateUserErrorView";
import HomeView from "./views/Home/HomeView";
import CreatePasswordView from "./views/LogIn/CreatePasswordView";
import LogInView from "./views/LogIn/LogInView";
import ResetPasswordView from "./views/LogIn/ResetPasswordView";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(() => {
    // get stored value for user if exists in local storage
    const savedUser = JSON.parse(localStorage.getItem("user"));
    return savedUser || null;
  });
  const [dosageFormList, setDosageFormList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [userTokenValid, setUserTokenValid] = useState(false);

  const _controllerRef = useRef(null);

  useEffect(() => {
    if (user) {
      _controllerRef.current = new AuthController(user);
      _controllerRef.current.verifyUserToken().then((result) => {
        if (result) {
          setUserTokenValid(true);
        } else {
          setUserTokenValid(false);
          localStorage.removeItem("user");
          navigate("/log-in");
        }
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname !== "/login" &&
      !location.pathname.startsWith("/account-setup") &&
      location.pathname !== "/reset-psw" &&
      !user
    ) {
      navigate("/log-in");
    }
  }, [user, location.pathname]);

  useEffect(() => {
    // store user in local storage if it does not exist
    if (userTokenValid && user && !localStorage.getItem("user")) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user, userTokenValid]);

  return (
    <Suspense fallback="loading">
      <ScreenProvider>
        <NavbarProvider>
          <ScrollProvider>
            <InventoryProvider>
              <Routes>
                {user && (
                  <Route
                    path="/"
                    element={
                      <HomeView
                        user={user}
                        onLogOut={async () => {
                          setUser(null);
                          navigate("/log-in");
                        }}
                      />
                    }
                  >
                    <Route
                      path={"/super-admin"}
                      element={<SuperAdmin user={user} />}
                    />
                    <Route
                      path={"dashboard"}
                      element={
                        <Dashboard
                          user={user}
                          dashboardReturnState={
                            location.state
                              ? location.state.dashboardReturnState
                              : null
                          }
                        />
                      }
                    />
                    <Route
                      path={"pdf_viewer"}
                      element={
                        <PDFReportViewer
                          user={user}
                          headers={location.state?.headers}
                          onBack={() => {
                            navigate(-1);
                          }}
                          data={location.state?.data}
                          title={location.state?.title}
                        />
                      }
                    />
                    <Route
                      path={"inventory"}
                      element={
                        <Inventory
                          user={user}
                          onLogOut={() => {
                            setUser(null);
                            navigate("/log-in");
                          }}
                        />
                      }
                    >
                      <Route
                        path="items"
                        element={
                          <InventoryItems
                            user={user}
                            onLogOut={() => {
                              setUser(null);
                              navigate("/log-in");
                            }}
                          />
                        }
                      />

                      <Route
                        path="receive"
                        element={
                          <InventoryItems
                            user={user}
                            onLogOut={() => {
                              setUser(null);
                              navigate("/log-in");
                            }}
                          />
                        }
                      />
                      <Route
                        path="transfer"
                        element={
                          <InventoryItems
                            user={user}
                            onLogOut={() => {
                              setUser(null);
                              navigate("/log-in");
                            }}
                          />
                        }
                      />

                      <Route
                        path={"facilities"}
                        element={
                          <Facilities
                            user={user}
                            profilePic={
                              location.state ? location.state.profilePic : null
                            }
                          />
                        }
                      >
                        <Route
                          path={"create"}
                          element={
                            <CreateFacility
                              user={user}
                              facility={
                                location.state ? location.state.facility : null
                              }
                            />
                          }
                        />
                        <Route
                          path={"deliverySchedule"}
                          element={
                            <DeliverySchedule
                              user={user}
                              facility={
                                location.state ? location.state.facility : null
                              }
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={"substitutions"}
                        element={
                          <TherapeuticEquivalencies
                            user={user}
                            refresh={
                              location.state ? location.state.refresh : false
                            }
                          />
                        }
                      >
                        <Route
                          path={"create"}
                          element={
                            <CreateTherapeuticEquivalency
                              user={user}
                              teCode={
                                location.state ? location.state.teCode : null
                              }
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={"canister-inventory"}
                        element={
                          "Canister inventory"
                        }
                      >
                      </Route>

                      <Route
                        path={"medications"}
                        element={
                          <Products
                            user={user}
                            alarm={location.state ? location.state.alarm : null}
                          />
                        }
                      >
                        <Route
                          path={"create"}
                          element={
                            <CreateProduct
                              user={user}
                              routeList={routeList}
                              dosageFormList={dosageFormList}
                              product={
                                location.state ? location.state.product : null
                              }
                              isCopy={
                                location.state ? location.state.isCopy : false
                              }
                              dispensingOrderItem={
                                location.state
                                  ? location.state.dispensingOrderItem
                                  : null
                              }
                              isNew={location.state?.isNew === true}
                            />
                          }
                        />
                      </Route>
                    </Route>

                    <Route
                      path={"dispensing-orders"}
                      element={
                        <DispensingOrders
                          user={user}
                          navToggle={
                            location.state ? location.state.navToggle : null
                          }
                          selectedSite={location.state?.selectedSite}
                          selectedPackagingMethods={
                            location.state?.selectedPackagingMethods
                          }
                        />
                      }
                    />

                    <Route
                      path={
                        "site/:siteId/device/:deviceId/order/:orderId/trayCheck/:trayId"
                      }
                      element={<TrayCheckView user={user} />}
                    />

                    {location.state &&
                      location.state.site &&
                      location.state.dispensingOrder && (
                        <>
                          <Route
                            path={"dispensing-orders/pending"}
                            element={
                              <PendingDispensingOrder
                                user={user}
                                site={location.state.site}
                                dispensingOrder={location.state.dispensingOrder}
                                dispensingOrders={location.state.dispensingOrders}
                                selectedPackagingMethods={
                                  location.state.selectedPackagingMethods
                                }
                                readonly={false}
                              />
                            }
                          />

                          <Route
                            path={"dispensing-orders/routed"}
                            element={
                              <PendingDispensingOrder
                                user={user}
                                site={location.state.site}
                                dispensingOrder={location.state.dispensingOrder}
                                dispensingOrders={location.state.dispensingOrders}
                                selectedPackagingMethods={
                                  location.state.selectedPackagingMethods
                                }
                                readonly={true}
                              />
                            }
                          />

                          <Route
                            path={"dispensing-orders/packaged"}
                            element={
                              <PendingDispensingOrder
                                user={user}
                                site={location.state.site}
                                dispensingOrder={location.state.dispensingOrder}
                                dispensingOrders={location.state.dispensingOrders}
                                selectedPackagingMethods={
                                  location.state.selectedPackagingMethods
                                }
                                readonly={true}
                              />
                            }
                          />
                        </>
                      )}

                    {location.state && location.state.site && (
                      <Route
                        path={"dispensing-orders/retail-script"}
                        element={
                          <AddRetailScript
                            user={user}
                            site={location.state.site}
                            selectedPackagingMethods={
                              location.state.selectedPackagingMethods
                            }
                          />
                        }
                      />
                    )}
                    {location.state && location.state.site && (
                      <Route
                        path={"dispensing-orders/order-generation"}
                        element={
                          <SuperAdminOrderGeneration
                            user={user}
                            site={location.state.site}
                          />
                        }
                      />
                    )}
                    <Route
                      path={"devices"}
                      element={
                        <Devices
                          user={user}
                          stockLocation={
                            location.state ? location.state.stockLocation : null
                          }
                          dataFromDashboard={
                            location.state
                              ? location.state.dataFromDashboard
                              : null
                          }
                          site={location.state ? location.state.site : null}
                        />
                      }
                    />
                    {/*<Route path={"history"} element={<History user={user} />} />*/}
                    <Route
                      path={"reports"}
                      element={
                        <Reports
                          user={user}
                          navToggle={
                            location.state ? location.state.navToggle : null
                          }
                          returnURL={
                            location.state ? location.state.returnURL : null
                          }
                          reportName={
                            location.state ? location.state.reportName : null
                          }
                          dataFromDashboard={
                            location.state
                              ? location.state.dataFromDashboard
                              : null
                          }
                          onLogOut={() => {
                            setUser(null);
                            navigate("/log-in");
                          }}
                        />
                      }
                    />

                    <Route
                      path={"help"}
                      element={
                        <Help
                          user={user}
                          navToggle={
                            location.state ? location.state.navToggle : null
                          }
                        />
                      }
                    />

                    <Route
                      path={"support"}
                      element={
                        <Support
                          user={user}
                          navToggle={
                            location.state ? location.state.navToggle : null
                          }
                        />
                      }
                    />

                    <Route
                      path={"settings"}
                      element={
                        <Settings
                          user={user}
                          onLogOut={() => {
                            setUser(null);
                            navigate("/log-in");
                          }}
                        />
                      }
                    >
                      <Route
                        path={"account-settings"}
                        element={
                          <AccountSettings
                            user={user}
                            site={location.state ? location.state.site : null}
                          />
                        }
                      />

                      <Route
                        path={"sites"}
                        element={<PharmacySites user={user} />}
                      >
                        <Route
                          path={"create"}
                          element={
                            <CreatePharmacySite
                              user={user}
                              site={location.state ? location.state.site : null}
                            />
                          }
                        />
                        <Route
                          path={"stock-locations"}
                          element={
                            <StockLocations
                              user={user}
                              site={location.state ? location.state.site : null}
                            />
                          }
                        />
                      </Route>
                      <Route
                        path={"facilities"}
                        element={
                          <Facilities
                            user={user}
                            profilePic={
                              location.state ? location.state.profilePic : null
                            }
                          />
                        }
                      >
                        <Route
                          path={"create"}
                          element={
                            <CreateFacility
                              user={user}
                              facility={
                                location.state ? location.state.facility : null
                              }
                            />
                          }
                        />
                        <Route
                          path={"deliverySchedule"}
                          element={
                            <DeliverySchedule
                              user={user}
                              facility={
                                location.state ? location.state.facility : null
                              }
                            />
                          }
                        />
                      </Route>
                      <Route
                        path={"users"}
                        element={
                          <Users
                            user={user}
                            profilePic={
                              location.state ? location.state.profilePic : null
                            }
                            refresh={
                              location.state ? location.state.refresh : null
                            }
                          />
                        }
                      >
                        <Route
                          path={"create"}
                          element={
                            <CreateUser
                              currentUser={user}
                              user={location.state ? location.state.user : null}
                            />
                          }
                        />
                      </Route>
                      <Route
                        path={"products"}
                        element={
                          <Products
                            user={user}
                            alarm={location.state ? location.state.alarm : null}
                          />
                        }
                      >
                        <Route
                          path={"create"}
                          element={
                            <CreateProduct
                              user={user}
                              routeList={routeList}
                              dosageFormList={dosageFormList}
                              product={
                                location.state ? location.state.product : null
                              }
                              isCopy={
                                location.state ? location.state.isCopy : false
                              }
                              dispensingOrderItem={
                                location.state
                                  ? location.state.dispensingOrderItem
                                  : null
                              }
                              isNew={location.state?.isNew === true}
                            />
                          }
                        />
                      </Route>
                      <Route
                        path={"therapeuticEquivalencies"}
                        element={
                          <TherapeuticEquivalencies
                            user={user}
                            refresh={
                              location.state ? location.state.refresh : false
                            }
                          />
                        }
                      >
                        <Route
                          path={"create"}
                          element={
                            <CreateTherapeuticEquivalency
                              user={user}
                              teCode={
                                location.state ? location.state.teCode : null
                              }
                            />
                          }
                        />
                      </Route>
                    </Route>
                    <Route
                      path={"settings/products/equivalencies"}
                      element={
                        <InventoryEquiv
                          user={user}
                          product={
                            location.state ? location.state.product : null
                          }
                        />
                      }
                    />
                    <Route
                      path={"profile"}
                      element={
                        <EditProfile
                          user={user}
                          onUpdate={(user) => {
                            setUser(user);
                            navigate(-1);
                          }}
                        />
                      }
                    />
                    <Route
                      path={"/change-password"}
                      element={
                        <ChangePassword
                          user={user}
                          onUpdate={() => {
                            setUser(null);
                            navigate("/log-in");
                          }}
                        />
                      }
                    />
                  </Route>
                )}

                <Route
                  path="/account-setup"
                  element={<AccountSetupView user={user} />}
                />
                <Route
                  path="/account-setup/success"
                  element={<AccountSetupConfirmationView />}
                />
                <Route
                  path="/users/create/error"
                  element={<CreateUserErrorView />}
                />
                <Route
                  path="/users/create-password/:accountId/:userId"
                  element={<CreatePasswordView />}
                />
                <Route
                  path="/account-setup/set-psw/:accountId/:userId"
                  element={<CreatePasswordView />}
                />
                <Route
                  path="/log-in"
                  element={
                    <LogInView
                      onAuthenticated={(user) => {
                        setUser(user);
                        navigate("/");
                      }}
                    />
                  }
                />
                {/* Added by RWD on 3/9/2022 */}
                {/*<Route path="/*" element={<Navigate to="/" replace />} />*/}

                <Route path="/reset-psw" element={<ResetPasswordView />} />
              </Routes>
            </InventoryProvider>
          </ScrollProvider>
        </NavbarProvider>
      </ScreenProvider>
    </Suspense>
  );
}

// export {App as default, SERVER_URL};
export default App;
