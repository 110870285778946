import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import NavBar, {
  PROFILE_MENU_ITEM,
  DASHBOARD_MENU_ITEM,
  SETTINGS_MENU_ITEM,
  REPORTS_MENU_ITEM,
  // HISTORY_MENU_ITEM,
  ORDERS_MENU_ITEM,
  INVENTORY_MENU_ITEM,
  LOGOUT_MENU_ITEM,
  CHANGE_PASSWORD_MENU_ITEM,
  DEVICES_MENU_ITEM,
  HELP_MENU_ITEM,
  SUPPORT_MENU_ITEM,
    SUPER_ADMIN_MENU_ITEM
} from "../../partials/NavBar";

import AuthService from "../../services/AuthService";
import { NavbarContext } from "../../contexts/NavbarContext";
import { InventoryContext } from "../../contexts/InventoryContext";
import { ScreenContext } from "../../contexts/ScreenContext";
import HeaderForMobile from "../../components/HeaderForMobile/HeaderForMobile";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import ModalBackdrop from "../../components/Modal";
import styles from "./HomeView.module.css";
import UserService from "../../services/UserService";
import ConfigService from "../../services/ConfigService";

const HomeView = ({ user, onLogOut, updateUser }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuItem, setMenuItem] = useState(DASHBOARD_MENU_ITEM);

  const [showMobileMenu, setShowMobileMenu] = useState(true);
  const [clickedMenuItem, setClickedMenuItem] = useState(null);

  const {
    labelModificationInProgressViaContext,
    handleLabelModificationInProgressViaContext,
  } = useContext(NavbarContext);

  const { showSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const { t } = useTranslation("home");

  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
        setMenuItem(DASHBOARD_MENU_ITEM);
        break;
      case "/devices":
        setMenuItem(DEVICES_MENU_ITEM);
        break;
      case "/dispensing-orders":
        setMenuItem(ORDERS_MENU_ITEM);
        break;
      case "/help":
        setMenuItem(HELP_MENU_ITEM);
        break;
      case "/inventory/items":
        setMenuItem(INVENTORY_MENU_ITEM);
        break;
      case "/reports":
        setMenuItem(REPORTS_MENU_ITEM);
        break;
      case "/settings/facilities":
        setMenuItem(SETTINGS_MENU_ITEM);
        break;
      case "/settings/products":
        setMenuItem(SETTINGS_MENU_ITEM);
        break;
      case "/settings/products/create":
        setMenuItem(SETTINGS_MENU_ITEM);
        break;
      case "/settings/sites":
        setMenuItem(SETTINGS_MENU_ITEM);
        break;
      case "/settings/sites/create":
        setMenuItem(SETTINGS_MENU_ITEM);
        break;
      case "/settings/sites/stock-locations":
        setMenuItem(SETTINGS_MENU_ITEM);
        break;
      case "/settings/therapeuticEquivalencies":
        setMenuItem(SETTINGS_MENU_ITEM);
        break;
      case "/settings/users":
        setMenuItem(SETTINGS_MENU_ITEM);
        break;
      case "/super-admin":
        setMenuItem(SUPER_ADMIN_MENU_ITEM);
        break;
      default:
        setMenuItem(DASHBOARD_MENU_ITEM);
    }
  }, [location.pathname]);

  useEffect(() => {
    const loginLanguage = sessionStorage.getItem("loginLanguage");
    if (!loginLanguage) return;

    ConfigService.languages()
      .then((languages) => {
        (async () => {
          user.language = {
            type: loginLanguage,
            description: languages.find((o) => o.type === loginLanguage).description,
          };
          await UserService.update(user, user);
          sessionStorage.setItem("loginLanguage", "");
        })();
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", async (e) => {
      await AuthService.logOut(user);
      onLogOut(user);
    });
  }, [user]);

  useEffect(() => {
    const MENU_ITEM_MAPPING = {
      "dashboard": DASHBOARD_MENU_ITEM,
      "devices": DEVICES_MENU_ITEM,
      "inventory": INVENTORY_MENU_ITEM,
      "orders": ORDERS_MENU_ITEM,
      "reports": REPORTS_MENU_ITEM,
      "settings": SETTINGS_MENU_ITEM,
    };

    if (location.pathname === "/") {
      smScreen
        ? handleMenuSelection(DASHBOARD_MENU_ITEM)
        : handleMenuSelection(MENU_ITEM_MAPPING[user.defaultLandingPage.toLowerCase()] || DASHBOARD_MENU_ITEM);
    }

    // Needed for Cancel and back-arrow functionality to work properly in mobile views.
    if (smScreen) {
      switch (location.pathname) {
        case "/dashboard":
          setMenuItem(DASHBOARD_MENU_ITEM);
          break;
        case "/devices":
          setMenuItem(DEVICES_MENU_ITEM);
          break;
        case "/dispensing-orders":
          setMenuItem(ORDERS_MENU_ITEM);
          break;
        // case "/history":
        //   setMenuItem(HISTORY_MENU_ITEM);
        //   break;
        case "/inventory":
          setMenuItem(INVENTORY_MENU_ITEM);
          break;
        case "/reports":
          setMenuItem(REPORTS_MENU_ITEM);
          break;
        case "/settings/sites":
          setMenuItem(SETTINGS_MENU_ITEM);
          break;
        case "/help":
          setMenuItem(HELP_MENU_ITEM);
          break;
        default:
        // Do nothing
      }
    }
  }, [location, smScreen]);

  useEffect(() => {
    window.addEventListener("beforeunload", async (e) => {
      await AuthService.logOut(user);
      onLogOut(user);
    });
  }, [user]);

  useEffect(() => {
    if (
      smScreen &&
      (location.pathname === "/dashboard" ||
        location.pathname === "/devices" ||
        location.pathname === "/dispensing-orders" ||
        //location.pathname === "/history" ||
        location.pathname === "/settings/facilities" ||
        location.pathname === "/settings/products" ||
        location.pathname === "/inventory/items" ||
        location.pathname === "/reports" ||
        location.pathname === "/settings/sites" ||
        location.pathname === "/settings/users" ||
        location.pathname === "/help")
    ) {
      setShowMobileMenu(true);
    } else {
      setShowMobileMenu(false);
    }
  }, [location, smScreen]);

  const { updateFromDashboardGraphClick, updateRecordedStockItemId } =
    useContext(InventoryContext);

  const [navToggle, setNavToggle] = useState(false);

  const handleMenuSelection = (
    selectedMenuItem,
    ignoreLabelModification = false
  ) => {
    setClickedMenuItem(selectedMenuItem);
    if (!labelModificationInProgressViaContext || ignoreLabelModification) {
      if (ignoreLabelModification) {
        handleLabelModificationInProgressViaContext(false);
      }
      setNavToggle(!navToggle);
      if (selectedMenuItem === INVENTORY_MENU_ITEM) {
        updateFromDashboardGraphClick(false);
        updateRecordedStockItemId(-1);
        navigate("inventory");
      }
      if (selectedMenuItem === DASHBOARD_MENU_ITEM) {
        updateRecordedStockItemId(-1);
        navigate("dashboard");
      }
      if (selectedMenuItem === ORDERS_MENU_ITEM) {
        navigate("dispensing-orders", {
          state: { navToggle: navToggle },
        });
      }
      // if (selectedMenuItem === HISTORY_MENU_ITEM) {
      //   navigate("history");
      // }
      if (selectedMenuItem === REPORTS_MENU_ITEM) {
        navigate("reports", { state: { navToggle: navToggle } });
      }
      if (selectedMenuItem === SETTINGS_MENU_ITEM) {
        navigate("settings");
      }
      if (selectedMenuItem === PROFILE_MENU_ITEM) {
        navigate("profile");
      }
      if (selectedMenuItem === DEVICES_MENU_ITEM) {
        navigate("devices");
      }
      if (selectedMenuItem === HELP_MENU_ITEM) {
        navigate("help", { state: { navToggle: navToggle } });
      }
      if (selectedMenuItem === SUPPORT_MENU_ITEM) {
        navigate("support", { state: { navToggle: navToggle } });
      }
      if (selectedMenuItem === SUPER_ADMIN_MENU_ITEM) {
        navigate("super-admin", { state: { navToggle: navToggle } });
      }
      if (selectedMenuItem === CHANGE_PASSWORD_MENU_ITEM) {
        navigate("/change-password");
      }
      setMenuItem(selectedMenuItem);
      if (selectedMenuItem === LOGOUT_MENU_ITEM) {
        AuthService.logOut(user);
        localStorage.removeItem("user");
        onLogOut();
      }
    }
  };

  const Modal = (
    <ModalBackdrop
      width="100%"
      height="100%"
      top="0"
      left="0"
      padding="0"
      showButton={false}
      backgroundColor="#98a9bc4d"
      borderRadius="0"
    >
      <div className={styles.homeView__deleteWarningModal}>
        <p className={styles.homeView__deleteWarningModalMessage}>
          {t("labelEditorWarning")}
        </p>
        <div className={styles.homeView__ModalButtonsGroup}>
          <Button
            labelName={t("labelEditorCancel")}
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => {
              setClickedMenuItem(4);
            }}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName={t("labelEditorLeave")}
            minWidth={"123px"}
            isPrimary={true}
            onClick={() => handleMenuSelection(clickedMenuItem, true)}
          />
        </div>
      </div>
    </ModalBackdrop>
  );

  if (user && !smScreen) {
    return (
      <Fragment>
        {clickedMenuItem !== null &&
          clickedMenuItem !== 4 &&
          labelModificationInProgressViaContext &&
          Modal}
        <section className={styles.homeView__view}>
          <div className={styles.homeView__navBarContainer}>
            <NavBar
              user={user}
              onMenuSelection={handleMenuSelection}
              menuSelection={
                !labelModificationInProgressViaContext ? menuItem : 4
              }
            />
          </div>

          <section className={styles.homeView__content}>
            <Outlet />
          </section>
        </section>
      </Fragment>
    );
  } else if (user && smScreen) {
    return (
      <section className={styles["homeView__container--smallScreen"]}>
        {showMobileMenu &&
          (menuItem === DASHBOARD_MENU_ITEM ||
            menuItem === DEVICES_MENU_ITEM ||
            //menuItem === HISTORY_MENU_ITEM ||
            menuItem === INVENTORY_MENU_ITEM ||
            menuItem === ORDERS_MENU_ITEM ||
            menuItem === REPORTS_MENU_ITEM ||
            menuItem === HELP_MENU_ITEM ||
            menuItem === SETTINGS_MENU_ITEM) &&
          showSmScreenHeader && (
            <HeaderForMobile user={user} onLogOut={onLogOut} />
          )}
        {user && menuItem === DASHBOARD_MENU_ITEM ? (
          <section className={styles.homeView__content}>
            <Outlet />
          </section>
        ) : (
          <section className={styles.homeView__content}>
            <Outlet />
          </section>
        )}
      </section>
    );
  }

  {
    return null;
  }
};

export default HomeView;
