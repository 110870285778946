import { Rect as KonvaRect } from 'react-konva';
import { mmToPx } from "../functions/converters.js";
import { useEffect, useRef } from 'react';


const Rect = ({ obj, onDragStart, onDragMove, onDragEnd, onTransformEnd, viewOnly, attrs }) => {

  const rectRef = useRef(null);
  
  useEffect(() => {    
    rectRef.current.x(mmToPx(obj.x))
    rectRef.current.y(mmToPx(obj.y))
  }, [obj])

  const dragProps = onDragStart && onDragMove && onDragEnd ? {
    onDragStart,
    onDragMove,
    onDragEnd,
  } : {};

  const transformProps = onTransformEnd ? {
    onTransformEnd
  } : {};

  return (
    <KonvaRect
      ref={rectRef}
      objectType={"rect"}
      key={obj.id}
      id={obj.id}
      width={mmToPx(obj.width)}
      height={mmToPx(obj.height)}      
      rotation={obj.rotation}
      fill={obj.fill}
      stroke={obj.stroke}
      strokeWidth={mmToPx(obj.strokeWidth)}
      strokeScaleEnabled={false}
      listening={!viewOnly}
      locked={obj.locked}
      visible={obj.visible}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}
      {...(obj.rounded ? {cornerRadius: mmToPx(obj.cornerRadius)} : {})}
      {...dragProps}
      {...transformProps}
      {...(attrs ? attrs : {})}
    />
  )
}

export default Rect;