import React, { useRef } from "react";
import { Group as KonvaGroup, Rect as KonvaRect } from "react-konva";
import { mmToPx } from "../functions/converters.js";
import Barcode from "./Barcode";
import Image from "./Image";
import Text from "./Text";
import Rect from "./Rect";
import GridLayout from "./GridLayout.js";

const Group = ({
  obj,
  updateObject,
  selectObject,
  onDragStart,
  onDragMove,
  onDragEnd,
  onTransformEnd,
  viewOnly,
  setTransformerReset,
  attrs,
}) => {  
  const groupRef = useRef(null);
  
  const dragProps =
    onDragStart && onDragMove && onDragEnd
      ? {
          onDragStart,  
          onDragMove,
          onDragEnd,
        }
      : {};

  const transformProps = onTransformEnd
    ? {
        onTransformEnd,
      }
    : {};

  const components = {
    rect: Rect,
    text: Text,
    image: Image,
    barcode: Barcode,
    gridLayout: GridLayout,
    group: Group,
  };

  return (
    <>
      <KonvaGroup
        ref={groupRef}
        objectType={"group"}
        key={obj.id}
        id={obj.id}
        x={mmToPx(obj.x)}
        y={mmToPx(obj.y)}
        width={mmToPx(obj.width)}
        height={mmToPx(obj.height)}      
        rotation={obj.rotation}
        listening={!viewOnly}
        locked={obj.locked}
        visible={obj.visible}
        draggable={!obj.locked && onDragStart && onDragEnd}
        {...dragProps}
        {...transformProps}        
        {...(attrs ? attrs : {})}
      >
        <KonvaRect
          objectType={"group"}
          key={`${obj.id}`}
          x={0}
          y={0}
          width={mmToPx(obj.width)}
          height={mmToPx(obj.height)}
          //fill={"white"}
          stroke={!viewOnly && obj.showOutline ? "gray" : null}
          strokeWidth={1}
          dash={[2, 10]}
        />
        {obj.objects.toReversed().map((innerObj) => {
          const Component = components[innerObj.type];
          if (!Component) return null;
          return (
            <Component
              obj={innerObj}
              key={innerObj.id}
              updateObject={updateObject}
              selectObject={selectObject}
              onDragStart={onDragStart}
              onDragMove={onDragMove}
              onDragEnd={onDragEnd}
              onTransformEnd={onTransformEnd}
              viewOnly={viewOnly}
            />
          );
        })}
      </KonvaGroup>
    </>
  );
};

export default Group;
