import React, { useState, useEffect, useContext, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { Button } from "antd";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import TextInput from "../TextInput";
import Spacer from "../Spacer";
import { ReactComponent as RemoveIcon } from "../../assets/x.svg";
import { ScreenContext } from "../../contexts/ScreenContext";
import "./TimeCodes.css";
import styles from "./TimeCodes.module.css";
import { useTranslation } from "react-i18next";
import DropdownInput from "../DropDownInput/DropdownInput";

const TimeCodes = ({
  timeCodes,
  onLabel,
  onStartTime,
  onStopTime,
  onAdd,
  onRemove,
  onTimeCodesError,
  timeCodesErrors,
  onTimeCodesErrors,
  languageList,
}) => {
  const { t } = useTranslation("createFacility");
  const [errorsInitialized, setErrorsInitialized] = useState(false);
  const [errorsUpdatedAfterInitiation, setErrorsUpdatedAfterInitiation] =
    useState(false);

  const [timeCodesTotalError, setTimeCodesTotalError] = useState("")

  const [language, setLanguage] = useState("en");

  const { largeScreen } = useContext(ScreenContext);
  const lgScreen = useMediaQuery(largeScreen);


    const convertToMinutes = (time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };
    

    const isOverlapping = (range1, range2) => {
      // https://stackoverflow.com/a/67009163/7121545
      if (range1.startMin > range1.stopMin ) {
        return range1.stopMin >= range2.startMin;

      } else if (range2.stopMin < range2.startMin) {
        return range2.startMin <= range1.stopMin || range2.stopMin >= range1.startMin;
      } else {
        return range2.startMin <= range1.stopMin && range2.stopMin >= range1.startMin;
      }
    };

    const validateTotal = () => {
      if (timeCodes.length === 0) {
        return ""
      }

      const orderedTimeCodes = timeCodes
        .filter(tc => tc.startTime && tc.stopTime)
        .map(tc => ({
          ...tc,
          startMin: convertToMinutes(tc.startTime),
          stopMin: convertToMinutes(tc.stopTime),
        }))
        .sort((a, b) => a.startMin - b.startMin);
      
      const minutesInDay = 24 * 60;
      let coveredMinutes = 0;

      for (let i = 0; i < orderedTimeCodes.length; i++) {
        const current = orderedTimeCodes[i];
        const next = i < orderedTimeCodes.length - 1 
          ? orderedTimeCodes[i + 1] 
          : orderedTimeCodes[0]

        if (orderedTimeCodes.length > 1 && isOverlapping(current, next)) {
          return t("timeCodesOverlapping");
        }

        if (current.startMin <= current.stopMin) {
          coveredMinutes += (current.stopMin + 1) - current.startMin;
        } else {
          coveredMinutes += (minutesInDay - current.startMin) + (current.stopMin + 1);
        }
      }
    
      if (coveredMinutes < minutesInDay) {
        return t("timeCodesCoverage")
      }
    
      return "";
  }

  useEffect(() => {
    const errorTotal = validateTotal()
    setTimeCodesTotalError(errorTotal)    
  }, [timeCodes])

  useEffect(() => {
    if (!errorsInitialized) {
      let tempErrors = [];
      for (let i = 0; i < timeCodes.length; i++) {
        tempErrors.push({
          labelError: "",
          startTimeError: "",
          stopTimeError: "",
        });
      }
      onTimeCodesErrors(tempErrors);
      setErrorsInitialized(true);
    }
  }, [errorsInitialized, timeCodes, onTimeCodesErrors]);

  useEffect(() => {
    if (errorsInitialized) {
      let tempErrors = [...timeCodesErrors];
      if (timeCodes.length > tempErrors.length) {
        tempErrors.push({
          labelError: t("noLabelError"),
          startTimeError: t("startTimeError"),
          stopTimeError: t("stopTimeError"),
        });
        onTimeCodesErrors(tempErrors);
      }
    }
  }, [timeCodes.length, errorsInitialized, timeCodesErrors, onTimeCodesErrors]);

  useEffect(() => {
    let errorFound = false;
    for (const obj of timeCodesErrors) {
      if (obj.labelError || obj.startTimeError || obj.stopTimeError) {
        onTimeCodesError(true);
        errorFound = true;
        break;
      }
    }
    if (errorFound || timeCodesTotalError) {
      onTimeCodesError(true);
    } else {
      onTimeCodesError(false);  
    }
    
  }, [timeCodesErrors, timeCodesTotalError, onTimeCodesError]);

  useEffect(() => {
    const updateErrorsAllTypes = (index, tempErrors) => {
      if (errorsInitialized) {
        if (!timeCodes[index].label) {
          tempErrors[index].labelError = t("noLabelError");
        } else {
          tempErrors[index].labelError = "";
        }

        if (!timeCodes[index].startTime) {
          tempErrors[index].startTimeError = t("startTimeError");
        } else {
          tempErrors[index].startTimeError = "";
        }

        if (!timeCodes[index].stopTime) {
          tempErrors[index].stopTimeError = t("stopTimeError");
        } else {
          tempErrors[index].stopTimeError = "";
        }
      }
    };

    if (
      !errorsUpdatedAfterInitiation &&
      errorsInitialized &&
      timeCodesErrors.length === timeCodes.length
    ) {
      let tempErrors = [...timeCodesErrors];
      for (let i = 0; i < timeCodes.length; i++) {
        updateErrorsAllTypes(i, tempErrors);
      }
      setErrorsUpdatedAfterInitiation(true);
      onTimeCodesErrors(tempErrors);
    }
  }, [errorsInitialized, timeCodes, timeCodesErrors, errorsUpdatedAfterInitiation, onTimeCodesErrors]);

  const createTimeCode = () => {
    return {
      label: {},
      startTime: "",
      stopTime: "",
    };
  };

  const updateErrors = (index, type) => {
    let tempErrors = [...timeCodesErrors];

    if (type === "label" && !timeCodes[index].label) {
      tempErrors[index].labelError = t("noLabelError");
    } else if (type === "label") {
      tempErrors[index].labelError = "";
    }

    if (type === "startTime" && !timeCodes[index].startTime) {
      tempErrors[index].startTimeError = t("startTimeError");
    } else if (type === "startTime") {
      tempErrors[index].startTimeError = "";
    }

    if (type === "stopTime" && !timeCodes[index].stopTime) {
      tempErrors[index].stopTimeError = t("stopTimeError");
    } else if (type === "stopTime") {
      tempErrors[index].stopTimeError = "";
    }

    onTimeCodesErrors(tempErrors);
  };

  const handleChangeLabelName = (val, index, language) => {
    onLabel(val, index, language);
    updateErrors(index, "label");
  };

  const handleChangeStartTime = (val, index) => {
    onStartTime(val, index);
    updateErrors(index, "startTime");
  };

  const handleChangeStopTime = (val, index) => {
    onStopTime(val, index);
    updateErrors(index, "stopTime");
  };

  const handleRemoveTimeCode = (index) => {
    onRemove(index);
    timeCodesErrors.splice(index, 1);
  };

  return (
    <div>
      <div style={{width: "38.5%"}}>
        <DropdownInput
          value={
            language 
              ? t(language, {ns: "languages"}) 
              : ""
          }
          labelName={t("languageLabel")}
          validationText={""}
          isEnabled={true}
          placeholder={""}
          onSelection={(listItem) => {
            setLanguage(listItem.type);
          }}
          listElements={languageList}
          display={"displayName"}
        />
      </div>
      {timeCodes.length && timeCodesTotalError ? (
        <p
          className={styles.TimeCodes__errorTotal}
        >
          {timeCodesTotalError}
        </p>
      ) : null}
      {timeCodes.map((timeCode, index) => {
        return (
          <div key={index} className={styles.TimeCodes__row}>
            <div className={styles.TimeCodes__col}>
              <p className={styles.TimeCodes__timeLabel}>{t("labelLabel")}</p>
              <div className={styles.TimeCodes__inputGroup}>
                <TextInput
                  isEnabled={true}
                  type={"text"}
                  value={timeCode.label[language] || ""}
                  labelName={timeCode.labelName}
                  onChange={(val) => handleChangeLabelName(val, index, language)}
                />
                {timeCodesErrors.length && timeCodesErrors[index] && timeCodesErrors[index].labelError && (
                  <p
                    className={`${styles.TimeCodes__errorMessage} ${styles["TimeCodes__errorMessage--label"]}`}
                  >
                    {timeCodesErrors[index].labelError}
                  </p>
                )}
              </div>
            </div>
            <Spacer space={10} unit={"%"} />
            <div className={styles.TimeCodes__col}>
              <div className={styles.TimeCodes__timeCodesContainer}>
                <div className={styles.TimeCodes__timePickerContainer}>
                  <p className={styles.TimeCodes__timeLabel}>{t("startTimeLabel")}</p>
                  <TimePicker
                    onChange={(val) => handleChangeStartTime(val, index)}
                    value={
                      timeCode.startTime !== ""
                        ? dayjs(timeCode.startTime, "HH:mm")
                        : null
                    }
                    format="HH:mm"
                    showTime={{
                      format: "HH:mm",
                    }}
                    showNow={false}
                    allowClear={false}
                    style={{ width: lgScreen ? 196 : 100 }}
                  />
                  {timeCodesErrors.length &&
                    timeCodesErrors[index] &&
                    timeCodesErrors[index].startTimeError && (
                      <p
                        className={`${styles.TimeCodes__errorMessage} ${styles["TimeCodes__errorMessage--time"]}`}
                      >
                        {timeCodesErrors[index].startTimeError}
                      </p>
                    )}
                </div>
                <div className={styles.TimeCodes__timePickerContainer}>
                  <p className={styles.TimeCodes__timeLabel}>{t("stopTimeLabel")}</p>
                  <TimePicker
                    onChange={(val) => handleChangeStopTime(val, index)}
                    value={
                      timeCode.stopTime !== ""
                        ? dayjs(timeCode.stopTime, "HH:mm")
                        : null
                    }
                    format="HH:mm"
                    showTime={{
                      format: "HH:mm",
                    }}
                    showNow={false}
                    allowClear={false}
                    style={{ width: lgScreen ? 196 : 100 }}
                  />
                  {timeCodesErrors.length &&
                    timeCodesErrors[index] &&
                    timeCodesErrors[index].stopTimeError && (
                      <p
                        className={`${styles.TimeCodes__errorMessage} ${styles["TimeCodes__errorMessage--time"]}`}
                      >
                        {timeCodesErrors[index].stopTimeError}
                      </p>
                    )}
                </div>
                <div
                  className={styles.TimeCodes__remove_button}
                  onClick={() => handleRemoveTimeCode(index)}
                  style={{ cursor: "pointer" }}
                >
                  <div className={styles.TimeCodes__removeIconContainer}>
                    <RemoveIcon fill={"#121A2D"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className={`${styles.TimeCodes__antButtonContainer} TimeCodes__antButtonContainer`}
      >
        <Button
          onClick={() => onAdd(createTimeCode())}
          style={{ width: "fit-content", borderRadius: 30, height: 35 }}
        >
          <span className={styles.TimeCodes__addIcon}>+</span> {t("addTimeCodeLabel")}
        </Button>
      </div>
    </div>
  );
};

export default TimeCodes;
