import React, { useState } from "react";
import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";
import Input from "./Input";
import Checkbox from "../../../components/Checkbox";

const GridLayoutProperties = ({ unit, selectedObject, updateObject }) => {
  const { t } = useTranslation("labelEditor");

  const [rows, setRows] = useState(selectedObject.rows);
  const [cols, setCols] = useState(selectedObject.cols);

  const handleInputChange = (value, name, dataset) => {
    let { key } = dataset;

    updateObject({
      ...selectedObject,
      [name]: key ? { ...selectedObject[name], [key]: value } : value,
    });
  };

  const handleSizeChange = (value, name) => {
    const attr = name === "width" ? "widths" : "heights";

    updateObject({
      ...selectedObject,
      [name]: value,
      [attr]: selectedObject[attr].slice().map(val => val * (value / selectedObject[name]))
    });
  };

  const handleShowGrid = () => {
    updateObject({
      ...selectedObject,
      showGrid: !selectedObject.showGrid,
    });
  };
  
  const handleIndividualSizeChange = (value, name, dataset) => {
    let { index } = dataset;
    index = Number(index)

    const attr = name === "width" ? "widths" : "heights";

    updateObject({
      ...selectedObject,
      [name]: value,
      [attr]: selectedObject[attr].map((oldValue, i) => 
        i === index ? value : oldValue
      )
    });
  };
  
  const handleRowsChange = (value, name) => {
    const newRows = value;
    const prevRows = selectedObject.rows;

    if (newRows < 1 || selectedObject.objects.some(o => o.row >= newRows)) {
      return
    }

    setRows(newRows)
    updateObject({
      ...selectedObject,
      [name]: value,
      rows: newRows,
      heights:
        newRows > prevRows
          ? [...selectedObject.heights, selectedObject.heights.at(-1)]
          : selectedObject.heights.slice(0, newRows),
      objects: selectedObject.objects.slice(),
      name: `${t("gridLayoutLabel")} (${cols}x${newRows})`,
    });
  };

  const handleColsChange = (value, name) => {
    const newCols = value;
    const prevCols = selectedObject.cols;

    if (newCols < 1 || selectedObject.objects.some(o => o.col >= newCols)) {
      return
    }

    setCols(newCols)
    updateObject({
      ...selectedObject,
      [name]: value,
      cols: newCols,
      widths:
        newCols > prevCols
          ? [...selectedObject.widths, selectedObject.widths.at(-1)]
          : selectedObject.widths.slice(0, newCols),
      objects: selectedObject.objects.slice(),
      name: `${t("gridLayoutLabel")} (${newCols}x${rows})`,
    });
  };


  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("widthLabel")}
          </label>
          <Input
            value={selectedObject.width}
            name="width"
            type="number"
            unit={unit}
            onChange={handleSizeChange}
            disabled={selectedObject.locked}
            step={0.1}
            min={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("heightLabel")}
          </label>
          <Input
            value={selectedObject.height}
            name="height"
            type="number"
            unit={unit}
            onChange={handleSizeChange}
            disabled={selectedObject.locked}
            step={0.1}
            min={0.1}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X</label>
          <Input
            value={selectedObject.x}
            name="x"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            step={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y</label>
          <Input
            value={selectedObject.y}
            name="y"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            step={0.1}
          />
        </div>
      </div>

      <div className={styles.Properties__propertyFull}>
        <div className={styles.Properties__toggle}>
          <label className={styles.Properties__label}>{t("gridLinesLabel")}</label>
          <Checkbox
            labelName={t("showLabel")}
            labelColor={"var(--black)"}
            isOn={selectedObject.showGrid}
            onCheck={handleShowGrid}
            disabled={selectedObject.locked}
          />
        </div>
      </div>
      
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("leftMarginLabel")}</label>
          <Input
            value={selectedObject.margin.left}
            name="margin"
            data-key="left"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            min={0}
            step={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("topMarginLabel")}</label>
          <Input
            value={selectedObject.margin.top}
            name="margin"
            data-key="top"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            min={0}
            step={0.1}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("colsLabel")}</label>
          <Input
            value={selectedObject.cols}
            name="cols"
            type="number"
            onChange={handleColsChange}
            disabled={selectedObject.locked}
            min={1}
            step={1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("rowsLabel")}</label>
          <Input
            value={selectedObject.rows}
            name="rows"
            type="number"
            onChange={handleRowsChange}
            disabled={selectedObject.locked}
            min={1}
            step={1}
          />
        </div>
      </div>

      {Array(
        Math.max(selectedObject.widths.length, selectedObject.heights.length)
      )
        .fill(null)
        .map((_, i) => {
          return (
            <div key={i} className={styles.Properties__spacedRow}>
              {i < selectedObject.widths.length ? (
                <div className={styles.Properties__property}>
                  <label className={styles.Properties__label}>
                    {t("colLabel")} {i + 1}
                  </label>
                  <Input
                    value={selectedObject.widths[i]}
                    name="width"
                    data-index={i}
                    type="number"
                    unit={unit}
                    onChange={handleIndividualSizeChange}
                    disabled={selectedObject.locked}
                    step={0.1}
                    min={0.1}
                  />
                </div>
              ) : (
                <div className={styles.Properties__property}>
                  <input
                    className={styles.Properties__input}
                    style={{opacity: 0}}
                  />
                </div>
              )}

              {i < selectedObject.heights.length ? (
                <div className={styles.Properties__property}>
                  <label className={styles.Properties__label}>
                  {t("rowLabel")} {i + 1}
                  </label>
                  <Input
                    value={selectedObject.heights[i]}
                    name="height"
                    data-index={i}
                    type="number"                    
                    unit={unit}
                    onChange={handleIndividualSizeChange}
                    disabled={selectedObject.locked}
                    step={0.1}
                    min={0.1}
                  />
                </div>
              ) : (
                <div className={styles.Properties__property}>
                  <input
                    className={styles.Properties__input}
                    style={{opacity: 0}}
                  />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default GridLayoutProperties;
