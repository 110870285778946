import styled from "styled-components";
import { ReactComponent as MoreActions } from "../assets/more-actions.svg";
import Spacer from "./Spacer";
import { ReactComponent as GridImage } from "../assets/grid-outline.svg";
import React, { useState } from "react";
import Popup from "./PopupMenu/Popup";
import { Rings } from "react-loader-spinner";

//region CSS
const TemplateContainer = styled.button`
  width: 169px;
  background: #ffffff;
  border: 1px dashed #006874;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
    border: 2px dashed #006874;
  }
`;
const LabelContainer = styled.div`
  width: 169px;
  background: #ffffff;
  border: 1px solid #006874;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  &:focus,
  &:active {
    outline: none;
    border: 2px solid #006874;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 640px) {
    width: 80px;
  }
  @media (min-width: 641px) {
    width: 169px;
  }
  @media (max-width: 640px) {
    height: 116px;
  }
  @media (min-width: 641px) {
    // height: 183px;
  }

  @media (max-width: 640px) {
    margin-right: 12px;
  }
  @media (min-width: 641px) {
    // margin-right: 40px;
  }
`;
const Label = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #454b60;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
`;
const ImageContainer = styled.div`
  @media (min-width: 641px) {
    width: 70px;
  }
  @media (min-width: 641px) {
    height: 70px;
  }
  @media (min-width: 641px) {
    margin-top: 15px;
  }
  object-fit: contain;
  overflow: hidden;
  cursor: pointer;
`;
//endregion

const LabelTile = ({
  isTemplate,
  imageName,
  imageFillColor,
  title,
  onCreateTemplate,
  actions,
  onAction,
  onSelectLabel,
  onClickedLabel,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleKeyUp = (event, container) => {
    // If report is focused, open it if the Enter key is pressed
    const code = event.keyCode || event.which;
    if (code === 13) {
      if (container === "Template") {
        onCreateTemplate();
      } else if (container === "Label") {
        onSelectLabel();
      }
    }
  };

  if (isTemplate) {
    return (
      <Container>
        <TemplateContainer onKeyUp={(event) => handleKeyUp(event, "Template")}>
          <Header>
            <MoreActions fill={"#A7BBCF"} />
          </Header>
          <ImageContainer>
            {imageName === "barChart" ? ( 
              <GridImage stroke={imageFillColor} />
            ) : imageName === "fetching" ? (
              <Rings color="#089BAB" height={70} width={70} />
            ) : null}
          </ImageContainer>
        </TemplateContainer>
        <Spacer space={13} unit={"px"} />
        <Label>{title}</Label>
      </Container>
    );
  } else {
    return (
      <>
        <Container>
          <LabelContainer onKeyUp={(event) => handleKeyUp(event, "Label")}>
            <Header>
              {actions.length > 0 && (
                <MoreActions
                  style={{ zIndex: 50 }}
                  fill={"#089BAB"}
                  onClick={(e) => {
                    setShowPopup(true);
                  }}
                />
              )}
              {showPopup && (
                <Popup
                  onAction={(action) => {
                    onAction(action);
                    setShowPopup(false);
                  }}
                  menuItems={actions}
                  onClose={() => setShowPopup(false)}
                  rightJustify={true}
                />
              )}
            </Header>
            <ImageContainer onClick={onClickedLabel}>
              {imageName === "barChart" && (
                <GridImage stroke={imageFillColor} />
              )}
            </ImageContainer>
          </LabelContainer>
          <Spacer space={13} unit={"px"} />
          <Label>{title}</Label>
        </Container>
      </>
    );
  }
};

export default LabelTile;
