const PRECISION = 5
const MM_TO_PX = 3.7795275591
const IN_TO_PX = 96
const IN_TO_MM = 25.4


export const pxToMm = (value) => {
  return Math.round(value / MM_TO_PX * 10) / 10;
}

export const mmToPx = (value) => {
  let px = Math.round((value * MM_TO_PX));
  return px ? px : value ? 1 : 0;
}

export const roundToDecimal = (value, decimal = PRECISION) => {
  const factor = Math.pow(10, decimal);
  return Math.round(value * factor) / factor;
}


export const pxToUnit = (value, unit, decimal = PRECISION) => {
  if (unit === "in") {
    return roundToDecimal(value / IN_TO_PX, decimal);
  } else if (unit === "mm") {
    return roundToDecimal(value / MM_TO_PX, decimal);
  }
  return value
}

export const mmToUnit = (value, unit, decimal = PRECISION) => {
  if (unit === "in") {
    return roundToDecimal(value / IN_TO_MM, decimal);
  }
  return roundToDecimal(value, decimal)
}

export const unitToMm = (value, unit, decimal = PRECISION) => {
  if (unit === "in") {
    return roundToDecimal(value * IN_TO_MM, decimal);
  }
  return roundToDecimal(value, decimal)
} 