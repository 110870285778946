import { useRef, useEffect } from 'react';
import { Text as KonvaText } from 'react-konva';
import { mmToPx } from "../functions/converters.js";


const Text = ({ obj, onDragStart, onDragMove, onDragEnd, onTransformEnd, viewOnly, attrs }) => {  
  const textRef = useRef(null);

  useEffect(() => {
    textRef.current.x(mmToPx(obj.x))
    textRef.current.y(mmToPx(obj.y))
  }, [obj])


  const dragProps = onDragStart && onDragMove && onDragEnd ? {
    onDragStart,
    onDragMove,
    onDragEnd,
  } : {};

  const handleTransform = (e) => {
    const node = e.target;
    node.setAttrs({
      width: node.width() * node.scaleX(),
      height: node.height() * node.scaleY(),
      scaleX: 1,
      scaleY: 1
    });
  }
  
  const transformProps = onTransformEnd ? {
    onTransform: handleTransform,
    onTransformEnd
  } : {};


  return (
    <KonvaText
      ref={textRef}
      objectType={"text"}
      key={obj.id}
      id={obj.id}
      width={mmToPx(obj.width)}
      height={mmToPx(obj.height)}      
      rotation={obj.rotation}
      text={Object.hasOwn(obj, "value") ? obj.value : obj.defaultValue}
      fontSize={obj.fontSize}
      fontFamily={obj.fontFamily}
      fontStyle={obj.fontStyle}
      textDecoration={obj.textDecoration}
      wrap={obj.wrap}
      wrapLines={obj.wrapLines}
      align={obj.align}
      strokeScaleEnabled={false}
      fill={obj.fill}
      listening={!viewOnly}
      locked={obj.locked}
      visible={obj.visible}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}      
      {...dragProps}
      {...transformProps}
      {...(attrs ? attrs : {})}
    />
  )
}

export default Text;