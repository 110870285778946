import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  Button as AntdButton,
  Checkbox,
  Col,
  Divider,
  Input,
  Menu,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackButton } from "../../../assets/back_icon.svg";
import { ReactComponent as PillIcon } from "../../../assets/capsule-and-pill.svg";
import Button from "../../../components/Button";
import DateRangePickerComponent from "../../../components/DateRangePickerComponent";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import ErrorBanner from "../../../components/ErrorBanner";
import SearchBar from "../../../components/SearchBar";
import tableStyles from "../../../components/Table/Table.module.css";
import WaitIndicator from "../../../components/WaitIndicator";
import ProductFieldController from "../../../controllers/ProductFieldController";
import { dateSelectionRangeTimeAdjustment } from "../../../functions/dates";
import { getLocalizedDate } from "../../../functions/localization";
import RoleCheckService from "../../../services/RoleCheckService";
import CreateProduct from "../../Product/CreateProduct2";
import { NavbarContext } from "./../../../contexts/NavbarContext";
import AdjustQuantity from "./AdjustQuantity";
import AdminDateTime from "./AdminDateTime";
import ChangePatientLanguage from "./ChangePatientLanguage";
import ChangePatientLocation from "./ChangePatientLocation";
import ChangePriority from "./ChangePriority";
import styles from "./PendingDispensingOrder.module.css";
import PendingDispensingOrderController from "./PendingDispensingOrderController";
import PendingDispensingOrderItem from "./PendingDispensingOrderItem";
import SubstituteProduct from "./SubstituteProduct";

const PendingDispensingOrder = ({
  user,
  site,
  dispensingOrder,
  dispensingOrders,
  selectedPackagingMethods,
  readonly,
}) => {
  const { t } = useTranslation("pendingDispensingOrder");

  const { setState } = useContext(NavbarContext);

  const productFieldController = new ProductFieldController();

  const navigate = useNavigate();
  const _controllerRef = useRef(new PendingDispensingOrderController(user));
  const [error, setError] = useState("");

  const [updateTable, setUpdateTable] = useState(false);
  const updateTableRef = useRef(null);
  updateTableRef.current = updateTable;

  const [localDispensingOrderItems, setLocalDispensingOrderItems] = useState(
    []
  );
  const localDispensingOrderItemsRef = useRef(null);
  localDispensingOrderItemsRef.current = localDispensingOrderItems;

  const [searchText, setSearchText] = useState("");
  const [currentDataSource, setCurrentDataSource] = useState(null);
  const [filterType, setFilterType] = useState("ALL");
  const [dispensingData, setDispensingData] = useState([]);
  const [itemError, setItemError] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [patientSpecialDateInitialized, setPatientSpecialDateInitialized] =
    useState(false);

  const [displaySubstituteProductModal, setDisplaySubstituteProductModal] =
    useState(false);
  const displaySubstituteProductModalRef = useRef(null);
  displaySubstituteProductModalRef.current = displaySubstituteProductModal;

  const [displayAddProductModal, setDisplayAddProductModal] = useState(false);
  const displayAddProductModalRef = useRef(null);
  displayAddProductModalRef.current = displayAddProductModal;

  const [displayAdminDateTimeModal, setDisplayAdminDateTimeModal] =
    useState(false);
  const displayAdminDateTimeModalRef = useRef(null);
  displayAdminDateTimeModalRef.current = displayAdminDateTimeModal;

  const [displayAdjustQuantityModal, setDisplayAdjustQuantityModal] =
    useState(false);
  const displayAdjustQuantityModalRef = useRef(null);
  displayAdjustQuantityModalRef.current = displayAdjustQuantityModal;

  const [
    displayChangePatientLocationModal,
    setDisplayChangePatientLocationModal,
  ] = useState(null);
  const displayChangePatientLocationModalRef = useRef(null);
  displayChangePatientLocationModalRef.current =
    displayChangePatientLocationModal;

  const [
    displayChangePatientLanguageModal,
    setDisplayChangePatientLanguageModal,
  ] = useState(null);

  const [selectedItem, setSelectedItem] = useState("");
  const [displayChangePriorityModal, setDisplayChangePriorityModal] =
    useState(false);

  const [fetching, setFetching] = useState(false);
  const defaultWaitIndicatorMessage = t("fetchingOrder");
  const [waitIndicatorMessage, setWaitIndicatorMessage] = useState(
    defaultWaitIndicatorMessage
  );
  const [showDateTimeRangePicker, setShowDateTimeRangePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState(null);
  const [startDateString, setStartDateString] = useState("");
  const [stopDateString, setStopDateString] = useState("");

  const dispensingOrderIdSplit = dispensingOrder.dispensingOrderId.split("~");
  const [dispensingOrderId, setDispensingOrderId] = useState(
    dispensingOrderIdSplit[0]
  );
  const [dispensingOrderIdComplement, setDispensingOrderIdComplement] =
    useState(dispensingOrderIdSplit[1] ?? "");

  const [menuVisible, setMenuVisible] = useState(dispensingOrders.length > 0);
  const allDispensingOrders = [...dispensingOrders, dispensingOrder];
  const [splitOrderMenuItems, setSplitOrderMenuItems] = useState(
    allDispensingOrders.map((o) => ({
      id: o._id,
      dispensingOrderId: o.dispensingOrderId,
      dispensingOrder: o,
      dispensingOrderItems: [],
    }))
  );

  const [splitOrderMenuItemsSelectedKeys, setSplitOrderMenuItemsSelectedKeys] =
    useState([dispensingOrder.dispensingOrderId]);

  const calculateLocalizedDate = (dispensingOrder) => {
    if (dispensingOrder?.createdAt) {
      const date = new Date(dispensingOrder?.createdAt);
      const country = user?.defaultSite?.shippingAddress?.country;
      const localizedDate = getLocalizedDate(date, country, true);
      return localizedDate;
    }
    return "";
  };

  const [formatedCreatedAtLocalTime, setFormatedCreatedAtLocalTime] = useState(
    calculateLocalizedDate(dispensingOrder)
  );

  const updateDispensingOrderItems = (fetching = true) => {
    setFetching(fetching);
    _controllerRef.current
      .getDetails(
        allDispensingOrders.map((o) => o._id),
        site
      )
      .then((details) => {
        if (details.dispensingOrder?.createdAt) {
          const date = new Date(dispensingOrder.createdAt);
          const country = user?.defaultSite?.shippingAddress?.country;
          const localizedDate = getLocalizedDate(date, country, true);
          setFormatedCreatedAtLocalTime(localizedDate);
        }

        for (const o of splitOrderMenuItems) {
          o.dispensingOrderItems = details.dispensingOrderItems.filter(
            (doi) =>
              doi.dispensingOrder.dispensingOrderId === o.dispensingOrderId
          );
        }

        const selected = splitOrderMenuItems.find(
          (o) => o.dispensingOrderId === splitOrderMenuItemsSelectedKeys[0]
        );

        setLocalDispensingOrderItems(selected.dispensingOrderItems);

        setUpdateTable(false);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  // Revised by RWD on 7/31/2024
  useEffect(() => {
    // Used only for "Patient - Special" date initialization
    if (!patientSpecialDateInitialized && filterType === "Patient - Special") {
      if (dispensingData?.length) {
        // Date initialization to max date
        let startDateLocal = new Date(new Date(8640000000000000));

        dispensingData.forEach((arrayItem) => {
          // arrayItem: [{…}, {…}, 'Retail', 'LOW', 'FFF, FFF', '', '12345', '00904530680', 'DIPHENHYDRAMINE 25mg', '2024-07-27', '11:55', 1, '', {…}, '66a51880a8d1d44d494bbb46']

          const yearDayMonthArray = arrayItem[9].split("-");
          const [year, month, day] = yearDayMonthArray;
          const nextDateLocal = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date

          // assign the earliest date in the array
          if (nextDateLocal.getTime() < startDateLocal.getTime()) {
            startDateLocal = nextDateLocal;
          }
        });

        let stopDateLocal = new Date(startDateLocal);

        // get a 7-day date range
        stopDateLocal.setDate(startDateLocal.getDate() + 6);

        setSelectionRange(
          dateSelectionRangeTimeAdjustment(startDateLocal, stopDateLocal)
        );
        setPatientSpecialDateInitialized(true);
      }
    }
  }, [filterType, dispensingData, patientSpecialDateInitialized]);

  useEffect(() => {
    if (selectionRange?.startDate && selectionRange?.endDate) {
      const startDateObj = new Date(selectionRange.startDate);
      const stopDateObj = new Date(selectionRange.endDate);

      setStartDateString(
        getLocalizedDate(
          startDateObj,
          user?.defaultSite?.shippingAddress?.country
        )
      );
      setStopDateString(
        getLocalizedDate(
          stopDateObj,
          user?.defaultSite?.shippingAddress?.country
        )
      );
    }
  }, [selectionRange]);

  useEffect(() => {
    updateDispensingOrderItems();
  }, []);

  const handleDateRangeSelect = (ranges) => {
    const startDate = new Date(ranges.selection.startDate);
    const endDate = new Date(ranges.selection.endDate);
    setSelectionRange(dateSelectionRangeTimeAdjustment(startDate, endDate));
  };

  useEffect(() => {
    parseData();
  }, [
    localDispensingOrderItems,
    searchText,
    filterType,
    displayChangePriorityModal,
    startDateString,
    stopDateString,
  ]);

  const parseData = () => {
    if (dispensingOrder.errorExists) {
      setItemError(true);
    }

    const records = localDispensingOrderItems

      .filter((o) =>
        filterType === "ALL" || filterType === "Patient - Special"
          ? true
          : o.errorExists
      )

      .filter((o) => {
        if (
          filterType === "Patient - Special" &&
          o?.adminDate &&
          selectionRange?.startDate &&
          selectionRange?.endDate
        ) {
          const [year, month, day] = o.adminDate.split("-");

          const adminDateObject = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date

          return (
            adminDateObject >= selectionRange.startDate &&
            adminDateObject <= selectionRange.endDate
          );
        }
        return true;
      })

      .filter((o) => {
        if (!searchText) return true;

        const { patientInfo, product } = o;
        const upperSearchText = searchText.toUpperCase();

        return (
          o.facility?.name?.toUpperCase().includes(upperSearchText) ||
          o.priority?.toUpperCase().includes(upperSearchText) ||
          patientInfo?.id?.includes(searchText) ||
          patientInfo?.name?.toUpperCase().includes(upperSearchText) ||
          o.rxNumber?.toUpperCase().includes(upperSearchText) ||
          product?.packageId?.toUpperCase().includes(upperSearchText) ||
          product?.primaryName?.toUpperCase().includes(upperSearchText) ||
          product?.secondaryName?.toUpperCase().includes(upperSearchText) ||
          o.adminDate?.toUpperCase().includes(upperSearchText) ||
          o.adminTime?.toUpperCase().includes(upperSearchText)
        );
      })

      .map((item) => {
        if (!itemError && item.errorExists) {
          setItemError(true);
        }
        let patientNameTemplateString = item.patientInfo?.id
          ? `${item.patientInfo.name} (${item.patientInfo.id}),`
          : `${item.patientInfo.name}`;

        return [
          "_selectedObject" ? item : "",
          <div
            className={
              !item.errorExists
                ? styles.PendingDispensingOrders_itemStatusIndicator
                : [
                    styles["PendingDispensingOrders_itemStatusIndicator"],
                    styles[
                      "PendingDispensingOrders_itemStatusIndicator--withError"
                    ],
                  ].join(" ")
            }
          ></div>,
          item.facility?.name ?? "",
          item.priority ?? "",
          patientNameTemplateString,
          item.patientInfo?.room ?? "",
          item.rxNumber ? item.rxNumber : "",
          item.product?.packageId ?? "",
          item.product
            ? productFieldController.getDefaultName(item.product)
            : "",
          item.adminDate ? item.adminDate : "",
          item.adminTime ? item.adminTime.substring(0, 5) : "",
          item.qtyNeeded ? item.qtyNeeded : "",
          item.errorDescription
            ? item.errorDescription.replace(
                "There is no product associated with package id",
                t("noAssociationPrefixError")
              )
            : "",
          <PendingDispensingOrderItem
            user={user}
            item={item}
            onActivateSubstituteProductModal={() =>
              handleSubstituteProductModal(true)
            }
            onActivateAddProductModal={() => handleAddProductModal(true)}
            onActivateAdminDateTimeModal={() => handleAdminDateTimeModal(true)}
            onActivateAdjustQuantityModal={() =>
              handleAdjustQuantityModal(true)
            }
            onActivateChangePatientLocationModal={() =>
              handleChangePatientLocationModal(true)
            }
            onActivateChangePatientLanguageModal={() =>
              handleChangePatientLanguageModal(true)
            }
            onActivatePriorityModal={() => handleChangePriorityModal(item)}
            onHandleSelectedItem={handleSelectedItem}
          ></PendingDispensingOrderItem>,
          item._id,
        ];
      });

    setDispensingData(records);
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText.toUpperCase());
  };

  const filterListObjects = [
    {
      type: "ALL",
      description: t("ALL", { ns: "orderFilterTypes" }),
    },
    {
      type: "Patient - Special",
      description: t("Patient - Special", { ns: "orderFilterTypes" }),
    },
    {
      type: "ERROR",
      description: t("ERROR", { ns: "orderFilterTypes" }),
    },
  ];

  const navigateToDispensingOrders = () => {
    navigate("/dispensing-orders", {
      state: {
        selectedSite: site,
        selectedPackagingMethods: selectedPackagingMethods,
      },
    });
  };

  const handleUpdateTable = (bool) => {
    setUpdateTable(bool);
  };

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
  };

  const handleSubstituteProductModal = (bool) => {
    setDisplaySubstituteProductModal(bool);
  };

  const handleAddProductModal = (bool) => {
    setDisplayAddProductModal(bool);
  };

  const handleAdminDateTimeModal = (bool) => {
    setDisplayAdminDateTimeModal(bool);
  };

  const handleAdjustQuantityModal = (bool) => {
    setDisplayAdjustQuantityModal(bool);
  };

  const handleChangePatientLocationModal = (bool) => {
    setDisplayChangePatientLocationModal(bool);
  };

  const handleChangePatientLanguageModal = (bool) => {
    setDisplayChangePatientLanguageModal(bool);
  };

  const handleChangePriorityModal = (item) => {
    setDisplayChangePriorityModal(item);
  };

  const handleSplitOrder = async () => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";

    const splitOrderMenuItemsSelectedKey = splitOrderMenuItemsSelectedKeys[0];
    const selectedSplitOrderMenuItems = splitOrderMenuItems.find(
      (x) => x.dispensingOrderId === splitOrderMenuItemsSelectedKey
    );

    const dispensingOrderItems =
      selectedSplitOrderMenuItems?.dispensingOrderItems &&
      selectedSplitOrderMenuItems.dispensingOrderItems.length > 0
        ? selectedSplitOrderMenuItems.dispensingOrderItems
        : localDispensingOrderItems;

    const { selectedDispensingOrderItems, unselectedDispensingOrderItems } =
      dispensingOrderItems.reduce(
        (acc, item) => {
          if (selectedRowKeys.includes(item._id)) {
            acc.selectedDispensingOrderItems.push(item);
          } else {
            acc.unselectedDispensingOrderItems.push(item);
          }
          return acc;
        },
        { selectedDispensingOrderItems: [], unselectedDispensingOrderItems: [] }
      );

    if (selectedDispensingOrderItems.length <= 0) return;

    selectedSplitOrderMenuItems.dispensingOrderItems =
      unselectedDispensingOrderItems;

    const temp = [...splitOrderMenuItems];

    const splitedOrder = {
      id: selectedSplitOrderMenuItems.dispensingOrder._id,
      dispensingOrderId: dispensingOrderId,
      dispensingOrder: {
        ...selectedSplitOrderMenuItems.dispensingOrder,
      },
      dispensingOrderItems: selectedDispensingOrderItems,
    };

    temp.push(splitedOrder);

    temp.forEach((t, index) => {
      const suffix = alphabet[index % alphabet.length];
      const value = dispensingOrderId + "~" + suffix;
      t.dispensingOrder.qtyNeeded = t.dispensingOrderItems.reduce(
        (sum, item) => sum + item.qtyNeeded,
        0
      );
      t.dispensingOrderId = value;
    });

    setSplitOrderMenuItems(temp);
    setSplitOrderMenuItemsSelectedKeys([splitedOrder.dispensingOrderId]);

    setLocalDispensingOrderItems(selectedDispensingOrderItems);
    setSelectedRowKeys([]);
    setMenuVisible(true);
    setState({ unSavedChanges: true });
  };

  const handleCombineOrder = async () => {
    const selected = splitOrderMenuItems.filter((o) => o.selected);
    if (selected.length <= 1) return;

    const first = splitOrderMenuItems
      .filter((o) => o.selected)
      .sort((a, b) =>
        a.dispensingOrderId.localeCompare(b.dispensingOrderId)
      )[0];

    const temp = splitOrderMenuItems.filter((o) => !o.selected);

    const selectedDispensingOrderItems = selected
      .map((o) => o.dispensingOrderItems)
      .flat();
    const combinedOrder = {
      id: first.dispensingOrder._id,
      dispensingOrderId: first.dispensingOrderId,
      dispensingOrder: {
        ...first.dispensingOrder,
      },
      dispensingOrderItems: selectedDispensingOrderItems,
    };

    combinedOrder.dispensingOrder.qtyNeeded =
      combinedOrder.dispensingOrderItems.reduce(
        (sum, item) => sum + item.qtyNeeded,
        0
      );

    temp.push(combinedOrder);

    temp.forEach((item) => (item.selected = false));
    setSplitOrderMenuItems(temp);
    setSplitOrderMenuItemsSelectedKeys([combinedOrder.dispensingOrderId]);
    setLocalDispensingOrderItems(combinedOrder.dispensingOrderItems);
    setMenuVisible(true);
    setState({ unSavedChanges: true });
  };

  const distinctByIndex = (array, index) => {
    const uniqueItems = new Set(array.map((record) => record[index]));
    return [...uniqueItems].map((value) => ({
      text: value,
      value: value,
    }));
  };

  const onTableChange = (_pagination, _filters, _sorter, extra) => {
    setCurrentDataSource(extra.currentDataSource);
  };

  let HeaderComponent = (
    <div className={styles.PendingDispensingOrder__header}>
      <BackButton
        className={styles.PendingDispensingOrder__button}
        onClick={navigateToDispensingOrders}
      />
      <h1>
        {site.name} <span>{t("pendingDispensingOrderTitle")}</span>
      </h1>
    </div>
  );

  const pagination = {
    pageSize: 100,
  };

  const sortedSplitOrderMenuItems = splitOrderMenuItems.toSorted((a, b) =>
    a.dispensingOrderId.localeCompare(b.dispensingOrderId)
  );

  if (fetching) {
    return <WaitIndicator message={waitIndicatorMessage} />;
  } else if (displayChangePriorityModal) {
    return (
      <ChangePriority
        user={user}
        dispensingOrderItem={displayChangePriorityModal}
        handleUpdateTable={handleUpdateTable}
        onCloseModal={(updateTable) => {
          handleChangePriorityModal(null);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
        pendDisOrderControllerRef={_controllerRef.current}
      />
    );
  } else if (displaySubstituteProductModal) {
    return (
      <SubstituteProduct
        pendDisOrderControllerRef={_controllerRef.current}
        handleUpdateTable={handleUpdateTable}
        user={user}
        selectedItem={selectedItem}
        onCloseSubstituteProductModal={(updateTable) => {
          handleSubstituteProductModal(false);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
      />
    );
  } else if (displayAddProductModal) {
    return (
      <CreateProduct
        user={user}
        product={null}
        dispensingOrderItem={selectedItem}
        onCloseAddProductModal={(updateTable) => {
          handleAddProductModal(false);
          //update table is not need
          //after create a product user is redirected to products page
        }}
        isNew={true}
      />
    );
  } else if (displayAdminDateTimeModal) {
    return (
      <AdminDateTime
        pendDisOrderControllerRef={_controllerRef.current}
        user={user}
        selectedItem={selectedItem}
        onCloseAdminDateTimeModal={(updateTable) => {
          handleAdminDateTimeModal(false);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
      />
    );
  } else if (displayAdjustQuantityModal) {
    return (
      <AdjustQuantity
        pendDisOrderControllerRef={_controllerRef.current}
        updateTable={updateTable}
        handleUpdateTable={handleUpdateTable}
        user={user}
        selectedItem={selectedItem}
        onCloseAdjustQuantityModal={(updateTable) => {
          handleAdjustQuantityModal(false);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
      />
    );
  } else if (displayChangePatientLocationModal) {
    return (
      <ChangePatientLocation
        pendDisOrderControllerRef={_controllerRef.current}
        user={user}
        selectedItem={selectedItem}
        dispensingOrder={dispensingOrder}
        onCloseChangePatientLocationModal={(updateTable) => {
          handleChangePatientLocationModal(false);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
      />
    );
  } else if (displayChangePatientLanguageModal) {
    return (
      <ChangePatientLanguage
        pendDisOrderControllerRef={_controllerRef.current}
        user={user}
        selectedItem={selectedItem}
        dispensingOrder={dispensingOrder}
        onCloseChangePatientLanguageModal={(updateTable) => {
          handleChangePatientLanguageModal(false);
          if (updateTable) {
            updateDispensingOrderItems();
            parseData();
          }
        }}
      />
    );
  } else {
    const userCanSplitOrder = RoleCheckService.userCanSplitOrder(user);
    return (
      <Row>
        <Col span={menuVisible ? 20 : 23}>
          {showDateTimeRangePicker && (
            <div className={styles.PendingDispensingOrder__overlay}>
              <div className={styles.PendingDispensingOrder__modal}>
                <DateRangePickerComponent
                  ranges={[selectionRange]}
                  onChange={handleDateRangeSelect}
                  rangeColors={["#089BAB"]}
                  country={user?.defaultSite?.shippingAddress?.country}
                />
                <br />
                <Button
                  labelName={t("continueButtonLabel")}
                  isPrimary={true}
                  onClick={() => setShowDateTimeRangePicker(false)}
                />
              </div>
            </div>
          )}

          {HeaderComponent}

          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Row>
              <Col span={24}>
                {error && (
                  <div>
                    <br />
                    <ErrorBanner
                      message={error}
                      onClose={() => {
                        setError("");
                      }}
                    />
                    <br />
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Row align="middle" gutter={5}>
                  <Col flex="15px">
                    <div
                      className={
                        !dispensingOrder?.errorExists
                          ? styles.PendingDispensingOrders__groupStatusIndicator
                          : [
                              styles[
                                "PendingDispensingOrders__groupStatusIndicator"
                              ],
                              styles[
                                "PendingDispensingOrders__groupStatusIndicator--withError"
                              ],
                            ].join(" ")
                      }
                    ></div>
                  </Col>
                  <Col flex="150px">
                    <span
                      className={`${styles.PendingDispensingOrders__orderInfoItemText}`}
                    >
                      {t("orderIdLabel")}:
                    </span>
                  </Col>
                  <Col flex="auto">
                    <Input
                      className={`${styles.PendingDispensingOrders__orderInfoItemValue}`}
                      size="large"
                      value={dispensingOrderId}
                      onChange={(e) => {
                        setDispensingOrderId(e.target.value);

                        const selectedKey = splitOrderMenuItemsSelectedKeys[0];
                        const doi = splitOrderMenuItems.find(
                          (d) => d.dispensingOrderId === selectedKey
                        );
                        if (doi && e.target.value) {
                          doi.dispensingOrderId =
                            e.target.value + "~" + dispensingOrderIdComplement;

                          setSplitOrderMenuItemsSelectedKeys([
                            doi.dispensingOrderId,
                          ]);
                        }
                      }}
                      isEnabled={readonly === false}
                    />
                  </Col>
                  <Col flex="auto">
                    <Input
                      className={`${styles.PendingDispensingOrders__orderInfoItemValue}`}
                      size="large"
                      value={dispensingOrderIdComplement}
                      onChange={(e) => {
                        setDispensingOrderIdComplement(e.target.value);
                        const selectedKey = splitOrderMenuItemsSelectedKeys[0];
                        const doi = splitOrderMenuItems.find(
                          (d) => d.dispensingOrderId === selectedKey
                        );
                        if (doi && e.target.value) {
                          doi.dispensingOrderId =
                            dispensingOrderId + "~" + e.target.value;
                          setSplitOrderMenuItemsSelectedKeys([
                            doi.dispensingOrderId,
                          ]);
                        }
                      }}
                      isEnabled={readonly === false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Row align="middle" gutter={5}>
                  <Col flex="15px"></Col>
                  <Col flex="150px">
                    <span
                      className={`${styles.PendingDispensingOrders__orderInfoItemText}`}
                    >
                      {t("receivedButtonLabel")}:
                    </span>
                  </Col>
                  <Col flex="auto">
                    <span
                      className={`${styles.PendingDispensingOrders__orderInfoItemValue}`}
                    >
                      {formatedCreatedAtLocalTime}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="start" align={"bottom"}>
              <Col span={8}>
                <SearchBar
                  placeholder={t("searchPlaceholder")}
                  onSearch={handleSearch}
                  focus={true}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={4}>
                {filterType === "Patient - Special" && (
                  <div
                    className={styles.PendingDispensingOrder__dateRangeGroup}
                  >
                    <p
                      className={styles.PendingDispensingOrder__dateRangeLabel}
                    >
                      {t("dateRangeLabel")}
                    </p>
                    <p
                      className={styles.PendingDispensingOrder__dateRange}
                      onClick={() => setShowDateTimeRangePicker(true)}
                    >
                      {startDateString || ""}-{stopDateString || ""}
                    </p>
                  </div>
                )}
              </Col>

              <Col span={4}>
                <DropdownInput
                  labelName={t("typeLabel")}
                  value={t(filterType, { ns: "orderFilterTypes" })}
                  validationText={""}
                  placeholder={""}
                  isEnabled={true}
                  listElements={filterListObjects}
                  onSelection={(value) => setFilterType(value.type)}
                  display={"description"}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Table
                  columns={[
                    {
                      title: " ",
                      key: "status",
                      render: (record) => record[1],
                      width: "25px",
                    },
                    {
                      title: t("facilityCol"),
                      key: "Facility",
                      render: (record) => record[2],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        2
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[2].includes(value),
                      ellipsis: true,
                    },
                    {
                      title: t("priorityCol"),
                      key: "Priority",
                      render: (record) => t(record[3], { ns: "orderPriority" }),
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        3
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[3].includes(value),
                      width: "95px",
                      ellipsis: true,
                    },
                    {
                      title: t("patientCol"),
                      key: "Patient",
                      render: (record) => record[4],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        4
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[4].includes(value),
                      ellipsis: true,
                    },
                    {
                      title: t("locationCol"),
                      key: "Location",
                      render: (record) => record[5],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        5
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[5].includes(value),
                      //width: "100px",
                      ellipsis: true,
                    },
                    {
                      title: t("rxNumberCol"),
                      key: "Rx Number",
                      render: (record) => record[6],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        6
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[6].includes(value),
                      ellipsis: true,
                    },
                    {
                      title: t("orderIdCol"),
                      key: "ID",
                      render: (record) => record[7],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        7
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[7].includes(value),
                      ellipsis: true,
                    },
                    {
                      title: t("productCol"),
                      key: "Product",
                      render: (record) => record[8],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        8
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[8].includes(value),
                      ellipsis: true,
                    },
                    {
                      title: t("adminDateCol"),
                      key: "Admin Date",
                      render: (record) => record[9],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        9
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[9].includes(value),
                      //width: "125px",
                      ellipsis: true,
                    },
                    {
                      title: t("adminTimeCol"),
                      key: "Admin Time",
                      render: (record) => record[10],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        10
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[10].includes(value),
                      //width: "130px",
                      ellipsis: true,
                    },
                    {
                      title: t("qtyCol"),
                      key: "Qty",
                      render: (record) => record[11],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        11
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[11].includes(value),
                      width: "65px",
                      ellipsis: true,
                    },
                    {
                      title: t("errorCol"),
                      key: "Error",
                      render: (record) => record[12],
                      filters: distinctByIndex(
                        currentDataSource ?? dispensingData,
                        12
                      ),
                      filterMode: "tree",
                      filterSearch: true,
                      onFilter: (value, record) => record[12].includes(value),
                      onCell: () => {
                        return {
                          style: {
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          },
                        };
                      },
                    },
                    {
                      title: t("actionsCol"),
                      key: "Actions",
                      render: (record) => record[13],
                      width: "70px",
                      hidden: readonly === true,
                    },
                  ]}
                  dataSource={dispensingData}
                  pagination={pagination}
                  rowClassName={(record) => {
                    if (record[15] === true) {
                      return `${tableStyles.Table__row} ${tableStyles.Table__selectedRow}`;
                    }
                    return `${tableStyles.Table__row} ${tableStyles.Table__unSelectedRow}`;
                  }}
                  rowKey={(record) => record[14]}
                  scroll={
                    (!dispensingData || dispensingData.length === 0) &&
                    (!currentDataSource || currentDataSource.length === 0)
                      ? undefined
                      : { y: window.innerHeight - 425 }
                  }
                  size="small"
                  onChange={onTableChange}
                  rowSelection={
                    readonly === true
                      ? undefined
                      : {
                          selectedRowKeys: selectedRowKeys,
                          columnTitle: (checkbox) => {
                            setSelectAllSelected(checkbox.props.checked);
                            return checkbox;
                          },
                          onChange: (
                            newSelectedRowKeys,
                            _selectedRows,
                            info
                          ) => {
                            if (info.type === "all") {
                              const dataSource =
                                currentDataSource ?? dispensingData;

                              const currentKeys = dataSource.map(
                                (record) => record[14]
                              );

                              if (selectAllSelected) {
                                //unselect all
                                const unselected = selectedRowKeys.filter(
                                  (item) => !currentKeys.includes(item)
                                );
                                setSelectedRowKeys(unselected);
                              } else {
                                //select all
                                const mySet = new Set([
                                  ...selectedRowKeys,
                                  ...currentKeys,
                                ]);
                                setSelectedRowKeys([...mySet]);
                              }
                            } else if (info.type === "single") {
                              //select line
                              setSelectedRowKeys(newSelectedRowKeys);
                            }
                          },
                        }
                  }
                  locale={{ emptyText: t("noDataLabel") }}
                ></Table>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Row justify="start" gutter={12}>
                  <Col>
                    {readonly === false &&
                      selectedRowKeys &&
                      selectedRowKeys.length >= 1 && (
                        <AntdButton
                          type="primary"
                          style={{ width: "fit-content" }}
                          disabled={!userCanSplitOrder}
                          onClick={() => handleSplitOrder()}
                          size="large"
                        >
                          {t("splitOrderButtonLabel")}
                        </AntdButton>
                      )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Space>
        </Col>

        <Col span={menuVisible ? 4 : 1}>
          <Row>
            <Col span={2}>
              <Divider
                type="vertical"
                style={{ height: "100%", borderWidth: "3px" }}
              />
            </Col>
            <Col span={22}>
              <Row>
                <Col span={24}>
                  <AntdButton
                    type="primary"
                    onClick={() => {
                      setMenuVisible(!menuVisible);
                    }}
                  >
                    {menuVisible ? (
                      <MenuUnfoldOutlined />
                    ) : (
                      <MenuFoldOutlined />
                    )}
                  </AntdButton>
                </Col>
                {menuVisible && (
                  <>
                    <Col span={24}>
                      <Menu
                        style={{ height: window.innerHeight - 180 }}
                        items={sortedSplitOrderMenuItems.map((o) => ({
                          key: o.dispensingOrderId,
                          className: styles["my-menu-item"],
                          label: (
                            <Row>
                              <Col span={24}>
                                <Checkbox
                                  checked={o.selected ?? false}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => {
                                    o.selected = e.target.checked;
                                    setSplitOrderMenuItems([
                                      ...sortedSplitOrderMenuItems,
                                    ]);
                                  }}
                                >
                                  <Tooltip title={o.dispensingOrderId}>
                                    {o.dispensingOrderId}
                                  </Tooltip>
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <PillIcon />
                              </Col>
                              <Col span={20}>{o.dispensingOrder.qtyNeeded}</Col>
                            </Row>
                          ),
                        }))}
                        selectedKeys={splitOrderMenuItemsSelectedKeys}
                        multiple={false}
                        onSelect={(info) => {
                          setSplitOrderMenuItemsSelectedKeys(info.selectedKeys);
                          const doi = splitOrderMenuItems.find(
                            (d) => d.dispensingOrderId === info.key
                          );
                          setLocalDispensingOrderItems(
                            doi.dispensingOrderItems
                          );

                          const split = info.key.split("~");
                          setDispensingOrderId(split[0]);
                          setDispensingOrderIdComplement(split[1]);

                          setFormatedCreatedAtLocalTime(
                            calculateLocalizedDate(doi?.dispensingOrder)
                          );
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <Row align={"middle"} justify={"center"}>
                        <Col>
                          <AntdButton
                            type="primary"
                            size="large"
                            style={{ width: "120px" }}
                            onClick={async () => {
                              try {
                                if (splitOrderMenuItems.length >= 1) {
                                  setWaitIndicatorMessage(t("splittingOrders"));
                                  setFetching(true);

                                  const newDispensingOrders =
                                    splitOrderMenuItems.map((d) => ({
                                      id: d.id,
                                      dispensingOrderId: d.dispensingOrderId,
                                      dispensingOrderItems:
                                        d.dispensingOrderItems.map(
                                          (i) => i._id
                                        ),
                                    }));
                                  await _controllerRef.current.splitOrder(
                                    allDispensingOrders.map((d) => d._id),
                                    newDispensingOrders
                                  );

                                  navigateToDispensingOrders();
                                } else {
                                  setError(t("singleTransactionError"));
                                }
                              } catch (error) {
                                setError(error);
                              } finally {
                                setFetching(false);
                              }
                            }}
                          >
                            {t("submitButtonLabel")}
                          </AntdButton>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row align={"middle"} justify={"center"}>
                        <Col>
                          <AntdButton
                            type="primary"
                            size="large"
                            style={{ width: "120px" }}
                            disabled={
                              !splitOrderMenuItems ||
                              splitOrderMenuItems.filter((o) => o.selected)
                                .length <= 1
                            }
                            onClick={handleCombineOrder}
                          >
                            {t("combineButtonLabel")}
                          </AntdButton>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
};

export default PendingDispensingOrder;
