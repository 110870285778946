import { useEffect, useContext, useState, useMemo, useCallback, useRef } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as CloseModalIcon } from "../../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { ScreenContext } from "../../../contexts/ScreenContext";
import styles from "./PrintMedList.module.css";
import PatientList from "./PatientList";
import PDFDocumentViewer from "./PDFDocumentViewer";
import MedListsDocument from "./MedListsDocument";
import FacilitiesRedirect from "./FacilitiesRedirect";
import { useTranslation } from "react-i18next";
import PatientEdit from "./PatientEdit";
import Button from "../../../components/Button";
import DispensingOrderController from "../../../controllers/DispensingOrderController";

const PrintMedList = ({
  user,
  patients,
  medLists,
  selectedSiteIndex,
  dispensingOrder,
  onHandleClosePrintMedListModal,
  onHandleModal,
}) => {
  const { t } = useTranslation("medList");
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectedMedLists, setSelectedMedLists] = useState([]);
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  const [invalidFacilities, setInvalidFacilities] = useState([]);
  const [showFacilitiesRedirect, setShowFacilitiesRedirect] = useState(false);

  const [editPatient, setEditPatient] = useState(null);  
  const [editLanguage, setEditLanguage] = useState(null);

  const [areaWidth, setAreaWidth] = useState(0);
  const [areaHeight, setAreaHeight] = useState(0);

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const _dispensingOrderControllerRef = useRef(null)
  _dispensingOrderControllerRef.current = new DispensingOrderController(user);

  const titleLabel = 
    showFacilitiesRedirect
      ? t("missingTimeCodesTitle")
      : editPatient
      ? t("patientEditTitle")
      : t("printMedListTitle")

  const mainActionLabel =
    showPDFViewer ||showFacilitiesRedirect || editPatient
      ? t("backButton")
      : smScreen
      ? t("downloadListsButton")
      : t("previewListsButton")

  const memoizedPatients = useMemo(
    () => patients.filter((p) => selectedPatients.includes(p._id)),
    [patients, selectedPatients]
  );
  
  useEffect(() => {
    smScreen && handleShowSmScreenHeader(false);
  }, [smScreen, handleShowSmScreenHeader]);

  useEffect(() => {
    const calculateArea = () => {
      const availableWidth = window.innerWidth * 0.6;
      const availableHeight = window.innerHeight * 0.9;

      setAreaWidth(availableWidth);
      setAreaHeight(availableHeight);
    }

    if (!smScreen) {
      calculateArea();
      window.addEventListener("resize", calculateArea);      
    };
    
    return () => window.removeEventListener("resize", calculateArea);
  }, [smScreen]);

  useEffect(() => {
    setSelectedPatients(patients.map((p) => p._id));
  }, [patients]);

  useEffect(() => {
    setSelectedMedLists(
      medLists.filter((ml) => selectedPatients.includes(ml.patientId))
    );
  }, [selectedPatients, medLists]);

  const MemoizedDocument = useMemo(() => {
    return () => (
      <MedListsDocument patients={memoizedPatients} meds={selectedMedLists} />
    );
  }, [memoizedPatients, selectedMedLists]);

  const validateFacilities = useCallback(() => {
    const invalids = [];
    for (const patient of patients) {
      patient.facility.validationError = null;
      const language = patient.language || patient.facility.defaultLanguage
        if (
          !patient.facility.localizedTimeCodes.length
        ) {
          patient.facility.validationError = "missingTimeCodes";
        } else if (
          patient.facility.localizedTimeCodes.filter((timeCode) =>
            language in timeCode.label && timeCode.label[language]
          )?.length !== patient.facility.localizedTimeCodes.length
        ) {
          patient.facility.validationError = "missingLanguage";
        }

        if (
          patient.facility.validationError &&
          !invalids.some((f) => f._id === patient.facility._id)
        ) {
          invalids.push(
            patient.facility
          )
        }
    }
    return invalids;
  }, [patients]);

  useEffect(() => {
    setInvalidFacilities(validateFacilities())
  }, [validateFacilities])

  const handleCloseButton = () => {
    onHandleClosePrintMedListModal()
  }

  const handleBackButton = () => {
    if (showFacilitiesRedirect) {
      setShowFacilitiesRedirect(false);
    } else if (editPatient) {
      setEditPatient(null);
    } else {
      onHandleModal(false);
      handleShowSmScreenHeader(true);
    }
  }

  const handleUpdatePatient = () => {
    console.log("editing", editPatient, editLanguage, dispensingOrder)    
    if (!_dispensingOrderControllerRef.current) return

    _dispensingOrderControllerRef.current.updatePatientLanguage(
      dispensingOrder,
      editPatient._id,
      editLanguage,
    ).then(() => {
      editPatient.language = editLanguage  
      setEditPatient(null);
      setEditLanguage(null);
    })
  }

  const handleMainButton = () => {    
    if (showPDFViewer) {
      setShowPDFViewer(false);
    } else if (showFacilitiesRedirect) {
      setShowFacilitiesRedirect(false);
    } else if (editPatient) {
      setEditPatient(null);
    } else if (selectedPatients.length) {
      const invalids = validateFacilities();
      setInvalidFacilities(invalids);
      if (invalids.length) {
        setShowFacilitiesRedirect(true);
      } else {
        setShowPDFViewer(true);
      }
    }
  }

  return (
    <>
      <div
        className={styles.PrintMedList__container}
        style={
          showPDFViewer && !smScreen
            ? { width: areaWidth, height: areaHeight, minWidth: 900 }
            : {}
        }
      >
        {!smScreen ? (
          <section className={styles.PrintMedList__headingGroup}>
            <p className={styles.PrintMedList__title}>{titleLabel}</p>
            <CloseModalIcon
              className={styles.PrintMedList__closeModalIcon}
              onClick={handleCloseButton}
            />
          </section>
        ) : (
          <section className={styles.PrintMedList__headingGroup}>
            <div
              className={styles.PrintMedList__backArrow}
              onClick={handleBackButton}
            >
              <BackArrowMobile
                className={styles.PrintMedList__backArrow__icon}
              />
              <p className={styles.PrintMedList__goBackTitle}>{t("backButton")}</p>
            </div>
            <p className={styles.PrintMedList__title}>{titleLabel}</p>
          </section>
        )}

        {showFacilitiesRedirect ? (
          <FacilitiesRedirect 
            facilities={invalidFacilities}
            selectedSiteIndex={selectedSiteIndex}
            dispensingOrder={dispensingOrder}
          />
        ) : showPDFViewer ? (
          <PDFDocumentViewer Document={MemoizedDocument} />
        ) : editPatient ? (
          <PatientEdit
            patient={editPatient}
            setEditLanguage={setEditLanguage}
          />
        ) : (
          <PatientList
            patients={patients}
            selectedPatients={selectedPatients}
            setSelectedPatients={setSelectedPatients}
            setEditPatient={setEditPatient}            
          />
        )}

        <div className={styles.PrintMedList__buttonContainer}>
          {smScreen && !invalidFacilities.length && !showFacilitiesRedirect && !editPatient ? (
            <div className={`${styles.row} ${styles.centered}`}>
              <Button
                labelName={t("backButton")}
                isPrimary={false}
                onClick={handleBackButton}                
              />
              <PDFDownloadLink
                document={<MemoizedDocument />}
                fileName={`${t("outputFileName")}.pdf`}
              >
                {({ blob, url, loading, error }) => (
                  <Button
                    labelName={t("downloadListsButton")}
                    isDisabled={selectedPatients.length === 0 || loading}
                    isPrimary={true}
                  />
                )}
              </PDFDownloadLink>
            </div>
          ) : (
            <div className={`${styles.row} ${styles.centered}`}>
              <Button
                labelName={mainActionLabel}
                isPrimary={!showPDFViewer && !showFacilitiesRedirect && !editPatient}
                isDisabled={selectedPatients.length === 0}
                onClick={handleMainButton}                
              />
              {editPatient && (
                <Button
                  labelName={t("updatePatientButton")}
                  isPrimary={true}
                  onClick={handleUpdatePatient}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PrintMedList;
