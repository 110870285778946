import React from 'react';
import styles from './Properties.module.css';
import { useTranslation } from 'react-i18next';
import Input from './Input';


const LabelProperties = ({ 
  unit,
  width,
  setWidth,
  height,
  setHeight,
}) => {
  const { t } = useTranslation("labelEditor");

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("widthLabel")}</label>
          <Input
            value={width}
            name="width"
            type="number"
            unit={unit}
            onChange={setWidth}
            min={0.1}
            step={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("heightLabel")}</label>
          <Input
            value={height}
            name="height" 
            type="number"
            unit={unit}
            onChange={setHeight}
            min={0.1}
            step={0.1}
          />
        </div>     
      </div>
       
    </div>
  );
};

export default LabelProperties;
