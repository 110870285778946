const DropIndicator = ({ activeId, dropTarget, flattenedObjects, containerRect }) => {
  if (!dropTarget || !containerRect) return null;

  const activeIndex = flattenedObjects.findIndex(item => item.id === activeId);
  const overIndex = flattenedObjects.findIndex(item => item.id === dropTarget.id);
  const itemHeight = 40; 

  let groupHeight = 0
  if (activeIndex < overIndex) {
    const activeItem = flattenedObjects.find(item => item.id === activeId);
    if (activeItem && activeItem.type === "group") {
      groupHeight += 40 * activeItem.objects.length
    }
  }
  
  const top = (overIndex + 1) * itemHeight - 1 - groupHeight; 

  return (
    <div 
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: `${top}px`,
        height: '2px',
        backgroundColor: 'blue',
        marginLeft: `${dropTarget.depth * 20}px`,
        pointerEvents: 'none',
      }}
    />
  );
};

export default DropIndicator;