import { useState, useEffect, useCallback } from "react";
import { Table } from "antd";
import { ReactComponent as Square } from "../../../../../assets/square.svg";
import AntButton from "../../../../../components/Ant/AntButton/AntButton";
import { getLocalizedDate } from "../../../../../functions/localization";
import styles from "./DispensedMedicationsTable.module.css";
import "./DispensedMedicationsTableAntStyles.css";

const DispensedMedicationsTable = ({
  user,
  activeKey,
  canisterProductsSelectedDay,
  canisterProducts,
  startDateLastWeek,
  stopDateLastWeek,
  selectedDayTray,
  selectedDayCanister,
  rowStatus,
  onRowStatus,
  trayProductsSelectedDay,
  trayProducts,
  onShowCanisterPlacementPopup,
}) => {
  const [dataTrayTable, setDataTrayTable] = useState([]);
  const [dataCanisterTable, setDataCanisterTable] = useState([]);
  const [allTrayDrugsSelected, setAllTrayDrugsSelected] = useState(false);
  const [atleastOneTrayDrugSelected, setAtleastOneTrayDrugSelected] =
    useState(false);
  const [tableTitle, setTableTitle] = useState("");

  const handleRowSelection = useCallback(
    (packageId) => {
      let tempRowStatus = { ...rowStatus };
      if (tempRowStatus[packageId].selected === true) {
        tempRowStatus[packageId].selected = false;
      } else {
        if (tempRowStatus[packageId].selected === false) {
          tempRowStatus[packageId].selected = true;
        }
      }
      onRowStatus(tempRowStatus);
    },
    [onRowStatus, rowStatus]
  );

  useEffect(() => {
    const hasKey = (obj) => {
      for (const key in obj) {
        if (Object.hasOwn(obj, key)) {
          return true;
        }
      }
      return false;
    };
    if (activeKey === "tray" && hasKey(rowStatus)) {
      const createIDSquare = (id) => {
        const IDSquare = (
          <div
            className={
              rowStatus[id].selected === true
                ? `${styles.DispensedMedicationsTable__idSquareContainer} ${styles["DispensedMedicationsTable__idSquareContainer--active"]}`
                : styles.DispensedMedicationsTable__idSquareContainer
            }
            onClick={() => handleRowSelection(id)}
          >
            <Square width="24px" height="24px" />
          </div>
        );
        return IDSquare;
      };

      if (activeKey === "tray") {
        let trayProds;
        if (selectedDayTray !== null) {
          trayProds = trayProductsSelectedDay;
        } else {
          trayProds = trayProducts;
        }

        setDataTrayTable(
          trayProds.map((product) => {
            return {
              key: `${product.packageId}_tray`,
              idSquare: createIDSquare(product.packageId),
              packageId_tray: product.packageId,
              medication_tray: product.productName,
              quantity_tray: product.qty,
              trays: product.trays,
            };
          })
        );
      }
    }
  }, [
    activeKey,
    trayProducts,
    trayProductsSelectedDay,
    rowStatus,
    selectedDayTray,
    handleRowSelection,
  ]);

  useEffect(() => {
    if (activeKey === "canister") {
      let canisterProds;

      if (selectedDayCanister !== null) {
        canisterProds = canisterProductsSelectedDay;
      } else {
        canisterProds = canisterProducts;
      }
      setDataCanisterTable(
        canisterProds.map((product) => {
          return {
            key: `${product.packageId}_canister`,
            packageId_canister: product.packageId,
            medication_canister: product.productName,
            quantity_canister: product.qty,
            refills: "---",
            canisterNumber: "---",
          };
        })
      );
    }
  }, [
    activeKey,
    canisterProducts,
    canisterProductsSelectedDay,
    rowStatus,
    selectedDayCanister,
  ]);

  useEffect(() => {
    let drugSelected = false;
    if (activeKey === "tray") {
      for (const [key, value] of Object.entries(rowStatus)) {
        if (value.selected) {
          drugSelected = true;
          break;
        }
      }
      setAtleastOneTrayDrugSelected(drugSelected);
      if (!drugSelected) {
        onShowCanisterPlacementPopup(false);
      }
    }
  }, [activeKey, rowStatus, onShowCanisterPlacementPopup]);

  useEffect(() => {
    let dateRangeForTitle = "";
    let tempTableTitle = "";
    let startDateLocalized;
    let stopDateLocalized;
    if (
      selectedDayTray ||
      selectedDayCanister ||
      selectedDayTray === 0 ||
      selectedDayCanister === 0
    ) {
      const selectedDate = new Date(startDateLastWeek);
      selectedDate.setDate(selectedDate.getDate() + selectedDayTray);

      startDateLocalized = getLocalizedDate(
        selectedDate,
        user?.defaultSite?.shippingAddress?.country
      );
      dateRangeForTitle = startDateLocalized;
    } else {
      const startDate = new Date(startDateLastWeek);
      startDateLocalized = getLocalizedDate(
        startDate,
        user?.defaultSite?.shippingAddress?.country
      );

      const stopDate = new Date(stopDateLastWeek);
      stopDateLocalized = getLocalizedDate(
        stopDate,
        user?.defaultSite?.shippingAddress?.country
      );

      dateRangeForTitle = `${startDateLocalized} - ${stopDateLocalized}`;
    }

    if (activeKey === "tray") {
      tempTableTitle = (
        <div className={styles.DispensedMedicationsTable__tableTitle}>
          <h2 className={styles.DispensedMedicationsTable__tableTitleMain}>
            Most Filled
          </h2>
          <h3 className={styles.DispensedMedicationsTable__tableTitleSub}>
            {dateRangeForTitle}
          </h3>
        </div>
      );
    } else {
      tempTableTitle = (
        <div className={styles.DispensedMedicationsTable__tableTitle}>
          <h2 className={styles.DispensedMedicationsTable__tableTitleMain}>
            Most Dispensed
          </h2>
          <h3 className={styles.DispensedMedicationsTable__tableTitleSub}>
            {dateRangeForTitle}
          </h3>
        </div>
      );
    }
    setTableTitle(tempTableTitle);
  }, [
    selectedDayTray,
    selectedDayCanister,
    activeKey,
    startDateLastWeek,
    stopDateLastWeek,
  ]);

  const handleChange = (pagination, filters, sorter) => {
    // console.log("Various parameters", pagination, filters, sorter);
  };

  const trayColumnsInfo = [
    {
      title: (
        <div
          className={
            allTrayDrugsSelected
              ? `${styles.DispensedMedicationsTable__idSquareContainer} ${styles["DispensedMedicationsTable__idSquareContainer--active"]}`
              : styles.DispensedMedicationsTable__idSquareContainer
          }
          onClick={() => handleAllTrayDrugsSelected()}
        >
          <Square width="24px" height="24px" />
        </div>
      ),
      dataIndex: "idSquare",
      key: activeKey === "idSquare",
      width: "26px",
    },
    {
      title: "Drug ID",
      dataIndex: "packageId_tray",
      key: "packageId_tray",
      sorter: (a, b) => {
        return a.packageId_tray.localeCompare(b.packageId_tray);
      },
      sortDirections: ["ascend", "descend"],
      width: "15%",
    },
    {
      title: "Description",
      dataIndex: "medication_tray",
      key: "medication_tray",
      sorter: (a, b) => {
        return a.medication_tray.localeCompare(b.medication_tray);
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      align: "right",
      title: "QTY Filled",
      dataIndex: "quantity_tray",
      key: "quantity_tray",
      sorter: (a, b) => a.quantity_tray - b.quantity_tray,
      sortDirections: ["ascend", "descend"],
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            console.log("row & cell info: ", record, rowIndex, ev);
          },
          style: { cursor: "pointer" },
        };
      },
      width: "175px",
    },
    {
      align: "right",
      title: "Total Trays",
      dataIndex: "trays",
      key: activeKey === "trays",
      sorter: (a, b) => a.trays - b.trays,
      sortDirections: ["ascend", "descend"],
      width: "175px",
    },
  ];

  const canisterColumnsInfo = [
    {
      title: "Drug Id",
      dataIndex: "packageId_canister",
      key: "packageId_canister",
      sorter: (a, b) => {
        return a.packageId_canister.localeCompare(b.packageId_canister);
      },
      sortDirections: ["ascend", "descend"],
      width: "15%",
    },
    {
      title: "Description",
      dataIndex: "medication_canister",
      key: "medication_canister",
      sorter: (a, b) => {
        return a.medication_canister.localeCompare(b.medication_canister);
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      align: "right",
      title: "QTY Dispensed",
      dataIndex: "quantity_canister",
      key: "quantity_canister",
      sorter: (a, b) => a.quantity_canister - b.quantity_canister,
      sortDirections: ["ascend", "descend"],
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            console.log("row & cell info: ", record, rowIndex, ev);
          },
          style: { cursor: "pointer" },
        };
      },
      width: "175px",
    },
    {
      align: "right",
      title: "Total Refills",
      dataIndex: "refills",
      key: "refills",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            console.log("row & cell info: ", record, rowIndex, ev);
          },
          style: { cursor: "pointer" },
        };
      },
      width: "160px",
    },
    {
      align: "left",
      title: "Canister Number",
      dataIndex: "canisterNumber",
      key: "canister_number",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            console.log("row & cell info: ", record, rowIndex, ev);
          },
          style: { cursor: "pointer" },
        };
      },
      width: "175px",
    },
  ];

  const data = activeKey === "tray" ? dataTrayTable : dataCanisterTable;
  const columnsInfo =
    activeKey === "tray" ? trayColumnsInfo : canisterColumnsInfo;

  const columns = columnsInfo.map((column) => {
    return {
      title: column.title,
      dataIndex: column.dataIndex,
      key: column.key,
      sortColumnKey: column.key,
      width: column.width ? column.width : null,
      sorter: column.sorter ? column.sorter : null,
      ellipsis: true,
      onCell: column.onCell ? column.onCell : null,
    };
  });

  const handleAllTrayDrugsSelected = () => {
    let tempRowStatus = { ...rowStatus };
    if (allTrayDrugsSelected) {
      Object.keys(rowStatus).forEach((key) => {
        tempRowStatus[key].selected = false;
      });
      setAllTrayDrugsSelected(false);
    } else {
      Object.keys(rowStatus).forEach((key) => {
        tempRowStatus[key].selected = true;
      });
      setAllTrayDrugsSelected(true);
    }
    onRowStatus(tempRowStatus);
  };

  const handleCalculate = () => {
    onShowCanisterPlacementPopup(true);
  };

  return (
    <div
      className={`${styles.DispensedMedicationsTable__tableContainer} DispensedMedicationsTable__AntStyle`}
    >
      {tableTitle}
      <div
        className={
          activeKey === "tray"
            ? "DispensedMedicationsTable__tableTypeTrays"
            : "DispensedMedicationsTable__tableTypeCanisters"
        }
      >
        <Table
          columns={columns}
          dataSource={data}
          onChange={handleChange}
          scroll={{ y: `calc(70vh - 460px)` }}
          pagination={{ pageSize: 10 }}
        />
      </div>
      {activeKey === "tray" && (
        <div
          className={
            styles.DispensedMedicationsTable__calculationButtoncontainer
          }
        >
          <AntButton
            onClick={() => handleCalculate()}
            text="Calculate"
            buttonstyle="ant-button-001"
            size="large"
            disabled={!atleastOneTrayDrugSelected}
          />
        </div>
      )}
    </div>
  );
};

export default DispensedMedicationsTable;
