import StockLocationsController from "../../controllers/StockLocationsController";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useStatem,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import style from "./StockLocationsData.module.css";
import Node from "../../functions/Node";
import { ReactComponent as MoreActionsIcon } from "../../assets/more-actions.svg";
import { ReactComponent as MoreActionsIconMobile } from "../../assets/more-actions-mobile.svg";
import { ReactComponent as EditIcon } from "../../assets/edit-icon-small.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash-icon-small.svg";
import { ReactComponent as ExpandIcon } from "../../assets/add-icon-small.svg";
import { ReactComponent as CollapseIcon } from "../../assets/subtract-circle-icon.svg";

import ContextMenu from "../../components/ContextMenu";
import RoleCheckService from "../../services/RoleCheckService";
import ProductController from "../../controllers/ProductController";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const StockLocationsData = ({
  site,
  user,
  nodeList,
  setNodeList,
  filteredNodeList,
  setCurrentAction,
  EDIT_STOCK_LOCATION,
  ADD_CHILD,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  setSelectedStockLocation,
  setParent,
  setIsSubmitting,
  setError,
  setAPIndex,
  contextMenu,
  onSetContextMenu,
  toggle,
  setToggle,
}) => {
  const { t } = useTranslation("stockLocations");
  const _controller = useRef(null);
  const pcRef = useRef(null);

  const [filteredNodes, setFilteredNodes] = useState([]);
  const [scrollTopVal, setScrollTopValue] = useState(0);
  const [prevIndex, setPrevIndex] = useState(null);
  const [rowIndex, setRowIndex] = useState(-1);
  const [clickedNode, setClickedNode] = useState(null);

  const { mediumScreen } = useContext(ScreenContext);
  const mdScreen = useMediaQuery(mediumScreen);

  useEffect(() => {
    var element = document.getElementById("scrollArea");

    element.onscroll = function () {
      setScrollTopValue(element.scrollTop);
    };
  }, []);

  useEffect(() => {
    setFilteredNodes(filteredNodeList);
  }, [filteredNodeList, toggle]);

  useEffect(() => {
    _controller.current = new StockLocationsController(user, site);
    pcRef.current = new ProductController();
  }, []);

  const getActionMenu = (site, stockLocation) => {
    let listItems = [...actionListItems];

    if (
      (stockLocation.children && stockLocation.children.length > 0) ||
      (stockLocation.productAssociations &&
        stockLocation.productAssociations.length > 0)
    ) {
      // Remove "Delete" option
      listItems.splice(5, 1);
    }

    if (!RoleCheckService.deleteStockLocations(user)) {
      let index = listItems.findIndex((o) => o.type === "Delete");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
    }

    if (stockLocation.type.type.includes("CANISTER")) {
      let index = listItems.findIndex((o) => o.type === "Add Child");
      listItems.splice(index, 1);
    }

    if (stockLocation.isEnabled) {
      let index = listItems.findIndex((o) => o.type === "enable");
      listItems.splice(index, 1);
    } else {
      let index;
      index = listItems.findIndex((o) => o.type === "disable");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
      index = listItems.findIndex((o) => o.type === "Add Child");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
      // index = listItems.findIndex((o) => o.type === "Add Product");
      // if (index !== -1) {
      //   listItems.splice(index, 1);
      // }
      index = listItems.findIndex((o) => o.type === "Edit");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
      index = listItems.findIndex((o) => o.type === "Delete");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
    }

    return listItems;
  };

  const handleAction = (action, site, setCurrentAction, node, e) => {
    switch (action.type) {
      case "Add Child":
        if (!node.isExpanded) {
          expand(node);
        }
        setParent(node);
        setSelectedStockLocation({
          obj: {
            name: null,
            type: node.obj.type,
            isControlled: null,
            orderPriority: null,
            isEnabled: true,
            device: null,
          },
        });

        setCurrentAction(ADD_CHILD);
        break;
      // case "Add Product":
      //   setCurrentAction(ADD_PRODUCT);
      //   setParent(node.parent);
      //   setSelectedStockLocation(node);
      //   break;
      case "Edit":
        setCurrentAction(EDIT_STOCK_LOCATION);
        setParent(node.parent);
        setSelectedStockLocation(node);
        break;

      case "Delete":
        if (
          node.obj.children.length > 0 ||
          node.productAssociations.length > 0
        ) {
          setError(t("error0"));
        } else {
          const stockLocationToDelete = { ...node.obj };

          const currentNodeList = [...nodeList];

          // Get parent node, if exists
          let parentNode = node.parent;

          const callback = (error, stockLocation) => {
            if (!error) {
              // Case where stockLocation does not have a parent
              if (!parentNode) {
                let sLIndex = currentNodeList.findIndex(
                  (node) => node.obj.id === stockLocationToDelete.id
                );
                currentNodeList.splice(sLIndex, 1);
                setNodeList(currentNodeList);
              } else {
                // if stockLocation has a parent

                parentNode.removeChildren(node);

                let parentStockLocation = parentNode.obj;
                let childIndex = parentStockLocation.children.findIndex(
                  (id) => id === stockLocationToDelete.id
                );

                parentStockLocation.children.splice(childIndex, 1);

                const callback2 = (error, stockLocation) => {
                  // Gets numOfChildren, if any, the parent stock location still has after removal of the selected child
                  let numOfChildren = parentNode.obj.children.length;

                  if (numOfChildren >= 1) {
                    collapse(parentNode);
                    expand(parentNode);
                  }
                  setNodeList(currentNodeList);
                  if (!error) {
                    setNodeList(currentNodeList);
                  } else {
                    console.log("error", error);
                  }
                };

                _controller.current.updateStockLocation(
                  parentStockLocation,
                  callback2
                );
              }
            } else {
              console.log("error", error);
            }
          };
          _controller.current.deleteStockLocation(node.obj, callback);
        }

        break;
      case "enable":
        enableStockLocations(node, true);
        break;

      case "disable":
        enableStockLocations(node, false);
        break;
      default:
        break;
    }
  };

  const enableStockLocations = (parent, enabled) => {
    parent.obj.isEnabled = enabled;
    _controller.current.updateStockLocation(parent.obj);
    for (let i = 0; i < parent.children.length; i++) {
      parent.children[i].obj.isEnabled = enabled;
      _controller.current.updateStockLocation(parent.children[i].obj);
    }
  };

  const isAuthorized = () => {
    return RoleCheckService.createStockLocations(user);
  };

  // const getStockedItemQuantity = async (stockLocation, product) => {
  //   const stockedItems = await _controller.current.getStockedItems(
  //     stockLocation,
  //     product
  //   );
  //   let qoh = 0;
  //   for (let i = 0; i < stockedItems.length; i++) {
  //     qoh += stockedItems[i].quantity;
  //   }
  //   return qoh;
  // };

  const actionListItems = [
    {
      type: "enable",
      description: t("menuItem0"),
    },
    {
      type: "disable",
      description: t("menuItem5"),
    },
    {
      type: "Add Child",
      description: t("menuItem1"),
    },
    // {
    //   type: "Add Product",
    //   description: t("menuItem2"),
    // },
    {
      type: "Edit",
      description: t("menuItem3"),
    },
    {
      type: "Delete",
      description: t("menuItem4"),
    },
  ];

  const select = (node, nodeObjChildrenLength, e) => {
    if (nodeObjChildrenLength > 0 || node.obj.productAssociations.length > 0) {
      filteredNodes.forEach((nodeItem) => {
        if (
          node.topParentId !== -1 &&
          nodeItem.topParentId !== node.topParentId &&
          nodeItem.isExpanded
        ) {
          collapse(nodeItem);
        }
      });

      if (!node.isExpanded) {
        expand(node);
      } else {
        collapse(node);
      }

      // Force rerendering of page with updates
      // const copy = [...filteredNodes];
      // setFilteredNodes(copy);
      setToggle((prevState) => {
        return !prevState;
      });
    }
  };

  const expand = async (node) => {
    filteredNodes.map((nodeMember) => {
      if (
        nodeMember.isExpanded &&
        node !== nodeMember &&
        node.parent === null
      ) {
        select(nodeMember);
      }
    });

    node.isSelected = !node.isSelected;
    node.isExpanded = !node.isExpanded;

    const callback = async (error, stockLocations) => {
      if (!error) {
        stockLocations.forEach((sl) => {
          let parentContainsChild = false;
          let nodeChildrenArray = node.children;

          for (let i = 0; i < nodeChildrenArray.length; i++) {
            if (nodeChildrenArray[i].objId === sl.id) {
              parentContainsChild = true;
              break;
            }
          }

          // This check is necessary to prevent a double entry upon a rapid double click.
          if (!parentContainsChild) {
            const child = new Node(sl);
            child.parent = node;
            node.addChild(child);
          }
        });
        //node.addproductAssociations(node.obj.productAssociations);

        // for (let i = 0; i < node.obj.productAssociations.length; i++) {
        //   node.obj.productAssociations[i].qoh = await getStockedItemQuantity(
        //     node.obj,
        //     node.obj.productAssociations[i].product
        //   );
        // }

        setFilteredNodes([...filteredNodes]);
      } else {
        console.log("Error", error);
      }
    };

    // Returns all stock locations that are children for a given parent stock location (node.obj).
    _controller.current.getStockLocations(node.obj, callback);
    //callback(null, node.obj.children);
  };

  const collapse = (node) => {
    node.isSelected = !node.isSelected;
    node.isExpanded = !node.isExpanded;
    node.removeChildren();
  };

  // const saveEditedProduct = useCallback(
  //   (editedStockLocation, node, APIndex) => {
  //     // Called by removeAP function
  //     async function doSaveData(node, APIndex) {
  //       const callback = (error, stockLocation) => {
  //         if (!error) {
  //           const currentNodeList = [...filteredNodes];
  //
  //           setFilteredNodes((prevState) => {
  //             return currentNodeList;
  //           });
  //           setFilteredNodes(currentNodeList);
  //
  //           setToggle(!toggle);
  //
  //           setIsSubmitting(false);
  //         } else {
  //           setIsSubmitting(false);
  //           setError(error);
  //         }
  //       };
  //       _controller.current.updateStockLocation(editedStockLocation, callback);
  //     }
  //     doSaveData(node, APIndex);
  //   }
  // );

  // const editAP = (APIndex, node) => {
  //   setAPIndex(APIndex);
  //   setParent(node.parent);
  //   setSelectedStockLocation(node);
  //   setCurrentAction(EDIT_PRODUCT);
  // };

  // const removeAP = (APIndex, node) => {
  //   node.productAssociations.splice(APIndex, 1);
  //   node.obj.productAssociations = node.productAssociations;
  //
  //   saveEditedProduct(node.obj, node, APIndex);
  // };

  const getNode = (node, index) => {
    // let productAssociationsBeforeSorting = node.productAssociations;
    //
    // function compare(a, b) {
    //   // Sorts productAssociations by Description
    //   const prodA = pcRef.current.getDefaultName(a.product).toUpperCase();
    //   const prodB = pcRef.current.getDefaultName(b.product).toUpperCase();
    //
    //   let comparison = 0;
    //   if (prodA > prodB) {
    //     comparison = 1;
    //   } else if (prodA < prodB) {
    //     comparison = -1;
    //   }
    //   return comparison;
    // }
    //
    // let productAssociationsAfterSorting = [];
    //
    // if (productAssociationsBeforeSorting.length > 0) {
    //   productAssociationsAfterSorting =
    //     // node.obj.productAssociations.sort(compare);
    //     node.productAssociations.sort(compare);
    // }

    let nodeObjChildrenLength = node.obj.children.length;

    return (
      <div
        key={node.objId}
        className={
          node.parent === null && node.isExpanded && node.children.length > 0
            ? [style["row__mainGroup"], style["row__mainGroup--expanded"]].join(
                " "
              )
            : node.parent === null
            ? style.row__mainGroup
            : ""
        }
      >
        <div
          style={
            node.obj.isEnabled
              ? { backgroundColor: "#FFFFFF" }
              : { backgroundColor: "#ededed" }
          }
          className={
            node.parent === null
              ? !node.isExpanded
                ? style.rowTop
                : [style["rowTop"], style["rowTop--expanded"]].join(" ")
              : [style["row"], style["row--children"]].join(" ")
          }
          onClick={(e) => {
            e.stopPropagation();
            if (
              clickedNode !== node &&
              rowIndex !== index &&
              nodeObjChildrenLength > 0 &&
              !node.isExpanded
            ) {
              setClickedNode(null);
            }
            select(node, nodeObjChildrenLength, e);
          }}
        >
          {nodeObjChildrenLength > 0 && node.obj.isEnabled ? (
            <span className={style.row__hasChildrenIconContainer}>
              {!node.isExpanded ? (
                <ExpandIcon className={style.row__hasChildrenIcon} />
              ) : (
                <CollapseIcon className={style.row__hasChildrenIcon} />
              )}
            </span>
          ) : (
            <span className={style.row__hasNoChildrenIconContainer}></span>
          )}

          <span className={style.subRow__name}>{node.obj.name}</span>

          <span className={style.subRow__nickname}>{node.obj.nickname}</span>

          <span className={style.subRow__type}>
            {t(node.obj.type.description, {ns: "stockLocationTypes"})}
          </span>

          <span className={style.subRow__position}>
            {node.obj.devicePosition || ""}
          </span>

          <span className={style.subRow__isControlled}>
            {node.obj.isControlled ? t("controlled") : t("notControlled") }
          </span>

          <span className={style.subRow__barcode}>{node.obj.barcode}</span>

          {isAuthorized() && (
            <div
              className={style.contextMenuContainer}
              onClick={(e) => {
                setClickedNode(node);
                e.stopPropagation();
                setRowIndex(index);
                if (
                  contextMenu === null ||
                  node !== clickedNode ||
                  (contextMenu !== null && index !== prevIndex)
                ) {
                  setPrevIndex(index);
                  onSetContextMenu({
                    minWidth: "90px",
                    left: "0",
                    top: "0",
                    listItems: getActionMenu(site, node.obj),
                    isRightAligned: false,
                    onSelect: (action) => {
                      handleAction(action, site, setCurrentAction, node);
                      onSetContextMenu(null);
                    },
                  });
                } else {
                  onSetContextMenu(null);
                }
              }}
            >
              {node === clickedNode && (
                <div className={style.contextMenuWrapper}>
                  {contextMenu && (
                    <ContextMenu
                      onSelect={contextMenu.onSelect}
                      listItems={contextMenu.listItems}
                      minWidth={contextMenu.minWidth}
                      top={contextMenu.top}
                      left={contextMenu.left}
                      isRightAligned={contextMenu.isRightAligned}
                      maxHeight={contextMenu.maxHeight}
                      onClose={() => onSetContextMenu(null)}
                    />
                  )}
                </div>
              )}

              <span className={style.action_items}>
                {mdScreen ? (
                  <MoreActionsIconMobile className="item__actionsIcon" />
                ) : (
                  <MoreActionsIcon className="item__actionsIcon" />
                )}
              </span>
            </div>
          )}
        </div>

        {/*{node.isExpanded && node.productAssociations.length > 0 ? (*/}
        {/*  <div>*/}
        {/*    {productAssociationsAfterSorting.map((ap, index) => {*/}
        {/*      return (*/}
        {/*        <div key={node.obj._id + index} className={style.row}>*/}
        {/*          <span className={style.subRow__product}>*/}
        {/*            {pcRef.current.getDefaultName(ap.product)}*/}
        {/*          </span>*/}
        {/*          <span className={style.subRow__qoh}>{ap.qoh || 0}</span>*/}
        {/*          <span className={style.subRow__min}>{ap.min}</span>*/}
        {/*          <span className={style.subRow__max}>{ap.max}</span>*/}
        {/*          <span*/}
        {/*            className={style.subRow__editIconContainer}*/}
        {/*            onClick={(e) => {*/}
        {/*              e.stopPropagation();*/}
        {/*              editAP(index, node, productAssociationsAfterSorting);*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            <EditIcon className={style.subRow__editIcon} />*/}
        {/*          </span>*/}
        {/*          <span*/}
        {/*            className={style.subRow__trashIconContainer}*/}
        {/*            onClick={() => {*/}
        {/*              if (!ap.qoh || ap.qoh === 0) {*/}
        {/*                removeAP(index, node);*/}
        {/*              } else {*/}
        {/*                setError(*/}
        {/*                  "A stock location cannot be deleted if there is quantity on hand"*/}
        {/*                );*/}
        {/*              }*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            <TrashIcon className={style.subRow__trashIcon} />*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </div>*/}
        {/*) : null}*/}
        {node.isExpanded &&
          node.children.map((childNode) => {
            return getNode(childNode, index);
          })}
      </div>
    );
  };

  return (
    <div id="scrollArea" className={style.scrollArea}>
      {filteredNodes.map((node, index) => {
        node.topParentId = index;
        return getNode(node, index);
      })}
    </div>
  );
};

export default StockLocationsData;
