import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./StockLocationsHeadings.module.css";

const StockLocationsHeadings = () => {
  const { t } = useTranslation("stockLocations");

  return (
    <div className={styles.mainHeading}>
      <div className={styles.mainHeading__name}>{t("nameCol")}</div>
      <div className={styles.mainHeading__nickname}>{t("nicknameCol")}</div>
      <div className={styles.mainHeading__type}>{t("typeCol")}</div>
      <div className={styles.mainHeading__position}>{t("positionCol")}</div>
      <div className={styles.mainHeading__controlled}>{t("controlledCol")}</div>
      <div className={styles.mainHeading__barcode}>{t("barcodeCol")}</div>
      {/*<div className={styles.mainHeading__qoh}>{t("qohCol")}</div>*/}
      {/*<div className={styles.mainHeading__min}>{t("minCol")}</div>*/}
      {/*<div className={styles.mainHeading__max}>{t("maxCol")}</div>*/}
    </div>
  );
};

export default StockLocationsHeadings;
