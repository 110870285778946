
import { Table } from "antd";
import styles from "./PrintMedList.module.css";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const PatientList = ({
  patients,
  selectedPatients,
  setSelectedPatients,
  setEditPatient,
}) => {
  const { t } = useTranslation("medList");

  const columns = [
    {
      title: t("firstNameLabel"),
      dataIndex: "firstName",
      align: "center",
    },
    {
      title: t("lastNameLabel"),
      dataIndex: "lastName",
      align: "center",
    },
    {
      title: t("languageLabel"),
      dataIndex: "language",
      align: "center",
      render: (value, record) =>
        t(value, { ns: "languages" }) ||
        `${t(record.facility.defaultLanguage, { ns: "languages" })} (${t(
          "default"
        )})`,
    },
    {
      title: t("actionLabel"),
      key: "action",
      align: "center",
      render: (_, record) => (
        <button
          onClick={() => {
            setEditPatient(record);
          }}
          className={styles.PrintMedList__buttonLink}
        >
          {t("editLanguageButton")}
        </button>
      ),
    },
  ];

  if (!patients.length) {
    return <Rings color="#089BAB" height={50} width={50} />
  }
  
  return (
    <div className={styles.PrintMedList__table}>
      <Table
        rowKey={"_id"}
        dataSource={patients}
        columns={columns}
        rowSelection={{
          selectedRowKeys: selectedPatients,
          onChange: setSelectedPatients
        }}
        pagination={
          patients.length > 10
            ? {
                pageSize: 10,
                total: patients.length,
              }
            : false
        }
      />
    </div>
  )
}

export default PatientList;