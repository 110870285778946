import React, { useEffect, useMemo, useState } from "react";
import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button";
import { Select } from "antd";
import { ReactComponent as RefreshSVG } from "../../../assets/refresh-2.svg";
import Input from "./Input";

const SheetProperties = ({
  _controllerRef,
  unit,
  applyPreset,
  labelPresets,
  setLabelPresets,
  presetId,
  setPresetId,
  presetName,
  setPresetName,
  labelWidth,
  setLabelWidth,
  labelHeight,
  setLabelHeight,
  sheetWidth,
  setSheetWidth,
  sheetHeight,
  setSheetHeight,
  rowCount,
  setRowCount,
  colCount,
  setColCount,
  previewOutline,
  setPreviewOutline,
  autoLayout,
  setAutoLayout,
  manualLayout,
  setManualLayout,
}) => {
  const { t } = useTranslation("labelEditor");  
  const [verticalCentered, setVerticalCentered] = useState(false);
  const [horizontalCentered, setHorizontalCentered] = useState(false);
  const [top, setTop] = useState(manualLayout.top)
  const [left, setLeft] = useState(manualLayout.left)

  const presets = useMemo(() => {
    const emptyPreset = {
      label: t("newPresetLabel"),
      value: null,
    };

    return [
      emptyPreset,
      ...labelPresets
        .sort((a, b) => {
          if (a.name === "Default") return -1;
          if (b.name === "Default") return 1;
          return a.name.localeCompare(b.name);
        })
        .map((preset) => ({
          ...preset,
          label: preset.name,
          value: preset._id,
        }))
    ];
  }, [labelPresets]);
  
  useEffect(() => {
    if (verticalCentered) {
      const remaining = sheetHeight - (labelHeight * rowCount) - (manualLayout.rowGap * (rowCount - 1));
      setTop(remaining / 2)
      setManualLayout((prevLayout) => ({
        ...prevLayout,
        top: remaining / 2
      }))
    } else {
      setTop(manualLayout.top)
    }    
  }, [verticalCentered, sheetHeight, labelHeight, rowCount, manualLayout.rowGap, manualLayout.top, setManualLayout])
  
  useEffect(() => {
    if (horizontalCentered) {
      const remaining = sheetWidth - (labelWidth * colCount) - (manualLayout.colGap * (colCount - 1));
      setLeft(remaining / 2)
      setManualLayout((prevLayout) => ({
        ...prevLayout,
        left: remaining / 2
      }))
    } else {
      setLeft(manualLayout.left)
    }    
  }, [horizontalCentered, sheetWidth, labelWidth, colCount, manualLayout.colGap, manualLayout.left, setManualLayout])

  const handleLayoutChange = (value, name) => {    
    setManualLayout((prevLayout) => ({
        ...prevLayout,
        [name]: value,      
    }));
  };

  const handleSelectPreset = (id) => {
    setPresetId(id);
  };
  
  const handleDeletePreset = (e) => {
    _controllerRef.current
      .deleteLabelPreset(
        presetId,
      )
      .then((result) => {
        if (result.status === 200) {
          console.log("Deleted preset with ID ", presetId)
          setPresetId(null);
          setLabelPresets((prevPresets) =>
            prevPresets.filter((preset) => preset._id !== presetId)
          );
        } else {
          console.log("error: ", result);
        }
      });
  };

  const handleCreatePreset = (e) => {
    _controllerRef.current
      .createLabelPreset(
        presetName,
        labelWidth,
        labelHeight,
        sheetWidth,
        sheetHeight,
        rowCount,
        colCount,
        autoLayout,
        manualLayout?.left,
        manualLayout?.top,
        manualLayout?.rowGap,
        manualLayout?.colGap
      )
      .then((result) => {
        if (result) {
          const createdPreset = {
            ...result,
            Value: result._id,
            Item: result.name,
          };
          setLabelPresets((prevPresets) => [createdPreset, ...prevPresets].sort((a, b) => {
            if (a.name === "Default") return -1;
            if (b.name === "Default") return 1;
            return a.name.localeCompare(b.name);
          }));
          setPresetId(createdPreset._id);
        } else {
          console.log("error: ", result);
        }
      });
  };

  const handleUpdatePreset = (e) => {
    _controllerRef.current
      .updateLabelPreset(
        presetId,
        presetName,
        labelWidth,
        labelHeight,
        sheetWidth,
        sheetHeight,
        rowCount,
        colCount,
        autoLayout,
        manualLayout?.left,
        manualLayout?.top,
        manualLayout?.rowGap,
        manualLayout?.colGap
      )
      .then((result) => {
        if (result) {
          const updatedPreset = {
            ...result,
            Value: result._id,
            Item: result.name,
          };
          setLabelPresets((prevPresets) => {
            return prevPresets.map((preset) =>
              preset._id === updatedPreset._id ? updatedPreset : preset
            );
          });
        } else {
          console.log("error: ", result);
        }
      });
  };

  if (!presets.length) {
    return null;
  }

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__spacedRow}>
        <div>
          <label className={styles.Properties__label}>
            {t("loadPresetLabel")}
          </label>
          <div style={{ display: "flex" }}>
            <div
              style={{ cursor: "pointer", marginTop: 10, marginRight: 5 }}
              onClick={applyPreset}
            >
              <RefreshSVG width={"16px"} height={"16px"} />
            </div>
            <Select
              value={presetId}
              options={presets}
              onChange={handleSelectPreset}
              style={{ maxWidth: 180, height: 38, fontSize: 18 }}
              popupMatchSelectWidth={false}
            />
          </div>
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__propertyFull}>
          <label className={styles.Properties__label}>
            {t("presetNameLabel")}
          </label>
          <Input
            value={presetName}
            name="presetName"
            type="text"
            onChange={setPresetName}
            disabled={presetId && presets.find((preset) => presetId === preset._id).name === "Default"}
          />
        </div>
      </div>            

      <div className={styles.Properties__spacedRow} style={{gap: 5}}>
        {presetId && presets.find((preset) => presetId === preset._id).name !== "Default" ? (
          <Button
            labelName={"X"}
            onClick={handleDeletePreset}
            isRed={true}
            minWidth={48}
            width={48}
          />
        ) : null}
        <Button
          labelName={presetId ? t("updatePresetLabel") : t("createPresetLabel")}
          onClick={presetId ? handleUpdatePreset : handleCreatePreset}
          isPrimary={true}
          minWidth={null}
          width="100%"
        />
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__toggle}>
          <Checkbox
            labelName={t("previewOutlineLabel")}
            labelColor={"var(--green)"}
            isOn={previewOutline}
            onCheck={setPreviewOutline}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("labelWidthLabel")}
          </label>
          <Input
            value={labelWidth}
            name="labelWidth"
            type="number"
            unit={unit}
            onChange={setLabelWidth}
            min={1}
            step={0.1}
          />

        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("labelHeightLabel")}
          </label>
          <Input
            value={labelHeight}
            name="labelHeight"
            type="number"
            unit={unit}
            onChange={setLabelHeight}
            min={1}
            step={0.1}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("sheetWidthLabel")}
          </label>
          <Input
            value={sheetWidth}
            name="sheetWidth"
            type="number"
            unit={unit}
            onChange={setSheetWidth}
            min={1}
            step={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("sheetHeightLabel")}
          </label>
          <Input
            value={sheetHeight}
            name="sheetHeight"
            type="number"
            unit={unit}
            onChange={setSheetHeight}
            min={1}
            step={0.1}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("colCountLabel")}
          </label>
          <Input
            value={colCount}
            name="colCount"
            type="number"
            onChange={setColCount}
            min={1}
            step={1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("rowCountLabel")}
          </label>
          <Input
            value={rowCount}
            name="rowCount"
            type="number"
            onChange={setRowCount}
            min={1}
            step={1}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__toggle}>
          <Checkbox
            labelName={t("autoLayoutLabel")}
            labelColor={"var(--green)"}
            isOn={autoLayout}
            onCheck={setAutoLayout}
          />
        </div>
      </div>
      
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("colGapLabel")}
          </label>
          <Input
            value={manualLayout.colGap}
            name="colGap"
            type="number"
            unit={unit}
            onChange={handleLayoutChange}
            min={0}
            step={0.1}
            disabled={autoLayout}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("rowGapLabel")}
          </label>
          <Input
            value={manualLayout.rowGap}
            name="rowGap"
            type="number"
            unit={unit}
            onChange={handleLayoutChange}
            min={0}
            step={0.1}
            disabled={autoLayout}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("leftMarginLabel")}
          </label>
          <Input
            value={left}
            name="left"
            type="number"
            unit={unit}
            onChange={handleLayoutChange}
            min={0}
            step={0.1}
            disabled={autoLayout || horizontalCentered}
          />
        </div>
        <div className={`
          ${styles.Properties__toggle}
          ${styles.half}
        `}>
          <Checkbox
            labelName={t("centeredLabel")}
            labelColor={"var(--green)"}
            isOn={autoLayout || horizontalCentered}
            onCheck={setHorizontalCentered}
            disabled={autoLayout}
          />
        </div>        

      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("topMarginLabel")}
          </label>
          <Input
            value={top}
            name="top"
            type="number"
            unit={unit}
            onChange={handleLayoutChange}
            min={0}
            step={0.1}
            disabled={autoLayout || verticalCentered}
          />
        </div>      
        <div className={`
          ${styles.Properties__toggle}
          ${styles.half}
        `}>
          <Checkbox
            labelName={t("centeredLabel")}
            labelColor={"var(--green)"}
            isOn={autoLayout || verticalCentered}
            onCheck={setVerticalCentered}
            disabled={autoLayout}
          />
        </div>
      </div>
    </div>
  );
};

export default SheetProperties;
