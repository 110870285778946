import { jsPDF } from "jspdf";
import { pxToMm } from "./functions/converters.js";
// import addFontsToPdf from "../../fonts/jsPdfAddFonts.js";

// The commented code is a deprecated option to insert real text in document
const generatePDF = async (stageRef, pages, setPage, zoom, setZoom) => {      
  setZoom(100);
  await new Promise((resolve) => setTimeout(resolve, 100));

  const pdf = new jsPDF('p', 'mm', [pxToMm(stageRef.current.width()), pxToMm(stageRef.current.height())])
  console.log(`generate pdf ${pxToMm(stageRef.current.width())} x ${pxToMm(stageRef.current.height())}`)
  

  // The function's loop should be finished if the feature is added again
  // await addFontsToPdf(pdf);        

  for (let i = 0; i < pages.length; i++) {
    setPage(i);
    await new Promise((resolve) => setTimeout(resolve, 100));
  
    //const textNodes = stageRef.current.find('Text');     
    //if (convertText && !showCanvaText) {
    //  textNodes.forEach(text => text.hide());      
    //}
    
    pdf.addImage(
      stageRef.current.toDataURL({ pixelRatio: 4, mimeType: 'image/jpeg' }),
      0,
      0,
      pxToMm(stageRef.current.width()),
      pxToMm(stageRef.current.height())
    );
    
    /* 
    if (convertText) {
      textNodes.forEach((text) => {
        const size = text.fontSize() * 0.75 // convert pixels to points

        pdf.setFontSize(size);
        pdf.setFont(text.fontFamily(), text.fontStyle())
        pdf.setTextColor(text.fill());


        let lines;
        if (text.attrs.wrap === "word") {
          lines = pdf.splitTextToSize(text.text(), pxToMm(text.width())).slice(0, text.attrs.wrapLines)
        } else if (text.attrs.wrap === "char") {
          lines = pdf.splitTextToSize(text.text(), pxToMm(text.width())).slice(0, text.attrs.wrapLines) // TODO
        } else {
          lines = pdf.splitTextToSize(text.text(), pxToMm(text.width()))[0]
        }

        const position = text.getAbsolutePosition()

        const x =
          position.x +
          (text.align() === "center"
            ? text.width() / 2
            : text.align() === "right"
            ? text.width()
            : 0);

        pdf.text(lines, pxToMm(x), pxToMm(position.y), {
          angle: -text.getAbsoluteRotation(),
          align: text.align(),
          baseline: 'top',
        });
      });
      textNodes.forEach(text => text.show());   
    }*/
    
    if (i < pages.length - 1) {
      pdf.addPage([pxToMm(stageRef.current.width()), pxToMm(stageRef.current.height())]);
    }
  }

  pdf.save('canvas.pdf');    

  setZoom(zoom)
}

export default generatePDF;