import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";
import SFDropDownList from "../../../components/SFDropDownList/SFDropDownList";
import getCanisterDataMappingDropdownList from "./getCanisterDataMappingDropdownList";
import Checkbox from "../../../components/Checkbox";
import Input from "./Input";
import { ReactComponent as BoldSVG } from "../svgs/text-bold.svg";
import { ReactComponent as ItalicSVG } from "../svgs/text-italic.svg";
import { ReactComponent as UnderlineSVG } from "../svgs/text-underline.svg";

const BarcodeProperties = ({ unit, selectedObject, updateObject, labelType }) => {
  const { t } = useTranslation("labelEditor");

  const handleInputChange = (value, name, dataset) => {
    let { key } = dataset;

    updateObject({
      ...selectedObject,
      [name]: key ? { ...selectedObject[name], [key]: value } : value,
    });
  };

  const handleSelectedSymbology = (symbology) => {
    updateObject({
      ...selectedObject,
      symbology: symbology,
      changed: true,
    });
  };
  
  const dropdownListSymbology = [
    { Item: "code128", Value: "Code128B", "displayName":  t("codeLabel", {code: "128"}) },
    { Item: "code39", Value: "Code39", "displayName":  t("codeLabel", {code: "39"}) },
    { Item: "datamatrix", Value: "DataMatrix", "displayName":  t("matrixLabel") },
    { Item: "qrcode", Value: "QRBarcode", "displayName":  t("qrLabel") },
  ].sort((a, b) => a.displayName.localeCompare(b.displayName));

  
  const dropdownListCanisterDataMapping = getCanisterDataMappingDropdownList();

  const handleCanisterDataMapping = (friendlyName, path) => {
    updateObject({
      ...selectedObject,
      mappingName: friendlyName,
      mappingPath: path,
    });
  };
  
  const handleShowValueCheckbox = () => {
    updateObject({
      ...selectedObject,
      showValue: !selectedObject.showValue,
    });
  };

  const toggleBold = () => {
    if (selectedObject.locked) return;

    let fontStyle = selectedObject.fontStyle;
    if (fontStyle === "normal") {
      fontStyle = "bold";
    } else if (fontStyle === "bold") {
      fontStyle = "normal";
    } else if (fontStyle === "italic") {
      fontStyle = "bold italic";
    } else if (fontStyle === "bold italic") {
      fontStyle = "italic";
    }

    updateObject({
      ...selectedObject,
      fontStyle,
    });
  };

  const toggleItalic = () => {
    if (selectedObject.locked) return;

    let fontStyle = selectedObject.fontStyle;
    if (fontStyle === "normal") {
      fontStyle = "italic";
    } else if (fontStyle === "italic") {
      fontStyle = "normal";
    } else if (fontStyle === "bold") {
      fontStyle = "bold italic";
    } else if (fontStyle === "bold italic") {
      fontStyle = "bold";
    }

    updateObject({
      ...selectedObject,
      fontStyle,
    });
  };

  const toggleUnderline = () => {
    if (selectedObject.locked) return;

    updateObject({
      ...selectedObject,
      textDecoration:
        selectedObject.textDecoration === "underline" ? "" : "underline",
    });
  };

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("widthLabel")}</label>
          <Input
            value={selectedObject.width}
            name="width"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            step={0.1}
            min={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("heightLabel")}</label>
          <Input
            value={selectedObject.height}
            name="height"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            step={0.1}
            min={0.1}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X</label>
          <Input
            value={selectedObject.x}
            name="x"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={
              selectedObject.locked ||
              selectedObject?.parent?.type === "gridLayout"
            }
            step={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y</label>
          <Input
            value={selectedObject.y}
            name="y"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={
              selectedObject.locked ||
              selectedObject?.parent?.type === "gridLayout"
            }
            step={0.1}
          />
        </div>
      </div>
      <div className={styles.Properties__propertyFull}>
        <SFDropDownList
          id={"symbology"}
          label={t("symbologyLabel")}
          defaultValue={selectedObject.symbology}
          dropDownList={dropdownListSymbology}
          onSelection={handleSelectedSymbology}
          display={"displayName"}
          enabled={!selectedObject.locked}
          blackUnderline={true}
          style={{height: 30}}
          labelStyle={{padding: 0, height: 18, margin: "0px !important"}}
        />
      </div>

      {labelType === "Canister" && (
        <div className={styles.Properties__canisterDataMapping}>
          <SFDropDownList
            id={"canisterDataMapping"}
            label={t("canisterDataLabel")}
            defaultValue={selectedObject.mappingName}
            dropDownList={dropdownListCanisterDataMapping}
            onSelection={handleCanisterDataMapping}
            display={"displayName"}
            enabled={!selectedObject.locked}
            popupWidth={"300px"}
            blackUnderline={true}
            style={{height: 30}}
            labelStyle={{padding: 0, height: 18, margin: "0px !important"}}
          />
        </div>
      )}

      <div className={styles.Properties__propertyFull}>
        <label className={styles.Properties__label}>          
          {selectedObject.mappingName === "Default Value"
            ? t("defaultValueLabel")
            : t("placeholderValueLabel")}
        </label>
        <Input
          value={selectedObject.defaultValues[selectedObject.symbology]}
          name="defaultValues"
          type="text"
          data-key={selectedObject.symbology}
          onChange={handleInputChange}
          disabled={selectedObject.locked}
        />
      </div>

      <div>
        <div className={styles.Properties__toggle}>
          <Checkbox
            labelName={t("showValueLabel")}
            labelColor={"var(--black)"}
            isOn={selectedObject.showValue}
            onCheck={handleShowValueCheckbox}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      {selectedObject.showValue ? (
        <div className={styles.Properties__spacedRow}>
          <div className={styles.Properties__property}>
            <label className={styles.Properties__label}>
              {t("fontSizeLabel")}
            </label>
            <Input
              value={selectedObject.fontSize}
              name="fontSize"
              type="number"
              onChange={handleInputChange}
              disabled={selectedObject.locked}
              min={1}
              step={1}
            />
          </div>

          <div className={styles.Properties__property}>
            <div className={styles.Properties__boxesContainer}>
              <div
                className={`${styles.Properties__styleContainer} ${
                  selectedObject.locked ? styles.disabled : styles.Properties__clickable
                }`}
                onClick={toggleBold}
              >
                <BoldSVG />
              </div>
              <div
                className={`${styles.Properties__styleContainer} ${
                  selectedObject.locked ? styles.disabled : styles.Properties__clickable
                }`}
                onClick={toggleItalic}
              >
                <ItalicSVG />
              </div>
              <div
                className={`${styles.Properties__styleContainer} ${
                  selectedObject.locked ? styles.disabled : styles.Properties__clickable
                }`}
                onClick={toggleUnderline}
              >
                <UnderlineSVG />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BarcodeProperties;
