import React, { useRef, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Imports need for header items (excluding DropDown menu)
import SearchBar from "../../components/SearchBar";
import ImageService from "../../services/ImageService";
// import AlarmDateTime from "../AlarmDateTime/AlarmDateTime";
import UserProfilePopup from "../../partials/UserProfilePopup";
import { ReactComponent as PicFrameEmpty } from "../../assets/pic-frame-empty.svg";
import styles from "./HeaderForMobile.module.css";

// Imports needed for DropDown menu
import DropDown from "../../components/DropDownMenuNew/DropDown";
import { InventoryContext } from "../../contexts/InventoryContext";
import { ScreenContext } from "../../contexts/ScreenContext";
import dashboardController from "../../controllers/DashboardController";
import AuthService from "../../services/AuthService";
import {
  PROFILE_MENU_ITEM,
  DASHBOARD_MENU_ITEM,
  SETTINGS_MENU_ITEM,
  REPORTS_MENU_ITEM,
  ORDERS_MENU_ITEM,
  INVENTORY_MENU_ITEM,
  LOGOUT_MENU_ITEM,
  CHANGE_PASSWORD_MENU_ITEM,
  DEVICES_MENU_ITEM,
  HELP_MENU_ITEM,
} from "../../partials/NavBar";

const HeaderForMobile = ({ user, handleSearch, onLogOut }) => {
  // State needed for header items (excluding DropDown menu)
  const [profilePic, setProfilePic] = useState("default");
  const [showPopup, setShowPopup] = useState(false);

  // State needed for DropDown menu
  const [alarm, setAlarm] = useState(null);
  const [navToggle, setNavToggle] = useState(false);
  const navigate = useNavigate();
  const [menuItem, setMenuItem] = useState(DASHBOARD_MENU_ITEM);

  // const { handleContextFilterText } = useContext(ScreenContext);

  // Needed for header items (excluding DropDown menu)
  useEffect(() => {
    if (user.profilePic) {
      ImageService.read(user, user.profilePic).then((imageDoc) => {
        setProfilePic(imageDoc);
      });
    } else {
      setProfilePic("default");
    }
  }, [user]);

  // Constants needed for header items (excluding Dropdown)
  const handlePopup = (boolVal) => {
    setShowPopup(boolVal);
  };

  // Constants needed for DropDown Menu
  const _controllerRef = useRef(dashboardController);
  const handleMenuSelection = (selectedMenuItem) => {
    setNavToggle(!navToggle);
    setAlarm(null);
    if (selectedMenuItem === INVENTORY_MENU_ITEM) {
      updateFromDashboardGraphClick(false);
      updateRecordedStockItemId(-1);
      navigate("/inventory");
    }
    if (selectedMenuItem === DASHBOARD_MENU_ITEM) {
      updateRecordedStockItemId(-1);
      navigate("/dashboard");
    }
    if (selectedMenuItem === ORDERS_MENU_ITEM) {
      navigate("/dispensing-orders", {
        state: { navToggle: navToggle },
      });
    }
    if (selectedMenuItem === REPORTS_MENU_ITEM) {
      navigate("/reports", { state: { navToggle: navToggle } });
    }
    if (selectedMenuItem === SETTINGS_MENU_ITEM) {
      navigate("/settings", { state: { alarm: alarm } });
    }
    if (selectedMenuItem === HELP_MENU_ITEM) {
      navigate("/help", { state: { navToggle: navToggle } });
    }
    if (selectedMenuItem === PROFILE_MENU_ITEM) {
      navigate("/profile");
    }
    if (selectedMenuItem === DEVICES_MENU_ITEM) {
      navigate("/devices");
    }
    if (selectedMenuItem === CHANGE_PASSWORD_MENU_ITEM) {
      navigate("/change-password");
    }
    setMenuItem(selectedMenuItem);
    if (selectedMenuItem === LOGOUT_MENU_ITEM) {
      // release the dashboard websocket for this user
      _controllerRef.current.releaseSocket();

      AuthService.logOut(user);
      onLogOut();
    }
  };

  const { updateFromDashboardGraphClick, updateRecordedStockItemId } =
    useContext(InventoryContext);

  return (
    <div className={styles.HeaderForMobile__container}>
      <DropDown
        user={user}
        onMenuSelection={handleMenuSelection}
        menuSelection={menuItem}
      />

      {/* <div className={styles.HeaderForMobile__smHeaderSearchBarContainer}>
        <SearchBar placeholder="Search" onSearch={handleContextFilterText} />
      </div> */}
      <div
        className={styles.HeaderForMobile__picFrameHeaderContainer}
        onClick={() => handlePopup(true)}
      >
        {profilePic && profilePic !== "default" ? (
          <img
            className={styles.HeaderForMobile__picFrameHeader}
            src={
              profilePic.mimeType
                ? `data:image/${profilePic.mimeType};base64,${profilePic.base64EncodedString}`
                : profilePic.base64EncodedString
            }
            alt={"logo"}
          />
        ) : (
          profilePic === "default" && (
            <div className={styles.picFrameContainer}>
              <PicFrameEmpty className={styles.Devices__picFrameHeader} />
            </div>
          )
        )}
        <UserProfilePopup
          user={user}
          showPopup={showPopup}
          onHandlePopup={handlePopup}
          onLogOut={onLogOut}
        />
      </div>
      {/* <AlarmDateTime /> */}
    </div>
  );
};

export default HeaderForMobile;
