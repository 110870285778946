import React, { useEffect, useState, useRef } from "react";
import PrintLabelsToPDF from "./PrintLabelsToPDF";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import RoleCheckService from "../../services/RoleCheckService";
import StockLocationsController from "../../controllers/StockLocationsController";
import StockLocationService from "../../services/StockLocationService";
import SFDropDownList from "../../components/SFDropDownList/SFDropDownList";
import Checkbox from "../../components/Checkbox";
import TextInput from "../../components/TextInput";
import InputCustomType from "../../components/InputCustomType/InputCustomType";
import styles from "./PrintLabels.module.css";
import { useTranslation } from "react-i18next";

const PrintLabels = ({
  user,
  label,
  onPrevious,
  onRefreshToggle,
}) => {
  const { t } = useTranslation("printLabels");

  const [sites, setSites] = useState([]);
  const [error, setError] = useState("");
  const [canisterRangePositionsError, setCanisterRangePositionsError] =
    useState("");
  const [selectedSite, setSelectedSite] = useState(user?.defaultSite);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedDeviceCanisters, setSelectedDeviceCanisters] = useState([]);
  const [devicesDropdownList, setDevicesDropdownList] = useState([]);
  const [sitesDropdownList, setSitesDropdownList] = useState([]);
  const [printOption, setPrintOption] = useState(1);
  const [printLabelsToPDF, setPrintLabelsToPDF] = useState(false);
  const [selectedSiteChanged, setSelectedSiteChanged] = useState(false);
  const [ignoreEmptyCanisters, setIgnoreEmptyCanisters] = useState(false);
  const [copies, setCopies] = useState(1);
  const [firstDevicePosition, setFirstDevicePosition] = useState("1");
  const [lastDevicePosition, setLastDevicePosition] = useState("1");
  const [listOfDevicePositions, setListOfDevicePositions] = useState("");
  const [
    FirstCanisterDevicePositionInputFocus,
    setFirstCanisterDevicePositionInputFocus,
  ] = useState(true);
  const [
    LastCanisterDevicePositionInputFocus,
    setLastCanisterDevicePositionInputFocus,
  ] = useState(false);

  const _stockLocationController = useRef(null);

  useEffect(() => {
    console.log("Ignore", ignoreEmptyCanisters)
  }, [ignoreEmptyCanisters])

  const deviceTypes = {
    BLISTER_PACKAGER: "TRUCARD",
    POUCH_PACKAGER: "TRUPAK",
    VIAL_FILLER: "TRUSCRIPT",
  };

  useEffect(() => {
    RoleCheckService.getAccessibleSites(user)
      .then((sites) => {
        setSites(
          sites.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (b.name.toUpperCase() < a.name.toUpperCase()) {
              return 1;
            }
            return 0;
          })
        );
      })
      .catch((error) => {
        setError(error);
      });
  }, [user]);

  useEffect(() => {
    if (!devices.length) {
      setError(t("noDeviceError"));
    } else if (!selectedDeviceCanisters.length) {
      setError(t("noCanisterError"));
    } else {
      setError("");
    }
  }, [devices, selectedDeviceCanisters]);

  useEffect(() => {
    // handle initial setting for case if user.defaultSite is not defined or does not exist
    if (!selectedSite) {
      if (user.sites.length) {
        setSelectedSite(sites[0]);
      } else if (sites.length) {
        setSelectedSite(sites[0]);
      }
    }
  }, [user, selectedSite, sites]);

  // Get devices available for the label and user/site
  useEffect(() => {
    if (selectedSite) {
      StockLocationService.getDispensableLocations(user, selectedSite)
        .then((stockLocations) => {
          const filteredDevices = stockLocations.filter(
            (device) =>
              deviceTypes[device.type.type] ===
              label.machineType.toUpperCase()
          );
          setDevices(filteredDevices);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [user, selectedSite]);

  // Get selected device canisters
  useEffect(() => {
    if (user && selectedDevice && selectedSite) {
      _stockLocationController.current = new StockLocationsController(
        user,
        selectedSite
      );

      function isPositiveInteger(n) {
        var floatN = parseFloat(n);
        return !isNaN(floatN) && isFinite(n) && floatN > 0 && floatN % 1 === 0;
      }

      const callback = async (error, stockLocations) => {
        if (!error) {
          const filteredStockLocations = stockLocations.filter(
            (stockLocation) => isPositiveInteger(stockLocation.devicePosition)
          );

          const sortedStockLocations = filteredStockLocations.sort((a, b) => {
            if (parseInt(a.devicePosition) > parseInt(b.devicePosition)) {
              return 1;
            }
            if (parseInt(a.devicePosition) < parseInt(b.devicePosition)) {
              return -1;
            }
            return 0;
          });

          setSelectedDeviceCanisters(sortedStockLocations);
        } else {
          console.log("Error", error);
        }
      };

      // Returns all stock locations (canisters) that are children for a given parent stock location (machine)
      _stockLocationController.current.getStockLocations(
        selectedDevice,
        callback
      );
    }
  }, [selectedDevice, selectedSite, user]);

  // Set lastDevicePosition to the devicePosition of the last canister in selectedDeviceCanisters if printOption is set to 1 (all canisters)
  useEffect(() => {
    if (
      selectedDeviceCanisters &&
      selectedDeviceCanisters.length &&
      printOption === 1
    ) {
      setLastDevicePosition(
        selectedDeviceCanisters[selectedDeviceCanisters.length - 1]
          .devicePosition
      );
    }
  }, [printOption, selectedDeviceCanisters]);

  // Set lastDevicePosition to firstDevicePosition if printOption is set to 3 (a specific canister)
  useEffect(() => {
    if (printOption === 3) {
      setLastDevicePosition(firstDevicePosition);
      setFirstCanisterDevicePositionInputFocus(true);
    }
  }, [printOption, firstDevicePosition]);

  // Error checking for selected canister positions
  useEffect(() => {
    setCanisterRangePositionsError("");
    if (
      // Range
      printOption === 2 &&
      parseInt(firstDevicePosition) > parseInt(lastDevicePosition)
    ) {
      setCanisterRangePositionsError(t("lastPositionError"));
    } else if (
      // Range, A specific canister
      printOption !== 4 &&
      selectedDeviceCanisters?.length &&
      parseInt(firstDevicePosition) <
        parseInt(selectedDeviceCanisters[0].devicePosition)
    ) {
      setCanisterRangePositionsError(
        t("lowestPositionError", {
          position: selectedDeviceCanisters[0].devicePosition,
        })
      );
    } else if (
      // Range, A specific canister
      (selectedDeviceCanisters?.length &&
        parseInt(firstDevicePosition) >
          parseInt(
            selectedDeviceCanisters[selectedDeviceCanisters.length - 1]
              .devicePosition
          )) ||
      (selectedDeviceCanisters?.length &&
        parseInt(lastDevicePosition) >
          parseInt(
            selectedDeviceCanisters[selectedDeviceCanisters.length - 1]
              .devicePosition
          ))
    ) {
      setCanisterRangePositionsError(
        t("highestPositionError", {
          position:
            selectedDeviceCanisters[selectedDeviceCanisters.length - 1]
              .devicePosition,
        })
      );
    } else if (printOption === 4) {
      // Comma separated list
      let highValueNotAllowed = false;
      let lowValueNotAllowed = false;
      const devicePositionsArray = listOfDevicePositions.split(",");

      for (let i = 0; i < devicePositionsArray.length; i++) {
        if (
          parseInt(devicePositionsArray[i]) <
          parseInt(selectedDeviceCanisters[0].devicePosition)
        ) {
          lowValueNotAllowed = true;
          break;
        } else if (
          parseInt(devicePositionsArray[i]) >
          parseInt(
            selectedDeviceCanisters[selectedDeviceCanisters.length - 1]
              .devicePosition
          )
        ) {
          highValueNotAllowed = true;
          break;
        }
      }

      if (lowValueNotAllowed) {
        setCanisterRangePositionsError(
          t("lowestPositionError", {
            position: selectedDeviceCanisters[0].devicePosition,
          })
        );
      } else if (highValueNotAllowed) {
        setCanisterRangePositionsError(
          t("highestPositionError", {
            position:
              selectedDeviceCanisters[selectedDeviceCanisters.length - 1]
                .devicePosition,
          })
        );
      }
    }
  }, [
    firstDevicePosition,
    lastDevicePosition,
    printOption,
    selectedDeviceCanisters,
    listOfDevicePositions,
  ]);

  // Set devicesDropdownList
  useEffect(() => {
    const dropdownList = devices.map((device) => {
      return {
        Item: device.name,
        Value: device.name,
        children: device.children,
      };
    });
    setDevicesDropdownList(dropdownList);
  }, [devices]);

  // intialize selectedDevice
  useEffect(() => {
    if (
      (!selectedDevice && devices && devices?.length) ||
      selectedSiteChanged
    ) {
      setSelectedDevice(devices[0]);
      setSelectedSiteChanged(false);
    }
  }, [selectedDevice, devices]);

  // for SyncFusion deviceSelection dropdown
  const handleSelectedDevice = (selectedDeviceItem, selectedDeviceValue) => {
    setSelectedDevice(getDeviceByName(selectedDeviceValue));
  };

  // Set sitesDropdownList
  useEffect(() => {
    const dropdownList = sites.map((site, index) => {
      return {
        Item: site.name,
        Value: index,
      };
    });
    setSitesDropdownList(dropdownList);
  }, [sites]);

  // for SyncFusion siteSelection dropdown
  const handleSelectedSite = (selectedSiteItem, selectedSiteValue) => {
    setSelectedSite(sites[selectedSiteValue]);
    setSelectedSiteChanged(true);
  };

  const handlePrevious = () => {
    onRefreshToggle();
    onPrevious();
  };

  const resetDevicePositionRange = (newPrintOption) => {
    if (newPrintOption === 1) {
      setFirstDevicePosition("1");
    } else {
      setFirstDevicePosition("1");
      setLastDevicePosition("1");
    }
    setListOfDevicePositions("");
  };

  const handlePrintOption1 = () => {
    if (!error && printOption !== 1) {
      resetDevicePositionRange(1);
      setTimeout(() => setPrintOption(1), 0); // Why?
    }
  };

  const handlePrintOption2 = () => {
    if (!error && printOption !== 2) {
      resetDevicePositionRange(2);
      setFirstCanisterDevicePositionInputFocus(true);
      setTimeout(() => setPrintOption(2), 0);
    }
  };

  const handlePrintOption3 = () => {
    if (!error && printOption !== 3) {
      resetDevicePositionRange(3);
      setFirstCanisterDevicePositionInputFocus(true);
      setTimeout(() => setPrintOption(3), 0);
    }
  };

  const handlePrintOption4 = () => {
    if (!error && printOption !== 4) {
      resetDevicePositionRange(4);
      setPrintOption(4);
    }
  };

  const getDeviceByName = (selectedName) => {
    const foundDevice = devices.find((device) => device.name === selectedName);
    return foundDevice;
  };

  const handleStartDevicePosition = (intValue) => {
    setFirstDevicePosition(intValue);
  };

  const handleEndDevicePosition = (intValue) => {
    setLastDevicePosition(intValue);
  };

  const handleListOfDevicePositions = (intValue) => {
    setListOfDevicePositions(intValue);
    setFirstDevicePosition(intValue[0]);
  };

  const handlePrintLabelsToPDF = (boolVal) => {
    setPrintLabelsToPDF(boolVal);
  };

  const handlePrintLabel = () => {
    handlePrintLabelsToPDF(true);
  };

  const customInputStyles = {
    fontFamily: "Poppins",
    fontSize: "14px",
    textAlign: "right",
    width: "45px",
    height: "24px",
    color: "#121a2d",
    backgroundColor: "#fff",
    border: "2px solid #089bab",
    borderRadius: "4px",
    boxSizing: "border-box",
    paddingRight: "6px",
    outline: "none",
  };

  const stylesForListOfDevicePositions = {
    ...customInputStyles,
    width: "100px",
    textAlign: "left",
    paddingLeft: "6px",
    paddingRight: 0,
  };

  const FirstCanisterDevicePositionInputClickHandler = () => {
    setFirstCanisterDevicePositionInputFocus(true);
  };

  const FirstCanisterDevicePositionInputBlurHandler = () => {
    setFirstCanisterDevicePositionInputFocus(false);
  };

  const FirstCanisterDevicePositionInput = (
    <div className={styles.PrintLabels__inputFieldGroup}>
      <InputCustomType
        userInput={firstDevicePosition}
        onHandleUserInput={handleStartDevicePosition}
        customType="whole number"
        enforceFocus={FirstCanisterDevicePositionInputFocus}
        clickHandler={FirstCanisterDevicePositionInputClickHandler}
        blurHandler={FirstCanisterDevicePositionInputBlurHandler}
        maxValue={999}
        inputFields={{
          type: "text",
          value: firstDevicePosition,
        }}
        styles={customInputStyles}
      />
    </div>
  );

  const LastCanisterDevicePositionInputClickHandler = () => {
    setLastCanisterDevicePositionInputFocus(true);
  };

  const LastCanisterDevicePositionInputBlurHandler = () => {
    setLastCanisterDevicePositionInputFocus(false);
  };

  const LastCanisterDevicePositionInput = (
    <div className={styles.PrintLabels__inputFieldGroup}>
      <InputCustomType
        userInput={lastDevicePosition}
        onHandleUserInput={handleEndDevicePosition}
        customType="whole number"
        maxValue={999}
        enforceFocus={LastCanisterDevicePositionInputFocus}
        clickHandler={LastCanisterDevicePositionInputClickHandler}
        blurHandler={LastCanisterDevicePositionInputBlurHandler}
        inputFields={{
          type: "text",
          value: lastDevicePosition,
        }}
        styles={customInputStyles}
      />
    </div>
  );

  const ListOfCanisterPositionsInput = (
    <div className={styles.PrintLabels__inputFieldGroup}>
      <InputCustomType
        userInput={listOfDevicePositions}
        onHandleUserInput={handleListOfDevicePositions}
        customType="comma-separated-list-of-numbers"
        enforceFocus={true}
        maxValue={999}
        inputFields={{
          type: "text",
          value: listOfDevicePositions,
        }}
        styles={stylesForListOfDevicePositions}
      />
    </div>
  );

  const hasNumbers = (str) => {
    return /\d/.test(str);
  };

  if (!printLabelsToPDF) {
    return (
      <div className={styles.PrintLabels__container}>
        <main>
          <header>
            <h1>{t("printTitle")}</h1>
          </header>
          <div className={styles.PrintLabels__labelNameContainer}>
            <p>{t("labelNameLabel")}</p>
            <p>{label.name}</p>
          </div>
          <div className={styles.PrintLabels__dropdowns}>
            <div className={styles.PrintLabels__dropdown}>
              <SFDropDownList
                id={"siteSelection"}
                label={t("siteLabel")}
                defaultValue={selectedSite ? selectedSite?.name : null}
                placeHolder={t("sitePlaceholder")}
                dropDownList={sitesDropdownList}
                onSelection={handleSelectedSite}
              />
            </div>
            <div className={styles.PrintLabels__dropdown}>
              <SFDropDownList
                id={"deviceSelection"}
                label={t("deviceLabel")}
                defaultValue={
                  devicesDropdownList.length ? devicesDropdownList[0].Item : ""
                }
                placeHolder={t("devicePlaceholder")}
                dropDownList={devicesDropdownList}
                onSelection={handleSelectedDevice}
              />
            </div>
          </div>
          <div className={styles.PrintLabels__checkboxes}>
            <header>
              <h2>{t("canisterSelectionLabel")}</h2>
            </header>
            <div className={styles.PrintLabels__checkbox}>
              <Checkbox
                labelName={t("allCanistersLabel")}
                isOn={!error && printOption === 1}
                onCheck={handlePrintOption1}
              />
            </div>
            <div className={styles.PrintLabels__checkbox}>
              <Checkbox
                labelName={t("rangeLabel")}
                isOn={!error && printOption === 2}
                onCheck={handlePrintOption2}
              />
              {printOption === 2 && (
                <div
                  className={styles.PrintLabels__rangeDevicePositionInputsGroup}
                >
                  {FirstCanisterDevicePositionInput}
                  <span>-</span>
                  {LastCanisterDevicePositionInput}
                </div>
              )}
            </div>
            <div className={styles.PrintLabels__checkbox}>
              <Checkbox
                labelName={t("commaLabel")}
                isOn={!error && printOption === 4}
                onCheck={handlePrintOption4}
              />
              {printOption === 4 && (
                <div
                  className={styles.PrintLabels__rangeDevicePositionInputsGroup}
                >
                  {ListOfCanisterPositionsInput}
                </div>
              )}
            </div>
            <div className={styles.PrintLabels__checkbox}>
              <Checkbox
                labelName={t("specificCanisterLabel")}
                isOn={!error && printOption === 3}
                onCheck={handlePrintOption3}
              />
              {printOption === 3 && (
                <div
                  className={styles.PrintLabels__rangeDevicePositionInputsGroup}
                >
                  {FirstCanisterDevicePositionInput}
                </div>
              )}
            </div>
            
            <header>
              <h2>{t("canisterOptionsLabel")}</h2>
            </header>
            <div className={styles.PrintLabels__checkbox}>
              <Checkbox
                labelName={t("ignoreEmptyCanistersLabel")}
                isOn={!error && ignoreEmptyCanisters}
                onCheck={setIgnoreEmptyCanisters}
              />
            </div>

            <div className={styles.CreateLabel__labelNameContainer}>
              <div className={styles.CreateLabel__inputContainer}>
                <TextInput
                  value={copies}
                  labelName={t("copiesLabel")}
                  focus={true}
                  isEnabled={true}
                  onChange={(val) => {
                    setCopies(Number(val));
                  }}
                  placeholder={""}
                  type={"number"}
                  step={1}
                  min={1}
                />
              </div>
            </div>

            {!error && canisterRangePositionsError && (
              <p className={styles.PrintLabels__error}>
                {canisterRangePositionsError}
              </p>
            )}
            {error && <p className={styles.PrintLabels__error}>{error}</p>}
          </div>
        </main>
        <div className={styles.PrintLabels__buttonsContainer}>
          <Button
            labelName={t("cancelButtonLabel")}
            minWidth={"123px"}
            isPrimary={false}
            onClick={handlePrevious}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName={t("printButtonLabel")}
            isPrimary={true}
            minWidth={"213px"}
            onClick={handlePrintLabel}
            isDisabled={
              error ||
              !selectedDeviceCanisters?.length ||
              canisterRangePositionsError ||
              !selectedSite ||
              !selectedDevice ||
              (printOption !== 4 && !firstDevicePosition) ||
              (printOption === 2 && !lastDevicePosition) ||
              (printOption === 4 &&
              (!listOfDevicePositions || !hasNumbers(listOfDevicePositions))
                ? true
                : false)
            }
          />
        </div>
      </div>
    );
  } else {
    const arrayOfDevicePositions = listOfDevicePositions
      .split(",")
      .map((value) => {
        return value.trim();
      });

    // remove empty values and duplicates
    const filteredArrayOfDevicePositions = arrayOfDevicePositions.filter(
      (value, index) => {
        return (
          value &&
          !isNaN(value) &&
          arrayOfDevicePositions.indexOf(value) === index
        );
      }
    );

    // sort filteredArrayOfDevicePositions in order of increasing value
    const sortedFilteredArrayOfDevicePositions =
      filteredArrayOfDevicePositions.sort((a, b) => {
        if (parseInt(a) < parseInt(b)) {
          return -1;
        }
        if (parseInt(b) < parseInt(a)) {
          return 1;
        }
        return 0;
      });

    return (
      <PrintLabelsToPDF
        user={user}
        label={label}
        selectedDeviceCanisters={selectedDeviceCanisters}
        onPrintLabelsToPDF={handlePrintLabelsToPDF}
        firstDevicePosition={
          printOption === 4
            ? filteredArrayOfDevicePositions[0]
            : firstDevicePosition
        }
        lastDevicePosition={lastDevicePosition}
        arrayOfDevicePositions={sortedFilteredArrayOfDevicePositions}
        ignoreEmptyCanisters={ignoreEmptyCanisters}
        copies={copies}
      />
    );
  }
};

export default PrintLabels;
