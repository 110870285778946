import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import Button from "../../../components/Button";
import ErrorBanner from "../../../components/ErrorBanner";
import InputCustomType from "../../../components/InputCustomType/InputCustomType";
import Spacer from "../../../components/Spacer";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { deepCopy } from "../../../functions/utilities";
import styles from "./ChangePatientLocation.module.css";

const ChangePatientLocation = ({
  pendDisOrderControllerRef,
  selectedItem,
  onCloseChangePatientLocationModal,
  dispensingOrder,
}) => {
  const { t } = useTranslation("pendingDispensingOrder");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const originalLocation =
    selectedItem && selectedItem.patientInfo && selectedItem.patientInfo.room
      ? selectedItem.patientInfo.room
      : "";
  const [location, setLocation] = useState(originalLocation);

  const [userError, setUserError] = useState("");

  const handleLocation = (locationEntry) => {
    setLocation(locationEntry);
  };

  const handleSubmit = () => {
    // Verify correct data format
    if (!location) {
      setUserError(t("missingLocationError"));
    } else {
      let modifiedDispensingOrderItem = deepCopy(selectedItem);
      modifiedDispensingOrderItem.patientInfo.room = location;

      pendDisOrderControllerRef.updatePatientLocation(
        dispensingOrder,
        selectedItem.patientInfo.id,
        location,
      );
      onCloseChangePatientLocationModal(true);
    }
  };

  let packageId;

  try {
    packageId = selectedItem.product.packageId;
  } catch (tryError) {
    packageId = "";
  }


  const goBackHandler = () => {
    onCloseChangePatientLocationModal(false);
  };

  return (
    <div className={styles.ChangePatientLocation__overlay}>
      <div className={styles.ChangePatientLocation__view}>
        <div className={styles.ChangePatientLocation__container}>
          <div className={styles.ChangePatientLocation__formCloseContainer}>
            {!smScreen && (
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => onCloseChangePatientLocationModal(false)}
              />
            )}
          </div>

          {smScreen && (
            <section className={styles.ChangePatientLocation__header}>
              <div
                className={styles.ChangePatientLocation__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.ChangePatientLocation__backArrowIcon}
                />
                <p className={styles.ChangePatientLocation__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <h1 className={styles.ChangePatientLocation__title}>
                {t("changePatientLocationLabel")}
              </h1>
            </section>
          )}

          {!smScreen && (
            <section className={styles.ChangePatientLocation__header}>
              <p className={styles.ChangePatientLocation__title}>
                {t("changePatientLocationLabel")}
              </p>
            </section>
          )}

          {userError && (
            <div className={styles.ChangePatientLocation__errorBannerContainer}>
              <ErrorBanner
                message={userError}
                onClose={() => setUserError("")}
              />
            </div>
          )}

          <section
            className={styles.ChangePatientLocation__productSelectionContainer}
          >
            <table className={styles.ChangePatientLocation__table}>
              <tr>
                <td className={styles.ChangePatientLocation__label}>{`${t("dispensingOrderLabel")}: `}</td>  
                <td className={styles.ChangePatientLocation__value}>{dispensingOrder.dispensingOrderId}</td>
              </tr>
              <tr>
                <td className={styles.ChangePatientLocation__label}>{`${t("patientIdLabel")}: `}</td>  
                <td className={styles.ChangePatientLocation__value}>{selectedItem.patientInfo.id}</td>
              </tr>
              <tr>
                <td className={styles.ChangePatientLocation__label}>{`${t("originalPatientLocationLabel")}: `}</td>
                <td className={styles.ChangePatientLocation__value}>{selectedItem.patientInfo.room}</td>
              </tr>
            </table>       
          </section>

          <section
            className={styles.ChangePatientLocation__quantityInputSection}
          >
            <p className={styles.ChangePatientLocation__current}>
              {`${t("patientLocationInputLabel")}`}
            </p>

            <div
              className={styles.ChangePatientLocation__quantityInputContainer}
            >
              <InputCustomType
                userInput={location}
                onHandleUserInput={handleLocation}
                customType="strip-multiple-spaces"
                enforceFocus={true}
                inputFields={{
                  type: "text",
                  value: location,
                }}
              />
            </div>
          </section>

          <section className={styles.ChangePatientLocation__footer}>
            <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={() => onCloseChangePatientLocationModal(false)}
              minWidth={smScreen ? 122 : null}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              labelName={t("changeLocationButtonLabel")}
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
              minWidth={smScreen ? 122 : 213}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default ChangePatientLocation;
