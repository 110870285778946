import DispensingOrderService from "../services/DispensingOrderService";
import PharmacyService from "../services/PharmacyService";

class DispensingOrderController {
  constructor(user, ignorePackagedItems) {
    console.log("constructing dispensing order controller");
    this.user = user;
    this.ignorePackagedItems = ignorePackagedItems || false;
    this.dispensingOrders = [];
    this.selectedSite = null;
    //this._startInterval();
  }

  // _startInterval(){
  //   this.intervalId = setInterval(async ()=>{
  //     if(this.selectedSite && this.addCallback && this.updateCallback){
  //       console.log('timer firing')
  //       await this.getDispensingOrders(this.selectedSite, this.addCallback, this.updateCallback);
  //     }
  //   }, 30000);
  // }

  // _stopInterval(){
  //   if(this.intervalId){
  //     clearInterval(this.intervalId);
  //     this.intervalId = null;
  //   }
  // }

  dispose = () => {
    console.log("disposing dispensing order controller");
    //this._stopInterval();
  };

  async getSites(sitesCallback) {
    //this._stopInterval();
    const sites = await PharmacyService.list(this.user);
    sitesCallback(sites);
    //this._startInterval();
  }

  async getDispensingOrders(site, addCallback, updateCallback) {
    //this._stopInterval();
    try {
      this.addCallback = addCallback;
      this.updateCallback = updateCallback;
      if (this.selectedSite !== site) {
        this.dispensingOrders = [];
      }
      this.selectedSite = site;
      if (site) {
        const dispensingOrders = await DispensingOrderService.getBySite(
          this.user,
          site,
          this.ignorePackagedItems
        );
        for (let i = 0; i < dispensingOrders.length; i++) {
          this.handleDispensingOrderUpdate(dispensingOrders[i]);
        }

        if (dispensingOrders.length === 0) {
          this.addCallback(null);
        }
      }
    } catch (e) {
      console.log(
        `DispensingOrderController.getDispensingOrders error for site ${site.name}`
      );
      console.log(e);
    }
    //this._startInterval();
  }

  handleDispensingOrderUpdate = (data) => {
    /*
        dispensingOrder
        patients
        dispensingOrderItems
        packagedItems

     */
    if (
      this.dispensingOrders.some(
        (o) => o.dispensingOrder._id === data.dispensingOrder._id
      )
    ) {
      const index = this.dispensingOrders.findIndex(
        (o) => o.dispensingOrder._id === data.dispensingOrder._id
      );
      let current = this.dispensingOrders[index];
      let updateReq = false;
      if (current.dispensingOrder.state !== data.dispensingOrder.state) {
        updateReq = true;
      } else if (
        current.dispensingOrder.dispensingOrderId !==
        data.dispensingOrder.dispensingOrderId
      ) {
        updateReq = true;
      } else if (
        current.dispensingOrder.errorExists !== data.dispensingOrder.errorExists
      ) {
        updateReq = true;
      } else if (
        current.dispensingOrderItems.length !== data.dispensingOrderItems.length
      ) {
        updateReq = true;
      } else if (
        current.dispensingOrderItems.length === data.dispensingOrderItems.length
      ) {
        for (let i = 0; i < current.dispensingOrderItems.length; i++) {
          let c = current.dispensingOrderItems[i];
          let n = data.dispensingOrderItems[i];
          if (c.state !== n.state) {
            updateReq = true;
            break;
          }
        }
      } else if (current.packagedItems.length !== data.packagedItems.length) {
        updateReq = true;
      } else if (current.packagedItems.length === data.packagedItems.length) {
        for (let i = 0; i < current.packagedItems.length; i++) {
          let c = current.packagedItems[i];
          let n = data.packagedItems[i];
          if (c.quantity !== n.quantity) {
            updateReq = true;
            break;
          }
        }
      }

      if (updateReq) {
        this.dispensingOrders[index] = data;
        this.updateCallback(data);
      }
    } else {
      this.dispensingOrders.push(data);
      this.addCallback(data);
    }
  };

  async handleAction(action, dispensingOrder) {
    switch (action) {
      case "remove":
        await this.deleteDispensingOrder(dispensingOrder);

        break;
      default:
        console.log(
          `${action} not handled in DispensingOrderController.handleAction`
        );
    }
  }

  async deleteDispensingOrder(dispensingOrder) {
    //this._stopInterval();
    await DispensingOrderService.delete(
      this.user,
      this.selectedSite,
      dispensingOrder.dispensingOrder
    );
    const index = this.dispensingOrders.findIndex(
      (o) =>
        o.dispensingOrder.dispensingOrderId ===
        dispensingOrder.dispensingOrder.dispensingOrderId
    );
    this.dispensingOrders.splice(index, 1);
    //this._startInterval();
  }

  async resetDispensingOrder(dispensingOrder, state) {
    //this._stopInterval();
    const index = this.dispensingOrders.findIndex(
      (o) =>
        o.dispensingOrder.dispensingOrderId ===
        dispensingOrder.dispensingOrder.dispensingOrderId
    );
    this.dispensingOrders[index].dispensingOrder.state = state;
    this.updateCallback(this.dispensingOrders[index]);
    await DispensingOrderService.resetOrder(
      this.user,
      this.selectedSite,
      dispensingOrder.dispensingOrder,
      state
    );

    //this._startInterval();
  }

  getNextDeliveryTime = (facility) => {
    if (!facility) {
      return "-";
    }
    let deliverySchedule = facility.deliverySchedule;
    if (!deliverySchedule || deliverySchedule.length === 0) {
      return "-";
    } else if (deliverySchedule.length === 1) {
      const s = deliverySchedule[0];
      let dt = new Date();
      dt.setHours(s.hour);
      dt.setMinutes(s.minute);
      const todayDayOfWeek = dt.getDay();
      let daysAhead = s.dayOfWeek - todayDayOfWeek;
      if (daysAhead < 0) {
        daysAhead = 7 + daysAhead;
      }
      dt.setDate(dt.getDate() + daysAhead);
      return this._getFormattedDateTime(dt);
    }

    // multiple scheduled dates
  };

  _getFormattedDateTime = (d) => {
    return Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // setting 24 hour format
    }).format(d);
  };

  routeToDevice = async (dispensingOrder, device) => {
    try {
      //this._stopInterval();
      dispensingOrder.dispensingOrder.state = "routed";
      await DispensingOrderService.routeOrder(
        this.user,
        this.selectedSite,
        device,
        dispensingOrder.dispensingOrder
      );
      this.updateCallback(dispensingOrder);
      //this._startInterval();
    } catch (e) {
      console.log(e);
    }
  };

  routeMultipleToDevice = async (dispensingOrders, device) => {
    try {
      for (let i = 0; i < dispensingOrders.length; i++) {
        dispensingOrders[i].dispensingOrder.state = "routed";
        await this.routeToDevice(dispensingOrders[i], device);
        this.updateCallback(dispensingOrders[i]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  calculateNeeds = async (dispensingOrders, stockLocation, callback) => {
    const needs = await DispensingOrderService.getDispensingNeeds(
      this.user,
      stockLocation,
      dispensingOrders
    );
    callback(needs.demands, needs.stockLocationGroups);
  };

  combineOrders = async (dispensingOrdersToCombine) => {
    for (const item of dispensingOrdersToCombine) {
      let index = this.dispensingOrders.findIndex(
        (o) => o.dispensingOrder.dispensingOrderId === item.dispensingOrderId
      );
      if (index != -1) {
        this.dispensingOrders.splice(index, 1);
      }
    }

    await DispensingOrderService.combineOrders(
      this.user,
      dispensingOrdersToCombine
    );
  };

  getSiteReplenishmentNeeds = async (site, cb) => {
    const needs = await DispensingOrderService.getSiteReplenishmentNeeds(
      this.user,
      site
    );
    cb(needs);
  };

  updatePatientLanguage = async (
    dispensingOrder,
    patientId,
    language
  ) => {
    await DispensingOrderService.updatePatientLanguage(
      this.user,
      dispensingOrder,
      patientId,
      language
    );
  };
}

export default DispensingOrderController;
