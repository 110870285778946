import Konva from "konva";
import { Image as KonvaImage } from "react-konva";
import { pxToMm, mmToPx } from "../functions/converters.js";
import useImage from "use-image";
import { useEffect, useRef } from "react";

const Image = ({
  obj,
  updateObject,
  onDragStart,
  onDragMove,
  onDragEnd,
  onTransformEnd,
  setTransformerReset,
  viewOnly,
  attrs,
}) => {
  const [image] = useImage(
    `data:${obj.mimeType};base64,${obj.base64EncodedString}`
  );

  const imageRef = useRef(null);

  useEffect(() => {
    if (obj && imageRef.current) {
      imageRef.current.x(mmToPx(obj.x));
      imageRef.current.y(mmToPx(obj.y));
    }
  }, [obj]);

  if (viewOnly && obj.ignoreImage) {
    return null;
  }

  const dragProps =
    onDragStart && onDragMove && onDragEnd
      ? {
          onDragStart,
          onDragMove,
          onDragEnd,
        }
      : {};

  const transformProps = onTransformEnd
    ? {
        onTransformEnd,
      }
    : {};

  if (!obj?.width && image?.width && !obj?.height && image?.height) {
    obj.width = pxToMm(image.width);
    obj.height = pxToMm(image.height);
    setTransformerReset(true);
  }

  return (
    <KonvaImage
      ref={imageRef}
      objectType={"image"}
      image={image}
      key={obj.id}
      id={obj.id}
      width={obj?.width ? mmToPx(obj.width) : 0}
      height={obj?.height ? mmToPx(obj.height) : 0}
      rotation={obj.rotation}
      filters={[Konva.Filters.Blur]}
      blurRadius={1}
      imageSmoothingEnabled={true}
      listening={!viewOnly}
      locked={obj.locked}
      visible={obj.visible}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}
      {...dragProps}
      {...transformProps}
      {...(attrs ? attrs : {})}
    />
  );
};

export default Image;
