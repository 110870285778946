import React from "react";
import { Slider } from "antd";

const AntSlider = ({ min, max, defaultValue, onChange }) => {
  const marks = [
    {
      value: min,
      label: min.toString(),
    },
    {
      value: max / 10,
      label: (max / 10).toString(),
    },
    {
      value: max,
      label: max.toString(),
    },
  ];

  return (
    <Slider
      min={min}
      max={max}
      defaultValue={defaultValue}
      onChange={onChange}
      step={min}
      //style={{ 'track': 'var(--green)' }}  // This applies to the filled part of the slider
      //track={{ backgroundColor: 'var(--greenDisabled)' }} 
      //marks={marks}
      //trackBg={"var(--green)"}
      //trackBgDisabled={"var(--greenDisabled)"}
    />
    //</div>
  );
};

export default AntSlider;
