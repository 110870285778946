import React, { useEffect, useState, useRef, Fragment } from "react";
import { DateRangePicker } from "react-date-range";
import RefillReportController from "./RefillReportController";
import ProductController from "../../../../controllers/ProductController";
import Button from "../../../../components/Button";
import AntSpin from "../../../../components/Ant/AntSpin/AntSpin.js";
import WaitIndicator from "../../../../components/WaitIndicator";
import MultiSelectCheckbox from "../../../../components/MultiSelectCheckbox/MultiSelectCheckbox";
import SearchBar from "../../../../components/SearchBar";
import GetSite from "../../../../components/SelectSiteWithDevice/SelectSiteWithDevice";
import SelectDevice from "../../../../components/SelectDevice/SelectDevice";
import { dateSelectionRangeTimeAdjustment } from "../../../../functions/dates";
import { ReactComponent as BackIcon } from "../../../../assets/back_icon.svg";
import { ReactComponent as ExpandIcon } from "../../../../assets/add-icon-small.svg";
import { ReactComponent as CollapseIcon } from "../../../../assets/subtract-circle-icon.svg";
import { ReactComponent as ExcelIcon } from "../../../../assets/excel.svg";
import { ReactComponent as PDFIcon } from "../../../../assets/pdf.svg";
import PDFRefillReportViewer from "./PDFRefillReportViewer";
import ExcelReportExporter from "./ExcelReportExporter";
import styles from "./RefillReport.module.css";
import tableStyles from "./RefillReportTable.module.css";
import { useTranslation } from "react-i18next";
import { getLocalizedDate } from "../../../../functions/localization.js";
import DateRangePickerComponent from "../../../../components/DateRangePickerComponent.js";
// import { batchesData } from "./testData";
// import { needsData } from "./testData";

const RefillReport = ({ user, onHandleSetCurrentAction, onClose }) => {
  const { t } = useTranslation("reportRefill")

  const _refillReportControllerRef = useRef(null);
  const _productControllerRef = useRef(null);
  const [tableRowsExpansionsInitialized, setTableRowsExpansionsInitialized] =
    useState(false);
  const [selectedSite, setSelectedSite] = useState("");
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [batches, setBatches] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [multiSelectValue, setMultiSelectValue] = useState([]);
  const [loadingBatchesData, setLoadingBatchesData] = useState(false);
  const [loadingNeedsData, setLoadingNeedsData] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [PDFReportData, setPDFReportData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectionRange, setSelectionRange] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [readyToUpdateDates, setReadyToUpdateDates] = useState(true);
  const [showDateTimeRangePicker, setShowDateTimeRangePicker] = useState(false);
  const [readyToGetBatches, setReadyToGetBatches] = useState(false);
  const [selectBatchesDataSource, setSelectBatchesDataSource] = useState([]);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [exportToExcel, setExportToExcel] = useState(false);

  _refillReportControllerRef.current = new RefillReportController(user);
  _productControllerRef.current = new ProductController();

  useEffect(() => {
    inititializeDateRange();
  }, []);

  useEffect(() => {
    if (Object.keys(selectionRange).length && readyToUpdateDates) {
      setStartDate(selectionRange.startDate);
      let endDateTime = selectionRange.endDate.getTime(); // milliseconds
      let endDateObj = new Date(endDateTime);
      endDateObj.setHours(23);
      endDateObj.setMinutes(59);
      endDateObj.setSeconds(59);
      setEndDate(endDateObj);
    }
    setReadyToUpdateDates(false);
  }, [selectionRange, readyToUpdateDates]);

  useEffect(() => {
    selectedSite.devices?.length > 0 &&
      setDevices(
        selectedSite.devices
          .filter((device) => {
            return (
              device.type.type === "POUCH_PACKAGER" ||
              device.type.type === "BLISTER_PACKAGER"
            );
          })
          .map((device, index) => {
            return device;
          })
      );
  }, [selectedSite]);

  useEffect(() => {
    if (batches.length) {
      setSelectBatchesDataSource(
        batches.map((batch) => {
          return {
            id: batch.dispensingOrderId,
            batchFriendlyName: `${batch.facility.name} (${batch.dispensingOrderId})`,
          };
        })
      );
    }
  }, [batches]);

  useEffect(() => {
    if (!tableRowsExpansionsInitialized && reportData.length) {
      let tempReportData = [...reportData];

      const compare = (a, b) => {
        const canisterNameA = a.canisterName.toUpperCase();
        const canisterNameB = b.canisterName.toUpperCase();

        let comparison = 0;
        if (canisterNameA > canisterNameB) {
          comparison = 1;
        } else if (canisterNameA < canisterNameB) {
          comparison = -1;
        }
        return comparison;
      };

      tempReportData.sort(compare);

      setReportData(tempReportData);
      setTableRowsExpansionsInitialized(true);
    }
  }, [tableRowsExpansionsInitialized, reportData]);

  useEffect(() => {
    const getBatches = async () => {
      setLoadingBatchesData(true);
      const siteId = selectedSite._id;
      let tempBatches = [];

      const callback = (error, results) => {
        if (!error) {
          results.batches.forEach((batch) => {
            tempBatches.push(batch);
          });
          if (!results.batches.length) {
            setBatches([]);
            setSelectedBatches([]);
            setSelectBatchesDataSource([]);
          }
        } else {
          console.log("error:", error);
        }
      };

      const stockLocationId = selectedDevice._id; // device id (stockLocation id)
      await _refillReportControllerRef.current.getBatches(
        callback,
        user,
        siteId,
        stockLocationId,
        selectedDevice,
        startDate,
        endDate
      );

      setBatches(tempBatches);
      setLoadingBatchesData(false);

      // For testing
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      // setBatches(batchesData());
      // setLoadingBatchesData(false);
    };
    if (selectedSite && endDate && startDate && selectedDevice) {
      getBatches();
    } else {
      setBatches([]);
      setSelectedBatches([]);
    }
  }, [user, selectedSite, startDate, endDate, selectedDevice]);

  useEffect(() => {
    if (!selectedBatches?.length) {
      setReportData([]);
    }
  }, [selectedBatches]);

  const inititializeDateRange = () => {
    let startDateObj = new Date();
    startDateObj.setDate(startDateObj.getDate() - 1);
    startDateObj.setHours(0);
    startDateObj.setMinutes(0);
    startDateObj.setSeconds(0);
    let endDateObj = new Date();
    endDateObj.setDate(endDateObj.getDate() - 1);
    endDateObj.setHours(23);
    endDateObj.setMinutes(59);
    endDateObj.setSeconds(59);
    setSelectionRange({
      startDate: startDateObj,
      endDate: endDateObj,
      key: "selection",
    });
    setStartDate(startDateObj);
    setEndDate(endDateObj);
  };

  const getNeeds = async () => {
    const siteId = selectedSite._id;

    const callback = (error, results) => {
      if (!error) {
        const compare = (a, b) => {
          // Use toUpperCase() to ignore character casing
          const productA = a.productDescription.toUpperCase();
          const productB = b.productDescription.toUpperCase();

          let comparison = 0;
          if (productA > productB) {
            comparison = 1;
          } else if (productA < productB) {
            comparison = -1;
          }
          return comparison;
        };
        setReportData(results.needs.needs.sort(compare));
      } else {
        console.log("error:", error);
      }
    };

    const batches = selectedBatches
      .map((batch) => {
        return batch.dispensingOrderId;
      })
      .toString();

    const stockLocationId = selectedDevice._id; // device id (stockLocation id)
    await _refillReportControllerRef.current.getNeeds(
      callback,
      user,
      siteId,
      stockLocationId,
      selectedDevice,
      startDate,
      endDate,
      batches
    );
    setLoadingNeedsData(false);

    // For testing
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    // setLoadingNeedsData(false);
    // setReportData(needsData());
  };

  const handleSelectedSite = (site) => {
    setSelectedSite(site);
  };

  const handleSelectedDevice = (device) => {
    setSelectedDevice(device);
  };

  const handleGoBackToSelectDevice = () => {
    setReadyToGetBatches(false);
    setSelectedBatches([]);
    setMultiSelectValue([]);
    inititializeDateRange();
  };

  const handleCloseSelectDeviceModal = () => {
    setSelectedSite("");
    setSelectedDevice("");
  };

  const handleSelectedBatches = (arr) => {
    setMultiSelectValue(arr);
    setSelectedBatches(
      batches.filter((batch) => {
        return arr.includes(batch.dispensingOrderId);
      })
    );
  };

  const handleDateRangeSelect = (ranges) => {
    const startDate = new Date(ranges.selection.startDate);
    const endDate = new Date(ranges.selection.endDate);
    setSelectionRange(dateSelectionRangeTimeAdjustment(startDate, endDate));
  };

  const handleDateRangeContinueButtonClicked = () => {
    setShowDateTimeRangePicker(false);
    setReadyToUpdateDates(true);
    setMultiSelectValue([]);
  };

  const handleRunReport = () => {
    setLoadingNeedsData(true);
    getNeeds();
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleExpansionClick = (dataRow) => {
    let tempReportData = [...reportData];
    tempReportData.forEach((tempDataRow) => {
      if (tempDataRow.canisterName === dataRow.canisterName) {
        if (tempDataRow.expanded) {
          tempDataRow.expanded = false;
        } else {
          tempDataRow.expanded = true;
        }
      } else {
        if (tempDataRow.expanded) {
          tempDataRow.expanded = false;
        }
      }
    });
    setReportData(tempReportData);
  };

  const PDFReportHeadings = [
    t("canisterIdCol"),
    t("packageIdCol"),
    t("descriptionCol"),
    t("lastQtyDispensedCol"),
    t("qohCol"),
    t("neededCol"),
  ];

  const PDFReportSubHeadings = [t("lotCol"), t("expDateCol"), t("qtyCol")];

  const reportTitle = t("title", {name: selectedDevice.name});

  const handlePDFReport = () => {
    handleExternalReport(true);
    setShowPDFViewer(true);
  };

  const handleOnBackFromPDFReport = () => {
    setShowPDFViewer(false);
    setFilterText("");
  };

  const handleExportDataToExcel = () => {
    handleExternalReport(false);
    setExportToExcel(true);
  };

  const handleExternalReport = (showStockedItems = false) => {
    let tempArray = [];
    reportData.forEach((reportItem) => {
      if (
        reportItem.need >= 0 &&
        (!filterText ||
          reportItem.productDescription
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          reportItem.packageId.toUppercase().includes(filterText.toUpperCase()))
      ) {
        tempArray.push([
          reportItem.canisterName,
          reportItem.packageId,
          reportItem.productDescription,
          reportItem.qtyDispensed,
          reportItem.qoh,
          reportItem.need,
        ]);
        if (reportItem?.stockedItems && showStockedItems) {
          reportItem.stockedItems.forEach((subItem) => {
            const expDate = new Date(subItem.expiration);
            const localeExpDate = getLocalizedDate(expDate, user?.defaultSite?.shippingAddress?.country);

            tempArray.push([
              subItem.lotNumber,
              localeExpDate,
              subItem.quantity,
            ]);
          });
        }
      }
    });
    setPDFReportData(tempArray);
  };

  const MainHeadings = (
    <div className={tableStyles.refillReportTable__mainHeading}>
      <p
        className={[
          tableStyles["refillReportTable__mainColumnName"],
          tableStyles["refillReportTable__mainColumnName--item1"],
        ].join(" ")}
      >
        {t("canisterIdCol")}
      </p>

      <p
        className={[
          tableStyles["refillReportTable__mainColumnName"],
          tableStyles["refillReportTable__mainColumnName--item2"],
        ].join(" ")}
      >
        {t("packageIdCol")}
      </p>

      <p
        className={[
          tableStyles["refillReportTable__mainColumnName"],
          tableStyles["refillReportTable__mainColumnName--item3"],
        ].join(" ")}
      >
        {t("descriptionCol")}
      </p>

      <p
        className={[
          tableStyles["refillReportTable__mainColumnName"],
          tableStyles["refillReportTable__mainColumnName--item4"],
        ].join(" ")}
      >
        {t("lastQtyDispensedCol")}
      </p>

      <p
        className={[
          tableStyles["refillReportTable__mainColumnName"],
          tableStyles["refillReportTable__mainColumnName--item5"],
        ].join(" ")}
      >
        {t("qohCol")}
      </p>

      <p
        className={[
          tableStyles["refillReportTable__mainColumnName"],
          tableStyles["refillReportTable__mainColumnName--item6"],
        ].join(" ")}
      >
        {t("neededCol")}
      </p>
    </div>
  );

  const SubHeadings = (
    <div className={tableStyles.refillReportTable__subHeadingsContainer}>
      <p className={tableStyles["refillReportTable__subHeading--item1"]}>
        {t("lotCol")}
      </p>
      <p className={tableStyles["refillReportTable__subHeading--item2"]}>
        {t("expDateCol")}
      </p>
      <p className={tableStyles["refillReportTable__subHeading--item3"]}>
        t("qtyCol")
      </p>
    </div>
  );

  const TableData = (
    <div className={tableStyles.refillReportTable__scrollArea}>
      {reportData
        .filter((dataRow) => {
          return (
            parseInt(dataRow["need"]) >= 0 &&
            (!filterText ||
              dataRow["productDescription"]
                .toUpperCase()
                .includes(filterText.toUpperCase()) ||
              dataRow["packageId"]
                .toUpperCase()
                .includes(filterText.toUpperCase()))
          );
        })
        .map((dataRow) => {
          return (
            <section
              key={dataRow.canisterName}
              onClick={
                dataRow.stockedItems.length > 0
                  ? () => handleExpansionClick(dataRow)
                  : () => {}
              }
              className={
                !dataRow.expanded
                  ? tableStyles.refillReportTable__mainContainer
                  : [
                      tableStyles["refillReportTable__mainContainer"],
                      tableStyles["refillReportTable__mainContainer--expanded"],
                    ].join(" ")
              }
            >
              <div
                className={
                  !dataRow.expanded
                    ? tableStyles.refillReportTable__productInfo
                    : [
                        tableStyles["refillReportTable__productInfo"],
                        tableStyles["refillReportTable__productInfo--expanded"],
                      ].join(" ")
                }
              >
                {dataRow.stockedItems && dataRow.stockedItems.length > 0 && (
                  <div
                    className={tableStyles.refillReportTable__boxIconContainer}
                  >
                    {dataRow.expanded ? (
                      <CollapseIcon
                        className={tableStyles.refillReportTable__boxIcon}
                      />
                    ) : (
                      <ExpandIcon
                        className={tableStyles.refillReportTable__boxIcon}
                      />
                    )}
                  </div>
                )}
                <p
                  className={
                    tableStyles["refillReportTable__mainColumnVal--item1"]
                  }
                >
                  {dataRow.canisterName}
                </p>
                <p
                  className={
                    tableStyles["refillReportTable__mainColumnVal--item2"]
                  }
                >
                  {dataRow.packageId}
                </p>
                <p
                  className={
                    tableStyles["refillReportTable__mainColumnVal--item3"]
                  }
                >
                  {dataRow.productDescription}
                </p>
                <p
                  className={
                    tableStyles["refillReportTable__mainColumnVal--item4"]
                  }
                >
                  {dataRow.qtyDispensed}
                </p>

                <p
                  className={
                    tableStyles["refillReportTable__mainColumnVal--item5"]
                  }
                >
                  {dataRow.qoh}
                </p>

                <p
                  className={
                    tableStyles["refillReportTable__mainColumnVal--item6"]
                  }
                >
                  {dataRow.need}
                </p>
              </div>
              {dataRow.expanded && (
                <div className={tableStyles.refillReport__subContainer}>
                  {dataRow.stockedItems &&
                    dataRow.stockedItems.length >= 0 &&
                    SubHeadings}

                  {dataRow.stockedItems.map((subItem, index) => {
                    const expDate = new Date(subItem.expiration);
                    const localeExpDate = getLocalizedDate(expDate, user?.defaultSite?.shippingAddress?.country);

                    return (
                      <div
                        key={subItem._id}
                        className={
                          tableStyles.refillReportTable__stockedItemContainer
                        }
                      >
                        <p
                          className={
                            tableStyles[
                              "refillReportTable__subColumnVal--item1"
                            ]
                          }
                        >
                          {subItem.lotNumber}
                        </p>
                        <p
                          className={
                            tableStyles[
                              "refillReportTable__subColumnVal--item2"
                            ]
                          }
                        >
                          {localeExpDate}
                        </p>
                        <p
                          className={
                            tableStyles[
                              "refillReportTable__subColumnVal--item3"
                            ]
                          }
                        >
                          {subItem.quantity}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </section>
          );
        })}
    </div>
  );

  if (!selectedSite) {
    return (
      <GetSite
        user={user}
        onHandleSetCurrentAction={onHandleSetCurrentAction}
        controllerRefCurrent={_refillReportControllerRef.current}
        onHandleSetSelectedSite={handleSelectedSite}
        onClose={onClose}
        title={t("title")}
      />
    );
  } else if (selectedSite && !readyToGetBatches) {
    return (
      <SelectDevice
        devices={devices}
        onHandleSetSelectedDevice={handleSelectedDevice}
        onHandleCloseSelectDeviceModal={handleCloseSelectDeviceModal}
        buttonTitle={t("getBatchesButtonLabel")}
        onHandleProcessing={() => setReadyToGetBatches(true)}
      />
    );
  } else if (showPDFViewer) {
    return (
      <PDFRefillReportViewer
        user={user}
        title={reportTitle}
        headings={PDFReportHeadings}
        subHeadings={PDFReportSubHeadings}
        data={PDFReportData}
        onBack={handleOnBackFromPDFReport}
        selectionRange={selectionRange}
      />
    );
  } else if (
    exportToExcel &&
    PDFReportHeadings.length &&
    PDFReportData.length
  ) {
    return (
      <ExcelReportExporter
        headings={PDFReportHeadings}
        data={PDFReportData}
        onBack={() => setExportToExcel(false)}
      />
    );
  }

  return (
    <Fragment>
      {showDateTimeRangePicker && (
        <div className={styles.refillReport__overlay}>
          <div className={styles.refillReport__modal}>
            <DateRangePickerComponent
              ranges={[selectionRange]}
              onChange={handleDateRangeSelect}
              rangeColors={["#089BAB"]}
              country={user?.defaultSite?.shippingAddress?.country}
            />
            <br />
            <Button
              labelName={t("continueButtonLabel")}
              isPrimary={true}
              onClick={handleDateRangeContinueButtonClicked}
            />
          </div>
        </div>
      )}
      <div className={styles.refillReport__view}>
        <div className={styles.refillReport__searchBarContainer}>
          <SearchBar
            placeholder={t("searchPlaceholder")}
            onSearch={handleSearch}
          />

          {!loadingBatchesData && !!selectedBatches?.length && (
            <div className={styles.refillReport__runReportButtonContainer}>
              {!!reportData.length && (
                <div className={styles.refillReport__iconContainer}>
                  <PDFIcon
                    className={styles.refillReport__fileIcon}
                    onClick={handlePDFReport}
                  />

                  <ExcelIcon
                    className={styles.refillReport__fileIcon}
                    onClick={handleExportDataToExcel}
                  />
                </div>
              )}
              <div className={styles.refillReport__runReportButtonContainer}>
                <Button
                  labelName={t("runButtonLabel")}
                  minWidth={"123px"}
                  isPrimary={true}
                  onClick={handleRunReport}
                />
              </div>
            </div>
          )}
        </div>

        <div className={styles.refillReport__deviceTitleRow}>
          <div className={styles.refillReport__backIconAndReportTitleGroup}>
            <BackIcon
              className={styles.refillReport__backIcon}
              onClick={handleGoBackToSelectDevice}
            />
            <div className={styles.refillReport__selectedDeviceRow}>
              <p
                className={[
                  styles["refillReport__selectedDeviceRowItem"],
                  styles["refillReport__selectedDeviceRowItem--rightPadded"],
                ].join(" ")}
              >
                {/* {selectedDevice.name} */}
              </p>
              <p className={styles.refillReport__selectedDeviceRowItem}>
                {t("title")}
              </p>
            </div>
          </div>
          <div className={styles.refillReport__dateRangeGroup}>
            <p className={styles.refillReport__dateRangeLabel}>
              {t("datesLabel")}
            </p>
            <p
              className={styles.refillReport__dateRange}
              onClick={() => setShowDateTimeRangePicker(true)}
            >
              {selectionRange.startDate?.toLocaleDateString() || ""}-
              {selectionRange.endDate?.toLocaleDateString() || ""}
            </p>
          </div>

          <div className={styles.refillReport__deviceMultiSelectContainer}>
            {loadingBatchesData && (
              <div className={styles.refillReport__batchesStatus}>
                <AntSpin
                  message={t("loadingBatchesWarning")}
                  containerStyle="container-000"
                  spinnerStyle="ant-spin-000"
                  messageStyle="message-000"
                  spinSize="default"
                />
              </div>
            )}
            {!!batches?.length && !loadingBatchesData && (
              <MultiSelectCheckbox
                checkboxId="checkboxBatches"
                dataSource={selectBatchesDataSource}
                fields={{ value: "id", text: "batchFriendlyName" }}
                placeHolder={
                  multiSelectValue.length
                    ? multiSelectValue
                    : t("selectBatchesPlaceholder")
                }
                value={multiSelectValue}
                onChanged={handleSelectedBatches}
              />
            )}
            {batches && !batches?.length && !loadingBatchesData && (
              <p className={styles.refillReport__noBatchesFound}>
                {t("noBatchesLabel")}
              </p>
            )}
          </div>
        </div>

        <section className={tableStyles.refillReportTable__container}>
          {loadingNeedsData && (
            <WaitIndicator message={t("calculatingNeeds")} />
          )}
          {!loadingNeedsData &&
            !loadingBatchesData &&
            !!selectedBatches?.length &&
            MainHeadings}
          {!loadingNeedsData &&
            !loadingBatchesData &&
            !!selectedBatches?.length &&
            TableData}
        </section>
      </div>
    </Fragment>
  );
};

export default RefillReport;
