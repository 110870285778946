
export const hasAncestorType = (obj, type, checkSelf) => {
  if (checkSelf && obj.type === type) {
    return true;
  } else if (!obj.parent) {
    return false;
  } else if (obj.parent.type === type) {
    return true;
  } else {
    return hasAncestorType(obj.parent, type);
  }
};

export const hasAncestorId = (obj, id, checkSelf) => {
  if (checkSelf && obj.id === id) {
    return true;
  } else if (!obj.parent) {
    return false;
  } else if (obj.parent.id === id) {
    return true;
  } else {
    return hasAncestorId(obj.parent, id);
  }
};

export const unreferencedObjects = (objects) => {
  return objects.map((obj) => ({
    ...obj,
    parent: null,
    ...(obj.objects ? { objects: unreferencedObjects(obj.objects) } : {}),
  }));
};

export const rereferencedObjects = (objects, parent = null) => {
  return objects.map((obj) => rereferencedObject(obj, parent));
}

export const rereferencedObject = (obj, parent = null) => {
  const newObj = {
    ...obj,
    parent: parent,
  };

  if (obj.objects) {
    newObj.objects = rereferencedObjects(obj.objects, newObj);
  }

  return newObj;
}

export const regenerateObjectIds = (obj) => {
  let newIdCounter = Date.now();

  const generateNewId = () => {
    newIdCounter++;
    return newIdCounter.toString();
  };

  const updateObjectIds = (_obj, parent = null) => {
    if (Array.isArray(_obj)) {
      return _obj.map((item) => updateObjectIds(item, parent));
    } else if (typeof obj === "object" && _obj !== null) {
      const newObj = { ..._obj, id: generateNewId() };

      if (parent) {
        newObj.parent = parent;
      } else {
        newObj.parent = null;
      }

      if (newObj.objects) {
        newObj.objects = updateObjectIds(newObj.objects, newObj);
      }

      return newObj;
    }
    return _obj;
  };

  return updateObjectIds(obj)
}


export const cloneObject = (obj) => {
  let newIdCounter = Date.now();

  const generateNewId = () => {
    newIdCounter++;
    return newIdCounter.toString();
  };

  const deepCloneObject = (_obj, parent = null) => {
    if (Array.isArray(_obj)) {
      return _obj.map((item) => deepCloneObject(item));
    } else if (typeof _obj === "object" && _obj !== null) {
      const newObj = { ..._obj, id: generateNewId() };
      if (newObj.parent) {
        newObj.x += newObj.parent.x;
        newObj.y += newObj.parent.y;
        newObj.parent = null;
      }

      if (parent) {
        newObj.parent = parent;
      } else {
        newObj.parent = null;
      }

      if (newObj.objects) {
        newObj.objects = deepCloneObject(newObj.objects, newObj);
      }
      return newObj;
    }
    return _obj;
  };

  return deepCloneObject(obj);
};

export const addLineagePosition = (obj, parent) => {
  obj.x += parent.x;
  obj.y += parent.y;

  if (parent.parent) {
    addLineagePosition(obj, parent.parent)
  }
}


export const removeLineagePosition = (obj, parent) => {
  obj.x -= parent.x;
  obj.y -= parent.y;

  if (parent.parent) {
    removeLineagePosition(obj, parent.parent)
  }
  
}