import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";
import Input from "./Input";
import Checkbox from "../../../components/Checkbox";

const GroupProperties = ({ unit, selectedObject, updateObject }) => {
  const { t } = useTranslation("labelEditor");

  const handleInputChange = (value, name) => {
    const newGroup = {
      ...selectedObject,
      [name]: value,
    }
    newGroup.objects = selectedObject.objects.map(o => ({
      ...o,
      parent: newGroup,
    }))

    updateObject(newGroup)
  };

  const handleShowOutline = () => {
    updateObject({
      ...selectedObject,
      showOutline: !selectedObject.showOutline,
    });
  };

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("widthLabel")}
          </label>
          <Input
            value={selectedObject.width}
            name="width"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            step={0.1}
            min={0.1}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("heightLabel")}
          </label>
          <Input
            value={selectedObject.height}
            name="height"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            step={0.1}
            min={0.1}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X</label>
          <Input
            value={selectedObject.x}
            name="x"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            step={0.1}
            disabled={selectedObject.locked || selectedObject?.parent?.type === "gridLayout"}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y</label>
          <Input
            value={selectedObject.y}
            name="y"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            step={0.1}
            disabled={selectedObject.locked || selectedObject?.parent?.type === "gridLayout"}
          />
        </div>
      </div>

      <div className={styles.Properties__propertyFull}>
        <div className={styles.Properties__toggle}>
          <label className={styles.Properties__label}>{t("groupOutlineLabel")}</label>
          <Checkbox
            labelName={t("showLabel")}
            labelColor={"var(--black)"}
            isOn={selectedObject.showOutline}
            onCheck={handleShowOutline}
            disabled={selectedObject.locked}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupProperties;
