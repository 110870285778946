import styles from "./Properties.module.css";
import { mmToUnit, unitToMm } from "../functions/converters";
import { useMemo } from "react";

const Input = ({ value, name, type, unit, onChange, disabled, min, step, ...props }) => {
  const convertedValue = useMemo(() => (
    unit 
      ? mmToUnit(value, unit) 
      : value
  ), [unit, value])

  const handleOnChange = (e) => {
    let { name, value, type, dataset } = e.target;

    value = type === "text" 
      ? e.target.value 
      : Number(e.target.value);

    value = unit 
      ? unitToMm(value, unit) 
      : value

    onChange(value, name, dataset)
  }


  return (
    <input      
      value={convertedValue}
      name={name}
      type={type}
      onChange={handleOnChange}
      className={`${styles.Properties__input} ${
        disabled ? styles.disabled : ""
      }`}
      min={min}
      step={step}
      disabled={disabled}
      {...props}
    />
)
}

export default Input;