import React, { useState, useEffect, useRef, useCallback } from "react";
import TruPakDashboardController from "../../../TruPakDashboard/TruPakDashboardController";
import DashboardService from "../../../../../services/DashboardService";
import DailyTotalBarChart from "../../graphs/DailyTotalBarChart";
import { ReactComponent as ArrowUpCircle } from "../../../../../assets/arrow-up-circle.svg";
import { ReactComponent as ArrowDownCircle } from "../../../../../assets/arrow-down-circle.svg";
import { getLocalizedDate } from "../../../../../functions/localization";
import styles from "./MetricsChartView.module.css";
const MetricsChartView = ({
  user,
  activeKey,
  selectedDayTray,
  onSelectedDayTray,
  selectedDayCanister,
  onSelectedDayCanister,
  selectedDevices,
  selectedSites,
  startDateLastWeek,
  stopDateLastWeek,
  startDateCurrentWeek,
  stopDateCurrentWeek,
  onTrayProducts,
  onTrayProductsSelectedDay,
  onCanisterProducts,
  onCanisterProductsSelectedDay,
  rowStatus,
  onRowStatus,
  onShowDropdowns,
}) => {
  const [totalDispensedCanisterLastWeek, setTotalDispensedCanisterLastWeek] =
    useState({
      U: 0,
      M: 0,
      T: 0,
      W: 0,
      R: 0,
      F: 0,
      S: 0,
      total: 0,
    });
  const [
    totalWholePillDispensedTrayLastWeek,
    setTotalWholePillDispensedTrayLastWeek,
  ] = useState({
    U: 0,
    M: 0,
    T: 0,
    W: 0,
    R: 0,
    F: 0,
    S: 0,
    total: 0,
  });
  const [batchesCurrentWeek, setBatchesCurrentWeek] = useState([]);
  const [batchesLastWeek, setBatchesLastWeek] = useState([]);
  const [canisterBargraphData, setCanisterBargraphData] = useState([]);
  const [trayBargraphData, setTrayBargraphData] = useState([]);
  const [productTrayCountLookup, setProductTrayCountLookup] = useState({});
  const [palette] = useState([
    "#218678",
    "#218678",
    "#218678",
    "#218678",
    "#218678",
    "#218678",
    "#218678",
  ]);

  const [classNameForBargraph, setClassNameforBargraph] = useState(null);

  const _controllerRef = useRef(TruPakDashboardController);
  const _dashboardServiceRef = useRef(DashboardService);

  const [
    totalDispensedCanisterCurrentWeek,
    setTotalDispensedCanisterCurrentWeek,
  ] = useState(0);
  const [
    totalWholePillDispensedTrayCurrentWeek,
    setTotalWholePillDispensedTrayCurrentWeek,
  ] = useState(0);

  /*******************************************************************************************************/
  useEffect(() => {
    if (!selectedDayTray) {
      let canisterPackageIds = [];
      let trayPackageIds = [];
      let dispensingOrderIds = [];
      let canisterProducts_temp = [];
      let trayProducts_temp = [];
      let totalCanisterQuantity = 0;
      let totalWholePillQuantity = 0;
      let tempCanisterQtySunday = 0;
      let tempCanisterQtyMonday = 0;
      let tempCanisterQtyTuesday = 0;
      let tempCanisterQtyWednesday = 0;
      let tempCanisterQtyThursday = 0;
      let tempCanisterQtyFriday = 0;
      let tempCanisterQtySaturday = 0;
      let tempWholePillQtySunday = 0;
      let tempWholePillQtyMonday = 0;
      let tempWholePillQtyTuesday = 0;
      let tempWholePillQtyWednesday = 0;
      let tempWholePillQtyThursday = 0;
      let tempWholePillQtyFriday = 0;
      let tempWholePillQtySaturday = 0;
      let dayOfWeek;
      let tempRowStatus = {};

      const incrementQuantityForDayOfWeek = (type, day, quantity) => {
        switch (day) {
          case 0:
            if (type === "tray") {
              tempWholePillQtySunday += quantity;
            } else if (type === "canister") {
              tempCanisterQtySunday += quantity;
            }
            break;
          case 1:
            if (type === "tray") {
              tempWholePillQtyMonday += quantity;
            } else if (type === "canister") {
              tempCanisterQtyMonday += quantity;
            }
            break;
          case 2:
            if (type === "tray") {
              tempWholePillQtyTuesday += quantity;
            } else if (type === "canister") {
              tempCanisterQtyTuesday += quantity;
            }
            break;
          case 3:
            if (type === "tray") {
              tempWholePillQtyWednesday += quantity;
            } else if (type === "canister") {
              tempCanisterQtyWednesday += quantity;
            }
            break;
          case 4:
            if (type === "tray") {
              tempWholePillQtyThursday += quantity;
            } else if (type === "canister") {
              tempCanisterQtyThursday += quantity;
            }
            break;
          case 5:
            if (type === "tray") {
              tempWholePillQtyFriday += quantity;
            } else if (type === "canister") {
              tempCanisterQtyFriday += quantity;
            }
            break;
          case 6:
            if (type === "tray") {
              tempWholePillQtySaturday += quantity;
            } else if (type === "canister") {
              tempCanisterQtySaturday += quantity;
            }
            break;
          default:
        }
      };

      for (const batch of batchesLastWeek) {
        dayOfWeek = new Date(batch.createdAt).getDay(); // gets day of corresponding local time

        if (!dispensingOrderIds.includes(batch.dispensingOrderId)) {
          dispensingOrderIds.push(batch.dispensingOrderId);
        }

        for (const dispensingDetail of batch.dispensingDetails) {
          if (
            !canisterPackageIds.includes(dispensingDetail.packageId) &&
            !dispensingDetail.isTrayMed
          ) {
            // canister only
            canisterPackageIds.push(dispensingDetail.packageId);
            canisterProducts_temp.push({
              packageId: dispensingDetail.packageId,
              productName: dispensingDetail.productDescription,
              qty: dispensingDetail.quantity,
            });
            totalCanisterQuantity += dispensingDetail.quantity;
            incrementQuantityForDayOfWeek(
              "canister",
              dayOfWeek,
              dispensingDetail.quantity
            );
          } else if (
            dispensingDetail.quantity > 0 &&
            !dispensingDetail.isTrayMed
          ) {
            // canister only
            if (
              canisterProducts_temp.some(
                (p) => p.packageId === dispensingDetail.packageId
              )
            ) {
              const product = canisterProducts_temp.find(
                (p) => p.packageId === dispensingDetail.packageId
              );
              product.qty += dispensingDetail.quantity;
              totalCanisterQuantity += dispensingDetail.quantity;
              incrementQuantityForDayOfWeek(
                "canister",
                dayOfWeek,
                dispensingDetail.quantity
              );
            }
          } else if (
            dispensingDetail.isTrayMed &&
            !dispensingDetail.isPartial
          ) {
            // tray only, no partials
            if (!trayPackageIds.includes(dispensingDetail.packageId)) {
              trayPackageIds.push(dispensingDetail.packageId);
              trayProducts_temp.push({
                packageId: dispensingDetail.packageId,
                productName: dispensingDetail.productDescription,
                qty: dispensingDetail.quantity,
                trays: 0,
              });
              tempRowStatus[dispensingDetail.packageId] = {
                selected: false,
                product: dispensingDetail.productDescription,
                manufacturer: dispensingDetail.productManufacturer,
              };
              totalWholePillQuantity += dispensingDetail.quantity;
              incrementQuantityForDayOfWeek(
                "tray",
                dayOfWeek,
                dispensingDetail.quantity
              );
            } else {
              if (
                trayProducts_temp.some(
                  (p) => p.packageId === dispensingDetail.packageId
                )
              ) {
                const product = trayProducts_temp.find(
                  (p) => p.packageId === dispensingDetail.packageId
                );
                product.qty += dispensingDetail.quantity;
                totalWholePillQuantity += dispensingDetail.quantity;
                incrementQuantityForDayOfWeek(
                  "tray",
                  dayOfWeek,
                  dispensingDetail.quantity
                );
              }
            }
          }
        } // end for batch of
      }

      setTotalDispensedCanisterLastWeek({
        total: totalCanisterQuantity,
        U: tempCanisterQtySunday,
        M: tempCanisterQtyMonday,
        T: tempCanisterQtyTuesday,
        W: tempCanisterQtyWednesday,
        R: tempCanisterQtyThursday,
        F: tempCanisterQtyFriday,
        S: tempCanisterQtySaturday,
      });
      setTotalWholePillDispensedTrayLastWeek({
        total: totalWholePillQuantity,
        U: tempWholePillQtySunday,
        M: tempWholePillQtyMonday,
        T: tempWholePillQtyTuesday,
        W: tempWholePillQtyWednesday,
        R: tempWholePillQtyThursday,
        F: tempWholePillQtyFriday,
        S: tempWholePillQtySaturday,
      });

      setCanisterBargraphData([
        { x: "U", y: tempCanisterQtySunday > 0 ? tempCanisterQtySunday : 0 },
        { x: "M", y: tempCanisterQtyMonday > 0 ? tempCanisterQtyMonday : 0 },
        { x: "T", y: tempCanisterQtyTuesday > 0 ? tempCanisterQtyTuesday : 0 },
        {
          x: "W",
          y: tempCanisterQtyWednesday > 0 ? tempCanisterQtyWednesday : 0,
        },
        {
          x: "R",
          y: tempCanisterQtyThursday > 0 ? tempCanisterQtyThursday : 0,
        },
        { x: "F", y: tempCanisterQtyFriday > 0 ? tempCanisterQtyFriday : 0 },
        {
          x: "S",
          y: tempCanisterQtySaturday > 0 ? tempCanisterQtySaturday : 0,
        },
      ]);

      setTrayBargraphData([
        { x: "U", y: tempWholePillQtySunday > 0 ? tempWholePillQtySunday : 0 },
        { x: "M", y: tempWholePillQtyMonday > 0 ? tempWholePillQtyMonday : 0 },
        {
          x: "T",
          y: tempWholePillQtyTuesday > 0 ? tempWholePillQtyTuesday : 0,
        },
        {
          x: "W",
          y: tempWholePillQtyWednesday > 0 ? tempWholePillQtyWednesday : 0,
        },
        {
          x: "R",
          y: tempWholePillQtyThursday > 0 ? tempWholePillQtyThursday : 0,
        },
        { x: "F", y: tempWholePillQtyFriday > 0 ? tempWholePillQtyFriday : 0 },
        {
          x: "S",
          y: tempWholePillQtySaturday > 0 ? tempWholePillQtySaturday : 0,
        },
      ]);

      canisterProducts_temp.sort(compareByQuantityDescending);
      trayProducts_temp.sort(compareByQuantityDescending);

      // Restrict canisterProducts to top 25 by quantity dispensed
      const canisterProductsTempFiltered = canisterProducts_temp.filter(
        (product, index) => {
          if (index < 25) {
            return true;
          } else {
            return false;
          }
        }
      );

      // Restrict trayProducts to top 25 by quantity filled
      const trayProductsTempFiltered = trayProducts_temp.filter(
        (product, index) => {
          if (index < 25) {
            return true;
          } else {
            return false;
          }
        }
      );

      // convert packageIds array to string list
      let tempPackageIdsList = "";

      trayPackageIds.forEach((packageId) => {
        tempPackageIdsList += `${packageId},`;
      });

      tempPackageIdsList = tempPackageIdsList.substring(
        0,
        tempPackageIdsList.length - 1
      );

      const getTrayProductCounts = async (
        utcStartDate,
        utcStopDate,
        packageIds,
        dispensingOrderIds
      ) => {
        let tempProductTrayCountLookup = {};
        for (let device of selectedDevices) {
          for (let site of selectedSites) {
            await _dashboardServiceRef.current
              .getTruPakTrayProductCounts(
                user,
                site,
                device,
                utcStartDate,
                utcStopDate,
                packageIds,
                dispensingOrderIds
              )
              .then((results) => {
                for (const result of results.results) {
                  if (!(result.packageId in tempProductTrayCountLookup)) {
                    tempProductTrayCountLookup[result.packageId] =
                      result.trayCount;
                  } else {
                    tempProductTrayCountLookup[result.packageId] +=
                      result.trayCount;
                  }
                }
                for (const tempTrayProduct of trayProductsTempFiltered) {
                  if (
                    tempProductTrayCountLookup[tempTrayProduct.packageId] &&
                    !selectedDayTray
                  ) {
                    tempTrayProduct.trays =
                      tempProductTrayCountLookup[tempTrayProduct.packageId];
                  } else if (!selectedDayTray) {
                    tempTrayProduct.trays = 0;
                  }
                }
                setProductTrayCountLookup(tempProductTrayCountLookup); // not needed
                onTrayProducts(trayProductsTempFiltered);
                onCanisterProducts(canisterProductsTempFiltered);
                onRowStatus(tempRowStatus);
              });
          }
        }
      };

      let tempDispensingOrderIdsList = "";

      dispensingOrderIds.forEach((dispensingOrderId) => {
        tempDispensingOrderIdsList += `${dispensingOrderId},`;
      });

      tempDispensingOrderIdsList = tempDispensingOrderIdsList.substring(
        0,
        tempDispensingOrderIdsList.length - 1
      );

      if (
        startDateLastWeek &&
        stopDateLastWeek &&
        tempPackageIdsList?.length &&
        tempDispensingOrderIdsList?.length
      ) {
        getTrayProductCounts(
          startDateLastWeek,
          stopDateLastWeek,
          tempPackageIdsList,
          tempDispensingOrderIdsList
        );
      }
    }
  }, [
    user,
    batchesLastWeek,
    startDateLastWeek,
    stopDateLastWeek,
    selectedDevices,
    selectedSites,
    onTrayProducts,
    onCanisterProducts,
    onRowStatus,
    selectedDayTray,
  ]);

  /*******************************************************************************************************/

  useEffect(() => {
    let totalCanisterQuantity = 0;
    let totalWholePillQuantity = 0;

    for (const batch of batchesCurrentWeek) {
      for (const dispensingDetail of batch.dispensingDetails) {
        // canister
        if (!dispensingDetail.isTrayMed) {
          totalCanisterQuantity += dispensingDetail.quantity;
        } else if (!dispensingDetail.isPartial) {
          totalWholePillQuantity += dispensingDetail.quantity;
        }
      }
    }
    setTotalDispensedCanisterCurrentWeek(totalCanisterQuantity);
    setTotalWholePillDispensedTrayCurrentWeek(totalWholePillQuantity);
  }, [batchesCurrentWeek]);

  const updateBatches = useCallback(
    async (utcStartDate, utcStopDate, week) => {
      let batchesForAllSites = [];
      const updateBatchesForAllSites = (newBatchesToAdd) => {
        batchesForAllSites = [...batchesForAllSites, ...newBatchesToAdd];
      };
      for (const device of selectedDevices) {
        for (const site of selectedSites) {
          await _controllerRef.current
            .getTruPakCanisterUtilization(
              user,
              site,
              device,
              utcStartDate,
              utcStopDate,
              true // include detail
            )
            .then((DTO) => {
              updateBatchesForAllSites(DTO.results.batches);
            });
        }
      }
      if (week === "current") {
        setBatchesCurrentWeek(batchesForAllSites);
      } else if (week === "last") {
        setBatchesLastWeek(batchesForAllSites);
      }
    },
    [selectedDevices, selectedSites, user]
  );

  useEffect(() => {
    if (
      startDateCurrentWeek &&
      stopDateCurrentWeek &&
      selectedDevices.length &&
      selectedSites.length
    ) {
      const utcStartDate = startDateCurrentWeek.toUTCString(); // convert local time to UTC
      const utcStopDate = stopDateCurrentWeek.toUTCString(); // convert locatl time to UTC
      updateBatches(utcStartDate, utcStopDate, "current");
    }
  }, [
    startDateCurrentWeek,
    stopDateCurrentWeek,
    selectedDevices,
    selectedSites,
    updateBatches,
  ]);

  useEffect(() => {
    if (
      startDateLastWeek &&
      stopDateLastWeek &&
      selectedDevices.length &&
      selectedSites.length
    ) {
      const utcStartDate = startDateLastWeek.toUTCString(); // convert local time to UTC
      const utcStopDate = stopDateLastWeek.toUTCString(); // convert locatl time to UTC
      updateBatches(utcStartDate, utcStopDate, "last");
    }
  }, [
    startDateLastWeek,
    stopDateLastWeek,
    selectedDevices,
    selectedSites,
    updateBatches,
  ]);

  const dailyAverageCanister = (
    totalDispensedCanisterLastWeek.total / 7
  ).toFixed(1);

  const dailyAverageTray = (
    totalWholePillDispensedTrayLastWeek.total / 7
  ).toFixed(1);

  let percentIncreaseFromLastWeek;

  if (activeKey === "tray") {
    if (totalWholePillDispensedTrayLastWeek.total > 0) {
      percentIncreaseFromLastWeek =
        ((totalWholePillDispensedTrayCurrentWeek -
          totalWholePillDispensedTrayLastWeek.total) /
          totalWholePillDispensedTrayLastWeek.total) *
        100;
    } else {
      percentIncreaseFromLastWeek = NaN;
    }
  } else {
    if (totalDispensedCanisterLastWeek.total > 0) {
      percentIncreaseFromLastWeek =
        ((totalDispensedCanisterCurrentWeek -
          totalDispensedCanisterLastWeek.total) /
          totalDispensedCanisterLastWeek.total) *
        100;
    } else {
      percentIncreaseFromLastWeek = NaN;
    }
  }

  let percentMessage = `${parseFloat(percentIncreaseFromLastWeek).toFixed(
    1
  )}% increase from last week`;
  let IconIndicator = <ArrowUpCircle width="32px" height="32px" />;

  if (isNaN(percentIncreaseFromLastWeek)) {
    percentMessage = "No fills last week";
  } else if (percentIncreaseFromLastWeek < 0) {
    IconIndicator = <ArrowDownCircle width="32px" height="32px" />;
    percentMessage = `${(-parseFloat(percentIncreaseFromLastWeek)).toFixed(
      1
    )}% decrease from last week`;
  }

  const dailyAverage =
    activeKey === "tray" ? dailyAverageTray : dailyAverageCanister;

  /*-------------------------------------------------------------------------------------*/
  const handleTrayProductsSelectedDay = (day) => {
    let trayPackageIds = [];
    let trayProducts_temp = [];
    let dayOfWeek;
    let tempRowStatus = {};

    let batches = batchesLastWeek.filter((batchLastWeek) => {
      dayOfWeek = new Date(batchLastWeek.createdAt).getDay();
      return dayOfWeek === day || (dayOfWeek === 0 && day === 0);
    });

    let dispensingOrderIds = [];

    for (const batch of batches) {
      if (!dispensingOrderIds.includes(batch.dispensingOrderId)) {
        dispensingOrderIds.push(batch.dispensingOrderId);
      }

      for (const dispensingDetail of batch.dispensingDetails) {
        let packageIdInLookup = false;
        let lookupPackageId = dispensingDetail?.packageId;
        if (lookupPackageId in productTrayCountLookup) {
          packageIdInLookup = true;
        }
        if (
          !trayPackageIds.includes(dispensingDetail.packageId) &&
          dispensingDetail.isTrayMed &&
          !dispensingDetail.isPartial
        ) {
          trayPackageIds.push(dispensingDetail.packageId);
          trayProducts_temp.push({
            packageId: dispensingDetail.packageId,
            productName: dispensingDetail.productDescription,
            qty: dispensingDetail.quantity,
            trays: packageIdInLookup
              ? // ? productTrayCountLookup[dispensingDetail.packageId]
                // : 0,
                0
              : 0,
          });
          tempRowStatus[dispensingDetail.packageId] = "not selected";
        } else if (dispensingDetail.isTrayMed && !dispensingDetail.isPartial) {
          if (
            trayProducts_temp.some(
              (p) => p.packageId === dispensingDetail.packageId
            )
          ) {
            const product = trayProducts_temp.find(
              (p) => p.packageId === dispensingDetail.packageId
            );
            product.qty += dispensingDetail.quantity;
          }
        }
      }
    }

    trayProducts_temp.sort(compareByQuantityDescending);

    // Restrict trayProducts to top 25 by quantity filled
    let trayProductsTempFiltered = trayProducts_temp.filter(
      (product, index) => {
        if (index < 25) {
          return true;
        } else {
          return false;
        }
      }
    );

    if (batches.length) {
      // convert packageIds array to string list
      let trayPackageIdsList = "";

      trayPackageIds.forEach((packageId) => {
        trayPackageIdsList += `${packageId},`;
      });

      trayPackageIdsList = trayPackageIdsList.substring(
        0,
        trayPackageIdsList.length - 1
      );

      // convert dispensingOrderIds array to string list
      let trayDispensingOrderIdsList = "";

      dispensingOrderIds.forEach((dispensingOrderId) => {
        trayDispensingOrderIdsList += `${dispensingOrderId},`;
      });

      trayDispensingOrderIdsList = trayDispensingOrderIdsList.substring(
        0,
        trayDispensingOrderIdsList.length - 1
      );

      let startDateSelectedDay = new Date(batches[0].createdAt);
      startDateSelectedDay.setHours(0);
      startDateSelectedDay.setMinutes(0);
      startDateSelectedDay.setSeconds(0);

      let stopDateSelectedDay = new Date(batches[0].createdAt);
      stopDateSelectedDay.setHours(23);
      stopDateSelectedDay.setMinutes(59);
      stopDateSelectedDay.setSeconds(59);

      const getTrayProductCounts = async (
        utcStartDate,
        utcStopDate,
        packageIds,
        dispensingOrderIds
      ) => {
        let tempProductTrayCountLookup = {};
        for (let device of selectedDevices) {
          for (let site of selectedSites) {
            await _dashboardServiceRef.current
              .getTruPakTrayProductCounts(
                user,
                site,
                device,
                utcStartDate,
                utcStopDate,
                packageIds,
                dispensingOrderIds
              )
              .then((results) => {
                for (const result of results.results) {
                  if (!(result.packageId in tempProductTrayCountLookup)) {
                    tempProductTrayCountLookup[result.packageId] =
                      result.trayCount;
                  } else {
                    tempProductTrayCountLookup[result.packageId] +=
                      result.trayCount;
                  }
                }
                for (const tempTrayProduct of trayProductsTempFiltered) {
                  if (tempProductTrayCountLookup[tempTrayProduct.packageId]) {
                    tempTrayProduct.trays =
                      tempProductTrayCountLookup[tempTrayProduct.packageId];
                  } else {
                    tempTrayProduct.trays = 0;
                  }
                }
                onTrayProductsSelectedDay(trayProductsTempFiltered);
                setProductTrayCountLookup(tempProductTrayCountLookup); // not needed
              });
          }
        }
      };
      getTrayProductCounts(
        startDateSelectedDay,
        stopDateSelectedDay,
        trayPackageIdsList,
        trayDispensingOrderIdsList
      );
    } else {
      onTrayProductsSelectedDay(trayProductsTempFiltered);
    }
  };
  /*-------------------------------------------------------------------------------------*/

  const handleCanisterProductsSelectedDay = (day) => {
    let canisterPackageIds = [];
    let canisterProducts_temp = [];
    let dayOfWeek;
    let tempRowStatus = {};

    for (const batch of batchesLastWeek) {
      dayOfWeek = new Date(batch.createdAt).getDay(); // gets day of corresponding local time
      for (const dispensingDetail of batch.dispensingDetails) {
        if (
          day === dayOfWeek &&
          !canisterPackageIds.includes(dispensingDetail.packageId) &&
          !dispensingDetail.isTrayMed
        ) {
          // canister only
          canisterPackageIds.push(dispensingDetail.packageId);
          canisterProducts_temp.push({
            packageId: dispensingDetail.packageId,
            productName: dispensingDetail.productDescription,
            qty: dispensingDetail.quantity,
          });
          tempRowStatus[dispensingDetail.packageId] = "not selected";
        } else if (
          dispensingDetail.quantity > 0 &&
          !dispensingDetail.isTrayMed
        ) {
          // tray only, no partials
          if (
            canisterProducts_temp.some(
              (p) => p.packageId === dispensingDetail.packageId
            )
          ) {
            const product = canisterProducts_temp.find(
              (p) => p.packageId === dispensingDetail.packageId
            );
            product.qty += dispensingDetail.quantity;
            // product.trays += 1;
          }
        }
      }
    }

    canisterProducts_temp.sort(compareByQuantityDescending);

    // Restrict canisterProducts to top 25 by quantity filled
    const canisterProductsTempFiltered = canisterProducts_temp.filter(
      (product, index) => {
        if (index < 25) {
          return true;
        } else {
          return false;
        }
      }
    );

    onCanisterProductsSelectedDay(canisterProductsTempFiltered);
  };

  const compareByQuantityDescending = (a, b) => {
    if (a.qty < b.qty) {
      return 1;
    } else if (a.qty > b.qty) {
      return -1;
    } else if (a.productName.toUpperCase() < b.productName.toUpperCase()) {
      return -1;
    }
    return 1;
  };

  const handleClassNameForBargraph = (name) => {
    setClassNameforBargraph(name);
  };

  let startDateLocalized = getLocalizedDate(
    startDateLastWeek,
    user?.defaultSite?.shippingAddress?.country
  );

  let stopDateLocalized = getLocalizedDate(
    stopDateLastWeek,
    user?.defaultSite?.shippingAddress?.country
  );

  const dateRangeForTitle = `${startDateLocalized} - ${stopDateLocalized}`;

  let tableTitle;

  if (activeKey === "tray") {
    tableTitle = (
      <div className={styles.MetricChartView__bargraphTitle}>
        <h2 className={styles.MetricChartView__bargraphTitleMain}>
          Daily Tray Total
        </h2>
        <h3 className={styles.MetricChartView__bargraphTitleSub}>
          {dateRangeForTitle}
        </h3>
      </div>
    );
  } else {
    tableTitle = (
      <div className={styles.MetricChartView__bargraphTitle}>
        <h2 className={styles.MetricChartView__bargraphTitleMain}>
          Daily Canister Usage
        </h2>
        <h3 className={styles.MetricChartView__bargraphTitleSub}>
          {dateRangeForTitle}
        </h3>
      </div>
    );
  }

  return (
    <>
      <div className={styles.MetricChartView__tableContainer}>
        <div className={styles.MetricChartView__bargraphHeadingContainer}>
          <div className={styles.MetricChartView__dailyAverageContainer}>
            <h3>Daily Average</h3>
            <p className={styles.MetricChartView_dailyAverage}>
              {dailyAverage}
            </p>
          </div>
          {tableTitle}
          <div
            className={styles.MetricChartView__percentageFromLastWeekContainer}
          >
            {IconIndicator}
            <p>{percentMessage}</p>
          </div>
        </div>

        <DailyTotalBarChart
          activeKey={activeKey}
          data={activeKey === "tray" ? trayBargraphData : canisterBargraphData}
          chartHeight="300px"
          chartWidth="650px"
          columnWidth="0.9"
          columnSpacing=".3"
          onProductsSelectedDay={
            activeKey === "tray"
              ? handleTrayProductsSelectedDay
              : handleCanisterProductsSelectedDay
          }
          selectedDay={
            activeKey === "tray" ? selectedDayTray : selectedDayCanister
          }
          onSelectedDay={
            activeKey === "tray" ? onSelectedDayTray : onSelectedDayCanister
          }
          palette={palette}
          classNameForBargraph={classNameForBargraph}
          onClassNameForBargraph={handleClassNameForBargraph}
          rowStatus={rowStatus}
          onRowStatus={onRowStatus}
          onShowDropdowns={onShowDropdowns}
        />
      </div>
    </>
  );
};

export default MetricsChartView;
