import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PrintMedList.module.css";
import ConfigService from "../../../services/ConfigService";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import ErrorBanner from "../../../components/ErrorBanner";
import Spacer from "../../../components/Spacer";

const PatientEdit = ({
  patient,
  setEditLanguage,
}) => {
  const { t } = useTranslation("medList");
  
  const facilityLanguage = patient.facility.defaultLanguage || "en";
  const patientLanguage = patient.language || "N/A"

  const [languageList, setLanguageList] = useState([])
  const [language, setLanguage] = useState(patient.language || facilityLanguage);

  const [error, setError] = useState("");
  
  useEffect(() => {
    ConfigService.languages()
      .then((languages) => {
        setLanguageList(
          languages
            .map((language) => ({
              ...language,
              displayName: t(language.type, {ns: "languages"})
            }))
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
        );
      })
      .catch((error) => setError(error));
  }, []);

  useEffect(() => {
    setEditLanguage(language)
  }, [language, setEditLanguage])

  const handleLanguageSelect = (listItem) => {
    setLanguage(listItem.type);
  };
  
  return (
    <>    
      {error && (
        <div className={styles.PrintMedList__errorBannerContainer}>
          <ErrorBanner
            message={error}
            onClose={() => setError("")}
          />
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.PrintMedList__infoLabelText}>{t("facilityLanguageLabel")}:&nbsp;</div>
        <span className={styles.PrintMedList__infoText}>{t(facilityLanguage, {ns: "languages"})}</span>      
      </div>
      <div className={styles.row}>
        <div className={styles.PrintMedList__infoLabelText}>{t("patientLanguageLabel")}:&nbsp;</div>
        <span className={styles.PrintMedList__infoText}>{t(patientLanguage, {ns: "languages"})}</span>      
      </div>
      <Spacer space={20} unit={"px"} />
      <DropdownInput
        value={
          language 
            ? t(language, {ns: "languages"}) 
            : ""
        }
        labelName={t("newPatientLanguageLabel")}
        validationText={""}
        isEnabled={true}
        placeholder={""}
        onSelection={handleLanguageSelect}
        listElements={languageList}
        display={"displayName"}
      />
    </>
  )
}

export default PatientEdit;