import React, { Fragment, useEffect, useMemo, useRef } from "react";
import {
  Group as KonvaGroup,
  Rect as KonvaRect,
} from "react-konva";
import { mmToPx } from "../functions/converters.js";
import Barcode from "./Barcode";
import Image from "./Image";
import Text from "./Text";
import Rect from "./Rect";
import Group from "./Group";
import ResizeLine from "./ResizeLine.js";
import { calculateCorners } from "../functions/arithmetic.js";

const GridLayout = ({
  obj,
  updateObject,
  selectObject,
  onDragStart,
  onDragMove,
  onDragEnd,
  onTransformEnd,
  setTransformerReset,
  viewOnly,
}) => {
  const gridLayoutRef = useRef(null);

  const totalWidth = obj.widths.reduce((sum, a) => sum + a);
  const totalHeight = obj.heights.reduce((sum, a) => sum + a);


  const components = {
    rect: Rect,
    text: Text,
    image: Image,
    barcode: Barcode,
    group: Group,
    gridLayout: GridLayout,
  };

  const cells = useMemo(() => {
    return Array.from({ length: obj.rows }, (_, row) => (
        Array.from({ length: obj.cols }, (_, col) => ({
          id: obj.id,
          width: obj.widths[col],
          height: obj.heights[row],
          x: obj.widths.slice(0, col).length
            ? obj.widths.slice(0, col).reduce((sum, width) => sum + width)
            : 0,
          y: obj.heights.slice(0, row).length
            ? obj.heights.slice(0, row).reduce((sum, height) => sum + height)
            : 0,          
        }))))
  }, [obj])

  useEffect(() => {
    gridLayoutRef.current.x(mmToPx(obj.x));
    gridLayoutRef.current.y(mmToPx(obj.y));
  }, [obj]);

  const dragProps =
    onDragStart && onDragMove && onDragEnd
      ? {
          onDragStart,
          onDragMove,
          onDragEnd,
        }
      : {};

  const transformProps = onTransformEnd
    ? {
        onTransformEnd,
      }
    : {};

  const handleTransform = (e) => {
    e.target
      .getChildren(
        (node) => !["gridLayout", "resizeLine"].includes(node.attrs.objectType)
      )
      .forEach((node) => {
        //const rotation = node.rotation()
        //node.rotation(0)
        node.scaleX(1 / e.target.scaleX());
        node.scaleY(1 / e.target.scaleY());
        //node.rotation(rotation)
      });
  };

  return (
    <KonvaGroup
      ref={gridLayoutRef}
      objectType={"gridLayout"}
      key={obj.id}
      id={obj.id}
      width={mmToPx(totalWidth)}
      height={mmToPx(totalHeight)}
      objects={obj.objects}
      rotation={obj.rotation}
      listening={!viewOnly}
      locked={obj.locked}
      visible={obj.visible}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}
      {...dragProps}
      {...transformProps}
      onTransform={handleTransform}
    >
      {cells.map((row, y) => (
        row.map((cell, x) => (
          <KonvaRect
            parentId={obj.id}
            objectType={"gridLayout"}
            key={`rect${cell.id}-${y}-${x}`}
            x={mmToPx(cell.x)}
            y={mmToPx(cell.y)}
            width={mmToPx(cell.width)}
            height={mmToPx(cell.height)}
            row={y}
            col={x}
            fill={null}
            dash={[10, 10]}
          />
        ))))}

      {!viewOnly && obj.showGrid ? (
        <Fragment key={`${obj.id}-resizeLines`}>
          <ResizeLine
            parentId={obj.id}
            key={`vline${obj.id}-0-0`}
            id={`vline${obj.id}-0-0`}
            obj={obj}
            updateObject={updateObject}
            selectObject={selectObject}
            points={[mmToPx(0), mmToPx(0), mmToPx(0), mmToPx(totalHeight)]}
            orientation={"vertical"}
            currentIndex={0}
            attrs={{ currentIndex: 0 }}
          />
          <ResizeLine
            parentId={obj.id}
            key={`hline${obj.id}-0-0`}
            id={`vline${obj.id}-0-0`}
            updateObject={updateObject}
            selectObject={selectObject}
            points={[mmToPx(0), mmToPx(0), mmToPx(totalWidth), mmToPx(0)]}
            orientation={"horizontal"}
            currentIndex={0}
            attrs={{ currentIndex: 0 }}
          />

          {cells.map((row, y) => (
            row.map((cell, x) => {
              const result = [];
              if (y === 0) {
                result.push(
                  <ResizeLine
                    parentId={obj.id}
                    key={`vline-${obj.id}-${x + 1}-${y + 1}`}
                    id={`vline-${obj.id}-${x + 1}-${y + 1}`}
                    updateObject={updateObject}
                    selectObject={selectObject}
                    points={[
                      mmToPx(cell.x + cell.width),
                      mmToPx(0),
                      mmToPx(cell.x + cell.width),
                      mmToPx(totalHeight),
                    ]}
                    orientation={"vertical"}
                    currentIndex={x + 1}
                    attrs={{ currentIndex: x + 1 }}
                  />
                );
              }

              if (x === 0) {
                result.push(
                  <ResizeLine
                    parentId={obj.id}
                    key={`hline${obj.id}-${x + 1}-${y + 1}`}
                    id={`hline${obj.id}-${x + 1}-${y + 1}`}
                    updateObject={updateObject}
                    selectObject={selectObject}
                    points={[
                      mmToPx(0),
                      mmToPx(cell.y + cell.height),
                      mmToPx(totalWidth),
                      mmToPx(cell.y + cell.height),
                    ]}
                    orientation={"horizontal"}
                    currentIndex={y + 1}
                    attrs={{ currentIndex: y + 1 }}
                  />
                );
              }
              return result;
            })
          ))}
        </Fragment>
      ) : null}

      {cells.map((row, y) => (
        <Fragment key={`${obj.id}-row-${y}`}>
         {row.map((cell, x) => {
            const innerObj = obj.objects.find(
              (o) => o.row === y && o.col === x
            );
            const Component = components[innerObj?.type] || null;

            let deltaX = 0,
              deltaY = 0;
            if (Component) {
              let minX = Infinity,
                minY = Infinity;
              innerObj.x = cell.x;
              innerObj.y = cell.y;

              const corners = calculateCorners(innerObj);
              corners.forEach((corner) => {
                minX = Math.min(minX, corner.x);
                minY = Math.min(minY, corner.y);
              });

              if (minX < cell.x) {
                deltaX = minX - cell.x;
              }
              if (minY < cell.y) {
                deltaY = minY - cell.y;
              }
              innerObj.x -= deltaX;
              innerObj.y -= deltaY;
              innerObj.x += obj.margin.left
              innerObj.y += obj.margin.top
            }
            let pxDeltaX = mmToPx(deltaX);
            let pxDeltaY = mmToPx(deltaY);

            return (
              <Fragment key={`${obj.id}-${y}-${x}`}>                
                {Component && (
                  <Component
                    obj={innerObj}
                    key={innerObj.id}
                    updateObject={updateObject}
                    selectObject={selectObject}
                    onDragStart={onDragStart}
                    onDragMove={onDragMove}
                    onDragEnd={onDragEnd}
                    onTransformEnd={onTransformEnd}
                    setTransformerReset={setTransformerReset}
                    viewOnly={viewOnly}
                    attrs={{ row: y, col: x, pxDeltaX, pxDeltaY }}
                  />
                )}
              </Fragment>
            );
          })}
        </Fragment>
      ))}
    </KonvaGroup>
  );
};

export default GridLayout;
