import { Tabs } from "antd";
import "./AntTabs.css";

/* 
  Example of use
    <AntTabs
        defaultActiveKey="tray"
        activeKey={activeKey}
        tabItems={tabItems}
        onChange={onChange}
    />
*/

const AntTabs = ({
  tabsStyle,
  defaultActiveKey,
  activeKey,
  tabItems,
  onChange,
}) => {
  return (
    <div className={tabsStyle || null}>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        activeKey={activeKey}
        items={tabItems}
        onChange={onChange}
      />
    </div>
  );
};

export default AntTabs;