import React, { useEffect, useRef } from "react";
import { Group, Rect, Text, Path } from 'react-konva';
import { mmToPx } from "../functions/converters.js";
import bwipjs from 'bwip-js'; 


const Barcode = ({ obj, updateObject, onDragStart, onDragMove, onDragEnd, onTransformEnd, viewOnly, attrs }) => { 
  const barcodeRef = useRef(null)
  
  useEffect(() => {
    if (obj && barcodeRef.current) {
      barcodeRef.current.x(mmToPx(obj.x))
      barcodeRef.current.y(mmToPx(obj.y))
    }
  }, [obj])
  
  if (Object.hasOwn(obj, "value") && !obj.value) {
    return null;
  }

  const dragProps = onDragStart && onDragMove && onDragEnd ? {
    onDragStart,
    onDragMove,
    onDragEnd,
  } : {};

  const transformProps = onTransformEnd ? {
    onTransformEnd
  } : {};


  const svgString = bwipjs.toSVG({
    bcid:        obj.symbology,     
    text:        Object.hasOwn(obj, "value") ? obj.value : obj.defaultValues[obj.symbology],    
    //height:      12,           
    //includetext: obj.showValue,           
    //textxalign:  'center',       
    //textcolor:   '000000',   
  });
  const template = document.createElement('template');
  template.innerHTML = svgString;  

  const svg = template.content.querySelector('svg');
  const viewBox = svg.getAttribute('viewBox').split(' ').map(Number);
  const originalWidth = viewBox[2];
  const originalHeight = viewBox[3];
  if (
    (!obj.width && !obj.height) ||
    obj.changed
  ) {
    const height = obj.height || 25.4
    obj.height = height
    obj.width = height * (originalWidth / originalHeight)
    obj.changed = false;
  };
  

  const paths = template.content.querySelectorAll("svg path");

  const scaleX = mmToPx(obj.width) / originalWidth;
  const scaleY = mmToPx(obj.height) / originalHeight;

  return (
    <Group
      ref={barcodeRef}
      objectType={"barcode"}
      key={obj.id}
      id={obj.id}
      width={mmToPx(obj.width)}
      height={mmToPx(obj.height) + (obj.showValue ? 12 : 0)}
      rotation={obj.rotation}
      listening={!viewOnly}
      locked={obj.locked}
      visible={obj.visible}
      draggable={!obj.locked && onDragStart && onDragMove && onDragEnd}
      {...dragProps}
      {...transformProps}      
      {...(attrs ? attrs : {})}
    >
      <Rect
        parentId={obj.id}
        objectType={"barcode"}
        key={`rect${obj.id}`}
        width={mmToPx(obj.width)}
        height={mmToPx(obj.height) + (obj.showValue ? 12 : 0)}
        fill="#ffffff"
      />
      {Array.from(paths).map((path, index) => {
        return (
          <Path
            objectType={"barcode"}
            key={`path${obj.id}-${index}`}
            id={`path${obj.id}-${index}`}
            data={path.getAttribute("d")}
            scaleX={scaleX}
            scaleY={scaleY}
            {...(path.getAttribute("fill") ? { fill: "#000000" } : {})}
            {...(path.getAttribute("stroke") ? { stroke: "#000000" } : {})}
            {...(path.getAttribute("stroke-width") ? { strokeWidth: Number(path.getAttribute("stroke-width")) } : {})}
          />
        );
      })}
      {obj.showValue ? (
        <Text 
          parentId={obj.id}
          objectType={"barcode"}
          key={`text${obj.id}`}
          text={Object.hasOwn(obj, "value") ? obj.value : obj.defaultValues[obj.symbology]}
          y={mmToPx(obj.height)}
          width={mmToPx(obj.width)}
          align={"center"}
          fontSize={obj.fontSize}
          fontStyle={obj.fontStyle}
          textDecoration={obj.textDecoration}
          wrap={"char"}
        />
      ) : null}
    </Group>
  );
};

export default Barcode;