import styles from "./Properties.module.css";
import SFDropDownList from "../../../components/SFDropDownList/SFDropDownList";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { useTranslation } from "react-i18next";
import { ReactComponent as BoldSVG } from "../svgs/text-bold.svg";
import { ReactComponent as ItalicSVG } from "../svgs/text-italic.svg";
import { ReactComponent as UnderlineSVG } from "../svgs/text-underline.svg";
import { ReactComponent as LeftAlignSVG } from "../svgs/text-left2.svg";
import { ReactComponent as CenterAlignSVG } from "../svgs/text-center2.svg";
import { ReactComponent as RightAlignSVG } from "../svgs/text-right2.svg";
import getCanisterDataMappingDropdownList from "./getCanisterDataMappingDropdownList";
import { pxToMm } from "../functions/converters.js";
import Input from "./Input.js";

const TextProperties = ({ unit, selectedObject, updateObject, labelType }) => {
  const { t, i18n } = useTranslation("labelEditor");

  const handleInputChange = (value, name) => {
    const linkedValues = {};
    if (name === "fontSize") {
      linkedValues.height = pxToMm(value * selectedObject.wrapLines);
    } else if (name === "wrapLines") {
      linkedValues.height = pxToMm(value * selectedObject.fontSize);
    }

    updateObject({
      ...selectedObject,
      [name]: value,
      ...linkedValues,
    });
  };

  const handleColorChange = (args, name) => {
    updateObject({
      ...selectedObject,
      [name]: args.currentValue.hex,
    });
  };

  const dropdownListFont = [
    { Item: "Poppins", Value: 1 },
    { Item: "Roboto", Value: 2 },
    { Item: "Open Sans", Value: 3 },
  ];

  const handleSelectedFont = (font) => {
    updateObject({
      ...selectedObject,
      fontFamily: font,
    });
  };

  const dropdownListCanisterDataMapping = getCanisterDataMappingDropdownList();

  const handleCanisterDataMapping = (friendlyName, path) => {
    updateObject({
      ...selectedObject,
      mappingName: friendlyName,
      mappingPath: path,
    });
  };

  const dropdownListWrap = [
    { Item: "none", Value: 1, displayName: t("noWrapValue") },
    { Item: "word", Value: 2, displayName: t("wordWrapValue") },
    { Item: "char", Value: 3, displayName: t("charWrapValue") },
  ];

  const handleSelectedWrap = (wrap) => {
    updateObject({
      ...selectedObject,
      wrap: wrap,
    });
  };

  const toggleBold = () => {
    if (selectedObject.locked) return;

    let fontStyle = selectedObject.fontStyle;
    if (fontStyle === "normal") {
      fontStyle = "bold";
    } else if (fontStyle === "bold") {
      fontStyle = "normal";
    } else if (fontStyle === "italic") {
      fontStyle = "bold italic";
    } else if (fontStyle === "bold italic") {
      fontStyle = "italic";
    }

    updateObject({
      ...selectedObject,
      fontStyle,
    });
  };

  const toggleItalic = () => {
    if (selectedObject.locked) return;

    let fontStyle = selectedObject.fontStyle;
    if (fontStyle === "normal") {
      fontStyle = "italic";
    } else if (fontStyle === "italic") {
      fontStyle = "normal";
    } else if (fontStyle === "bold") {
      fontStyle = "bold italic";
    } else if (fontStyle === "bold italic") {
      fontStyle = "bold";
    }

    updateObject({
      ...selectedObject,
      fontStyle,
    });
  };

  const toggleUnderline = () => {
    if (selectedObject.locked) return;

    updateObject({
      ...selectedObject,
      textDecoration:
        selectedObject.textDecoration === "underline" ? "" : "underline",
    });
  };

  const toggleTextAlignment = (align) => {
    if (selectedObject.locked) return;

    updateObject({
      ...selectedObject,
      align,
    });
  };

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("widthLabel")}</label>
          <Input
            value={selectedObject.width}
            name="width"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            step={0.1}
            min={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("heightLabel")}</label>
          <Input
            value={selectedObject.height}
            name="height"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={true}
            step={0.1}
            min={0.1}
          />
        </div>
      </div>
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X</label>
          <Input
            value={selectedObject.x}
            name="x"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={
              selectedObject.locked ||
              selectedObject?.parent?.type === "gridLayout"
            }
            step={0.1}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y</label>
          <Input
            value={selectedObject.y}
            name="y"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={
              selectedObject.locked ||
              selectedObject?.parent?.type === "gridLayout"
            }
            step={0.1}
          />
        </div>
      </div>

      {labelType === "Canister" && (
        <div className={styles.Properties__canisterDataMapping}>
          <SFDropDownList
            id={"canisterDataMapping"}
            label={t("canisterDataLabel")}
            defaultValue={selectedObject.mappingName}
            dropDownList={dropdownListCanisterDataMapping}
            onSelection={handleCanisterDataMapping}
            display={"displayName"}
            enabled={!selectedObject.locked}
            popupWidth={"300px"}
            blackUnderline={true}
            style={{height: 30}}
            labelStyle={{padding: 0, height: 18, margin: "0px !important"}}
          />
        </div>
      )}

      <div className={styles.Properties__propertyFull}>
        <label className={styles.Properties__label}>
          {selectedObject.mappingName === "Default Value"
            ? t("defaultValueLabel")
            : t("placeholderValueLabel")}
        </label>
        <Input
          value={selectedObject.defaultValue}
          name="defaultValue"
          type="text"
          onChange={handleInputChange}
          disabled={selectedObject.locked}
        />
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <SFDropDownList
            id={"fontFamily"}
            label={t("fontLabel")}
            defaultValue={selectedObject.fontFamily}
            dropDownList={dropdownListFont}
            onSelection={handleSelectedFont}
            enabled={!selectedObject.locked}
            popupWidth={"150px"}
            blackUnderline={true}
            style={{height: 30}}
            labelStyle={{padding: 0, height: 18, margin: "0px !important"}}
          />
        </div>

        <div className={styles.Properties__property}>
          <div className={styles.Properties__boxesContainer}>
            <div
              className={`${styles.Properties__styleContainer} ${
                selectedObject.locked
                  ? styles.disabled
                  : styles.Properties__clickable
              }`}
              onClick={toggleBold}
            >
              <BoldSVG />
            </div>
            <div
              className={`${styles.Properties__styleContainer} ${
                selectedObject.locked
                  ? styles.disabled
                  : styles.Properties__clickable
              }`}
              onClick={toggleItalic}
            >
              <ItalicSVG />
            </div>
            <div
              className={`${styles.Properties__styleContainer} ${
                selectedObject.locked
                  ? styles.disabled
                  : styles.Properties__clickable
              }`}
              onClick={toggleUnderline}
            >
              <UnderlineSVG />
            </div>
          </div>

          <div className={styles.Properties__boxesContainer}>
            <div
              className={`${styles.Properties__alignmentContainer} ${
                selectedObject.locked
                  ? styles.disabled
                  : styles.Properties__clickable
              }`}
              onClick={() => {
                toggleTextAlignment("left");
              }}
            >
              <LeftAlignSVG />
            </div>
            <div
              className={`${styles.Properties__alignmentContainer} ${
                selectedObject.locked
                  ? styles.disabled
                  : styles.Properties__clickable
              }`}
              onClick={() => {
                toggleTextAlignment("center");
              }}
            >
              <CenterAlignSVG />
            </div>
            <div
              className={`${styles.Properties__alignmentContainer} ${
                selectedObject.locked
                  ? styles.disabled
                  : styles.Properties__clickable
              }`}
              onClick={() => {
                toggleTextAlignment("right");
              }}
            >
              <RightAlignSVG />
            </div>
          </div>
        </div>

      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("fontSizeLabel")}
          </label>
          <Input
            value={selectedObject.fontSize}
            name="fontSize"
            type="number"
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            min={1}
            step={1}
          />
        </div>
        
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("fontColorLabel")}
          </label>

          <ColorPickerComponent
            id={"fill-color-picker"}
            name="fill"
            value={selectedObject.fill}
            defaultValue={selectedObject.fill}
            // mode="Palette"
            // noColor={false}
            showButtons={true}
            modeSwitcher={true}
            change={(args) => handleColorChange(args, "fill")}
            locale={i18n.language}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <SFDropDownList
            id={"wrap"}
            label={t("wrapLabel")}
            defaultValue={selectedObject.wrap}
            dropDownList={dropdownListWrap}
            onSelection={handleSelectedWrap}
            display={"displayName"}
            enabled={!selectedObject.locked}
            popupWidth={"150px"}
            blackUnderline={true}
            style={{height: 30}}
            labelStyle={{padding: 0, height: 18, margin: "0px !important"}}
          />
        </div>

        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>{t("maxLinesLabel")}</label>
          <Input
            value={selectedObject.wrapLines}
            name="wrapLines"
            type="number"
            onChange={handleInputChange}
            min={1}
            step={1}
            disabled={selectedObject.locked}
          />
        </div>

      </div>
      
    </div>
  );
};

export default TextProperties;
