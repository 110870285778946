import StockedItemService from "../../../services/StockedItemService";
import ProductController from "../../../controllers/ProductController";
import { isCanister } from "../../../functions/utilities.js";
import i18n from "../../../i18nextConf.js";

class InventoryListController {
  constructor(user) {
    this.user = user;
    this._pc = new ProductController(user);
  }

  async getStockedItems(
    site,
    selectedDevices,
    automationFilter,
    forExpandableTable
  ) {
    let stockedItems = await StockedItemService.list(this.user, site);
    stockedItems = stockedItems.filter((si) => si.product != null);
    if (automationFilter) {
      stockedItems = stockedItems.filter((si) => {
        if (automationFilter.type === "All Automation") {
          return si.deviceCompatability !== null;
        } else {
          return !si.deviceCompatability;
        }
      });
    }
    if (selectedDevices.length) {
      try {
        stockedItems = stockedItems.filter((si) => {
          if (
            selectedDevices.some((sd) => {
              return (
                sd.type === si.stockLocation?.type?.type ||
                sd.type === si.deviceCompatability
              );
            })
          ) {
            return true;
          } else if (
              selectedDevices.some((sd) => {
                return (
                    sd.type === 'SMART_SHELF' && si.stockLocation?.parent?.type.type.includes(sd.type)
                );
              })
          ) {
            return true;
          }  else {
            return false;
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (forExpandableTable) {
      return this.getFormattedModelItemsForExpandableTable(stockedItems, true);
    } else {
      stockedItems.sort((a, b) => {
        if (
          this._pc.getDefaultName(a.product) <
          this._pc.getDefaultName(b.product)
        ) {
          return -1;
        }
        if (
          this._pc.getDefaultName(b.product) <
          this._pc.getDefaultName(a.product)
        ) {
          return 1;
        }
        return 0;
      });

      return this.getViewModelItems(stockedItems);
    }
  }

  getFormattedModelItemsForExpandableTable = (stockedItems) => {
    stockedItems.sort((a, b) => {
      let parentA = null;
      let parentB = null;

      parentA = a?.stockLocation?.parent?.name.toLowerCase();

      parentB = b?.stockLocation?.parent?.name.toLowerCase();

      let locationA = null;
      let locationB = null;

      if (!parentA || !isCanister(a?.stockLocation)) {
        locationA = a?.stockLocation?.name.toLowerCase();
      }

      if (!parentB || !isCanister(b?.stockLocation)) {
        locationB = b?.stockLocation?.name.toLowerCase();
      }

      let userA = null;
      let userB = null;

      if (!parentA && !locationA && a?.user && a.user?.firstName) {
        userA = (a.user.firstName + " " + a.user?.lastName).toLowerCase();
      }

      if (!parentB && !locationB && b?.user && b.user?.firstName) {
        userB = (b.user.firstName + " " + b.user?.lastName).toLowerCase();
      }

      let ownerA = userA || locationA || parentA;
      let ownerB = userB || locationB || parentB;

      if (!ownerA) {
        ownerA = "zzz";
      }

      if (!ownerB) {
        ownerB = "zzz";
      }

      if (a.site._id === b.site._id && ownerA <= ownerB) {
        return -1;
      }

      if (a.site._id === b.site._id && ownerB < ownerA) {
        return 1;
      }

      return 0;
    });

    stockedItems.sort((a, b) => {
      let parentA = null;
      let parentB = null;

      parentA = a?.stockLocation?.parent?.name.toLowerCase();

      parentB = b?.stockLocation?.parent?.name.toLowerCase();

      let locationA = null;
      let locationB = null;

      if (!parentA || !isCanister(a?.stockLocation)) {
        locationA = a?.stockLocation?.name.toLowerCase();
      }

      if (!parentB || !isCanister(b?.stockLocation)) {
        locationB = b?.stockLocation?.name.toLowerCase();
      }

      let userA = null;
      let userB = null;

      if (!parentA && !locationA && a?.user && a.user?.firstName) {
        userA = (a.user.firstName + " " + a.user?.lastName).toLowerCase();
      }

      if (!parentB && !locationB && b?.user && b.user?.firstName) {
        userB = (b.user.firstName + " " + b.user?.lastName).toLowerCase();
      }

      let ownerA = userA || locationA || parentA;
      let ownerB = userB || locationB || parentB;

      if (
        a.site._id === b.site._id &&
        ownerA === ownerB &&
        a.product.packageId <= b.product.packageId
      ) {
        return -1;
      }
      if (
        a.site._id === b.site._id &&
        ownerA === ownerB &&
        b.product.packageId < a.product.packageId
      ) {
        return 1;
      }

      return 0;
    });

    let recordObjects = [];

    let recordObjectsIndex = -1;

    stockedItems.forEach((item, index) => {
      if (
        index > 0 &&
        recordObjects[recordObjectsIndex][0].site._id === item.site._id &&
        recordObjects[recordObjectsIndex][0].product.packageId ===
          item.product.packageId &&
        ((recordObjects[recordObjectsIndex][0]?.stockLocation?.parent?.name &&
          item?.stockLocation?.parent?.name &&
          recordObjects[recordObjectsIndex][0]?.stockLocation?.parent?.name ===
            item?.stockLocation?.parent?.name) ||
          (((recordObjects[recordObjectsIndex][0]?.user?._id &&
            item?.user?._id) ||
            (recordObjects[recordObjectsIndex][0]?.stockLocation?.name &&
              item?.stockLocation?.name)) &&
            ((recordObjects[recordObjectsIndex][0]?.user?._id &&
              recordObjects[recordObjectsIndex][0]?.user?._id ===
                item?.user?._id) ||
              (recordObjects[recordObjectsIndex][0]?.stockLocation?.name &&
                recordObjects[recordObjectsIndex][0]?.stockLocation?.name ===
                  item?.stockLocation?.name))))
      ) {
        recordObjects[recordObjectsIndex].push(item);
      } else {
        recordObjects.push([item]);
        recordObjectsIndex++;
      }
    });

    let records = [];

    recordObjects.forEach((object, index) => {
      const site = object[0]?.site?.name || "";

      let owner;
      if (object[0]?.stockLocation && isCanister(object[0].stockLocation)) {
        owner =
          object[0]?.stockLocation?.parent?.name ||
          (object[0].stockLocation === null &&
            object[0]?.user &&
            `${object[0]?.user?.firstName} ${object[0]?.user?.lastName}`) ||
          object[0]?.stockLocation?.name ||
          "";
      } else {
        owner =
          object[0]?.stockLocation?.name ||
          object[0]?.stockLocation?.parent?.name ||
          (object[0].stockLocation === null &&
            object[0]?.user &&
            `${object[0]?.user?.firstName} ${object[0]?.user?.lastName}`) ||
          "";
      }

      const packageId = object[0]?.product?.packageId;

      // const readableId =
      //   object[0]?.product?.humanReadableId ||
      //   packageId||
      //   "";

      const description = this._pc?.getDefaultName(object[0]?.product || "");
      const form =
        object[0].product?.dosageForm?.type ||
        object[0].product?.dosageForm?.description ||
        "";
      const manufacturer =
        object[0].product?.manufacturer?.abbrName ||
        object[0].product?.manufacturer?.fullName ||
        "";
      const totalQty = "";
      const totalCost = "";

      let subHeaders;

      subHeaders =
        object[0]?.stockLocation &&
        isCanister(object[0].stockLocation) &&
        object[0].stockLocation?.parent
          ? [
              i18n.t("nameCol", {ns: "inventory"}),
              i18n.t("positionCol", {ns: "inventory"}),
              i18n.t("lotCol", {ns: "inventory"}),
              i18n.t("expCol", {ns: "inventory"}),
              i18n.t("minCol", {ns: "inventory"}),
              i18n.t("maxCol", {ns: "inventory"}),
              i18n.t("qohCol", {ns: "inventory"}),
              i18n.t("costCol", {ns: "inventory"}),
            ]
          : [
              i18n.t("lotCol", {ns: "inventory"}), 
              i18n.t("expCol", {ns: "inventory"}), 
              i18n.t("minCol", {ns: "inventory"}), 
              i18n.t("maxCol", {ns: "inventory"}), 
              i18n.t("qohCol", {ns: "inventory"}), 
              i18n.t("costCol", {ns: "inventory"})
            ];

      let subData = [];

      object.forEach((item, index) => {
        item?.stockLocation &&
        isCanister(item.stockLocation) &&
        object[0].stockLocation?.parent
          ? subData.push([
              item?.stockLocation?.name || "",
              item?.stockLocation?.devicePosition || "",
              item?.lotNumber || "",
              item?.expiration || "",
              item?.stockLocation?.productAssociations[0]?.min || 0,
              item?.stockLocation?.productAssociations[0]?.max || 0,
              item?.quantity || 0,
              this.getCost(item) * item?.quantity || 0,
            ])
          : subData.push([
              item?.lotNumber || "",
              item?.expiration || "",
              item?.stockLocation?.productAssociations[0]?.min || 0,
              item?.stockLocation?.productAssociations[0]?.max || 0,
              item?.quantity || 0,
              this.getCost(item) * item?.quantity || 0,
            ]);
      });

      let containers = "";
      let container = "";

      subData.forEach((containersArray, containersArrayIndex) => {
        if (
          containersArray.length === 8 &&
          containersArray[0].trim() &&
          containers.indexOf(containersArray[0].trim()) === -1
        ) {
          container = containersArray[0].trim();
        } else {
          container = "";
        }

        if (container && containersArrayIndex === subData.length - 1) {
          containers = containers + container;
        } else if (container && containersArrayIndex < subData.length - 1) {
          containers = containers + container + ", ";
        }
      });

      // Strip trailing white space/comma
      containers = containers.trim();
      if (containers && containers[containers.length - 1] === ",") {
        containers = containers.substring(0, containers.length - 1);
      }

      records.push([
        site,
        owner,
        containers,
        packageId,
        description,
        form,
        manufacturer,
        totalQty,
        totalCost,
        subHeaders,
        subData,
      ]);
    });

    records.forEach((record) => {
      if (record[9].length === 8) {
        this.getTotal(record, 7, 10, 6);
        this.getTotal(record, 8, 10, 7);
      } else {
        this.getTotal(record, 7, 10, 4);
        this.getTotal(record, 8, 10, 5);
      }
    });

    return records;
  };

  getTotal(record, headerIndex, subRecordIndex, subColumnIndex) {
    let total = 0;
    record[subRecordIndex].forEach(function (item) {
      total += item[subColumnIndex];
    });
    record[headerIndex] = total;
  }

  getViewModelItems = (stockedItems) => {
    let arr = [];
    for (let i = 0; i < stockedItems.length; i++) {
      const stockedItem = stockedItems[i];
      try {
        let row = [
          stockedItem.site.name,
          stockedItem.product.packageId,
          this._pc.getDefaultName(stockedItem.product),
          stockedItem.product.dosageForm?.type ||
            stockedItem.product.dosageForm?.description ||
            "",
          stockedItem.product.manufacturer?.abbrName ||
            stockedItem.product.manufacturer?.fullName ||
            "",
          stockedItem.stockLocation
            ? stockedItem.stockLocation.name
            : stockedItem.user.firstName + " " + stockedItem.user.lastName,
          stockedItem.stockLocation?.parent?.name || "",
          stockedItem.lotsNumber || "",
          stockedItem.expiration || "",
          stockedItem.stockLocation
            ? this.getProductAssociation(stockedItem)?.min
            : "-",
          stockedItem.stockLocation
            ? this.getProductAssociation(stockedItem)?.max
            : "-",
          stockedItem.quantity,
          this.getCost(stockedItem),
        ];
        arr.push(row);
      } catch (e) {
        console.log(
          `could not map to inventory list view model.  StockedItem _id = ${stockedItem._id}`
        );
      }
    }
    return arr;
  };

  getProductAssociation = (stockedItem) => {
    const stockLocation = stockedItem.stockLocation;
    const product = stockedItem.product;
    return stockLocation.productAssociations.find((pa) => {
      return pa.product._id === product._id;
    });
  };

  getCost = (stockedItem) => {
    const site = stockedItem.site;
    const product = stockedItem.product;
    switch (site.type.type) {
      case "Nutraceuticals":
      case "Retail":
        return product.retailAwpItem;
      default:
        return product.ltcAwpBulk;
    }
  };
}

export default InventoryListController;
