import { PDFViewer, Document, Page, Text, View } from "@react-pdf/renderer";
import backIcon from "../../../../assets/back_icon.svg";
import React from "react";

import { registerPoppins } from "../../../../fonts/registerFonts";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getLocalizedDate } from "../../../../functions/localization";

registerPoppins();

//region CSS
const ReportContainer = styled.div`
  display: flex;
  @media (min-width: 641px) and (max-width: 1007px) {
    min-width: 100vw;
  }
  @media (min-width: 1008px) {
    width: 100%;
  }
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
  max-height: 1024px;
  fontfamily: "Poppins";
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
`;
//endregion

const PDFRefillReportViewer = ({
  user,
  title,
  headings,
  subHeadings,
  data,
  onBack,
  selectionRange,
}) => {
  const { t } = useTranslation("pdfReportViewer");

  const headingsSpacing = [
    { columnWidth: 117, marginLeft: 20, marginRight: 0, textAlign: "left" },
    { columnWidth: 138, marginLeft: 0, marginRight: 0, textAlign: "left" },
    { columnWidth: 160, marginLeft: 0, marginRight: 0, textAlign: "left" },
    { columnWidth: 60, marginLeft: 90, marginRight: 0, textAlign: "right" },
    { columnWidth: 60, marginLeft: 0, marginRight: 0, textAlign: "right" },
    { columnWidth: 50, marginLeft: 20, marginRight: 0, textAlign: "right" },
  ];

  const mainDataSpacing = [
    { columnWidth: 97, marginLeft: 20, marginRight: 20, textAlign: "left" },
    { columnWidth: 133, marginLeft: 0, marginRight: 5, textAlign: "left" },
    { columnWidth: 210, marginLeft: 0, marginRight: 5, textAlign: "left" },
    { columnWidth: 95, marginLeft: 0, marginRight: 5, textAlign: "right" },
    { columnWidth: 56, marginLeft: 0, marginRight: 5, textAlign: "right" },
    { columnWidth: 50, marginLeft: 15, marginRight: 0, textAlign: "right" },
  ];

  const subHeadingsSpacing = [
    { columnWidth: 132, marginLeft: 8, marginRight: 0, textAlign: "left" },
    { columnWidth: 116, marginLeft: 0, marginRight: 324, textAlign: "left" },
    { columnWidth: 50, marginLeft: 0, marginRight: 0, textAlign: "right" },
  ];

  const subDataSpacing = [
    { columnWidth: 127, marginLeft: 8, marginRight: 5, textAlign: "left" },
    { columnWidth: 111, marginLeft: 0, marginRight: 329, textAlign: "left" },
    { columnWidth: 50, marginLeft: 0, marginRight: 0, textAlign: "right" },
  ];

  let startDate = null;
  let endDate = null;

  if (selectionRange && selectionRange?.startDate && selectionRange?.endDate) {
    startDate = getLocalizedDate(
      selectionRange.startDate,
      user?.defaultSite?.shippingAddress?.country
    );
    endDate = getLocalizedDate(
      selectionRange.endDate,
      user?.defaultSite?.shippingAddress?.country
    );
  }

  const Report = () => (
    <Document title={title}>
      <Page
        wrap
        size={"LETTER"}
        orientation={"landscape"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          boxSizing: "border-box",
          padding: 20,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              fontFamily: "Poppins",
              fontSize: 12,
              fontWeight: 500,
              fontStyle: "normal",
              color: "#121A2D",
              margin: 0,
              padding: 0,
            }}
          >
            {title}
          </Text>

          <Text
            style={{
              fontFamily: "Poppins",
              fontSize: 12,
              fontWeight: 500,
              fontStyle: "normal",
              color: "#121A2D",
              margin: 0,
              padding: 0,
              paddingRight: 10,
            }}
          >
            {startDate && endDate && `${startDate} - ${endDate}`}
          </Text>
        </View>

        {/* headings */}
        <View
          fixed
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            height: 50,
            marginBottom: 10,
            borderBottom: "2px solid #089BAB",
          }}
        >
          {headings.map((header, index) => {
            return (
              <Text
                key={index}
                style={{
                  fontFamily: "Poppins",
                  fontSize: 10,
                  fontWeight: 500,
                  fontStyle: "normal",
                  color: "#121A2D",
                  width: headingsSpacing[index].columnWidth,
                  textAlign: headingsSpacing[index].textAlign,
                  margin: 0,
                  marginLeft: headingsSpacing[index].marginLeft,
                  marginRight: headingsSpacing[index].marginRight,
                  padding: 0,
                }}
              >
                {header}
              </Text>
            );
          })}
        </View>

        {data.map((row, r) => {
          return (
            // data
            <View
              key={r}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {row.length === 6 ? (
                <View
                  // main data & subHeaders container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <View
                    // main data
                    style={{
                      padding: 0,
                      margin: 0,
                      marginTop: r === 0 ? 0 : 14,
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginBottom: 8,
                    }}
                  >
                    {row.map((text, t) => {
                      return (
                        <Text
                          key={t}
                          style={{
                            textAlign: mainDataSpacing[t].textAlign,
                            fontFamily: "Poppins",
                            fontSize: 10,
                            fontWeight: 400,
                            fontStyle: "normal",
                            color: "#121A2D",
                            width: mainDataSpacing[t].columnWidth,
                            marginLeft: mainDataSpacing[t].marginLeft,
                            marginRight:
                              t < 5 ? mainDataSpacing[t].marginRight : 0,
                          }}
                        >
                          {text}
                        </Text>
                      );
                    })}
                  </View>

                  {data[r + 1] && (
                    <View
                      // subHeadings
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: 720,
                        marginBottom: 5,
                        paddingBottom: 3,
                        borderBottom: "1px solid #089bab",
                        height: 24,
                      }}
                    >
                      {subHeadings.map((text, t) => {
                        return (
                          <Text
                            key={t}
                            style={{
                              padding: 0,
                              fontFamily: "Poppins",
                              fontSize: 9,
                              fontStyle: "normal",
                              fontWeight: 400,
                              color: row[0] === "Type" ? "#045d66" : "#089bab",
                              width: subHeadingsSpacing[t].columnWidth,
                              marginLeft: subHeadingsSpacing[t].marginLeft,
                              marginRight: subHeadingsSpacing[t].marginRight,
                              textAlign: subHeadingsSpacing[t].textAlign,
                            }}
                          >
                            {text}
                          </Text>
                        );
                      })}
                    </View>
                  )}
                </View>
              ) : (
                <View
                  // subData
                  style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: 720,
                    // backgroundColor: r % 2 ? "#EBEDF8" : "#ffffff",
                    height: 14,
                  }}
                >
                  {row.map((text, t) => {
                    return (
                      <Text
                        key={t}
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 9,
                          fontWeight: 300,
                          fontStyle: "normal",
                          color: "#089bab",
                          width: subDataSpacing[t].columnWidth,
                          marginLeft: subDataSpacing[t].marginLeft,
                          marginRight: subDataSpacing[t].marginRight,
                          textAlign: subDataSpacing[t].textAlign,
                        }}
                      >
                        {text}
                      </Text>
                    );
                  })}
                </View>
              )}
            </View>
          );
        })}

        <Text
          style={{
            margin: 0,
            fontFamily: "Poppins",
            fontSize: 10,
            fontWeight: 300,
            fontStyle: "normal",
            color: "#121A2D",
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <ReportContainer>
      <Header>
        <TitleGroup>
          <BackButton src={backIcon} onClick={onBack} />
          <ReportTitle>{t("title")}</ReportTitle>
        </TitleGroup>
      </Header>
      {/*PDF Area*/}
      <PDFViewer style={{ height: "100%" }}>
        <Report />
      </PDFViewer>
    </ReportContainer>
  );
};

export default PDFRefillReportViewer;
