import DashboardService from "../../../services/DashboardService";

class TruPakDashboardController {
  /*
        This method will provide data as follows:
        user:           required
        site:           optional - if not provided, data will represent a total view of the account.
                        If provided, will be based on just the specified site
        stockLocation:  optional.  If provided, will be specific to that stockLocation (device)

        returns:        JSON structure with results array and rollingAverage for a the given time period
    */
  getTruPakPouchesPerHourData = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    return await DashboardService.getTruPakPouchesPerHourData(
      user,
      site,
      stockLocation,
      utcStartDate,
      utcStopDate
    );
  };

  getTruPakBatchesVsPackagedOnData = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    return await DashboardService.getTruPakBatchesVsPackagedOnData(
      user,
      site,
      stockLocation,
      utcStartDate,
      utcStopDate
    );
  };

  getTruPakInventory = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    return await DashboardService.getTruPakInventory(
      user,
      site,
      stockLocation,
      utcStartDate,
      utcStopDate
    );
  };

  // Returns the following JSON: [{errorCode:<>,description:<>,count:<>}]
  getTruPakAlarmHistory = async (
    user,
    site,
    startDate,
    stopDate,
    details,
    deviceId
  ) => {
    return await DashboardService.getTruPakAlarmHistory(
      user,
      site,
      startDate,
      stopDate,
      details,
      deviceId
    );
  };

  getTruPakCanisterUtilization = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate,
    includeDetail,
  ) => {
    return await DashboardService.getTruPakCanisterUtilization(
      user,
      site,
      stockLocation,
      utcStartDate,
      utcStopDate,
      includeDetail,
    );
  };
}

const instance = new TruPakDashboardController();
export default instance;
