import React, { Fragment } from "react";
import { ReactComponent as MoreActionsIconMobile } from "../../../assets/more-actions-mobile.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/add-icon-small.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/subtract-circle-icon.svg";
import { useTranslation } from "react-i18next";

import ContextMenu from "../../../components/ContextMenu";
import style from "./StockLocationCard.module.css";

const StockLocationCard = ({
  site,
  index,
  node,
  nodeObjChildrenLength,
  clickedNode,
  onClickedNode,
  expandedNode,
  getNode,
  isAuthorized,
  rowIndex,
  onRowIndex,
  prevIndex,
  onPrevIndex,
  select,
  getActionMenu,
  onActionSelection,
  onCurrentAction,
  contextMenu,
  onSetContextMenu,
  lastParentCard,
}) => {
  const { t } = useTranslation("stockLocations");  

  const Card = (
    <Fragment>
      <div
        className={
          node.parent === null && node.isExpanded && node.children.length > 0
            ? [
                style["StockLocationCard__parentAndChildrenCardsGroup"],
                style[
                  "StockLocationCard__parentAndChildrenCardsGroup--expanded"
                ],
              ].join(" ")
            : node.parent === null
            ? style.StockLocationCard__parentAndChildrenCardsGroup
            : ""
        }
      >
        <div
          className={
            node.parent === null
              ? !node.isExpanded || !node.children.length
                ? style.StockLocationCard__parentCard
                : [
                    style["StockLocationCard__parentCard"],
                    style["StockLocationCard__parentCard--expanded"],
                  ].join(" ")
              : style.StockLocationCard__childCard
          }
          onClick={(e) => {
            e.stopPropagation();
            if (node.parent === null) {
              if (
                clickedNode !== node &&
                rowIndex !== index &&
                nodeObjChildrenLength > 0 &&
                !node.isExpanded
              ) {
                onClickedNode(null);
              }
              nodeObjChildrenLength > 0 &&
                select(node, nodeObjChildrenLength, e);
            }
          }}
        >
          {nodeObjChildrenLength > 0 &&
            node.obj.isEnabled &&
            node.parent === null && (
              <span
                className={
                  !node.isExpanded
                    ? `${style.StockLocationCard__hasChildrenIconContainer} ${style["StockLocationCard__hasChildrenIconContainer--collapsed"]}`
                    : `${style.StockLocationCard__hasChildrenIconContainer} ${style["StockLocationCard__hasChildrenIconContainer--expanded"]}`
                }
              >
                {!node.isExpanded ? (
                  <ExpandIcon
                    className={style.StockLocationCard__hasChildrenIcon}
                  />
                ) : (
                  <CollapseIcon
                    className={style.StockLocationCard__hasChildrenIcon}
                  />
                )}
              </span>
            )}

          {isAuthorized() && (
            <div
              className={
                !node.isExpanded
                  ? node.parent === null || node.children.length
                    ? `${style.StockLocationCard__contextMenuContainer} ${style["StockLocationCard__contextMenuContainer--collapsed"]}`
                    : `${style.StockLocationCard__contextMenuContainer} ${style["StockLocationCard__contextMenuContainer--collapsedChild"]}`
                  : `${style.StockLocationCard__contextMenuContainer} ${style["StockLocationCard__contextMenuContainer--expanded"]}`
              }
              onClick={(e) => {
                onClickedNode(node);
                e.stopPropagation();
                onRowIndex(index);
                if (
                  contextMenu === null ||
                  node !== clickedNode ||
                  (contextMenu !== null && index !== prevIndex)
                ) {
                  onPrevIndex(index);
                  onSetContextMenu({
                    minWidth: "90px",
                    left: "0",
                    top: "0",
                    listItems: getActionMenu(site, node.obj),
                    isRightAligned: false,
                    onSelect: (action) => {
                      onActionSelection(action, site, onCurrentAction, node);
                      onSetContextMenu(null);
                    },
                  });
                } else {
                  onSetContextMenu(null);
                }
              }}
            >
              {node === clickedNode && (
                <div className={style.StockLocationCard__contextMenuWrapper}>
                  {contextMenu && (
                    <ContextMenu
                      onSelect={contextMenu.onSelect}
                      listItems={contextMenu.listItems}
                      minWidth={contextMenu.minWidth}
                      top={contextMenu.top}
                      left={contextMenu.left}
                      isRightAligned={contextMenu.isRightAligned}
                      maxHeight={contextMenu.maxHeight}
                      onClose={() => onSetContextMenu(null)}
                    />
                  )}
                </div>
              )}

              <span className={style.StockLocationCard__action_items}>
                <MoreActionsIconMobile className="item__actionsIcon" />
              </span>
            </div>
          )}

          <div className={style.StockLocationCard__cardRow}>
            <div className={style.StockLocationCard__headingAndValueGroup}>
              <h2 className={style.StockLocationCard__cardHeading}>{t("nameCol")}</h2>
              <p className={style.StockLocationCard__cardData}>
                {node.obj.name}
              </p>
            </div>

            <div className={style.StockLocationCard__headingAndValueGroup}>
              <h2 className={style.StockLocationCard__cardHeading}>{t("nicknameCol")}</h2>
              <p className={style.StockLocationCard__cardData}>
                {node.obj.nickname}
              </p>
            </div>
          </div>

          <div className={style.StockLocationCard__cardRow}>
            <div className={style.StockLocationCard__headingAndValueGroup}>
              <h2 className={style.StockLocationCard__cardHeading}>{t("typeCol")}</h2>
              <p className={style.StockLocationCard__cardData}>
                {t(node.obj.type.description, {ns: "stockLocationTypes"})}
              </p>
            </div>

            <div className={style.StockLocationCard__headingAndValueGroup}>
              <h2 className={style.StockLocationCard__cardHeading}>{t("positionCol")}</h2>
              <p className={style.StockLocationCard__cardData}>
                {node.obj.devicePosition || ""}
              </p>
            </div>
          </div>

          <div className={style.StockLocationCard__cardRow}>
          <div className={style.StockLocationCard__headingAndValueGroup}>
              <h2 className={style.StockLocationCard__cardHeading}>
                {t("controlledCol")}
              </h2>
              <p className={style.StockLocationCard__cardData}>
                {node.obj.isControlled ? t("controlled") : t("notControlled")}
              </p>
            </div>
            
            <div className={style.StockLocationCard__headingAndValueGroup}>
              <h2 className={style.StockLocationCard__cardHeading}>{t("barcodeCol")}</h2>
              <p className={style.StockLocationCard__cardData}>
                {node.obj.barcode}
              </p>
            </div>
          </div>
        </div>

        {expandedNode &&
          (expandedNode === node ||
            (node.parent === expandedNode &&
              expandedNode.children[expandedNode.children.length - 1] !==
                node)) && <hr className={style.StockLocationCard__hr}></hr>}
        {node.isExpanded &&
          node.children.map((childNode) => {
            return getNode(childNode, index);
          })}
      </div>
      {!expandedNode && !lastParentCard && (
        <hr className={style.StockLocationCard__hr}></hr>
      )}
    </Fragment>
  );

  return Card;
};

export default StockLocationCard;
