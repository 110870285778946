import React, { useEffect, useState, useRef } from "react";
import styles from "./VBMDevice.module.css";
import Table from "../../../components/Table/Table";
import Accordion from "../../../components/Accordion/Accordion";
import VBMDeviceController from "./VBMDeviceController";
import { useTranslation } from "react-i18next";

const VBMDevice = ({ user, device }) => {
  const { t } = useTranslation("vbmDevice")

  const contRef = useRef(null);
  const [records, setRecords] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    let timerInterval;
    if (accordionOpen && !records.length) {
      contRef.current = new VBMDeviceController(user);
      contRef.current.initializeSocket();
      setIsFetching(true);
      contRef.current.getInventory(device, handleInventoryRecords);
      timerInterval = setInterval(handleTick, 60000);
    }
    return () => {
      if (timerInterval) {
        contRef.current.releaseSocket();
        clearInterval(timerInterval);
      }
    };
  }, [user, device, accordionOpen, records]);

  const handleInventoryRecords = (records) => {
    setRecords(records);
    setIsFetching(false);
  };

  // const handleInventoryRecord = (record) => {
  //     setRecords(prev=>{
  //         return [...prev, record]
  //     });
  // }

  const handleTick = () => {
    contRef.current.getInventory(device, handleInventoryRecords);
  };

  const handleAccordionOpen = () => {
    setAccordionOpen((prevState) => !prevState);
  };

  let id = device.name.replaceAll(" ", "_");
  id = id.replaceAll("-", "_");
  return (
    <div onClick={handleAccordionOpen}>
      <Accordion title={device?.nickname || device.name}>
        <div className={styles.VBMDevice__container}>
          {isFetching && <p>{t("fetching")}</p>}
          {!isFetching && (
            <Table
              id={id}
              headers={[
                t("positionCol"),
                t("idCol"),
                t("nameCol"),
                t("manufacturerCol"),
                t("minCol"),
                t("maxCol"),
                t("lotCol"),
                t("expirationCol"),
                t("qohCol")
              ]}
              records={records}
              locale={{emptyText: t("noDataLabel")}}
            />
          )}
        </div>
      </Accordion>
    </div>
  );
};

export default VBMDevice;
