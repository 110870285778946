import { Line } from 'react-konva';
import { pxToMm } from "../functions/converters.js";
import { useRef } from "react";
import { calculateBoundingBox } from "../functions/arithmetic.js";



const ResizeLine = ({ id, parentId, points, orientation, dimension, setDimension, currentIndex, updateObject, selectObject, attrs })  => {  
  const lineRef = useRef(null);
  const initialPosition = useRef(null);

  const resizedParentObject = (e, parent) => {
    let deltaY, deltaX;

    if (orientation === "horizontal") {
      deltaY = pxToMm(e.target.y() - initialPosition.current);
      if (currentIndex === 0) {
        parent.heights = [
          parent.heights[currentIndex] - deltaY,
          ...parent.heights.slice(currentIndex + 1),
        ];
        parent.y += deltaY;
      } else if (currentIndex === parent.heights.length) {
        parent.heights = [
          ...parent.heights.slice(0, currentIndex - 1),
          parent.heights[currentIndex - 1] + deltaY,
        ];
      } else {
        parent.heights = parent.heights.map((height, index) => {
          if (index === currentIndex - 1) {
            return height + deltaY;
          } else if (index === currentIndex) {
            return height - deltaY;
          } else {
            return height;
          }
        });
      }
      parent.height = parent.heights.reduce((sum, a) => sum + a);
    } else if (orientation === "vertical") {
      deltaX = pxToMm(e.target.x() - initialPosition.current);
      if (currentIndex === 0) {
        const newWidths = [
          parent.widths[currentIndex] - deltaX,
          ...parent.widths.slice(currentIndex + 1),
        ];
        parent.widths = newWidths;
        parent.x += deltaX;
      } else if (currentIndex === parent.widths.length) {
        parent.widths = [
          ...parent.widths.slice(0, currentIndex - 1),
          parent.widths[currentIndex - 1] + deltaX,
        ];
      } else {
        parent.widths = parent.widths.map((width, index) => {
          if (index === currentIndex - 1) {
            return width + deltaX;
          } else if (index === currentIndex) {
            return width - deltaX;
          } else {
            return width;
          }
        });
      }
      parent.width = parent.widths.reduce((sum, a) => sum + a);
    }
    return parent;
  }

  const resizeNodeHorizontal = (e) => {
    e.target.x(0);

    let deltaY    
    deltaY = e.evt.movementY;

    let maxY, minY
    const parallelLines = e.target.parent.children.filter(
      (o) =>
        o.attrs.objectType === "resizeLine" &&
        o.attrs.orientation === "horizontal"
    );
    if (!currentIndex) {
      const maxWall = parallelLines.find(o => o.attrs.currentIndex === currentIndex + 1)
      maxY = maxWall.points()[1] - 5
    } else if (currentIndex === parallelLines.length - 1) {
      const minWall = parallelLines.find(o => o.attrs.currentIndex === currentIndex - 1)
      minY = minWall.points()[1] - e.target.points()[1] + 5
    } else {
      const minWall = parallelLines.find(o => o.attrs.currentIndex === currentIndex - 1)
      minY = minWall.points()[1] - e.target.points()[1] + 5
      const maxWall = parallelLines.find(o => o.attrs.currentIndex === currentIndex + 1)
      maxY = maxWall.points()[1] - e.target.points()[1] - 5
    }

    if (e.target.y() + deltaY > maxY) {
      deltaY = 0;
      e.target.y(maxY)
    }
  
    if (e.target.y() + deltaY < minY) {
      deltaY = 0;
      e.target.y(minY)
    }
  
    const perpendicularLines = e.target.parent.children.filter(
      (o) =>
        o.attrs.objectType === "resizeLine" &&
        o.attrs.orientation === "vertical"
    );    
    perpendicularLines.forEach((line) => {
      const points = line.points()
      if (!currentIndex) {
        points[1] = e.target.y()
      } else if (currentIndex === parallelLines.length - 1) {
        points[3] = e.target.y() + e.target.points()[1]
      }
    })
    
    const nodes = e.target.parent.children.filter(
      (o) =>
        o.attrs.objectType !== "resizeLine" &&
        o.attrs.objectType !== "gridLayout" &&
        o.attrs.row === currentIndex
    );  
    nodes.forEach((node) => {
      node.y(e.target.y() + e.target.points()[1] - node.attrs.pxDeltaY)
    })

  }

  const resizeNodeVertical = (e) => {
    e.target.y(0);

    let deltaX    
    deltaX = e.evt.movementX;

    let maxX, minX
    const parallelLines = e.target.parent.children.filter(
      (o) =>
        o.attrs.objectType === "resizeLine" &&
        o.attrs.orientation === "vertical"
    );
    if (!currentIndex) {
      const maxWall = parallelLines.find(o => o.attrs.currentIndex === currentIndex + 1)
      maxX = maxWall.points()[0] - 5
    } else if (currentIndex === parallelLines.length - 1) {
      const minWall = parallelLines.find(o => o.attrs.currentIndex === currentIndex - 1)
      minX = minWall.points()[0] - e.target.points()[0] + 5
    } else {
      const minWall = parallelLines.find(o => o.attrs.currentIndex === currentIndex - 1)
      minX = minWall.points()[0] - e.target.points()[0] + 5
      const maxWall = parallelLines.find(o => o.attrs.currentIndex === currentIndex + 1)
      maxX = maxWall.points()[0] - e.target.points()[0] - 5
    }

    if (e.target.x() + deltaX > maxX) {
      deltaX = 0;
      e.target.x(maxX)
    }
  
    if (e.target.x() + deltaX < minX) {
      deltaX = 0;
      e.target.x(minX)
    }
  
    const perpendicularLines = e.target.parent.children.filter(
      (o) =>
        o.attrs.objectType === "resizeLine" &&
        o.attrs.orientation === "horizontal"
    );    
    perpendicularLines.forEach((line) => {
      const points = line.points()
      if (!currentIndex) {
        points[0] = e.target.x()
      } else if (currentIndex === parallelLines.length - 1) {
        points[2] = e.target.x() + e.target.points()[0]
      }
    })
    
    const nodes = e.target.parent.children.filter(
      (o) =>
        o.attrs.objectType !== "resizeLine" &&
        o.attrs.objectType !== "gridLayout" &&
        o.attrs.col === currentIndex
    );  
    nodes.forEach((node) => {
      node.x(e.target.x() + e.target.points()[0] - node.attrs.pxDeltaX)
    })
  }

  const handleDragStart = (e) => {
    if (e.target.parent.attrs.locked) {
      e.evt.cancelBubble = true
      e.target.stopDrag()
    }
    else if (orientation === "horizontal") {
      initialPosition.current = e.target.y();
    } else {
      initialPosition.current = e.target.x();
    }
  };

  const handleDragMove = (e) => {
    if (e.target.parent.attrs.locked) {
      return
    }     
    if (orientation === "horizontal") {
      resizeNodeHorizontal(e)
    } else {      
      resizeNodeVertical(e)
    }
  };

  const handleDragEnd = (e) => {
    if (e.target.parent.attrs.locked) {
      return
    }
    const parent = selectObject(parentId)
    const newGridLayout = resizedParentObject(e, { ...parent });
    if (newGridLayout.parent && newGridLayout.parent.type === "group") {
      const newGroup = {...newGridLayout.parent}    
      newGroup.objects = newGroup.objects.map((o) => o.id === newGridLayout.id ? newGridLayout : o)
      
      const bbox = calculateBoundingBox(newGroup.objects)
      newGroup.x += bbox.x
      newGroup.y += bbox.y
      newGroup.width = bbox.width
      newGroup.height = bbox.height        
      newGroup.objects = newGroup.objects.map(o => ({
        ...o,
        x: o.x - bbox.x,
        y: o.y - bbox.y,
        parent: newGroup,
      }))
      updateObject(newGroup)
    } else {
      updateObject(newGridLayout) 
    }
    
  }
  
  return (
    <Line
      ref={lineRef}
      objectType={"resizeLine"}
      key={id}
      id={id}
      points={points}
      orientation={orientation}
      stroke="black"
      strokeWidth={1}
      strokeScaleEnabled={false}
      dash={[10, 10]}
      hitStrokeWidth={5}
      draggable={true}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      {...(attrs ? attrs : {})}
    />
  );
};

export default ResizeLine;