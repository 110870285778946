import React, { useContext, useEffect, useState } from "react";

import TrayList from "./TrayList";
import TabBar from "../../../components/TabBar";
import Accordion from "../../../components/Accordion/Accordion";

import RoleCheckService from "../../../services/RoleCheckService";
import { InventoryContext } from "../../../contexts/InventoryContext";

import styles from "./TruPakDevice.module.css";
import Alarms from "../Alarms/Alarms";
import TruPakStatus from './TruPakStatus';
import TruPakInventory from './TruPakInventory';
import { useTranslation } from "react-i18next";

const TruPakDevice = ({ user, site, device, dataFromDashboard }) => {  
  const { t } = useTranslation("trupakDevice")

  const { selectedDevice, updateSelectedDevice } = useContext(InventoryContext);

  const [tabs, setTabs] = useState([]);
  const [Views, setViews] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [accordionOpen, setAccordionOpen] = useState(
    !!selectedDevice && selectedDevice._id === device._id
  );

  useEffect(() => {
    setAccordionOpen(!!selectedDevice && selectedDevice._id === device._id);
  }, [selectedDevice, device]);

  useEffect(() => {
    if (accordionOpen) updateSelectedDevice(device);
  }, [accordionOpen]);

  useEffect(() => {
    if (accordionOpen) {
      const tempTabs = [];
      let tabIndex = 0;
      let TempViews = [];

      const getTabs = () => {
        if (RoleCheckService.viewDeviceOrders(user)) {
          tempTabs.push({
            index: tabIndex,
            text: t("traysTab"),
          });

          TempViews.push(
            <TrayList key={tabIndex} user={user} site={site} device={device} />
          );
          tabIndex++;
        }
        // replace 'false' with test condition
        if (RoleCheckService.viewDeviceOrders(user) && false) {
          tempTabs.push({
            index: tabIndex,
            text: `${t("numberedTab", {number: tabIndex + 1})}`,
          });
          TempViews.push(<div key={tabIndex}>View for Tab {tabIndex + 1}</div>);
          tabIndex++;
        }
        // replace 'false' with test condition
        if (RoleCheckService.viewDeviceOrders(user) && false) {
          tempTabs.push({
            index: tabIndex,
            text: `${t("numberedTab", {number: tabIndex + 1})}`
          });
          TempViews.push(<div key={tabIndex}>View for Tab {tabIndex + 1}</div>);
          tabIndex++;
        }

        if(RoleCheckService.viewDeviceAlarms(user)){
          tempTabs.push({
            index: tabIndex,
            text: t("alarmsTab")
          });
          TempViews.push(<Alarms user={user} stockLocation={device} />);
          tabIndex++;
        }

        if(RoleCheckService.viewDeviceOrders(user)){
          tempTabs.push({
            index: tabIndex,
            text: t("statusTab")
          });
          TempViews.push(<TruPakStatus user={user} stockLocation={device} />);
          tabIndex++;
        }

        if(RoleCheckService.viewInventory(user)){
          tempTabs.push({
            index: tabIndex,
            text: t("canistersTab")
          });
          TempViews.push(<TruPakInventory user={user} stockLocation={device} />);
          tabIndex++;
        }

        return tempTabs;
      };
      setTabs(getTabs());

      if (TempViews.length) {
        setViews(TempViews);
      }
    } else {
      setViews([]);
    }
  }, [site, accordionOpen]);

  const getAccordionState = (boolVal) => {
    setAccordionOpen(boolVal);
  };

  return (
    <Accordion
      title={device?.nickname || device.name}
      openByDefault={
        !!dataFromDashboard ||
        (!!selectedDevice && selectedDevice._id === device._id)
      }
      onGetAccordionState={(val) => getAccordionState(val)}
    >
      <div className={styles.EV54Device__header}>
        <div className={styles.EV54Device__tabContainer}>
          {Views.length ? (
            <TabBar
              tabs={tabs}
              onSelectedTab={(tabIndex) => {
                setSelectedTabIndex(tabIndex);
              }}
              dynamicActiveIndex={
                dataFromDashboard ? dataFromDashboard.selectedTabIndex : null
              }
            />
          ) : null}
        </div>
      </div>
      <br />
      {Views.length ? Views[selectedTabIndex] : <div>{t("noTabCriteria")}</div>}
    </Accordion>
  );
};

export default TruPakDevice;
