import DispensingOrderService from "../../../services/DispensingOrderService";

class PendingDispensingOrdersController {
  constructor(user) {
    this.user = user;
  }

  getDetails = async (dispensingOrderIds, site) => {
    return await DispensingOrderService.getDetails(
      this.user,
      site,
      dispensingOrderIds
    );
  };

  clearError = async (dispensingOrder, site) => {
    return await DispensingOrderService.clearError(
      this.user,
      site,
      dispensingOrder
    );
  };

  approveOrder = async (dispensingOrder, site) => {
    await DispensingOrderService.resetOrder(
      this.user,
      site,
      dispensingOrder,
      "pending"
    );
  };

  rejectOrder = async (dispensingOrder) => {
    await DispensingOrderService.delete(
      this.user,
      dispensingOrder.site,
      dispensingOrder
    );
  };

  productAdded = async (dispensingOrderItem, product) => {
    await DispensingOrderService.addProduct(
      this.user,
      dispensingOrderItem,
      product
    );
  };

  substituteProduct = async (
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    await DispensingOrderService.substituteProduct(
      this.user,
      originalDispensingOrderItem,
      modifiedDispensingOrderItem
    );
  };

  updateAdminDateTime = async (
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    await DispensingOrderService.updateAdminDateTime(
      this.user,
      originalDispensingOrderItem,
      modifiedDispensingOrderItem
    );
  };

  updateOrderPriority = async (
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    await DispensingOrderService.updateOrderPriority(
      this.user,
      originalDispensingOrderItem,
      modifiedDispensingOrderItem
    );
  };

  updateQuantity = async (
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    await DispensingOrderService.updateQuantity(
      this.user,
      originalDispensingOrderItem,
      modifiedDispensingOrderItem
    );
  };

  updatePatientLocation = async (
    dispensingOrder,
    patientId,
    room
  ) => {
    await DispensingOrderService.updatePatientLocation(
      this.user,
      dispensingOrder,
      patientId,
      room
    );
  };

  updatePatientLanguage = async (
    dispensingOrder,
    patientId,
    language
  ) => {
    await DispensingOrderService.updatePatientLanguage(
      this.user,
      dispensingOrder,
      patientId,
      language
    );
  };

  splitOrder = async (originalDispensingOrderIds, newDispensingOrders) => {
    await DispensingOrderService.splitOrder(
      this.user,
      originalDispensingOrderIds,
      newDispensingOrders
    );
  };

  deleteDispensingOrderItem = async (dispensingOrderItem) => {
    return await DispensingOrderService.deleteDispensingOrderItem(
      this.user,
      dispensingOrderItem
    );
  };
}

export default PendingDispensingOrdersController;
