import { useState, useEffect, useCallback } from "react";
import MetricsChartView from "./MetricsChartView";
import DispensedMedicationsTable from "./DispensedMedicationsTable";
import SelectTrayPillsForCanister from "./SelectTrayPillsForCanister";
import backIcon from "../../../../../assets/back_icon.svg";
import AntTabs from "../../../../../components/Ant/AntTabs/AntTabs";
import {
  getTimeRangeCurrentWeek,
  getTimeRangeWeek,
} from "../../../../../functions/dates";
import styles from "./MedicationsDispensed.module.css";
const MedicationsDispensed = ({
  user,
  selectedDevices,
  selectedSites,
  onShowMedicationsDispensed,
  showCanisterMedicationsDispensed,
  onShowCanisterMedicationsDispensed,
  onShowTrayMedicationsDispensed,
  onShowDropdowns,
}) => {
  const [canisterProducts, setCanisterProducts] = useState([]);
  const [canisterProductsSelectedDay, setCanisterProductsSelectedDay] =
    useState([]);
  const [trayProducts, setTrayProducts] = useState([]);
  const [trayProductsSelectedDay, setTrayProductsSelectedDay] = useState([]);
  const [activeKey, setActiveKey] = useState("tray");
  const [startDateCurrentWeek, setStartDateCurrentWeek] = useState(null);
  const [stopDateCurrentWeek, setStopDateCurrentWeek] = useState(null);
  const [startDateLastWeek, setStartDateLastWeek] = useState(null);
  const [stopDateLastWeek, setStopDateLastWeek] = useState(null);
  const [rowStatus, setRowStatus] = useState({});
  const [selectedDayTray, setSelectedDayTray] = useState(null);
  const [selectedDayCanister, setSelectedDayCanister] = useState(null);
  const [showCanisterPlacementPopup, setShowCanisterPlacementPopup] =
    useState(false);

  useEffect(() => {
    const dateRangeObjectCurrentWeek = getTimeRangeCurrentWeek();
    setStartDateCurrentWeek(dateRangeObjectCurrentWeek.startDate);
    setStopDateCurrentWeek(dateRangeObjectCurrentWeek.stopDate);
    const dateRangeObject = getTimeRangeWeek();
    setStartDateLastWeek(dateRangeObject.startDate);
    setStopDateLastWeek(dateRangeObject.stopDate);
  }, []);

  useEffect(() => {
    if (showCanisterMedicationsDispensed) {
      setActiveKey("canister");
    } else {
      setActiveKey("tray");
    }
    setSelectedDayTray(null);
    setSelectedDayCanister(null);
  }, [showCanisterMedicationsDispensed]);

  const handleTrayProducts = useCallback((trayProductsArray) => {
    setTrayProducts(trayProductsArray);
  }, []);

  const handleCanisterProducts = useCallback((canisterProductsArray) => {
    setCanisterProducts(canisterProductsArray);
  }, []);

  const handleRowStatus = useCallback((rowObject) => {
    setRowStatus(rowObject);
  }, []);

  const handleShowCanisterPlacementPopup = useCallback((boolVal) => {
    setShowCanisterPlacementPopup(boolVal);
  }, []);

  const tabItems = [
    { key: "tray", label: "Tray medications" },
    { key: "canister", label: "Canister medications" },
  ];

  const handleTabChange = (key) => {
    if (key === "canister") {
      onShowTrayMedicationsDispensed(false);
      onShowCanisterMedicationsDispensed(true);
    } else {
      onShowTrayMedicationsDispensed(true);
      onShowCanisterMedicationsDispensed(false);
    }
  };

  const handleSelectedDayTray = (day) => {
    setSelectedDayTray(day);
  };

  const handleSelectedDayCanister = (day) => {
    setSelectedDayCanister(day);
  };

  const handleTrayProductsSelectedDay = (trayProductsArray) => {
    setTrayProductsSelectedDay(trayProductsArray);
  };

  const handleCanisterProductsSelectedDay = (canisterProductsArray) => {
    setCanisterProductsSelectedDay(canisterProductsArray);
  };

  const handleGoBack = () => {
    onShowMedicationsDispensed(false);
    onShowCanisterMedicationsDispensed(false);
    onShowTrayMedicationsDispensed(false);
    onShowDropdowns(true);
  };

  return (
    <div className={styles.MedicationsDispensed__medicationsContainer}>
      {!showCanisterPlacementPopup && (
        <div>
          <div className={styles.MedicationsDispensed__pageTitleContainer}>
            <div className={styles.MedicationsDispensed__backButton}>
              <img src={backIcon} alt="" onClick={handleGoBack} />
            </div>
            <h2 className={styles.MedicationsDispensed__pageTitle}>
              Medications Dispensed
            </h2>
          </div>

          <AntTabs
            tabsStyle={"ant-tabs-000"}
            defaultActiveKey="tray"
            activeKey={activeKey}
            tabItems={tabItems}
            onChange={handleTabChange}
          />
        </div>
      )}
      <div
        className={
          showCanisterPlacementPopup
            ? styles["MedicationsDispensed__medicationsGroup--fullHeight"]
            : styles.MedicationsDispensed__medicationsGroup
        }
      >
        <div
          className={
            showCanisterPlacementPopup
              ? styles["MedicationsDispensed__MetricsChartView--hide"]
              : styles["MedicationsDispensed__MetricsChartView--show"]
          }
        >
          <MetricsChartView
            user={user}
            activeKey={activeKey}
            selectedDayTray={selectedDayTray}
            onSelectedDayTray={handleSelectedDayTray}
            selectedDayCanister={selectedDayCanister}
            onSelectedDayCanister={handleSelectedDayCanister}
            selectedDevices={selectedDevices}
            selectedSites={selectedSites}
            startDateLastWeek={startDateLastWeek}
            stopDateLastWeek={stopDateLastWeek}
            startDateCurrentWeek={startDateCurrentWeek}
            stopDateCurrentWeek={stopDateCurrentWeek}
            onTrayProducts={handleTrayProducts}
            onTrayProductsSelectedDay={handleTrayProductsSelectedDay}
            onCanisterProducts={handleCanisterProducts}
            onCanisterProductsSelectedDay={handleCanisterProductsSelectedDay}
            rowStatus={rowStatus}
            onRowStatus={handleRowStatus}
            onShowDropdowns={onShowDropdowns}
          />
        </div>
        {showCanisterPlacementPopup && (
          <div
            className={
              styles.MedicationsDispensed__SelectTrayPillsForCanisterContainer
            }
          >
            <SelectTrayPillsForCanister
              user={user}
              rowStatus={rowStatus}
              onShowCanisterPlacementPopup={handleShowCanisterPlacementPopup}
              trayProducts={trayProducts}
              trayProductsSelectedDay={trayProductsSelectedDay}
              selectedDayTray={selectedDayTray}
              startDateLastWeek={startDateLastWeek}
              stopDateLastWeek={stopDateLastWeek}
            />
          </div>
        )}
      </div>

      {!showCanisterPlacementPopup && (
        <DispensedMedicationsTable
          user={user}
          activeKey={activeKey}
          canisterProductsSelectedDay={canisterProductsSelectedDay}
          canisterProducts={canisterProducts}
          startDateLastWeek={startDateLastWeek}
          stopDateLastWeek={stopDateLastWeek}
          selectedDayTray={selectedDayTray}
          selectedDayCanister={selectedDayCanister}
          rowStatus={rowStatus}
          onRowStatus={handleRowStatus}
          trayProductsSelectedDay={trayProductsSelectedDay}
          trayProducts={trayProducts}
          onShowCanisterPlacementPopup={handleShowCanisterPlacementPopup}
        />
      )}
    </div>
  );
};

export default MedicationsDispensed;
