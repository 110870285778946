import styles from "../LabelEditor.module.css";
import { ReactComponent as BarcodeSVG } from "../svgs/barcode2.svg";
import { ReactComponent as ImageSVG } from "../svgs/image.svg";
import { ReactComponent as RectSVG } from "../svgs/rectangle2.svg";
import { ReactComponent as RepeaterSVG } from "../svgs/repeater.svg";
import { ReactComponent as TextSVG } from "../svgs/text.svg";
import { ReactComponent as GroupSVG } from "../../../assets/group-items.svg";
import { ReactComponent as GridLayoutSVG } from "../../../assets/grid-layout.svg";
import { ReactComponent as LockedSVG } from "../../../assets/lock-closed.svg";
import { ReactComponent as UnlockedSVG } from "../../../assets/lock-open.svg";
import { ReactComponent as VisibleSVG } from "../../../assets/visible.svg";
import { ReactComponent as InvisibleSVG } from "../../../assets/invisible.svg";
import { ReactComponent as DragHandleSVG } from "../../../assets/drag-handle.svg";
import { memo } from "react";
import { hasAncestorType } from "../functions/objects";

const LayerItem = ({ item, depth, isDragging, updateObject, selectedId, setSelectedId, dragHandleProps, isDropTarget, dropTargetDepth, viewerMode }) => {
  const Icon = memo(({ obj }) => {
    let IconComponent;

    switch (obj.type) {
      case "barcode":
        IconComponent = BarcodeSVG;
        break;
      case "image":
        IconComponent = ImageSVG;
        break;
      case "rect":
        IconComponent = RectSVG;
        break;
      case "repeater":
        IconComponent = RepeaterSVG;
        break;
      case "text":
        IconComponent = TextSVG;
        break;
      case "group":
        IconComponent = GroupSVG;
        break;
      case "gridLayout":
        IconComponent = GridLayoutSVG;
        break;
      default:
        IconComponent = null;
    }

    return (
      <div
        id={`icon-${obj.id}`}
        className={`
          ${styles.LabelEditor__svgContainer}
          ${viewerMode ? styles.LabelEditor__disabled : styles.LabelEditor__clickable}
          ${
            obj.type === "text"
              ? styles.LabelEditor__objectExplorerTextSVGContainer
              : obj.type === "image"
              ? styles.LabelEditor__objectExplorerImageSVGContainer
              : styles.LabelEditor__objectExplorerSVGContainer
          }
        `}  
        onClick={!viewerMode ? (e) => setSelectedId(item.id) : undefined}   
      >
        {IconComponent && <IconComponent />}
      </div>
    );
  });

  const LockToggle = memo(({ obj }) => (
    <div
      className={`${styles.LabelEditor__layerButton} ${styles.LabelEditor__clickable}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        updateObject({
          ...obj,
          locked: !obj.locked,
        });
      }}
    >
      {obj?.locked ? (
        <LockedSVG fill={"var(--rhumbaOrange)"} />
      ) : (
        <UnlockedSVG fill={"var(--green)"} />
      )}
    </div>
  ));

  const VisibleToggle = memo(({ obj }) => (
    <div
      className={`${styles.LabelEditor__layerButton} ${styles.LabelEditor__clickable}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        updateObject({
          ...obj,
          visible: !obj.visible,
        });
      }}
    >
      {obj?.visible ? (
        <VisibleSVG fill={"var(--green)"} />
      ) : (
        <InvisibleSVG fill={"var(--rhumbaOrange)"} />
      )}
    </div>
  ));

  return (
    <>
      <div 
        style={{ 
          paddingLeft: `${depth * 20 + (viewerMode || hasAncestorType(item, "gridLayout") ? 18 : 0)}px`, 
          opacity: isDragging ? 0.5 : 1,
          height: '30px', 
          //backgroundColor: isDropTarget ? 'rgba(0, 0, 255, 0.1)' : 'transparent'
        }}
        className={`${styles.LabelEditor__layer} ${
          selectedId === item.id ? styles.LabelEditor__selectedLayer : ""
        }`}
      >      
        {!hasAncestorType(item, "gridLayout") && !viewerMode ? <div 
          className={`${styles.LabelEditor__layerButton} ${styles.LabelEditor__movable}`} {...dragHandleProps}
        >
          <DragHandleSVG />
        </div> : null}      
        <div 
          className={`
            ${styles.LabelEditor__layerName}
            ${viewerMode ? styles.LabelEditor__disabled : styles.LabelEditor__clickable}
          `}  
          onClick={!viewerMode ? (e) => setSelectedId(item.id) : undefined}          
        >
          <Icon obj={item} />
        </div>
        <div 
          className={`
            ${styles.LabelEditor__layerName}
            ${viewerMode ? styles.LabelEditor__disabled : styles.LabelEditor__clickable}
          `}  
          onClick={!viewerMode ? (e) => setSelectedId(item.id) : undefined}  
        >
          {item.displayName}
        </div>
        {!viewerMode ? <div 
          className={styles.LabelEditor__layerOptions}
        >
          <VisibleToggle obj={item} />     
          <LockToggle obj={item} />            
        </div> : null}   
      </div>  
      {item.objects && (hasAncestorType(item, "gridLayout", true) || isDragging) ? (
        item.objects.map(gridItem => (
          <LayerItem 
            key={`layerItem-${gridItem.id}`}
            item={gridItem}
            depth={gridItem.depth}
            isDragging={isDragging}
            updateObject={updateObject}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            viewerMode={viewerMode}
          />    
      ))) : null} 
    </>
  );
};

export default LayerItem;
