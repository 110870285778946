import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import LabelEditor from "./LabelEditor";
import PrintLabels from "./PrintLabels";
import TextInput from "../../components/TextInput";
import SFDropDownList from "../../components/SFDropDownList/SFDropDownList";
import CreateButton from "../../components/CreateButton";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import SearchBar from "../../components/SearchBar";
import LabelController from "./LabelController";
import LabelTile from "../../components/LabelTile";
import ModalBackdrop from "../../components/Modal";
import styles from "./CreateLabel.module.css";
import { Rings } from "react-loader-spinner";

const CreateLabel = ({ user, onSelectedTab, onShowTabBar }) => {
  const [createLabel, setCreateLabel] = useState(false);
  const [showLabelCanvas, setShowLabelCanvas] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [labelType, setLabelType] = useState("Canister");
  const [machineType, setMachineType] = useState("TruCard");
  const [customizedLabel, setCustomizedLabel] = useState("");
  const [labels, setLabels] = useState(null);
  const [labelsNameAndAccount, setLabelsNameAndAccount] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [clickedLabel, setClickedLabel] = useState(null);
  const [nodesArray, setNodesArray] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [nameError, setNameError] = useState("");
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [showMigrateModal, setShowMigrateModal] = useState(false);

  const preloaded = useRef(false)

  const [objects, setObjects] = useState([]);
  const [history, setHistory] = useState([[]]);

  const [presetId, setPresetId] = useState(null);
  const [labelPresets, setLabelPresets] = useState(null)

  const _controllerRef = useRef(null);
  const { t } = useTranslation("labels");


  useEffect(() => {
    if (selectedLabel) {
      setLabelName(selectedLabel?.name || "");
      setLabelType(selectedLabel?.labelType || "Canister");
      setMachineType(selectedLabel?.machineType || "TruCard");
      setCustomizedLabel(selectedLabel?.customizedLabel || "");
      setPresetId(selectedLabel?.presetId || null)
    }
  }, [selectedLabel]);

  useEffect(() => {
    if (!showLabelCanvas && !presetId && labelPresets) {
      setPresetId(labelPresets.find((preset) => preset.name === "Default")._id)
    } else if (presetId && labelPresets && !labelPresets.find((preset) => preset._id === presetId)) {
      // Re-assign default if DB lost preset
      const defaultPreset = labelPresets.find((preset) => preset.name === "Default")
      if (defaultPreset) {
        setPresetId(defaultPreset._id)
      } else {
        createDefaultLabelPreset().then((preset) => {
          if (preset) {
            setPresetId(preset._id)
          } else {
            console.log("error: failed to create default");
          }          
        })
      }
    }
  }, [showLabelCanvas, presetId, labelPresets])

  useEffect(() => {
    if (createLabel) {
      onShowTabBar(false);
    } else {
      onShowTabBar(true);
    }
  }, [createLabel, onShowTabBar]);

  useEffect(() => {
    getLabels();
    getLabelPresets();
  }, [refreshToggle]);

  useEffect(() => {
    if (selectedLabel && nodesArray?.length) {
      setCreateLabel(true);
    }
  }, [selectedLabel, nodesArray]);

  const handleRefreshToggle = (prevState) => setRefreshToggle(!prevState);

  const actionListItems = [
    {
      type: "modify",
      description: t("action0"),
    },
    {
      type: "copy",
      description: t("action2"),
    },
    {
      type: "delete",
      description: t("action1"),
    },
    // {
    //   type: "standardize",
    //   description: "standardize",
    // },
  ];

  const getActionMenu = () => {
    let listItems = [...actionListItems];
    return listItems;
  };

  // const getStandardActionMenu = (reportObj) => {
  //   let listItems = [];

  //   if (RoleCheckService.canCreateReports(user)) {
  //     listItems = [...actionListItems];
  //     // remove modify & delete
  //     let index = listItems.findIndex((o) => o.type === "modify");
  //     listItems.splice(index, 1);
  //     index = listItems.findIndex((o) => o.type === "delete");
  //     listItems.splice(index, 1);
  //   }
  //   return listItems;
  // };

  const handleAction = async (action, label) => {
    switch (action.type) {
      case "modify":
        handleSelectedLabel(label);
        handleNodesArray(label);
        break;
      case "delete":
        handleDeleteLabel(label);
        break;
      case "copy":
        handleCopyLabel(label);
        break;
      default:
        break;
    }
  };

  const arrayContainsObject = (obj, array) => {
    return array.some((elem) => elem === obj);
  };

  const buildNodesArray = (node, parent, array = []) => {
    // Grandfathered objects builder from label template

    for (let i = 0; i < node["childNodes"]?.length; i++) {
      buildNodesArray(node["childNodes"][i], node, array); // Traverse child nodes
    }
    if (!arrayContainsObject(node, array)) {
      if (parent) {
        node.parent = parent;
      }
      array.push(node);

      // sort nodesArrayTemp by nodeId, ascending order
      array.sort((a, b) => {
        return a.nodeId - b.nodeId;
      });
    }
    // BASE CASE
    return array;
  };

  const MigrateModal = (
    <ModalBackdrop
      width="100%"
      height="100%"
      top="0"
      left="0"
      padding="0"
      showButton={false}
      backgroundColor="#98a9bc4d"
      borderRadius="0"
    >
      <div className={styles.CreateLabel__deleteWarningModal}>
        <p className={styles.CreateLabel__deleteWarningModalMessage}>
          {t("migrateWarning")}
        </p>
        <div className={styles.CreateLabel__ModalButtonsGroup}>
          <Button
            labelName={t("cancelButtonLabel")}
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => {
              handleResetCreateLabelStatesToDefaults()
              setShowMigrateModal(false)
            }}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName={t("continueButtonLabel")}
            minWidth={"123px"}
            isPrimary={true}
            onClick={() => {
              setShowMigrateModal(false)
            }}
          />
        </div>
      </div>
    </ModalBackdrop>
  );

  useEffect(() => {
    if (user) {
      _controllerRef.current = new LabelController(user);
      getLabels();
      getLabelPresets()
    }
  }, [user]);


  const getLabels = () => {
    if (!_controllerRef.current) return Promise.resolve();
    
    return _controllerRef.current.getLabels().then((results) => {
      if (results) {
        let tempLabels = [];
        results.labels.forEach((label) => {
          if (label?.template && label?.name) {
            tempLabels.push(label);
          }
        });
        setLabels(tempLabels);

        const tempLabelsNameAndAccount = tempLabels.map(
          (label) => `${label.name}/${label.account}`
        );

        setLabelsNameAndAccount(tempLabelsNameAndAccount);
      } else {
        console.log("error: ", results);
      }
    });
  };

  
  const getLabelPresets = () => {
    if (!_controllerRef.current) return Promise.resolve();
    
    return _controllerRef.current.getLabelPresets().then((results) => {
      if (results) {
        if (!results.some((preset) => preset.name === "Default")) {
          createDefaultLabelPreset().then((defaultPreset) => {
            if (defaultPreset) {
              setLabelPresets(
                [...results, defaultPreset].map((preset) => ({
                  ...preset,
                  Item: preset._id,
                  Value: preset.name,
                }))
              );
            } else {
              console.log("error: failed to create default");
            }
          });
        } else {
          setLabelPresets(
            results.map((preset) => ({
              ...preset,
              Item: preset._id,
              Value: preset.name,
            }))
          );
        }
      } else {
        console.log("error: ", results);
      }
    });
  };

  const createDefaultLabelPreset = () => {
    if (!_controllerRef.current) return Promise.resolve();

    return _controllerRef.current
      .createLabelPreset(
        "Default",
        101.6,
        50.8,
        215.9,
        279.4,
        5,
        2,
        false,
        4.7625,
        12.7,
        0,
        3.175
      )
      .then((result) => {
        return result
      })  
  }

  const handleCopyLabel = (label) => {
    setLabels((prev) => [
      ...prev,
      {
        action: "updating",
        name: t("copyLabel", {name: label.name}),
      }
    ]);

    const newLabelName = label.name + "-copy";
    const machineType = label?.machineType || "TruCard";
    const customizedLabel = label?.customizedLabel || "";

    _controllerRef.current
      .createLabel(
        newLabelName, 
        labelType,
        machineType, 
        label.sheetWidth,
        label.sheetHeight,
        label.labelWidth,
        label.labelHeight,
        label.rowCount,
        label.colCount,
        label.rowGap,
        label.colGap,
        label.leftMargin,
        label.topMargin,
        label.presetId,
        customizedLabel, 
        label.unit,
        label.template,
      )
      .then((result) => {
        if (result) {
          getLabels()
          getLabelPresets()
        } else {
          console.log("error: ", result);
        }
      });
  };

  const handleDeleteLabel = (label) => {
    setLabels((prev) =>
      prev.map((_label) =>
        _label._id === label._id
          ? {
              ..._label,
              action: "updating",
              name: t("deleteLabel", { name: label.name }),
            }
          : _label
      )
    );
    _controllerRef.current.deleteLabel(label).then((result) => {
      if (result) {
        getLabels()
        getLabelPresets()
      } else {
        console.log("error: ", result);
      }
    });
  };

  const handleNodesArray = (label) => {
    if (Array.isArray(label.template)) {
      setNodesArray(label.template);
    }  
    else {
      setNodesArray(buildNodesArray(label.template, null));
    }    
  };

  const handleSelectedLabel = (label) => {
    setSelectedLabel(label);
    handleNodesArray(label);
  };

  const handleResetCreateLabelStatesToDefaults = () => {
    preloaded.current = false
    setCreateLabel(false);
    setShowLabelCanvas(false);
    setLabelName("");
    setLabelType("Canister");
    setMachineType("TruCard");
    setCustomizedLabel("");
    setLabels(null);
    setSelectedLabel(null);
    setNodesArray([]);
    setPresetId(null)
    setLabelPresets(null)
    setObjects([])
    setHistory([[]])
    getLabels();
    getLabelPresets();
  };

  const handleLabelName = (name) => {
    const labelNameAccountString = `${name}/${user.account._id}`;
    if (labelsNameAndAccount.includes(labelNameAccountString)) {
      setNameError(t("existingLabelError"));
    } else if (nameError) {
      setNameError("");
    }
    setLabelName(name);
  };

  const dropdownListLabelType = [
    { Item: "Canister", Value: 1, displayName: t("canisterLabel") },
    { Item: "Other", Value: 2, displayName: t("otherLabel") },
  ];

  const dropdownListCanisterMachineType = [
    { Item: "TruCard", Value: 1 },
    { Item: "TruPak", Value: 2 },
    { Item: "TruScript", Value: 3 },
  ];

  const handleSelectedLabelType = (
    selectedLabelType
  ) => {
    setLabelType(selectedLabelType);
  };

  const handleSelectedCanisterMachineType = (
    selectedCanisterMachineType
  ) => {
    setMachineType(selectedCanisterMachineType);
  };

  const handleSelectedLabelPreset = (id) => {
    setPresetId(id)
  }

  const handleHideLabelCanvas = () => {
    setShowLabelCanvas(false);
  };

  const handleCreateLabel = () => {
    setSelectedLabel(null);
    setNodesArray([]);
    setCreateLabel(true);
    setLabelName("");
  };

  const handleEditLabel = () => {
    setShowLabelCanvas(true)
  }

  const handlePrevious = () => {
    handleResetCreateLabelStatesToDefaults()
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleClickedLabel = (label) => {
    if (!label.labelWidth) {
      setShowMigrateModal(true)
      handleSelectedLabel(label)
    } else {
      setCreateLabel(false);
      setSelectedLabel(false);
      handleNodesArray(label);
      setClickedLabel(label);
      onShowTabBar(false);
    }
  };

  const handleUnsetClickedLabel = () => {
    setClickedLabel(null);
  };

  if (!createLabel && !clickedLabel) {
    return (
      <div className={styles.CreateLabel__container}>
        <Fragment>
          <div className={styles.CreateLabel__header}>
            <SearchBar
              placeholder={t("searchPlaceholder")}
              onSearch={handleSearch}
              focus={true}
            />
            {labelPresets !== null && (
              <div
                className={styles.CreateLabel__createButtonContainer}
                onClick={handleCreateLabel}
              >
                <CreateButton
                  labelName={t("newButtonLabel")}
                  isEnabled={
                    labelName && labelName.trim().length ? true : false
                  }
                />
              </div>
            )}
          </div>

          <div
            className={`${styles.CreateLabel__labelsPage} ${styles["CreateLabel__labelsPage--custom"]}`}
          >
            <Spacer space={20} unit={"px"} />

            <div className={styles.CreateLabel__labelsRow}>
              <div className={styles.CreateLabel__labelsTitle}>
                {t("customLabelTitle")}
              </div>
            </div>

            <div className={styles.CreateLabel__labelsContainer}>
              {labels === null ? (
                <div>
                  <Rings
                    color="#089BAB"
                    height={110}
                    width={110}
                  />
                  <p className={styles.CreateLabel__labelsLoader}>
                    {t("fetching")}
                  </p>
                </div>
              ) : (
                labels
                  .filter((filter) => {
                    return filter.name.toUpperCase().includes(filterText) ||
                      filterText === ""
                      ? true
                      : false;
                  })
                  .map((label) =>
                    label.action === "updating" ? (
                      <LabelTile
                        key={label.name}
                        imageName={"fetching"}
                        imageFillColor={"#089BAB"}
                        title={label.name}
                        isTemplate={true}
                      />
                    ) : (
                      <LabelTile
                        key={label.name}
                        imageName={"barChart"}
                        imageFillColor={"#089BAB"}
                        title={label.name}
                        isTemplate={false}
                        actions={getActionMenu(label)}
                        onAction={(action) => {
                          handleAction(action, label);
                        }}
                        onSelectLabel={() => {
                          handleSelectedLabel(label);
                        }}
                        onClickedLabel={() => {
                          handleClickedLabel(label);
                        }}
                      />
                    )
                  )
              )}
            </div>
          </div>
        </Fragment>
      </div>
    );
  } else if (!createLabel && clickedLabel) {
    return (
      <PrintLabels
        user={user}
        label={clickedLabel}
        onPrevious={handleUnsetClickedLabel}
        onClickedLabel={handleClickedLabel}
        onRefreshToggle={handleRefreshToggle}
      />  
    );
  } else if (createLabel && !showLabelCanvas) {
    return (
      <Fragment>
        {showMigrateModal && MigrateModal}
        <div
          className={`${styles.CreateLabel__container} ${styles["CreateLabel__container--center"]}`}
        >
          <header>
            <p className={styles.CreateLabel__heading}>
              {selectedLabel ? t("updateTitle") : t("createTitle")}
            </p>
          </header>
          <div className={styles.CreateLabel__inputContainers}>
            <div className={styles.CreateLabel__labelNameContainer}>
              <div className={styles.CreateLabel__inputContainer}>
                <TextInput
                  value={labelName}
                  labelName={t("labelNameLabel")}
                  focus={true}
                  isEnabled={true}
                  onChange={(val) => handleLabelName(val)}
                  placeholder={""}
                  type={"text"}
                />
                {nameError ? (
                  <p className={styles.CreateLabel__nameError}>{nameError}</p>
                ) : null}
              </div>
            </div>
            <div className={styles.CreateLabel__labelTypeContainer}>
              <SFDropDownList
                id={"LabelType"}
                label={t("labelTypeLabel")}
                defaultValue={labelType}
                placeHolder={t("labelTypePlaceholder")}
                dropDownList={dropdownListLabelType}
                onSelection={handleSelectedLabelType}
                display={"displayName"}
              />
            </div>
            {labelType === "Canister" ? (
              <div className={styles.CreateLabel__machineTypeContainer}>
                <SFDropDownList
                  id={"CanisterMachineType"}
                  label={t("machineTypeLabel")}
                  defaultValue={machineType}
                  placeHolder={t("machineTypePlaceholder")}
                  dropDownList={dropdownListCanisterMachineType}
                  onSelection={handleSelectedCanisterMachineType}
                />
              </div>
            ) : null}

            {!selectedLabel ? (
              <div className={styles.CreateLabel__machineTypeContainer}>
                <SFDropDownList
                  id={"labelPreset"}
                  label={t("loadPresetLabel")}
                  defaultValue={presetId}
                  dropDownList={
                    labelPresets.sort((a, b) => {
                      if (a.name === "Default") return -1;
                      if (b.name === "Default") return 1;
                      return a.name.localeCompare(b.name);
                    })
                  }
                  display={"name"}
                  onSelection={handleSelectedLabelPreset}
                />
              </div>
            ) : null}
          </div>
          <div className={styles.CreateLabel__buttonsContainer}>
            <Button
              labelName={t("cancelButtonLabel")}
              minWidth={"123px"}
              isPrimary={false}
              onClick={() => handlePrevious()}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              labelName={t("continueButtonLabel")}
              isPrimary={true}
              minWidth={"213px"}
              onClick={handleEditLabel}
              isDisabled={
                !nameError && labelName && labelName.trim().length
                  ? false
                  : true
              }
            />
          </div>
        </div>
      </Fragment>
    );
  } else if (showLabelCanvas) {
    return (
      <div
        className={`${styles.CreateLabel__container} ${styles["CreateLabel__container--center"]}`}
      >
        <LabelEditor
          user={user}
          label={selectedLabel}
          objects={objects}
          setObjects={setObjects}
          history={history}
          setHistory={setHistory}
          labelPresets={labelPresets}
          setLabelPresets={setLabelPresets}
          presetId={presetId}
          setPresetId={setPresetId}
          onResetCreateLabelStatesToDefaults={
            handleResetCreateLabelStatesToDefaults
          }
          labelName={labelName.trim()}
          labelType={labelType}
          machineType={machineType}
          customizedLabel={customizedLabel}
          onHideLabelCanvas={handleHideLabelCanvas}
          _controllerRef={_controllerRef}
          nodesArrayForUpdate={nodesArray}
          preloaded={preloaded}
        />
      </div>
    );
  }
};

export default CreateLabel;
