import React from "react";
import "./DailyTotalBarChart.css";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  ColumnSeries,
  Category,
  Legend,
  Tooltip,
  DataLabel,
  Selection,
} from "@syncfusion/ej2-react-charts";

function DailyTotalBarChart({
  activeKey,
  data,
  chartHeight,
  chartWidth,
  columnWidth,
  columnSpacing,
  selectedDay,
  onSelectedDay,
  palette,
  onProductsSelectedDay,
  classNameForBargraph,
  onClassNameForBargraph,
  rowStatus,
  onRowStatus,
}) {
  const pointClick = (args) => {
    let tempRowStatus = { ...rowStatus };

    for (const [key, value] of Object.entries(tempRowStatus)) {
      if (value.selected) {
        value.selected = false;
      }
    }
    onRowStatus(tempRowStatus);

    let tempSelectedDay = null;

    if (selectedDay !== args.pointIndex) {
      tempSelectedDay = args.pointIndex;

      switch (tempSelectedDay) {
        case 0:
          onClassNameForBargraph("DailyTotalBarChart__SundaySelected");
          break;
        case 1:
          onClassNameForBargraph("DailyTotalBarChart__MondaySelected");
          break;
        case 2:
          onClassNameForBargraph("DailyTotalBarChart__TuesdaySelected");
          break;
        case 3:
          onClassNameForBargraph("DailyTotalBarChart__WednesdaySelected");
          break;
        case 4:
          onClassNameForBargraph("DailyTotalBarChart__ThursdaySelected");
          break;
        case 5:
          onClassNameForBargraph("DailyTotalBarChart__FridaySelected");
          break;
        case 6:
          onClassNameForBargraph("DailyTotalBarChart__SaturdaySelected");
          break;
        default:
          onClassNameForBargraph("DailyTotalBarChart__allDaysSelected");
      }
      onSelectedDay(tempSelectedDay);
      onProductsSelectedDay(args.pointIndex);
    } else {
      onSelectedDay(null);
      onProductsSelectedDay([]);
      onClassNameForBargraph("DailyTotalBarChart__allDaysSelected");
    }
  };

  const ChartTray = (
    <div className={classNameForBargraph}>
      <ChartComponent
        // delayUpdate={true}
        id={activeKey}
        key={activeKey}
        selectionMode="Point"
        palettes={palette}
        pointClick={pointClick}
        style={{ textAlign: "center", height: "300px", width: chartWidth }}
        primaryXAxis={{
          valueType: "Category",
          majorGridLines: { width: 0 },
          majorTickLines: { width: 0 },
          minorTickLines: { width: 0 },
        }}
        load={load.bind(this)}
        primaryYAxis={{
          majorGridLines: { width: 0 },
          majorTickLines: { width: 0 },
          lineStyle: { width: 0 },
          labelStyle: {
            color: "transparent",
          },
        }}
        legendSettings={{ visible: false }}
        // title="Daily Tray total"
        width="100%"
        loaded={onChartLoad.bind(this)}
      >
        <Inject
          services={[
            ColumnSeries,
            Category,
            Legend,
            Tooltip,
            DataLabel,
            Selection,
          ]}
        />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={data}
            xName="x"
            yName="y"
            type="Column"
            columnWidth={columnWidth}
            columnSpacing={columnSpacing}
            name="Dispensed"
            marker={{
              dataLabel: {
                visible: true,
                position: "Top",
                font: { size: "13px", fontWeight: "600", color: "#ffffff" },
              },
            }}
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );

  return ChartTray;
  function onChartLoad(args) {
    // let chart = document.getElementById(activeKey);
    // chart.setAttribute("title", "Daily Tray Total");
  }

  function load(args) {
    let selectedTheme = "Fluent2";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/contrast/i, "Contrast")
      .replace(/-highContrast/i, "HighContrast");
  }
}
export default DailyTotalBarChart;
