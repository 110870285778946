import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { useCallback, useEffect, useState } from "react";
import ProductFieldController from "../../../controllers/ProductFieldController";
import { useTranslation } from "react-i18next";

const MedListsDocument = ({ patients, meds }) => {
  const { t, i18n } = useTranslation("medList");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    Promise.all([
      i18n.loadNamespaces('medList'),
      i18n.loadLanguages(['en', 'fr', 'es'])
    ]).then(() => {
      setIsLoaded(true);
    });
  }, [i18n]);


  const title = t("outputFileName");
  const [patientMedLists, setPatientMedLists] = useState([]);

  const controller = new ProductFieldController();

  const convertToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const getTimeLabel = useCallback((time, timeCodes, language) => {
    const timeRanges = timeCodes.map((tc) => ({
      label: tc.label[language],
      startMinute: convertToMinutes(tc.startTime),
      endMinute: convertToMinutes(tc.stopTime),
    }));

    const [hours, minutes] = time.split(":").map(Number);
    const timeInMinutes = hours * 60 + minutes;

    const match = timeRanges.find((range) => {
      if (range.endMinute < range.startMinute) {
        return (
          timeInMinutes >= range.startMinute || timeInMinutes <= range.endMinute
        );
      }
      return (
        timeInMinutes >= range.startMinute && timeInMinutes <= range.endMinute
      );
    });

    return match ? match.label : "Unknown";
  }, []);

  useEffect(() => {
    setPatientMedLists(
      patients.map((patient) => ({
        ...patient,
        meds: meds
          .filter((med) => med.patientId === patient._id)
          .map((med) => ({
            ...med,
            adminLabels: med.adminTimes.map((time) =>
              getTimeLabel(
                time, 
                patient.facility.localizedTimeCodes,
                patient.language || patient.facility.defaultLanguage
              )
            ),
          })),
      }))
    );
  }, [patients, meds, getTimeLabel]);

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      padding: 20,
      fontFamily: "Helvetica",
    },
    title: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },

    row: {
      display: "flex",
      flexDirection: "row",
    },

    warning: {
      fontSize: 12,
      fontWeight: 700,
      width: "30%",
      textAlign: "center",
    },

    table: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    card: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    leftContent: {
      display: "flex",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "black",
      color: "white",
      justifyContent: "space-between",
      width: "100%",
      paddingVertical: 2,
      paddingHorizontal: 5,
    },
    section: {
      display: "flex",
      flexDirection: "column",
    },

    text: {
      regular: {
        fontSize: 10,
        fontFamily: "Helvetica",
      },
      large: {
        fontSize: 12,
        fontFamily: "Helvetica",
      },
      title: {
        fontSize: 18,
        fontFamily: "Helvetica-Bold",
        padding: 5,
        backgroundColor: "#E6DAA0",
      },
      subtitle: {
        fontSize: 10,
        fontFamily: "Helvetica-Bold",
        marginRight: 5,
      },
      description: {
        fontSize: 10,
        flex: 1,
        fontFamily: "Helvetica",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      nowrap: {
        whiteSpace: "nowrap",
        overflow: "ellipsis",
      },
      tilted: {},
    },
    timeBoxes: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: 4,
      marginLeft: "auto",
    },
    timeBox: {
      width: 34,
      height: 34,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      border: "1px solid #ccc",
      fontSize: 10,
      marginTop: 15,
      paddingTop: 12,
    },

    timeLabelsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      position: "relative",
      height: 40,
      gap: 4,
    },
    timeLabel: {
      position: "absolute",
      bottom: 0,
      width: 60,
      textAlign: "left",
      fontSize: 10,
      fontWeight: 500,
      transform: "rotate(-45deg)",
      transformOrigin: "bottom left",
      whiteSpace: "nowrap",
      overflow: "visible",
    },
    imageContainer: {
      width: 80,
      height: 34,
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      marginLeft: "auto",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
    },
  });

  if (!isLoaded || !patientMedLists) {
    return null;
  }
  

  return (
    <Document title={title}>
      {patientMedLists.map((patient) => {
        const language = patient.language || patient.facility.defaultLanguage;

        return (
          <Page
            size={"LETTER"}
            orientation={"portrait"}
            key={`${patient._id}`}
            style={styles.page}
            wrap={true}
          >
            {/* HEADER */}
            <View style={styles.title} fixed={true}>
              <Text style={styles.text.title}>{patient.name}</Text>
              <Text
                style={styles.text.warning}
                render={({ subPageNumber, subPageTotalPages }) =>
                  subPageTotalPages > 1
                    ? `${subPageNumber} / ${subPageTotalPages}`
                    : ""
                }
              />
              <View
                style={[
                  styles.timeLabelsContainer,
                  { width: `${patient.facility.localizedTimeCodes.length * 34}px` },
                ]}
              >
                {patient.facility.localizedTimeCodes.toReversed().map((range, index) => (
                  <Text
                    key={index}
                    style={[styles.timeLabel, { right: index * 35 - 40 }]}
                  >
                    {range.label[language]}
                  </Text>
                ))}
              </View>
            </View>

            {/* MEDICATION TABLE */}
            <View style={styles.table}>
              {patient.meds.map((med, i) => (
                <View key={i} style={styles.card} wrap={true}>
                  <View
                    wrap={false}
                    style={[
                      styles.leftContent,
                      { width: `${95 - patient.facility.localizedTimeCodes.length * 5}%` },
                    ]}
                  >
                    <View style={styles.header}>
                      <Text style={[styles.text.regular, styles.text.nowrap]}>
                        {controller.getDefaultName(med.product)}
                      </Text>
                      <Text style={[styles.text.regular, styles.text.nowrap]}>
                        {i18n.t("ndcLabel", {lng: language, ns: "medList"})} {med.product.packageId}
                      </Text>
                    </View>

                    <View style={[styles.row, { gap: 6 }]}>
                      <View style={[styles.section, { width: "45%" }]}>
                        <Text style={styles.text.subtitle}>{i18n.t("directionsLabel", {lng: language, ns: "medList"})}</Text>
                        <Text style={styles.text.large}>{med.instructions}</Text>
                      </View>

                      <View
                        style={[
                          styles.section,
                          {
                            width: med.image ? "45%" : "55%",
                            marginRight: med.image ? 0 : 5,
                          },
                        ]}
                      >
                        <View style={[styles.row, { height: 12 }]}>
                          <Text style={[styles.text.subtitle, { width: 23 }]}>
                            {i18n.t("rxLabel", {lng: language, ns: "medList"})}
                          </Text>
                          <Text style={[styles.text.description]}>
                            {med.rxNumber}
                          </Text>
                        </View>

                        <View style={[styles.row, { height: 12 }]}>
                          <Text style={[styles.text.subtitle, { width: 23 }]}>
                            {i18n.t("manufacturerLabel", {lng: language, ns: "medList"})}
                          </Text>
                          <Text style={[styles.text.description]}>
                            {med.product.manufacturer.fullName ||
                              med.product.manufacturer.abbrName ||
                              i18n.t("naLabel", {lng: language, ns: "medList"})}
                          </Text>
                        </View>

                        <View style={[styles.row, { height: 12 }]}>
                          <Text style={[styles.text.subtitle, { width: 23 }]}>
                            {i18n.t("descriptionLabel", {lng: language, ns: "medList"})}
                          </Text>
                          <Text style={styles.text.description}>
                            {med.product.comments || i18n.t("naLabel", {lng: language, ns: "medList"})}
                          </Text>
                        </View>
                      </View>

                      {med.image && (
                        <View style={[styles.imageContainer, { width: "10%" }]}>
                          <Image
                            src={`data:${med.image.mimeType};base64,${med.image.base64EncodedString}`}
                            style={styles.image}
                          />
                        </View>
                      )}
                    </View>
                  </View>

                  <View
                    wrap={false}
                    style={[
                      styles.timeBoxes,
                      { width: `${patient.facility.localizedTimeCodes.length * 34}px` },
                    ]}
                  >
                    {patient.facility.localizedTimeCodes.map((range, j) => (
                      <Text key={j} style={styles.timeBox}>
                        {med.adminLabels.includes(range.label[language]) ? med.qty : ""}
                      </Text>
                    ))}
                  </View> 
                </View>
              ))}
            </View>
          </Page>
        )}
      )}
    </Document>
  );
};

export default MedListsDocument;
