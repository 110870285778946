import ApiService from "./ApiService";
import axios from "axios";

class LabelService extends ApiService {
  static getLabels = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labels`;
      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getLabel = async (user, label) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/labels/${label._id}`,
        label,
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
  
  static getLabelPresets = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labelPresets`;
      let response = await axios.get(url, rc);
      return response.data.labelPresets;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static createLabel = async (
    user,
    token,
    name,
    labelType,
    machineType,  
    sheetWidth,  
    sheetHeight,
    labelWidth,
    labelHeight,
    rowCount,
    colCount,
    rowGap,
    colGap,
    leftMargin,
    topMargin,
    presetId,
    customizedLabel,
    unit,
    template,
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labels`;
      let response = await axios.post(url, {
        user: user,
        token: token,
        name: name,
        labelType: labelType,
        machineType: machineType,     
        sheetWidth: sheetWidth,  
        sheetHeight: sheetHeight,    
        labelWidth: labelWidth,
        labelHeight: labelHeight,
        rowCount: rowCount,
        colCount: colCount,
        rowGap: rowGap,
        colGap: colGap,
        leftMargin: leftMargin,
        topMargin: topMargin,
        presetId: presetId,
        customizedLabel: customizedLabel,
        unit: unit,
        template: JSON.stringify(template),
        rc: rc,
      });
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updateLabel = async (
    user,
    token,
    label,
    name,
    labelType,
    machineType,   
    sheetWidth,
    sheetHeight, 
    labelWidth,
    labelHeight,
    rowCount,
    colCount,
    rowGap,
    colGap,
    leftMargin,
    topMargin,
    presetId,
    customizedLabel,
    unit,
    template,
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labels/${label._id}`;

      label.template = template; 

      let response = await axios.put(url, {
        user: user,
        token: token,
        label: label,
        name: name,
        labelType: labelType,
        machineType: machineType,     
        sheetWidth: sheetWidth,
        sheetHeight: sheetHeight,     
        labelWidth: labelWidth,
        labelHeight: labelHeight,
        rowCount: rowCount,
        colCount: colCount,
        rowGap: rowGap,
        colGap: colGap,
        leftMargin: leftMargin,
        topMargin: topMargin,
        presetId: presetId,
        customizedLabel: customizedLabel,
        unit: unit,
        template: JSON.stringify(template),
        rc: rc,
      });
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static deleteLabel = async (user, label) => {
    try {
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labels/${label._id}`;

      const rc = {
        headers: { "x-access-token": user.token },
      };
      return await axios.delete(url, rc);
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static createLabelPreset = async (
    user,
    token,
    name,
    labelWidth,
    labelHeight,
    sheetWidth,  
    sheetHeight,
    rowCount,
    colCount,
    autoLayout,
    leftMargin,
    topMargin,
    rowGap,
    colGap,
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labelPresets`;
      let response = await axios.post(url, {
        user: user,
        token: token,
        name: name,  
        labelWidth: labelWidth,
        labelHeight: labelHeight,
        sheetWidth: sheetWidth,  
        sheetHeight: sheetHeight,    
        rowCount: rowCount,
        colCount: colCount,
        autoLayout: autoLayout,
        leftMargin: leftMargin,
        topMargin: topMargin,
        rowGap: rowGap,
        colGap: colGap,
        rc: rc,
      });

      return response.data.labelPreset;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  
  static updateLabelPreset = async (
    user,
    token,
    labelPresetId,
    name,
    labelWidth,
    labelHeight,
    sheetWidth,  
    sheetHeight,
    rowCount,
    colCount,
    autoLayout,
    leftMargin,
    topMargin,
    rowGap,
    colGap,
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labelPresets/${labelPresetId}`;

      let response = await axios.put(url, {
        user: user,
        token: token,
        name: name,  
        labelWidth: labelWidth,
        labelHeight: labelHeight,
        sheetWidth: sheetWidth,  
        sheetHeight: sheetHeight,    
        rowCount: rowCount,
        colCount: colCount,
        autoLayout: autoLayout,
        leftMargin: leftMargin,
        topMargin: topMargin,
        rowGap: rowGap,
        colGap: colGap,
        rc: rc,
      });
      return response.data.labelPreset;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
  
  static deleteLabelPreset = async (user, labelPresetId) => {
    try {
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labelPresets/${labelPresetId}`;

      const rc = {
        headers: { "x-access-token": user.token },
      };
      return await axios.delete(url, rc);
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default LabelService;
