import styles from './Properties.module.css';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Radio, Slider, Tooltip } from "antd";
import { ReactComponent as PlusSVG } from "../../../assets/plus.svg";
import { ReactComponent as MinusSVG } from "../../../assets/minus.svg";

const AreaProperties = ({ 
  zoom,
  setZoom,
  viewerMode,
  setViewerMode,
  unit,
  setUnit,
}) => {
  const { t } = useTranslation("labelEditor");

  const modes = [
    {
      value: "edit",
      label: <Tooltip 
        title={t("modeTooltip")} 
        color={"var(--green)"} 
        mouseLeaveDelay={0}
      >{t("editModeLabel")}</Tooltip>
    },    
    {
      value: "preview",
      label: <Tooltip 
        title={t("modeTooltip")} 
        color={"var(--green)"} 
        mouseLeaveDelay={0}
      >{t("previewModeLabel")}</Tooltip>
    }
  ]

  const units = [
    {
      value: "mm",
      label: <Tooltip 
        title={t("unitTooltip")} 
        color={"var(--green)"} 
        mouseLeaveDelay={0}
      >mm</Tooltip>
    },
    {
      value: "in",
      label: <Tooltip 
        title={t("unitTooltip")} 
        color={"var(--green)"} 
        mouseLeaveDelay={0}
      >{t("inLabel")}</Tooltip>
    }
  ];

  const handleUnitChange = (e) => {
    setUnit(e.target.value);
  };
  
  const handleModeChange = (e) => {
    if (e.target.value === "edit") {
      setViewerMode(false)
    } else {
      setViewerMode(true)
    }
  };

  const handleZoomPlus = (e) => {
    setZoom((prev) => prev > 290 ? 300 : prev + 10)
  }

  const handleZoomMinus = (e) => {
    setZoom((prev) => prev < 10 ? 0 : prev - 10)
  }


  return (
    <div className={styles.Properties__areaRow}>
      <div className={styles.Properties__propertyArea} style={{width: "100px"}}>    
        <div 
          onClick={handleZoomMinus} 
          className={`
            ${styles.Properties__clickable} 
            ${styles.Properties__row}
          `}
        >
          <Tooltip 
            title={t("zoomOutTooltip")} 
            color={"var(--green)"} 
            mouseLeaveDelay={0}
          >
            <MinusSVG 
              width={18}
              height={18}
              color={"var(--green)"}
            />
          </Tooltip>
        </div>
        <Slider
          min={10}
          max={300}
          value={zoom}
          onChange={setZoom}
          style={{ width: '100%' }}
          tooltip={{
            color: "var(--green)",
            formatter: (value) => `${value}%`
          }}
          styles={{
            track: {
              background: 'var(--green)',
            },
          }}
        />
        <div 
          onClick={handleZoomPlus} 
          className={`
            ${styles.Properties__clickable} 
            ${styles.Properties__row}
          `}
        >
          <Tooltip 
            title={t("zoomInTooltip")} 
            color={"var(--green)"} 
            mouseLeaveDelay={0}
          >
            <PlusSVG 
              width={18}
              height={18}
              color={"var(--green)"}
            />
          </Tooltip>
        </div>
      </div>
      

      <div className={styles.Properties__propertyArea}>
        <ConfigProvider
          theme={{
            components: {
              Radio: {
                colorPrimary: "var(--green)",
                colorPrimaryHover: "var(--green)",
              },
            },
          }}
        >
          <Radio.Group
            block
            options={modes}
            defaultValue={viewerMode ? "preview" : "edit"}
            optionType="button"
            buttonStyle="solid"
            onChange={handleModeChange}
          />
        </ConfigProvider>
      </div>  

      <div className={styles.Properties__propertyArea}>
        <ConfigProvider
          theme={{
            components: {
              Radio: {
                colorPrimary: "var(--green)",
                colorPrimaryHover: "var(--green)",
              },
            },
          }}
        >
          <Radio.Group
            block
            options={units}
            value={unit}
            optionType="button"
            buttonStyle="solid"
            onChange={handleUnitChange}
          />
        </ConfigProvider>
      </div>   
    </div>
  );
};

export default AreaProperties;
