import LabelService from "../../services/LabelService";

class LabelController {
  constructor(user, site) {
    this.user = user;
    this.site = site;
  }

  getLabels = async () => {
    let results = undefined;
    try {
      results = await LabelService.getLabels(this.user);
    } catch (err) {
      console.log("err: ", err);
    }
    return results;
  };

  getLabel = async (label) => {
    let results = undefined;
    try {
      results = await LabelService.getLabel(this.user, label);
    } catch (err) {
      console.log(err);
    }
    return results;
  };

  getLabelPresets = async () => {
    let results = undefined;
    try {
      results = await LabelService.getLabelPresets(this.user);
    } catch (err) {
      console.log("err: ", err);
    }
    return results;
  };

  createLabel = async (
    name,
    labelType,
    machineType,   
    sheetWidth,
    sheetHeight, 
    labelWidth,
    labelHeight,
    rowCount,
    colCount,    
    rowGap,
    colGap,
    leftMargin,
    topMargin,
    presetId,
    customizedLabel,
    unit,
    template,
  ) => {
    let results = undefined;
    try {
      results = await LabelService.createLabel(
        this.user,
        this.user.token,
        name,
        labelType,
        machineType,
        sheetWidth,
        sheetHeight, 
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        rowGap,
        colGap,
        leftMargin,
        topMargin,
        presetId,
        customizedLabel,
        unit,
        template,
      );
    } catch (err) {
      console.log(err);
    }
    return results;
  };

  updateLabel = async (
    label,
    name,
    labelType,
    machineType,
    sheetWidth,
    sheetHeight, 
    labelWidth,
    labelHeight,
    rowCount,
    colCount,
    rowGap,
    colGap,
    leftMargin,
    topMargin,
    presetId,
    customizedLabel,
    unit,
    template,
  ) => {
    let results = undefined;
    try {
      results = await LabelService.updateLabel(
        this.user,
        this.user.token,
        label,
        name,
        labelType,
        machineType,
        sheetWidth,
        sheetHeight, 
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        rowGap,
        colGap,
        leftMargin,
        topMargin,
        presetId,
        customizedLabel,
        unit,
        template,
      );
    } catch (err) {
      console.log(err);
    }
    return results;
  };

  deleteLabel = async (label) => {
    let results = undefined;
    try {
      results = await LabelService.deleteLabel(this.user, label);
    } catch (err) {
      console.log(err);
    }
    return results;
  };

  createLabelPreset = async (
    name,
    labelWidth,
    labelHeight,
    sheetWidth,
    sheetHeight, 
    rowCount,
    colCount,    
    autoLayout,
    leftMargin,
    topMargin,
    rowGap,
    colGap,
  ) => {
    let result = undefined;
    try {
      result = await LabelService.createLabelPreset(
        this.user,
        this.user.token,
        name,
        labelWidth,
        labelHeight,
        sheetWidth,
        sheetHeight, 
        rowCount,
        colCount,    
        autoLayout,
        leftMargin,
        topMargin,
        rowGap,
        colGap,
      );
    } catch (err) {
      console.log(err);
    }
    return result;
  };

  
  updateLabelPreset = async (
    labelPresetId,
    name,
    labelWidth,
    labelHeight,
    sheetWidth,
    sheetHeight, 
    rowCount,
    colCount,    
    autoLayout,
    leftMargin,
    topMargin,
    rowGap,
    colGap,
  ) => {
    let result = undefined;
    try {
      result = await LabelService.updateLabelPreset(
        this.user,
        this.user.token,
        labelPresetId,
        name,
        labelWidth,
        labelHeight,
        sheetWidth,
        sheetHeight, 
        rowCount,
        colCount,    
        autoLayout,
        leftMargin,
        topMargin,
        rowGap,
        colGap,
      );
    } catch (err) {
      console.log(err);
    }
    return result;
  };
  
  deleteLabelPreset = async (labelPresetId) => {
    let results = undefined;
    try {
      results = await LabelService.deleteLabelPreset(this.user, labelPresetId);
    } catch (err) {
      console.log(err);
    }
    return results;
  };
}

export default LabelController;
