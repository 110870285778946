import styles from "./Properties.module.css";
import { useTranslation } from "react-i18next";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import Input from "./Input";
import Checkbox from "../../../components/Checkbox";

const RectProperties = ({ unit, selectedObject, updateObject }) => {
  const { t, i18n } = useTranslation("labelEditor");

  const handleInputChange = (value, name) => {
    updateObject({
      ...selectedObject,
      [name]: value,
    });
  };

  const handleColorChange = (args, name) => {
    updateObject({
      ...selectedObject,
      [name]: args.currentValue.hex,
      ...(name === "fill" ? {fillColor: args.currentValue.hex} : {})
    });
  };

  const handleBackgroundToggle = (val) => {
    if (!val) {
      updateObject({
        ...selectedObject,
        fill: "#ffffff00",
      });
    } else {
      updateObject({
        ...selectedObject,
        fill: selectedObject.fillColor,
      });
    }
  }

  const handleCornersToggle = (val) => {
    updateObject({
      ...selectedObject,
      rounded: val,
    });
  }

  return (
    <div className={styles.Properties__container}>
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("widthLabel")}
          </label>
          <Input
            value={selectedObject.width}
            name="width"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            step={0.1}
            min={0.1}
            disabled={selectedObject.locked}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("heightLabel")}
          </label>
          <Input
            value={selectedObject.height}
            name="height"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            step={0.1}
            min={0.1}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>X</label>
          <Input
            value={selectedObject.x}
            name="x"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            step={0.1}
            disabled={selectedObject.locked || selectedObject?.parent?.type === "gridLayout"}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>Y</label>
          <Input
            value={selectedObject.y}
            name="y"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            step={0.1}
            disabled={selectedObject.locked || selectedObject?.parent?.type === "gridLayout"}
          />
        </div>
      </div>
      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("borderWidthLabel")}
          </label>
          <Input
            value={selectedObject.strokeWidth}
            name="strokeWidth"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            step={0.1}
            min={0}
          />
        </div>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("borderColorLabel")}
          </label>
          <ColorPickerComponent
            id={"stroke-color-picker"}
            name="stroke"
            value={selectedObject.stroke}
            defaultValue={selectedObject.stroke}
            // mode="Palette"
            // noColor={false}
            showButtons={true}
            modeSwitcher={true}
            change={(args) => handleColorChange(args, "stroke")}
            locale={i18n.language}
            disabled={selectedObject.locked}
          />
        </div>
      </div>

      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
              {t("backgroundLabel")}
            </label>
          <div className={`
            ${styles.Properties__toggle}
            ${styles.half}
          `}>
            <Checkbox
              labelName={t("fillLabel")}
              labelColor={"black"}
              isOn={selectedObject.fill !== "#ffffff00"}
              onCheck={handleBackgroundToggle}
              disabled={selectedObject.locked}
            />
          </div>
        </div>

        {selectedObject.fill !== "#ffffff00" && <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("backgroundColorLabel")}
          </label>
          <ColorPickerComponent
            id={"fill-color-picker"}
            name="fill"
            value={selectedObject.fillColor}
            defaultValue={selectedObject.fillColor}
            // mode="Palette"
            // noColor={false}
            showButtons={true}
            modeSwitcher={true}
            change={(args) => handleColorChange(args, "fill")}
            locale={i18n.language}
            disabled={selectedObject.locked}
          />
        </div>}
      </div>


      <div className={styles.Properties__spacedRow}>
        <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
              {t("cornersLabel")}
            </label>
          <div className={`
            ${styles.Properties__toggle}
            ${styles.half}
          `}>
            <Checkbox
              labelName={t("roundedLabel")}
              labelColor={"black"}
              isOn={selectedObject.rounded}
              onCheck={handleCornersToggle}
              disabled={selectedObject.locked}
            />
          </div>
        </div>

        {selectedObject.rounded && <div className={styles.Properties__property}>
          <label className={styles.Properties__label}>
            {t("cornerRadiusLabel")}
          </label>
          <Input
            value={selectedObject.cornerRadius}
            name="cornerRadius"
            type="number"
            unit={unit}
            onChange={handleInputChange}
            disabled={selectedObject.locked}
            step={0.1}
            min={0}
          />
        </div>}
      </div>
    </div>
  );
};

export default RectProperties;
