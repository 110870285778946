import { Col, Row, Spin, Switch, message, Space, Checkbox, Button, Layout, Modal } from "antd";

import React, {useContext, useEffect, useState} from "react";
import AccountService from "../../services/AccountService";
import "./AccountSettings.module.css";
import RoleCheckService from "../../services/RoleCheckService";
import { useTranslation } from "react-i18next";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const { Footer, Content } = Layout;

const AccountSettings = ({ user, site }) => {
  const { t } = useTranslation("accountSettings");


  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [productApprovalRequired, setProductApprovalRequired] = useState(
    user.account.productApprovalRequired
  );
  const [autoUpdateFdb, setAutoUpdateFdb] = useState(
      false
  );
  const [updatePackageId, setUpdatePackageId] = useState(
      false
  );
  const [updateDescription, setUpdateDescription] = useState(
      false
  );
  const [updateManufacturerName, setUpdateManufacturerName] = useState(
      false
  );
  const [updateSubstitutionCode, setUpdateSubstitutionCode] = useState(
      false
  );
  const [updatePackageInfo, setUpdatePackageInfo] = useState(
      false
  );
  const [updateImages, setUpdateImages] = useState(
      false
  );

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const saveSettings = async () => {
    try {
      user.account.productApprovalRequired = productApprovalRequired;
      user.account.fdbSettings.autoUpdate = autoUpdateFdb;
      user.account.fdbSettings.updatePackageId = updatePackageId;
      user.account.fdbSettings.updateDescription = updateDescription;
      user.account.fdbSettings.updateManufacturerName = updateManufacturerName;
      user.account.fdbSettings.updateSubstitutionCode = updateSubstitutionCode;
      user.account.fdbSettings.updatePackageInfo = updatePackageInfo;
      user.account.fdbSettings.updateImages = updateImages;
      setLoading(true);
      await AccountService.put(user, user.account);
      message.success(t("updateSuccessWarning"));
    } catch (error) {
      console.error(error);
      message.error(t("updateFailWarning"));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(!user.account.fdbSettings){
      user.account.fdbSettings = {
        autoUpdate: false,
        updatePackageId: false,
        updateDescription: false,
        updateManufacturerName: false,
        updateSubstitutionCode: false,
        updatePackageInfo: false,
        updateImages: false
      }
    } else {
      setAutoUpdateFdb(user.account.fdbSettings.autoUpdate);
      setUpdatePackageId(user.account.fdbSettings.updatePackageId);
      setUpdateDescription(user.account.fdbSettings.updateDescription);
      setUpdateManufacturerName(user.account.fdbSettings.updateManufacturerName);
      setUpdateSubstitutionCode(user.account.fdbSettings.updateSubstitutionCode);
      setUpdatePackageInfo(user.account.fdbSettings.updatePackageInfo);
      setUpdateImages(user.account.fdbSettings.updateImages);
    }
  }, [user]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await saveSettings();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Layout>
        <Content style={{background: 'white'}}>
          <Space direction="vertical" size="large" style={{display: 'flex'}}>
            {!smScreen && <Row style={{ height: "20px" }} ></Row>}
            {loading &&
            <Row style={{ height: "70px" }} >
              <Col span={24}>{loading && <Spin size="large" />}</Col>
            </Row>
            }
            <Row gutter={20}>
              <Col>
                <span>{t("approvalLabel")}</span>
              </Col>
              <Col>
                <Switch
                  checkedChildren={t("onLabel")}
                  unCheckedChildren={t("offLabel")}
                  checked={productApprovalRequired}
                  onChange={(checked)=>{setProductApprovalRequired(checked)}}
                  disabled={loading || !RoleCheckService.canManageMedicationSettings(user)}
                />
              </Col>
            </Row>
            <Row gutter={20} >
              <Col>
                <span>{t("fdbAutoUpdateLabel")}</span>
              </Col>
              <Col>
                <Switch
                    checkedChildren={t("onLabel")}
                    unCheckedChildren={t("offLabel")}
                    checked={autoUpdateFdb}
                    onChange={(checked)=>{setAutoUpdateFdb(checked)}}
                    disabled={loading || !RoleCheckService.canManageMedicationSettings(user)}
                />
              </Col>
            </Row>
            <Row gutter={20} >
              <Col>
                <span>{t("fdbAutoInfoLabel")}</span>
              </Col>
            </Row>
            <Row gutter={20} >
              <Col>
                <Checkbox
                    checked={updatePackageId}
                    onChange={(e)=>{setUpdatePackageId(e.target.checked);}}
                    disabled={loading || !RoleCheckService.canManageMedicationSettings(user)}
                />
              </Col>
              <Col>
                <span>{t("packageIdLabel")}</span>
              </Col>
            </Row>
            <Row gutter={20} >
              <Col>
                <Checkbox
                    checked={updateDescription}
                    onChange={(e)=>{setUpdateDescription(e.target.checked);}}
                    disabled={loading || !RoleCheckService.canManageMedicationSettings(user)}
                />
              </Col>
              <Col>
                <span>{t("descriptionLabel")}</span>
              </Col>
            </Row>
            <Row gutter={20} >
              <Col>
                <Checkbox
                    checked={updateManufacturerName}
                    onChange={(e)=>{setUpdateManufacturerName(e.target.checked);}}
                    disabled={loading || !RoleCheckService.canManageMedicationSettings(user)}
                />
              </Col>
              <Col>
                <span>{t("manufacturerLabel")}</span>
              </Col>
            </Row>
            <Row gutter={20} >
              <Col>
                <Checkbox
                    checked={updateSubstitutionCode}
                    onChange={(e)=>{setUpdateSubstitutionCode(e.target.checked);}}
                    disabled={loading || !RoleCheckService.canManageMedicationSettings(user)}
                />
              </Col>
              <Col>
                <span>{t("gcnLabel")}</span>
              </Col>
            </Row>
            <Row gutter={20} >
              <Col>
                <Checkbox
                    checked={updatePackageInfo}
                    onChange={(e)=>{setUpdatePackageInfo(e.target.checked);}}
                    disabled={loading || !RoleCheckService.canManageMedicationSettings(user)}
                />
              </Col>
              <Col>
                <span>{t("packageLabel")}</span>
              </Col>
            </Row>
            <Row style={{ height: "20px" }}>
            </Row>
            <Row gutter={20} >
              <Col>
                <span>{t("fdbImagePullLabel")}</span>
              </Col>
            </Row>
            <Row gutter={20} >
              <Col>
                <Checkbox
                    checked={updateImages}
                    onChange={(e)=>{setUpdateImages(e.target.checked);}}
                    disabled={loading || !RoleCheckService.canManageMedicationSettings(user)}
                />
              </Col>
              <Col>
                <span>{t("imageLabel")}</span>
              </Col>
            </Row>
          </Space>
        </Content>
        <Footer style={{background: 'white', textAlign:'center'}}>
          <Button type='primary' onClick={showModal} disabled={!RoleCheckService.canManageMedicationSettings(user)}>{t("saveButtonLabel")}</Button>
        </Footer>
      </Layout>
      <Modal title={t("saveTitle")} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText={t("okLabel")} cancelText={t("cancelButton")} >
        <p>{t("saveConfirmation")}</p>
      </Modal>
    </>
  );
};

export default AccountSettings;
