import React, { useEffect } from "react";
// import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import metadata from "libphonenumber-js/metadata.min.json";

const TextInput = ({
  labelName,
  value,
  isEnabled,
  type,
  placeholder,
  onChange,
  focus,
  validationText,
  onEnter,
  id,
  autoComplete,
  countryCode="US",
  min,
}) => {
  const handleKeyUpEvent = (e) => {
    if (onEnter && e.key === "Enter") {
      onEnter();
    }
  };
  useEffect(() => {
    window.addEventListener("keyup", handleKeyUpEvent);

    return () => {
      window.removeEventListener("keyup", handleKeyUpEvent);
    };
  }, [handleKeyUpEvent]);

  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <div className={"controlGroup"}>
      <label className={"controlLabel"}>{labelName}</label>
      {type === "tel" ? (
        <Input
          className={"controlInput"}
          placeholder={placeholder}
          metadata={metadata}
          value={value}
          defaultCountry={countryCode}
          onChange={(value) => {
            onChange(value);
          }}
        />
      ) : id ? (
        <input
          id={id}
          className={"controlInput"}
          type={type}
          placeholder={placeholder}
          disabled={!isEnabled}
          value={value}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
          autoFocus={focus}
          onFocus={handleFocus}
          min={min}
        />
      ) : (
        <input
          className={"controlInput"}
          type={type}
          placeholder={placeholder}
          disabled={!isEnabled}
          value={value}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
          autoFocus={focus}
          onFocus={handleFocus}
          autoComplete={autoComplete || "on"}
          min={min}
        />
      )}
      <div className={"controlBorder"} />
      {validationText && (
        <label className={"controlValidationLabel"}>{validationText}</label>
      )}
    </div>
  );
};

export default TextInput;
