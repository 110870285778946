import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import Button from "../../../components/Button";
import ErrorBanner from "../../../components/ErrorBanner";
import Spacer from "../../../components/Spacer";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { deepCopy } from "../../../functions/utilities";
import styles from "./ChangePatientLanguage.module.css";
import ConfigService from "../../../services/ConfigService";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";

const ChangePatientLanguage = ({  
  pendDisOrderControllerRef,
  selectedItem,
  onCloseChangePatientLanguageModal,
  dispensingOrder,
}) => {
  const { t } = useTranslation("pendingDispensingOrder");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const facilityLanguage = selectedItem.facility.defaultLanguage || "en";
  const patientLanguage = selectedItem.patientInfo.language || "N/A"

  const [languageList, setLanguageList] = useState([])
  const [language, setLanguage] = useState(selectedItem.patientInfo.language || "en");

  const [userError, setUserError] = useState("");
  
  useEffect(() => {
    ConfigService.languages()
      .then((languages) => {
        setLanguageList(
          languages
            .map((language) => ({
              ...language,
              displayName: t(language.type, {ns: "languages"})
            }))
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
        );
      })
      .catch((error) => setUserError(error));
  }, []);

  const handleLanguageUpdate = (listItem) => {
    setLanguage(listItem.type);
  };

  const handleSubmit = () => {
    pendDisOrderControllerRef.updatePatientLanguage(
      dispensingOrder,
      selectedItem.patientInfo.id,
      language,
    );
    onCloseChangePatientLanguageModal(true);
  };

  const goBackHandler = () => {
    onCloseChangePatientLanguageModal(false);
  };

  return (
    <div className={styles.ChangePatientLanguage__overlay}>
      <div className={styles.ChangePatientLanguage__view}>
        <div className={styles.ChangePatientLanguage__container}>
          <div className={styles.ChangePatientLanguage__formCloseContainer}>
            {!smScreen && (
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => onCloseChangePatientLanguageModal(false)}
              />
            )}
          </div>

          {smScreen && (
            <section className={styles.ChangePatientLanguage__header}>
              <div
                className={styles.ChangePatientLanguage__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.ChangePatientLanguage__backArrowIcon}
                />
                <p className={styles.ChangePatientLanguage__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <h1 className={styles.ChangePatientLanguage__title}>
                {t("ChangePatientLanguageLabel")}
              </h1>
            </section>
          )}

          {!smScreen && (
            <section className={styles.ChangePatientLanguage__header}>
              <p className={styles.ChangePatientLanguage__title}>
                {t("ChangePatientLanguageLabel")}
              </p>
            </section>
          )}

          {userError && (
            <div className={styles.ChangePatientLanguage__errorBannerContainer}>
              <ErrorBanner
                message={userError}
                onClose={() => setUserError("")}
              />
            </div>
          )}

          <section
            className={styles.ChangePatientLanguage__productSelectionContainer}
          >
            <table className={styles.ChangePatientLanguage__table}>
              <tr>
                <td className={styles.ChangePatientLanguage__label}>{`${t("dispensingOrderLabel")}: `}</td>  
                <td className={styles.ChangePatientLanguage__value}>{dispensingOrder.dispensingOrderId}</td>
              </tr>
              <tr>
                <td className={styles.ChangePatientLanguage__label}>{`${t("patientIdLabel")}: `}</td>  
                <td className={styles.ChangePatientLanguage__value}>{selectedItem.patientInfo.id}</td>
              </tr>
              <tr>
                <td className={styles.ChangePatientLanguage__label}>{`${t("facilityLanguageLabel")}: `}</td>
                <td className={styles.ChangePatientLanguage__value}>{t(facilityLanguage, {ns: "languages"})}</td>
              </tr>
              <tr>
                <td className={styles.ChangePatientLanguage__label}>{`${t("originalPatientLanguageLabel")}: `}</td>
                <td className={styles.ChangePatientLanguage__value}>{t(patientLanguage, {ns: "languages"})}</td>
              </tr>
            </table>    
          </section>

          <section
            className={styles.ChangePatientLanguage__quantityInputSection}
          >
            <div
              className={styles.ChangePatientLanguage__quantityInputContainer}
            >                
              <p className={styles.ChangePatientLanguage__current}>
                {`${t("patientLanguageInputLabel")} `}
              </p>
              <DropdownInput
                value={
                  language 
                    ? t(language, {ns: "languages"}) 
                    : ""
                }
                labelName={""}
                validationText={""}
                isEnabled={true}
                placeholder={""}
                onSelection={handleLanguageUpdate}
                listElements={languageList}
                display={"displayName"}
              />
            </div>
          </section>

          <section className={styles.ChangePatientLanguage__footer}>
            <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={() => onCloseChangePatientLanguageModal(false)}
              minWidth={smScreen ? 122 : null}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              labelName={t("changeLanguageButtonLabel")}
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
              minWidth={smScreen ? 122 : 213}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default ChangePatientLanguage;
