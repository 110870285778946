export const calculateCorners = (obj) => {  
  const rotation = obj?.rotation ? obj.rotation * Math.PI / 180 : 0;

  const cos = Math.cos(rotation);
  const sin = Math.sin(rotation);

  const corner1 = {
    x: obj.x,
    y: obj.y,
  };

  const corner2 = {
    x: obj.x + obj.width * cos,
    y: obj.y + obj.width * sin,
  };

  const corner3 = {
    x: obj.x + obj.width * cos - obj.height * sin,
    y: obj.y + obj.width * sin + obj.height * cos,
  };

  const corner4 = {
    x: obj.x - obj.height * sin,
    y: obj.y + obj.height * cos,
  };

  return [corner1, corner2, corner3, corner4];
}


export const calculateBoundingBox = (objects) => {
  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
  objects.forEach(o => {    
    const corners = calculateCorners(o)
    corners.forEach(corner => {
      minX = Math.min(minX, corner.x);
      minY = Math.min(minY, corner.y);
      maxX = Math.max(maxX, corner.x);
      maxY = Math.max(maxY, corner.y);
    });
    console.log("corners", corners)
  })

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY, 
  }
}